import { REVERT_TO_LAST_UPDATE_ERROR } from './constants'
const initialState = {
  isFetching: false,
  error: false,
}

const revertToLastUpdateReducer = (state = initialState, { type, error }) => {
  switch (type) {
    case REVERT_TO_LAST_UPDATE_ERROR:
      return { ...state, error: error }
    default:
      return state
  }
}

export default revertToLastUpdateReducer
