import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      height: '9.5vh',
      boxShadow: '0 2px 10px 0 rgba(211, 211, 211, 0.5)',
      position: 'fixed',
      top: '7.7vh',
      left: 0,
      zIndex: 993,
      width: '100%',
      backgroundColor: '#fff',
    },
    logo: {
      textAlign: 'left',
      paddingLeft: '1.8vw',
      fontSize: '1.8vh',
      fontWeight: 900,
    },
    stepsWrapper: {
      height: '100%',
    },
    steps: {
      backgroundColor: '#dbe8f9',
      height: '100%',
      lineHeight: '10vh',
      justifyContent: 'center',
      textAlign: 'center',
      fontSize: '1.9vh',
      fontWeight: 400,
      color: '#999999',
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:first-child': {
        backgroundColor: 'rgba(219, 232, 249,0.2)',
      },
      '&:nth-child(2)': {
        backgroundColor: 'rgba(219, 232, 249,0.4)',
      },
      '&:nth-child(3)': {
        backgroundColor: 'rgba(219, 232, 249,0.6)',
      },
      '&:nth-child(4)': {
        backgroundColor: 'rgba(219, 232, 249,0.8)',
      },
    },
    stepsItemActive: {
      color: '#f28165',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '0.6vh',
        backgroundColor: '#f28165',
        left: 0,
        bottom: 0,
      },
    },
    disabledinput: {
      '& div:before': {
        borderBottom: 'none !important',
      },
    },
    surveynameEditbtn: {
      marginRight: '0.6vw',
      '&:focus': {
        outline: 'none',
      },
    },
    surveynameEditbtnDisabled: {
      marginRight: '0.6vw',
      '&:focus': {
        outline: 'none',
      },
      opacity: 0.3,
      pointerEvents: 'none',
    },
    surveynamefield: {
      width: '100%',
    },
    changestatusctrl: {
      width: '80%',
      textAlign: 'right',
      color: '#999',
    },
    changebtn: {
      color: '#4b93fd',
      textTransform: 'Capitalize',
      marginLeft: 5,
      fontSize: '1.6vh',
    },
    statusMenu: {
      position: 'absolute',
      right: 0,
      backgroundColor: '#fff',
      boxShadow: '0 3px 5px #ccc',
      minWidth: 100,
    },
    comingtxt: {
      position: 'absolute',
      bottom: '1.5vh',
      lineHeight: 'normal',
      fontSize: '1.5vh',
    },
    pointerEventsNone: {
      pointerEvents: 'none',
    },
    statusWarningContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& > img:first-child': {
        width: '8vw',
        marginRight: '2vw',
        alignSelf: 'flex-start',
        height: '14vh',
      },

      '& > div:nth-child(2)': {
        display: 'flex',
        flexDirection: 'column',

        '& > span:first-child': {
          fontSize: '2.5vh',
        },

        '& > span:nth-child(2)': {
          fontSize: '2.5vh',

          '& > span:first-child': {
            fontWeight: 'bold',
          },
        },

        '& > div:nth-child(3)': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginTop: '3vh',
          width: '18vw',
        },
      },
    },
  }
})

export default styles
