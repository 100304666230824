export const SITE_EMAIL = `OHI-Helpdesk@mckinsey.com`
export const LINK_MONITOR =
  'https://reportal.intranet.mckinsey.com/sso/ohi/Reportal.aspx?PortalId=228'
export const LINK_ANALYZE =
  'https://reportal.intranet.mckinsey.com/sso/ohi/Reportal.aspx?PortalId=228'
export const LINK_ANALYZE_MONITOR = `${process.env.REACT_APP_MONITOR_ANALYZE_URL}?#jwt=__MID_TOKEN_`

export const ENV_VARS = process.env
export const ADDITIONAL_QUESTION_SUBTITLE_ANCHOR =
  'https://mckinsey.box.com/s/8kb6h4vig2dh4gp6932evi9nxs6ue84w'
export const DEFAULT_LOADER_TEXT = 'Please wait....'
export const survey_Status_For_Disabling_Post_Req = [
  'Closed',
  'Live',
  'Deleted',
]
export const BASE_LANGUAGE_TOOLTIP_ANCHOR =
  'https://mckinsey.box.com/s/h2os2xchfpbgwf6yn33ydl2aqo87lltg'
export const UPDATE_SURVEY_TEXT =
  'Please wait while we update your survey text – this action is being\n' +
  '              performed behind the scenes and could take up to 3 minutes. Thank\n' +
  '              you!'
export const AGREEMENT_INFO_CHECKLIST = [
  'I have verified all the information above is correct',
  'All sources of personal data are deleted following OS Data Protocols (e.g., there is no raw/personal data on Repo A or B)',
  'All final deliverables are stored on Box using the proper filing system and naming convention',
]
export const ALLOWED_FILE_TYPES = ['.xls', '.xlsx', '.xlsm']

export const SUPER_USER_EMAIL = [
  'Eleni_Kursten@mckinsey.com',
  'Arun_Kumar_Singh@mckinsey.com',
]

export const ORGSCI_OPTIONS = [
  {
    id: 1,
    name: 'No, only standard practices were used',
  },
  {
    id: 2,
    name: 'Removed questions',
  },
  {
    id: 3,
    name: 'Major wording changes to the questions (non-OrgSci approved)',
  },
  {
    id: 4,
    name:
      'Non-representative sample (e.g. all leaders + percentage of frontline workers)',
  },
  {
    id: 5,
    name: 'Weighted responses',
  },
  {
    id: 6,
    name: 'More than one of the above',
  },
  {
    id: 7,
    name: 'Other (please specify)',
  },
]

export const SUBMIT_HELPDESK_MSG =
  "In order to 'Submit to Helpdesk' please check your launch date - the launch date must be at least 3 working days from today to give time for the additional changes, testing, and QA process. This is not the 24 hour freeze, please still have all changes complete 24 hours prior to launch."

export const MASK_CLIENT_NAME_MSG =
  "If the client has requested for their company's name not to be shared in the benchmarking database, please select “Mask client name”. If they have not made such a request, please select “Client name visible”"

export const SURVEY_ADMIN_MSG =
  'You have selected a backcoded and generic link survey, when you land of the demo upload page you will see the option to upload your backcoded demos only.  Please complete this and have the Helpdesk complete the generic link portion.  Alternatively, please submit the survey to the Helpdesk early by selecting the button on the demo page “Demos too complex” and have them complete everything!  Thank you!'

export const SURVEY_MARKED_TEST =
  'Your survey is still marked as a ‘Test’ survey.  Please go back to the Client Details page, enter in your opportunity name and deselect ‘Test’.  You cannot proceed with launch until this is done.  Thank you!'

export const WORKSHOPS_CONDUCTED_INFO_CHECKLIST = [
  { key: 'excoResultsDiscussion', name: 'Exco results discussion' },
  { key: 'buReadouts', name: 'BU readouts' },
  { key: 'mirrorWorkshop', name: 'Mirror workshop' },
  { key: 'actionPlanningSessions', name: 'Action planning sessions' },
  { key: 'interviewsFocusGroups', name: 'Interviews / focus groups' },
  { key: 'other', name: 'Other (please specify)' },
  { key: 'other_Text', name: 'Your Text Here' },
]

export const BENCHMARK_INFO_CHECKLIST = [
  {
    key: 'legalAgreements',
    name:
      'Legal agreements do not prohibit the inclusion of survey results in benchmarking database',
  },
  {
    key: 'surveyInclude',
    name: 'Survey included all Outcomes and Practices questions',
  },
  {
    key: 'coreOHIQuestions',
    name: 'Meaning of the core OHI questions was not changed',
  },
  {
    key: 'surveyAllEmployees',
    name:
      'The survey included all employees from the organization or was based on the proper sampling (e.g., it is not leaders only survey)',
  },
]

export const CLIENT_RECIPE_INFO = [
  {
    name: 'leadershipFactory',
    value: 'Leadership factory',
  },
  {
    name: 'marketShaper',
    value: 'Market shaper',
  },
  {
    name: 'executionEdge',
    value: 'Execution edge',
  },
  {
    name: 'talentKnowledge',
    value: 'Talent & knowledge core',
  },
  {
    name: 'clientiDontKnow',
    value: 'Client have not selected any Recipe',
  },
  {
    name: 'notSelectAnyRecipe',
    value: "I don't know",
  },
]
export const PRIORITY_PRACTICES_INFO = [
  {
    name: 'selectPriorityPractices',
    value: 'Click here to select priority practices',
  },
  {
    name: 'notselectPriorityPractices',
    value: 'Client have not selected any priority practices',
  },
  {
    name: 'priorityiDontKnow',
    value: "I don't know",
  },
]

export const PRIORITY_PRACTICES_INFO_OPTIONS = [
  'Risk Management',
  'Strategic Clarity',
  'Inspirational Leaders',
]

export const OHI_4_SECTIONS = 'ohi 4.0 sections'
export const Optional_Sections = 'optional questions'
export const Intent_Leave =
  '(question conditionally displayed if respondent selects “agree” or “strongly agree” to the Intent to Leave question in core Employee Experience section of OHI 4.0)'
// export const Emp_Exp_Mandate =
//   'Please provide a reason for skipping "Employee Experience" section questions'
export const Emp_exp_mandate_text =
  'It is mandatory to keep "Employee Experience" under OHI 4.0 sections in order to continue'
// export const Title_exp = ' Skip adding "Employee Experience" section'
export const Emp_Exp_Mandate = key => {
  return `Please provide a reason for skipping "${key}" section questions`
}
export const Title_exp = key => {
  return `Skip adding "${key}" section`
}
