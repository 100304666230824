import { Box, makeStyles, Modal } from '@material-ui/core'
import React, { useState } from 'react'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement'
function PopupModal(props) {
  function getModalStyle() {
    const top = 50

    return {
      width: `${props.modalWidth ? props.modalWidth : '35.7vw'}`,
      top: `${props.top ? props.top : top}%`,
      left: `${props.left ? props.left : top}%`,
      transform: `translate(-${top}%, -${top}%)`,
      height: `${props.modalHeight ? props.modalHeight : 'auto'}`,
      boxShadow: props.background
        ? '0 10px 21px 6px rgba(75, 147, 253, 0.1)'
        : '',
        borderRadius: props.radius?props.radius:0
    }
  }

  const useModalStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: '35.7vw',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: '3.8vh',
      outline: 'none',
    },
  }))

  const [modalStyle] = useState(getModalStyle)
  const modalClasses = useModalStyles()
  const [open, setOpen] = useState(true)

  return (
    <Box>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        className={props.background ? 'demographic_modal' : ''}
      >
        <div style={modalStyle} className={`${modalClasses.paper} ${props.mdlclass?props.mdlclass:''}`}>
          {props.children}
        </div>
      </Modal>
    </Box>
  )
}

export default PopupModal
