import { REVERT_TO_LAST_UPDATE, REVERT_TO_LAST_UPDATE_ERROR } from './constants'

export const revertToLastUpdate = surveyId => {
  return {
    type: REVERT_TO_LAST_UPDATE,
    surveyId,
  }
}

export const revertToLastError = error => {
  return {
    type: REVERT_TO_LAST_UPDATE_ERROR,
    error,
  }
}
