import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Slide,
  Select,
  MenuItem,
  FormControl,
  Chip,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import clsx from 'clsx'
import { UPDATE_SURVEY_TEXT } from '../../utils/constants'

import SearchInput from '../../pages/ManageSurveys/components/SearchInput'
import { SelectIcon } from '../IconComponents'
import { toggleSummaryOfEdits } from '../../common/actions'
import { fetchSummaryOfEdits } from '../../pages/SurveyIntroduction/actions'
import closeModalIcon from '../../pages/ClientInfo/assets/Cancel_Icon.png'

import useStyles from './styles'
import moment from 'moment'

function SummaryOfEditsComponent({
  toggleSummaryOfEdits,
  summary,
  availableLanguages,
  fetchSummaryOfEdits,
  baseLanguageKey,
  baseLanguageValue,
  canUpdateConfirmit,
  updateSurvey,
  selectedLanguageId,
  editorState,
  surveyId,
  outcomeId,
  hasUserSelcetdMultiLangOption,
}) {
  const classes = useStyles()
  const [summaryArray, setSummaryArray] = useState(summary)
  const [selectedDate, setSelectedDate] = useState(null)
  const [isActive, setIsActive] = useState(true)
  const [selectedValues, setSelectedValues] = useState({
    section: '',
    isEdited: 'Yes',
    user: 'No Selection',
  })
  const [isArabicSelected, setIsArabicSelected] = useState(
    baseLanguageKey === 3 || baseLanguageKey === 77
  )

  useEffect(() => {
    if (summary) {
      const valuesToSelect = summary.filter(item => {
        return Object.keys(item).some(key => {
          return item[key] === 'Yes'
        })
      })
      setSummaryArray(valuesToSelect)
    }
    setSelectedValues({
      section: '',
      isEdited: 'Yes',
      user: 'No Selection',
    })
  }, [summary])

  const handleDateChange = date => {
    if (date) {
      const newValues = summary.filter(summary => {
        return summary['date'] === date.toDateString()
      })
      setSummaryArray(newValues)
      setSelectedDate(date)
    } else setSummaryArray(summary)
  }

  function handleChange(event) {
    const { name, value } = event.target
    const newSelectedObj = JSON.parse(JSON.stringify(selectedValues))
    if (value !== 'No Selection') {
      const newValues = summary.filter(summary => {
        if (typeof summary[name] !== 'boolean' && summary[name]) {
          newSelectedObj[name] = value
          setSelectedValues(newSelectedObj)
          return summary[name].includes(value)
        }
        return summary[name] === value
      })
      setSummaryArray(newValues)
    } else {
      newSelectedObj[name] = value
      setSelectedValues(newSelectedObj)
      setSummaryArray(summary)
    }
  }

  function handleChipClick(languageId, baseLang) {
    setIsArabicSelected(languageId === 3 || languageId === 77)
    if (!baseLang) {
      setIsActive(false)
    } else setIsActive(true)
    fetchSummaryOfEdits(canUpdateConfirmit, languageId)
  }

  function getUsersList(summary) {
    return [...new Set(summary.map(record => record.user))]
  }

  function getSectionList(summary) {
    return [...new Set(summary.map(record => record.section))]
  }

  function createMarkup(content) {
    return { __html: content }
  }

  function renderTableBody() {
    return (
      <TableBody>
        {renderSearchRow()}
        {renderSurveyRows()}
      </TableBody>
    )
  }

  function renderSearchRow() {
    const usersList = getUsersList(summary)
    const sectionsList = getSectionList(summary)

    return (
      <TableRow className={`${classes.fixedHeightClass} fixedHeightClass`}>
        <TableCell
          align="center"
          size="small"
          className={classes.tableBodyCell}
        >
          <FormControl>
            <Select
              value={selectedValues.section}
              onChange={handleChange}
              displayEmpty
              name="section"
              IconComponent={SelectIcon}
              autoWidth
              disableUnderline
              className={classes.cstSelect}
            >
              <MenuItem value="">No Selection</MenuItem>
              {sectionsList.map((section, index) => (
                <MenuItem key={section + index} value={section}>
                  {section}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell
          align="center"
          size="small"
          className={classes.tableBodyCell}
        >
          <FormControl>
            <Select
              value={selectedValues.isEdited}
              onChange={handleChange}
              displayEmpty
              name="isEdited"
              IconComponent={SelectIcon}
              autoWidth
              disableUnderline
              defaultValue={'Yes'}
              className={classes.cstSelect}
            >
              <MenuItem value="">No Selection</MenuItem>
              <MenuItem value={'Yes'}>Yes</MenuItem>
              <MenuItem value={'No'}>No</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell
          align="center"
          component="th"
          scope="row"
          size="small"
          className={classes.tableBodyCell}
        >
          <SearchInput name="oldContent" onSearch={handleChange} />
        </TableCell>
        <TableCell
          align="center"
          size="small"
          className={classes.tableBodyCell}
        >
          <SearchInput name="editedContent" onSearch={handleChange} />
        </TableCell>
        <TableCell
          align="center"
          size="small"
          className={classes.tableBodyCell}
        >
          <FormControl>
            <Select
              value={selectedValues.user}
              onChange={handleChange}
              displayEmpty
              name="user"
              IconComponent={SelectIcon}
              autoWidth
              disableUnderline
              className={classes.cstSelect}
            >
              <MenuItem value="No Selection">No Selection</MenuItem>
              {usersList.map((user, index) => (
                <MenuItem key={user + index} value={user}>
                  {user}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell
          align="center"
          size="small"
          className={classes.tableBodyCell}
        ></TableCell>
        <TableCell
          align="center"
          size="small"
          className={classes.tableBodyCell}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="date-picker-inline"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </TableCell>
      </TableRow>
    )
  }

  function renderSurveyRows() {
    return summaryArray.length > 0 ? (
      summaryArray.map((row, index) => (
        <Slide
          direction="right"
          in={true}
          timeout={300}
          mountOnEnter
          unmountOnExit
          key={index}
        >
          <TableRow
            className={
              !row.isEdited.includes('Yes')
                ? classes.tableRow
                : classes.tableRowHighlighted
            }
          >
            <TableCell
              align="center"
              scope="row"
              className={classes.tableBodyCell}
            >
              {`${row.section} `}
            </TableCell>
            <TableCell
              align="center"
              scope="row"
              className={classes.tableBodyCell}
            >
              {`${row.isEdited} `}
            </TableCell>
            <TableCell
              align={isArabicSelected ? 'right' : 'left'}
              scope="row"
              className={
                isArabicSelected
                  ? `${classes.arabicTextDir} ${classes.tableBodyCell}`
                  : classes.tableBodyCell
              }
              dangerouslySetInnerHTML={createMarkup(row.oldContent)}
            ></TableCell>
            <TableCell
              align={isArabicSelected ? 'right' : 'left'}
              className={
                isArabicSelected
                  ? `${classes.arabicTextDir} ${classes.tableBodyCell}`
                  : classes.tableBodyCell
              }
              dangerouslySetInnerHTML={createMarkup(row.editedContent)}
            ></TableCell>
            <TableCell align="center" className={classes.tableBodyCell}>
              {row.user}
            </TableCell>
            <TableCell align="center" className={classes.tableBodyCell}>
              {row.time.length > 0
                ? moment(row.time, 'HH:mm:ss.a').format('hh:mm A')
                : ''}
            </TableCell>
            <TableCell align="center" className={classes.tableBodyCell}>
              {row.date}
            </TableCell>
          </TableRow>
        </Slide>
      ))
    ) : (
      <TableRow className={classes.tableRow}>
        <TableCell align="left" className={classes.noDataText} colSpan={7}>
          No changes for this language
        </TableCell>
      </TableRow>
    )
  }

  function renderTableHead() {
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell
            className={`${classes.tableHeadCell} ${classes.sortable}`}
            align="left"
          >
            Section
          </TableCell>
          <TableCell
            className={`${classes.tableHeadCell} ${classes.sortable}`}
            align="left"
          >
            Edited
          </TableCell>
          <TableCell
            className={`${classes.tableHeadCell} ${classes.sortable}`}
            align="left"
          >
            Original content
          </TableCell>
          <TableCell
            className={`${classes.tableHeadCell} ${classes.sortable}`}
            align="left"
          >
            Edited Content
          </TableCell>
          <TableCell
            className={`${classes.tableHeadCell} ${classes.sortable}`}
            align="left"
          >
            User
          </TableCell>
          <TableCell
            className={`${classes.tableHeadCell} ${classes.sortable}`}
            align="left"
          >
            Time
          </TableCell>
          <TableCell
            className={`${classes.tableHeadCell} ${classes.sortable}`}
            align="left"
          >
            Date
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  return (
    <div className={classes.summaryContainer}>
      <Box
        onClick={() => {
          toggleSummaryOfEdits()
          // if (canUpdateConfirmit) {
          //   setTimeout(() => {
          //     // window.location.reload()
          //   }, 1)
          // }
        }}
        className={'closeModal'}
        component="img"
        src={closeModalIcon}
      />
      <Box
        display="flex"
        flex="1"
        className={classes.text}
        justifyContent="space-between"
      >
        <span>Summary of Edits</span>
      </Box>
      <Box display="flex" justifyContent="row" alignItems="center">
        <Box
          display="flex"
          flex="1"
          justifyContent="flex-start"
          style={{ marginTop: '3.6vh' }}
        >
          <span className={classes.languageForm}>
            <Chip
              key={baseLanguageKey + 'language-chip'}
              className={clsx({
                [classes.chip]: true,
                [classes.activeChip]: isActive,
              })}
              variant={'default'}
              color={isActive ? 'primary' : 'default'}
              label={[
                <span className={classes.baselanguageIdentifier}></span>,
                baseLanguageValue,
              ]}
              onClick={() => handleChipClick(baseLanguageKey, 'baseLang')}
            />
            {availableLanguages.map(language => (
              <Chip
                key={language.key + 'language-chip'}
                className={clsx({
                  [classes.chip]: true,
                  [classes.activeChip]: false,
                })}
                variant={'default'}
                color={'default'}
                label={language.value}
                onClick={() => handleChipClick(language.key)}
              />
            ))}
          </span>
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <button
            className="button-round yes-button"
            onClick={() => {
              toggleSummaryOfEdits()
              window.popupMsg = UPDATE_SURVEY_TEXT
              updateSurvey(
                editorState,
                surveyId,
                selectedLanguageId,
                true,
                hasUserSelcetdMultiLangOption,
                outcomeId
              )
            }}
          >
            UPDATE SURVEY
          </button>
        </Box>
      </Box>
      <Table className={classes.surveyTable} size="medium" key={0}>
        {renderTableHead()}
        {renderTableBody()}
      </Table>
    </div>
  )
}

const mapStateToProps = state => ({
  summary: state.get('summaryOfEdits')['summary'],
  availableLanguages: state.get('summaryOfEdits')['availableLanguages'],
  baseLanguageKey: state.get('summaryOfEdits')['baseLanguageKey'],
  baseLanguageValue: state.get('summaryOfEdits')['baseLanguageValue'],
  canUpdateConfirmit: state.get('summaryOfEdits')['isSync'],
  surveyId: state
    .get('surveyname')
    .get('items')
    .get('id'),
})

const mapDispatchToProps = {
  toggleSummaryOfEdits,
  fetchSummaryOfEdits,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryOfEditsComponent)
