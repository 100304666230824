import ClientInfo from './ClientInfo'
import reducer, {
  surveyReducer,
  clientinforeducer,
  saveclientinforeducer,
  surveynamereducer,
  surveynameupdatereducer,
  createnewclienteducer,
  getopportunityReducer,
} from './reducer'

import sagas from './sagas'

export {
  reducer,
  sagas,
  surveyReducer,
  clientinforeducer,
  saveclientinforeducer,
  surveynamereducer,
  surveynameupdatereducer,
  createnewclienteducer,
  getopportunityReducer,
}
export default ClientInfo
