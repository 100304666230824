import React from 'react'
import { Box } from '@material-ui/core'

const ModalContent = props => {
  return (
    <Box>
      <h4 className="modal-title" style={{ marginBottom: '4.2vh' }}>
        Do you want to change the Confidentiality Text? - This is McKinsey's
        confidentiality/legal information and should not be edited. If you have
        the necessary approval, please go ahead and change the text.
      </h4>
      <Box display="flex" flex="1" justifyContent="center">
        <button
          className="button-round no-button"
          onClick={e => props.handleOnClick(e)}
        >
          No
        </button>
        <Box style={{ position: 'relative' }}>
          <button
            className="button-round yes-button"
            onClick={e => props.handleOnClick(e)}
          >
            Yes
          </button>
        </Box>
      </Box>
    </Box>
  )
}

export default ModalContent
