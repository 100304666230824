import {
  EMAIL_TEMPLATE_FETCH,
  LOCK_TEMPLATE,
  EMAIL_SINGLE_TEMPLATE_FETCH,
  UPDATE_TEMPLATE,
  DUPLICATE_TEMPLATE,
  SEND_TEST_EMAIL
} from './constants'

export const getEmailTemplate = payload => ({
  type: `${EMAIL_TEMPLATE_FETCH}_PENDING`,
  payload,
})

export const getEmailTemplateSuccess = payload => ({
  type: `${EMAIL_TEMPLATE_FETCH}_SUCCESS`,
  payload,
})

export const getEmailTemplateFailed = payload => ({
  type: `${EMAIL_TEMPLATE_FETCH}_FAILED`,
  payload,
})
// GET SINGLE TEMPLATE
export const getSingleEmailTemplate = payload => ({
  type: `${EMAIL_SINGLE_TEMPLATE_FETCH}_PENDING`,
  payload,
})

export const getSingleEmailTemplateSuccess = payload => ({
  type: `${EMAIL_SINGLE_TEMPLATE_FETCH}_SUCCESS`,
  payload,
})

export const getSingleEmailTemplateFailed = payload => ({
  type: `${EMAIL_SINGLE_TEMPLATE_FETCH}_FAILED`,
  payload,
})
// LOCK
export const lockEmailTemplate = payload => ({
  type: `${LOCK_TEMPLATE}_PENDING`,
  payload,
})

export const lockEmailTemplateSuccess = payload => ({
  type: `${LOCK_TEMPLATE}_SUCCESS`,
  payload,
})

export const lockEmailTemplateFailed = payload => ({
  type: `${LOCK_TEMPLATE}_FAILED`,
  payload,
})
// UPDATE TEMPLATE
export const updatetemplate = payload => ({
  type: `${UPDATE_TEMPLATE}_PENDING`,
  payload,
})

export const updatetemplateSuccess = payload => ({
  type: `${UPDATE_TEMPLATE}_SUCCESS`,
  payload,
})

export const updatetemplateFailed = payload => ({
  type: `${UPDATE_TEMPLATE}_FAILED`,
  payload,
})
// DUPLICATE TEMPLATE
export const duplicatemp = payload => ({
  type: `${DUPLICATE_TEMPLATE}_PENDING`,
  payload,
})

export const duplicatempSuccess = payload => ({
  type: `${DUPLICATE_TEMPLATE}_SUCCESS`,
  payload,
})

export const duplicatempFailed = payload => ({
  type: `${DUPLICATE_TEMPLATE}_FAILED`,
  payload,
})
// SEND PREVIEW MAIL
export const sendEmail = payload => ({
  type: `${SEND_TEST_EMAIL}_PENDING`,
  payload,
})

export const sendEmailSuccess = payload => ({
  type: `${SEND_TEST_EMAIL}_SUCCESS`,
  payload,
})

export const sendEmailFailed = payload => ({
  type: `${SEND_TEST_EMAIL}_FAILED`,
  payload,
})
export const clearState = payload => ({
  type: `CLEAR_EMAIL_STATE`,
  payload,
})

