import React, { lazy, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import Page404 from './pages/Page404'
import CallbackComponent from './components/CallbackComponent'
import FallbackRoute from './FallbackRoute'
import Backcoding from './pages/Backcoding'

const Login = lazy(() => {
  return Promise.all([
    import('./pages/Login'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const Home = lazy(() => {
  return Promise.all([
    import('./pages/Home'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const ManageSurveys = lazy(() => {
  return Promise.all([
    import('./pages/ManageSurveys'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const SurveyInfo = lazy(() => {
  return Promise.all([
    import('./pages/SurveyInfo'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const ClientInfo = lazy(() => {
  return Promise.all([
    import('./pages/ClientInfo'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const DesignSurvey = lazy(() => {
  return Promise.all([
    import('./pages/DesignSurvey'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const Summary = lazy(() => {
  return Promise.all([
    import('./pages/Summary'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const SurveyIntroduction = lazy(() => {
  return Promise.all([
    import('./pages/SurveyIntroduction'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const SurveyInstruction = lazy(() => {
  return Promise.all([
    import('./pages/SurveyIntroduction'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const SurveyOHI = lazy(() => {
  return Promise.all([
    import('./pages/SurveyOHI'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const SurveyAdditionalQuestions = lazy(() => {
  return Promise.all([
    import('./pages/SurveyAdditionalQuestions'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const StandardDemographics = lazy(() => {
  return Promise.all([
    import('./pages/SurveyAdditionalQuestions'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const DeepDive = lazy(() => {
  return Promise.all([
    import('./pages/DeepDive/DeepDive'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const OptionalQues = lazy(() => {
  return Promise.all([
    import('./pages/DeepDive/DeepDive'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const Ohi4 = lazy(() => {
  return Promise.all([
    import('./pages/DeepDive/DeepDive'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const GSDBPage = lazy(() => {
  return Promise.all([
    import('./pages/GSDB/GSDBPage'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const UserManagement = lazy(() => {
  return Promise.all([
    import('./pages/UserManagement/UserManagement.jsx'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const Dashboard = lazy(() => {
  return Promise.all([
    import('./pages/Dashboard'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

const Demographics = lazy(() => {
  return Promise.all([
    import('./pages/Demographics/Demographics'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})
const TestEmail = lazy(() => {
  return Promise.all([
    import('./pages/TestEmail'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})
const EmailEdit = lazy(() => {
  return Promise.all([
    import('./pages/TestEmail/EmailEdit'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})
const SendPreview = lazy(() => {
  return Promise.all([
    import('./pages/TestEmail/SendPreview'),
    new Promise(resolve => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports)
})

function Routes({ mid }) {
  useEffect(() => {
    if (window.location.pathname.includes('/global-template')) {
      sessionStorage.setItem('isGlobalTemplate', 'true')
    }
    return () => {
      sessionStorage.clear()
    }
    // eslint-disable-next-line
  }, [])
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  return (
    <Switch>
      <Route
        path="/implicit/callback"
        component={() => <CallbackComponent mid={mid} />}
      />
      <FallbackRoute path="/" component={Home} exact />
      <FallbackRoute path="/home" component={Home} exact />
      <FallbackRoute path="/manage-surveys" component={ManageSurveys} />
      <FallbackRoute component={SurveyInfo} path="/define/survey-info/:id" />
      <FallbackRoute component={ClientInfo} path="/define/client-info/:id" />
      <FallbackRoute
        component={DesignSurvey}
        path="/define/design-survey/:id"
      />
      <FallbackRoute component={Summary} path="/define/summary/:id" />
      <FallbackRoute
        component={SurveyIntroduction}
        path="/update-survey-text/introduction/:id"
      />
      <FallbackRoute
        component={SurveyInstruction}
        path="/update-survey-text/instructions/:id"
      />
      <FallbackRoute component={SurveyOHI} path="/update-survey-text/OHI/:id" />
      <FallbackRoute
        component={Ohi4}
        path="/update-survey-text/ohi 4.0 sections/:id"
      />
      <FallbackRoute
        component={DeepDive}
        path="/update-survey-text/add-ons/:id"
      />
      <FallbackRoute
        component={OptionalQues}
        path="/update-survey-text/optional questions/:id"
      />
      <FallbackRoute
        component={SurveyAdditionalQuestions}
        path="/update-survey-text/Additional Questions/:id"
      />
      <FallbackRoute
        component={StandardDemographics}
        path="/update-survey-text/standard demographics/:id"
      />
      <FallbackRoute component={GSDBPage} path="/survey/GSDB/:id" />
      <FallbackRoute component={UserManagement} path="/user-management" />
      {/* {userInfo.dashboardAccess === true ? (
        <FallbackRoute component={Dashboard} path="/dashboard" />
      ) : (
        <Route component={Page404} />
      )} */}
      <FallbackRoute component={Dashboard} path="/dashboard" />
      <FallbackRoute component={Backcoding} path="/backcoding" />
      <FallbackRoute component={Demographics} path="/survey/demographics/:id" />
      <FallbackRoute component={TestEmail} path="/test-emails/:surveyid" />
      <FallbackRoute component={EmailEdit} path="/emailedit/:surveyid/:id" />
      <FallbackRoute
        component={SendPreview}
        path="/previewemail/:surveyid/:id"
      />
      <FallbackRoute
        component={SendPreview}
        path="/sendpreview/:surveyid/:id"
      />
      <Route component={Page404} />
    </Switch>
  )
}

export default Routes
