import React, { useEffect, useState } from 'react'

import useStyles from './SurveyForm/styles'
import { Box, Button, Modal } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import arrowicon from '../assets/arrow.svg'

function ModalView({ savesurveyStatus, gotoNextpage }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [url, setURl] = useState('')

  function getModalStyle() {
    const top = 50

    return {
      top: `${top}%`,
      left: `${top}%`,
      transform: `translate(-${top}%, -${top}%)`,
    }
  }
  const [completed, setCompleted] = React.useState(0)
  const [buffer, setBuffer] = React.useState(10)
  const progress = React.useRef(() => {})
  const [copytxt, setCopytxt] = React.useState('')

  useEffect(() => {
    progress.current = () => {
      if (completed > 100) {
        setCompleted(0)
        setBuffer(10)
      } else {
        const diff = Math.random() * 10
        const diff2 = Math.random() * 10
        setCompleted(completed + diff)
        setBuffer(completed + diff + diff2)
      }
    }
  })
  useEffect(() => {
    if (savesurveyStatus.get('isLoading')) {
      setOpen(true)
    }
    // console.log(savesurveyStatus.toJS().items)
    if (savesurveyStatus.get('isFetched')) {
      if (savesurveyStatus.get('items').get('surveyUrl') != '') {
        setURl(savesurveyStatus.get('items').get('surveyUrl'))
      } else {
        setOpen(false)
        //gotoNextpage()
      }
    }
  })
  useEffect(() => {
    function tick() {
      progress.current()
    }
    const timer = setInterval(tick, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const useModalStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(6),
      outline: 'none',
    },
  }))
  const [modalStyle] = useState(getModalStyle)
  const modalClasses = useModalStyles()

  return <Box>{renderModal()}</Box>

  function renderModal() {
    /* const handleOpen = () => {
      setOpen(true)
    } */
    const handleClose = () => {
      setOpen(false)
    }
    const copytext = () => {
      var copyText = document.getElementById('surveyurl')
      copyText.select()
      document.execCommand('copy')
      setCopytxt('Link copied to clipboard')
      /* addToast('Copied url: ' + url, {
        appearance: 'success',
        autoDismiss: true,
      }) */
      //alert('Copied url: ' + url)
    }
    return (
      <Box>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={modalClasses.paper}>
            {url !== '' ? (
              <Box>
                <h4 className={classes.modaltitle} style={{ marginBottom: 18 }}>
                  Here is the standard template link <br></br>
                  <span style={{ fontSize: 12, color: '#999' }}>
                    This link is the basic OHI survey link, no designs choices
                    have been implemented at this stage
                  </span>
                </h4>
                <Box className={classes.linkmodal}>
                  <div className={classes.surveyLink}>
                    <span onClick={copytext}>Copy Link</span>
                    <input
                      id="surveyurl"
                      type="text"
                      readOnly
                      value={url || ''}
                    />
                  </div>
                  {copytxt !== '' ? (
                    <Box
                      display="flex"
                      style={{ color: '#038f2f', paddingTop: 5 }}
                    >
                      <Icon
                        style={{
                          fontSize: 19,
                          fontWeight: 'bold',
                          marginRight: 4,
                        }}
                      >
                        done
                      </Icon>
                      {copytxt}
                    </Box>
                  ) : null}
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 0, marginTop: '20px' }}
                    className={classes.buttonRound}
                    onClick={() => gotoNextpage()}
                  >
                    Proceed to Design
                    <img src={arrowicon} style={{ marginLeft: '7px' }} />
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <h4 className={classes.modaltitle} style={{ marginBottom: 35 }}>
                  Please wait while we generate your link – this action is being
                  performed behind the scenes and could take up to 3 minutes.
                  Thank you!
                </h4>
                <LinearProgress
                  style={{ height: '7px', borderRadius: '10px' }}
                />
              </Box>
            )}
          </div>
        </Modal>
      </Box>
    )
  }
}

export default ModalView
