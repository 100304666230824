import { PROGRESS_BAR_LOADER } from '../common/constants'
import { DEFAULT_LOADER_TEXT } from '../utils/constants'

const INITIAL_STATE = {
  isLoaderOpen: false,
  loaderText: DEFAULT_LOADER_TEXT,
}

const progressBarLoader = (
  state = INITIAL_STATE,
  { type, loaderState, text }
) => {
  if (text) {
    state.loaderText = text
  }
  switch (type) {
    case PROGRESS_BAR_LOADER:
      return {
        ...state,
        isLoaderOpen: loaderState,
      }
    default:
      return state
  }
}

export default progressBarLoader
