import { call, put, takeLatest } from 'redux-saga/effects'
import Endpoints from '../../Endpoints'
import request from '../../utils/request'
import { REVERT_TO_LAST_UPDATE } from './constants'
import { revertToLastError } from './actions'
function* revertToLastSave({ surveyId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.revertToLastUpdate.replace(':id', surveyId),
    })
    if (!error) {
      window.location.reload()
      return true
    }
    return yield put(revertToLastError(true))
  } catch (e) {
    return yield put(revertToLastError(true))
  }
}

function* revertToLastUpdate() {
  yield takeLatest(REVERT_TO_LAST_UPDATE, revertToLastSave)
}

export default [revertToLastUpdate]
