export const introDuctionClassNames = [
  'introOverview',
  'introConfidentiality',
  'introLength',
  'introOrganised',
  'introQuestions',
  'introCopyright',
  'introINS',
]

export const ohiClassNames = 'text'

export const surveyOhiEditor = 'survey-ohi-editor'

export const surveyIntroductionEditor = 'survey-introduction-editor'

export const BorderClass = 'editorDottedBorder'

export const IntroductionBorderClass = 'introductionBorderClass'

export const addtionalQuestionEditor = 'addtional-question-editor'
