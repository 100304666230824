import { call, put, takeLatest } from 'redux-saga/effects'

import { summaryFetchSuccess, summaryFetchFailed } from './actions'
import { SUMMARYINFO_FETCH } from './constants'
import { fetchSummaryinfo } from './apis'

function* fetchSummaryInfofn({ payload }) {
  try {
    const response = yield call(fetchSummaryinfo, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(summaryFetchSuccess(data))
    } else {
      yield put(summaryFetchFailed(response))
    }
  } catch (e) {
    yield put(summaryFetchFailed(e.response))
  }
}

const sagas = [takeLatest(`${SUMMARYINFO_FETCH}_PENDING`, fetchSummaryInfofn)]
export default sagas
