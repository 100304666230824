import {
  FETCH_OHI_OUTCOME_DATA,
  FETCH_OHI_OUTCOME_DATA_SUCCESS,
  FETCH_OHI_OUTCOME_DATA_ERROR,
  FETCH_OHI_OUTCOMES_DATA_LANG,
  FETCH_OHI_OUTCOMES_DATA_LANG_SUCCESS,
  FETCH_OHI_OUTCOMES_DATA_LANG_ERROR,
  UPDATE_SURVEY,
  REPLACE_EVERYWHERE,
} from './constants'

export function fetchOHIOutcomeData(surveyId, outcomeId) {
  return {
    type: FETCH_OHI_OUTCOME_DATA,
    surveyId,
    outcomeId,
  }
}

export function OHIOutcomeDataSuccess(data) {
  return {
    type: FETCH_OHI_OUTCOME_DATA_SUCCESS,
    data,
  }
}

export function OHIOutcomeDataError(error) {
  return {
    type: FETCH_OHI_OUTCOME_DATA_ERROR,
    error,
  }
}

export function fetchDataForOHIOutcomesLang({
  surveyId,
  outcomeId,
  languageId,
}) {
  return {
    type: FETCH_OHI_OUTCOMES_DATA_LANG,
    languageId,
    surveyId,
    outcomeId,
  }
}

export const fetchDataForOHIOutcomesLangSuccess = ({
  data,
  languageId,
  outcomeId,
}) => {
  return {
    type: FETCH_OHI_OUTCOMES_DATA_LANG_SUCCESS,
    data,
    languageId,
    outcomeId,
  }
}

export function fetchDataForOHIOutcomesLangError(error) {
  return {
    type: FETCH_OHI_OUTCOMES_DATA_LANG_ERROR,
    error,
  }
}

export const updateSurvey = (
  editorStateForOhi,
  surveyId,
  languageId,
  isSync,
  isMultiLanguage,
  outcomeId
) => {
  return {
    type: UPDATE_SURVEY,
    editorStateForOhi,
    surveyId,
    languageId,
    isSync,
    outcomeId,
    isMultiLanguage,
  }
}

export function replaceEverywhere({
  ignoreCase,
  selectedLanguageId,
  replaceText,
  findText,
  toggleState,
  outcomeId,
}) {
  return {
    type: REPLACE_EVERYWHERE,
    isCaseSensitive: !ignoreCase,
    replaceFrom: findText,
    replaceTo: replaceText,
    langId: selectedLanguageId,
    toggleState,
    outcomeId,
  }
}
