const Endpoints = {
  sideBarNavigation: '/api/survey/:id/textupdatemenu',
  introduction: '/api/survey/:id/introduction',
  introductionLanguage: '/api/survey/:id/introduction/:id',
  instructions: '/api/survey/:id/instructions',
  instructionsLanguage: '/api/survey/:id/instructions/:id',
  ohiOutcome: '/api/survey/:id/outcome/:outcomeId',
  additionalQuestion: '/api/survey/:id/additionalQuestion',
  optionalQuestion: '/api/survey/:id/OptionalQuestion/:outcomeid',
  standardDemographics: '/api/survey/:id/standardDemographicsQuestion',
  standardDemographicsLang:
    '/api/survey/:id/standardDemographicsQuestion/:languageId',
  additionalQuestionLanguage: '/api/survey/:id/additionalQuestion/:languageId',
  optionalQuestionLanguage:
    '/api/survey/:id/OptionalQuestion/:outcomeid/:languageId',
  deepDive: '/api/survey/:id/deepdive/:id',
  deepDiveLanguage: '/api/survey/:id/deepdive/:id/:languageId',
  ohi4Sections: '/api/survey/:id/deepdive/:defaultid/:langid/:id',
  ohi4SectionsLanguage: '/api/survey/:id/deepdive/:id/:languageId',
  ohiOutcomesLanguage: '/api/survey/:id/outcome/:outcomeId/:languageId',
  ohiOutcomesPost: '/api/survey/:id/outcome/',
  summaryOfEdits: '/api/survey/surveyId/summary/langId',
  summaryOfEditsDefault: '/api/survey/surveyId/summary/',
  revertToLastUpdate: '/api/survey/:id/revertall',
  importFile: '/api/survey/:id/import',
  exportFile: '/api/survey/:id/export',
  lockSurvey: '/api/surveys/lock/:surveyId',
  unlockSurvey: '/api/surveys/lock/:surveyId/1',
  emailSurvey: '/api/emailsurvey/:surveyId',
  unlockUserLockedSurvey: '/api/surveys/lock/0000/2',
  replaceEveryWhere: '/api/survey/:id/replaceglobal',
  GSDBApi: '/api/surveys/gsdb/:surveyId',
  uploadDemographics: '/api/survey-demographic/:surveyId/import',
  downloadDemographicTemplate:
    '/api/survey-demographic/:surveyId/export-template',
  getDemographicsData:
    '/api/survey-demographic/:surveyId/demographics/:languageId',
  getDemographicLanguages: '/api/demographicLanguage/:surveyId',
  addDemographicsArray: '/api/survey-demographic/:surveyId/add-demographic',
  getDemographicTypeLanguage:
    '/api/survey-demographic/:surveyId/demographictype',
  addNewDemographicsInList:
    '/api/survey-demographic/:surveyId/create-demographic',
  updateDemographicDataForBaseLanguage:
    '/api/survey-demographic/:surveyId/demographics',
  getBenchmarkData: '/api/survey-demographic/:surveyId/benchmarkdetails',
  getTags: '/api/survey-demographic/:surveyId/getbenchmarkmaster',
  updateDemographicFile: '/api/survey-demographic/:surveyId/update-demographic',
  uploadBackCodedDemographics:
    '/api/survey-demographic/:surveyId/create-demographic-backcoded',
  viewUpdateSurvey: '/api/survey-demographic/:surveyId/launchsurvey',
  userApi: '/api/users',
  exportTemplate: '/api/users/export-template',
  importTemplate: '/api/users/import-template',
  exportAllUsers: '/api/users/export',
  replaceWordsApi: '/api/selfserve/template-replaceword',
  filterUrl: '/api/surveys/filter-list',
  surveyDataUrl: '/api/surveys/dashboard-apply-filter',
  importBackcoding: '/api/hr/import/:pid',
  downloadMappingFileUrl: '/api/hr/download-excel',
  generateQuestion: '/api/hr/generate-questions',
  taskForLogsUrl: '/api/hr/task-log/:taskid',
  getClosingProtocolLang: '/api/getlanguages/:surveyid',
}

export default Endpoints
