export default function additionalQuesions(values) {
  let errors = {}

  if (!values.questionText[0]) {
    errors.questionText = 'This field is required'
  }
  if (values.questionType == 3 && !values.answerValues[0]) {
    errors.answerValues = 'This field is required'
  }
  if (values.listType < 1 && values.questionType == 2) {
    errors.listType = 'This field is required'
  }
  if (!values.listValues.length && values.questionType == 2) {
    errors.listValues = 'This field is required'
  }

  if ((!values.scaleType || values.scaleType < 1) && values.questionType == 3) {
    errors.scaleType = 'This field is required'
  }
  if (!values.scaleValues.length && values.questionType == 3) {
    errors.scaleValues = 'This field is required'
  }

  return errors
}
