import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import { SummaryDetails } from './Components'
import { summaryFetch } from './actions'
import { surveysStatusFetch } from '../ManageSurveys/actions'
import { surveynameFetch, surveynameUpdate } from '../ClientInfo/actions'
//import { getSurveyinfoFetch } from '../SurveyInfo/actions'
import { SurveyHeader, SurveyProgress, SurveySidebar } from '../../components'
import useStyles from './styles'
import { survey_Status_For_Disabling_Post_Req } from '../../utils/constants'
import { isTextUpdateEnabled } from '../../common/arrayFunctions'
import { ohi4ModuleFetch } from '../DesignSurvey/actions'

function Summary({
  match,
  history,
  surveyname,
  surveynameFetch,
  surveynameUpdate,
  surveynameupdateStatus,
  summaryinfo,
  summaryFetch,
  loader,
  surveysStatusFetch,
  surveyStatus,
  statuscode,
  ohiTestModuleData,
  // ohiUpdateDataStatus,
  designsurveyget,
  designupdateStatus,
  ohi4ModuleFetch,
  ohi4ModuleData,
}) {
  const classes = useStyles()
  document.title = 'OHI Portal - Summary'
  const surveyStatusReturned = surveyname.get('items').get('status')
  const isSurveyUserLocked = surveyname.get('items').get('isUserLock')
  const isSurveyAdminLocked = surveyname.get('items').get('isAdminLock')
  const surveyType = surveyname.get('items').get('surveyType')
  const sendHelpDeskEmailInitial = surveyname
    .get('items')
    .get('sendHelpDeskEmailInitial')
  const isButtonToBeDisabled =
    survey_Status_For_Disabling_Post_Req.indexOf(surveyStatusReturned) !== -1 ||
    isSurveyAdminLocked ||
    isSurveyUserLocked

  const [isDisabled, setIsdisable] = useState()
  function getpidfn(pid) {
    if (pid === '') {
      setIsdisable(true)
    } else {
      setIsdisable(false)
    }
  }

  function textEnabled() {
    const flag = isTextUpdateEnabled(
      sendHelpDeskEmailInitial,
      designupdateStatus,
      surveyType,
      designsurveyget,
      ohiTestModuleData
    )
    return flag
  }

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <SurveyHeader
        history={history}
        statuscode={statuscode}
        surveyId={match.params.id}
      />
      <SurveyProgress
        onSurveynameFetch={surveynameFetch}
        onSurveynameUpdate={surveynameUpdate}
        surveyname={surveyname}
        params={match.params.id}
        surveynameupdateStatus={surveynameupdateStatus}
        statusList={surveyStatus}
        surveysStatusFetch={surveysStatusFetch}
        ongetpidfn={getpidfn}
        isButtonToBeDisabled={isButtonToBeDisabled}
        textUpdate={textEnabled}
      />
      <Box display="flex" flex={1} className={classes.main}>
        <Box display="flex" flex={1} className={classes.sidebar}>
          <SurveySidebar params={match.params} isDisabled={isDisabled} />
        </Box>
        <Box
          display="flex"
          flex={5}
          className={`${classes.formWrapper} scrollbar-class`}
        >
          <SummaryDetails
            params={match.params.id}
            onSummaryFetch={summaryFetch}
            summaryinfo={summaryinfo}
            history={history}
            loader={loader}
            statuscode={statuscode}
            isTextUpdateEnabled={textEnabled}
            ohi4ModuleFetch={ohi4ModuleFetch}
            ohi4ModuleData={ohi4ModuleData}
            surveyname={surveyname}
          ></SummaryDetails>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  surveyname: state.get('surveyname'),
  surveynameupdateStatus: state.get('surveynameupdateStatus'),
  summaryinfo: state.get('summaryinfo'),
  loader: state.get('loader'),
  surveyStatus: state.get('surveyStatus'),
  statuscode: state.get('statuscode'),
  ohiTestModuleData: state.get('ohiTestModuleReducer'),
  ohiUpdateDataStatus: state.get('ohiUpdateStatus'),
  designsurveyget: state.get('designsurveyget'),
  designupdateStatus: state.get('designupdateStatus'),
  ohi4ModuleData: state.get('ohi4ModuleData'),
})

const mapDispatchToProps = {
  surveynameFetch,
  surveynameUpdate,
  summaryFetch,
  surveysStatusFetch,
  ohi4ModuleFetch,
}
export default connect(mapStateToProps, mapDispatchToProps)(Summary)
