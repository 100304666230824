import {
  UPLOAD_DEMOGRAPHICS,
  DOWNLOAD_DEMOGRAPHICS_TEMPLATE,
  GET_DEMOGRAPHIC_DATA,
  ADD_DEMOGRAPHICS_ARRAY,
  GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE,
  RESET_UPLOAD_DEMOGRAPHICS,
  UPDATE_DEMOGRAPHIC,
  DEMOGRAPHIC_STATE_CHANGE,
  ADD_NEW_DEMOGRAPHICS_IN_LIST,
  RESET_ADD_NEW_DEMOGRAPHICS_IN_LIST,
  GET_TAGS,
  GET_BENCHMARK_DATA,
  UPDATE_BENCHMARK_DATA,
  VIEW_UPDATE_SURVEY,
  RESET_DEMOGRAPHIC_LIST,
  RESET_UPDATE_DEMOGRAPHIC_DATA,
} from './constants'

export const uploadDemographics = (file, surveyId, isGeneric, toValidate) => ({
  type: `${UPLOAD_DEMOGRAPHICS}_PENDING`,
  file,
  surveyId,
  isGeneric,
  toValidate,
})

export const successUploadDemographics = () => ({
  type: `${UPLOAD_DEMOGRAPHICS}_SUCCESS`,
})

export const failedUploadDemographics = data => ({
  type: `${UPLOAD_DEMOGRAPHICS}_FAILED`,
  data,
})

export const downloadDemographicTemplate = surveyId => ({
  type: `${DOWNLOAD_DEMOGRAPHICS_TEMPLATE}_PENDING`,
  surveyId,
})

export const successDownloadDemographicTemplate = () => ({
  type: `${DOWNLOAD_DEMOGRAPHICS_TEMPLATE}_SUCCESS`,
})

export const failedDownloadDemographicTemplate = data => ({
  type: `${DOWNLOAD_DEMOGRAPHICS_TEMPLATE}_FAILED`,
  data,
})

export const getDemographicData = (surveyId, languageId) => {
  return {
    type: `${GET_DEMOGRAPHIC_DATA}_PENDING`,
    surveyId,
    languageId,
  }
}

export const successGetDemographicData = data => ({
  type: `${GET_DEMOGRAPHIC_DATA}_SUCCESS`,
  data,
})

export const failedGetDemographicData = data => ({
  type: `${GET_DEMOGRAPHIC_DATA}_FAILED`,
  data,
})

export const addDemographicsArray = (payload, surveyId) => ({
  type: `${ADD_DEMOGRAPHICS_ARRAY}_PENDING`,
  payload,
  surveyId,
})

export const getDemographicTypeAndLanguage = surveyId => ({
  type: `${GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE}_PENDING`,
  surveyId,
})

export const successGetDemographicTypeAndLanguage = data => ({
  type: `${GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE}_SUCCESS`,
  data,
})

export const failedGetDemographicTypeAndLanguage = data => ({
  type: `${GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE}_FAILED`,
  data,
})

export const resetUploadDemographics = () => ({
  type: RESET_UPLOAD_DEMOGRAPHICS,
})

export const updateDemographic = (
  demographic,
  surveyId,
  languageId,
  isBaseLang,
  isContinueAction
) => ({
  type: `${UPDATE_DEMOGRAPHIC}_PENDING`,
  demographic,
  surveyId,
  languageId,
  isBaseLang,
  isContinueAction,
})

export const successUpdateDemographic = () => ({
  type: `${UPDATE_DEMOGRAPHIC}_SUCCESS`,
})

export const failedUpdateDemographic = data => ({
  type: `${UPDATE_DEMOGRAPHIC}_FAILED`,
  data,
})

export const setDemographicStateChanged = isDemographicStateChanged => ({
  type: DEMOGRAPHIC_STATE_CHANGE,
  isDemographicStateChanged,
})

export const addNewDemographicsInList = (
  payload,
  surveyId,
  languageId,
  isFile
) => ({
  type: `${ADD_NEW_DEMOGRAPHICS_IN_LIST}_PENDING`,
  payload,
  surveyId,
  languageId,
  isFile,
})

export const successAddNewDemographicsInList = data => ({
  type: `${ADD_NEW_DEMOGRAPHICS_IN_LIST}_SUCCESS`,
  data,
})

export const failedAddNewDemographicsInList = data => ({
  type: `${ADD_NEW_DEMOGRAPHICS_IN_LIST}_FAILED`,
  data,
})

export const resetAddNewDemographicsInList = () => ({
  type: RESET_ADD_NEW_DEMOGRAPHICS_IN_LIST,
})

export const getTags = surveyId => ({
  type: `${GET_TAGS}_PENDING`,
  surveyId,
})

export const successGetTags = data => ({
  type: `${GET_TAGS}_SUCCESS`,
  data,
})

export const failedGetTags = () => ({
  type: `${GET_TAGS}_FAILED`,
})

export const getBenchmarkData = surveyId => {
  return {
    type: `${GET_BENCHMARK_DATA}_PENDING`,
    surveyId,
  }
}

export const successGetBenchmarkData = data => ({
  type: `${GET_BENCHMARK_DATA}_SUCCESS`,
  data,
})

export const failedGetBenchmarkData = data => ({
  type: `${GET_BENCHMARK_DATA}_FAILED`,
  data,
})

export const updateBenchmarkDataWithTag = (surveyId, updatedData) => {
  return {
    type: `${UPDATE_BENCHMARK_DATA}_PENDING`,
    surveyId,
    updatedData,
  }
}

export const successUpdateBenchmarkDataWithTag = data => ({
  type: `${UPDATE_BENCHMARK_DATA}_SUCCESS`,
  data,
})

export const failedUpdateBenchmarkDataWithTag = data => ({
  type: `${UPDATE_BENCHMARK_DATA}_FAILED`,
  data,
})

export const viewUpdateSurvey = (surveyId, viewSurvey) => ({
  type: `${VIEW_UPDATE_SURVEY}_PENDING`,
  surveyId,
  viewSurvey,
})

export const successViewUpdateSurvey = data => ({
  type: `${VIEW_UPDATE_SURVEY}_SUCCESS`,
  data,
})

export const failedViewUpdateSurvey = data => ({
  type: `${VIEW_UPDATE_SURVEY}_FAILED`,
  data,
})

export const resetDemographicList = () => ({
  type: RESET_DEMOGRAPHIC_LIST,
})

export const resetUpdateDemographicData = () => ({
  type: RESET_UPDATE_DEMOGRAPHIC_DATA,
})
