import React, { useEffect, useState, memo } from 'react'
import useStyles from './styles'
import {
  Box,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  createMuiTheme,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core'
import validateInclusion from '../../../../validations/inclusion'

import { ThemeProvider } from '@material-ui/styles'

//component
const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#021c4b',
    },
    secondary: {
      main: '#4b93fd',
    },
  },
})

function EditWorkEx({
  hideworkexEdit,
  inclusiondata,
  inlusiondataStaus,
  updateWorkEx,
  params,
}) {
  const classes = useStyles()

  const [inclusion, setInfInclusiondata] = useState({
    type: '',
  })

  const [loading, setLoading] = React.useState(false)
  const [msg, setMsg] = useState('')

  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (inclusiondata.get('isFetched')) {
      setInfInclusiondata(inclusiondata.toJS().workitems)
    }
  }, [inclusiondata])
  useEffect(() => {
    if (inlusiondataStaus.get('isFetched')) {
      setMsg(inlusiondataStaus.get('items'))
      setLoading(false)
      /* setLoading(false)
        addToast(influencerStatus.get('items').get('data'), {
          appearance: influencerStatus.get('items').get('error')
            ? 'error'
            : 'success',
          autoDismiss: true,
        }) */

      if (!inlusiondataStaus.get('items').get('error')) {
        hideworkexEdit()
      }
    }
  }, [inlusiondataStaus])
  //console.log(influencerdata)
  function handleInputChangeinf(name) {
    return function(event) {
      setInfInclusiondata({ ...inclusion, [name]: event.target.value })
    }
  }
  // console.log('inclusion', inclusion)
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      <ThemeProvider theme={outerTheme}>
        <Box className="overflowAuto">
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionHeading}
          >
            Employee Experience
          </Typography>
          {/* <Box className={classes.subtitle}>
            Note: your Inclusion open-ended questions will appear below when you
            "Save" at the bottom of the main page."
          </Box> */}

          {/*<FormLabel component="legend" className={classes.qtypelable}>*/}
          {/*  Inclusion Module Version 1*/}
          {/*</FormLabel>*/}
          <RadioGroup
            aria-label="type"
            name="type"
            value={`${inclusion.type}`}
            style={{ marginBottom: 20, marginTop: 20 }}
            onChange={handleInputChangeinf('type')}
            row
          >
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              style={{ marginBottom: '0' }}
              value="1"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="EX standard questions"
              labelPlacement="end"
            />
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              style={{ marginBottom: '0' }}
              value="2"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="EX with additional questions"
              labelPlacement="end"
            />

            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              style={{ marginBottom: '0' }}
              value="3"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="Employee Journey"
              labelPlacement="end"
            />

            {errors.type && (
              <FormHelperText
                id="my-helper-text-type"
                className={classes.myhelptext}
              >
                {errors.type}
              </FormHelperText>
            )}
          </RadioGroup>
        </Box>
      </ThemeProvider>
      {renderBottom()}
    </Box>
  )

  function renderBottom() {
    function cancelButton() {
      hideworkexEdit()
      setTimeout(function() {
        setInfInclusiondata(inclusiondata.toJS().workitems)
      }, 200)
    }
    function saveInclusion() {
      setErrors(validateInclusion(inclusion))
      setLoading(true)

      if (Object.keys(validateInclusion(inclusion)).length === 0) {
        setLoading(true)
        const copyinclusion = { ...inclusion }
        copyinclusion.surveyId = params
        updateWorkEx(copyinclusion)
      }

      //console.log(inclusion)
    }
    return (
      <Box className={classes.footer}>
        <Box className={classes.btnWrapper}>
          <Button
            color="primary"
            disabled={loading}
            variant="contained"
            className={classes.buttonRound}
            onClick={saveInclusion}
          >
            Save
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonRound}
            onClick={cancelButton}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    )
  }
}

export default memo(EditWorkEx)
