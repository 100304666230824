import {
  INTRODUCTION_FETCH,
  FETCH_SIDE_PANEL_DATA_SUCCESS,
  FETCH_SIDE_PANEL_DATA_ERROR,
  FETCH_INTRO_DATA_LANG_SUCCESS,
  FETCH_INTRO_DATA_LANG_ERROR,
  FETCH_INTRO_DATA_LANG,
  SET_SELECTED_LANG,
  HAS_EDITOR_STATE_CHANGED,
  IMPORT_FILE_FAILURE,
  TOGGLE_IMPORT_FILE_FAILURE_CONTAINER,
} from './constants'

const INITIAL_STATE = {
  introductionTexts: {
    isMultipleLanguage: false,
    availableLanguages: [],
    languageName: '',
    languageId: '',
  },
  initialStateIntroTexts: '',
  occurenceIndices: [],
  findText: '',
  currentPositionToReplace: -1,
  sidePanelData: [],
  sidePanelDataError: false,
  multipleLanguageData: [],
  multipleLangDataError: false,
  languageId: -1,
  hasEditorStateChanged: false,
  importFileFailureData: [],
  importFailureContainerIsOpened: false,
  introDuctionClassNames: [],
}

const sureveyIntroductionFetch = (
  state = INITIAL_STATE,
  { type, data, languageId, hasEditorStateChanged }
) => {
  switch (type) {
    case `${INTRODUCTION_FETCH}_PENDING`: {
      return state
    }

    case `${INTRODUCTION_FETCH}_SUCCESS`: {
      return {
        ...state,
        introductionTexts: data,
        initialStateIntroTexts: data,
        languageId: data.languageId,
        introDuctionClassNames: data.surveyIntroductions.map(ele => ele.id),
      }
    }

    case `${INTRODUCTION_FETCH}_FAILED`: {
      return state
    }

    case FETCH_SIDE_PANEL_DATA_SUCCESS: {
      return { ...state, sidePanelData: data }
    }

    case FETCH_SIDE_PANEL_DATA_ERROR: {
      return { ...state, sidePanelDataError: true }
    }

    case FETCH_INTRO_DATA_LANG_SUCCESS: {
      const multLangData = JSON.parse(
        JSON.stringify(state.multipleLanguageData)
      )

      const sameLanguageIdFound = multLangData.find(
        data => data.languageId === languageId
      )
      if (sameLanguageIdFound) {
        multLangData.forEach(element => {
          if (element.languageId === languageId) {
            element.value = data
          }
        })
      } else {
        multLangData.push({ languageId: languageId, value: data })
      }

      const newData = JSON.parse(JSON.stringify(multLangData))
      const newState = { ...state, multipleLanguageData: newData }
      return Object.assign({}, newState)
    }

    case FETCH_INTRO_DATA_LANG_ERROR: {
      return { ...state, multipleLangDataError: true }
    }

    case FETCH_INTRO_DATA_LANG: {
      return { ...state, multipleLangDataError: false }
    }

    case SET_SELECTED_LANG: {
      return { ...state, languageId: languageId }
    }

    case HAS_EDITOR_STATE_CHANGED: {
      return { ...state, hasEditorStateChanged: hasEditorStateChanged }
    }

    case IMPORT_FILE_FAILURE: {
      return { ...state, importFileFailureData: data }
    }

    case TOGGLE_IMPORT_FILE_FAILURE_CONTAINER:
      return {
        ...state,
        importFailureContainerIsOpened: !state.importFailureContainerIsOpened,
      }

    default:
      return state
  }
}
export default sureveyIntroductionFetch
