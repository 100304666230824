import SurveyInfo from './SurveyInfo'
import reducer, {
  analystsreducer,
  countriesreducer,
  regionsreducer,
  populationsreducer,
  surveytypesreducer,
  resurveyreducer,
  surveyinforeducer,
  savesurveyinforeducer,
  cohesionDemoReducer,
  OhiVersionreducer,
  ClosingProtocolLanguagesReducer,
} from './reducer'

import sagas from './sagas'

export {
  reducer,
  analystsreducer,
  countriesreducer,
  regionsreducer,
  populationsreducer,
  surveytypesreducer,
  resurveyreducer,
  savesurveyinforeducer,
  surveyinforeducer,
  cohesionDemoReducer,
  OhiVersionreducer,
  ClosingProtocolLanguagesReducer,
  sagas,
}
export default SurveyInfo
