import React, { Fragment } from 'react'
import { Router } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { Box } from '@material-ui/core'
import Routes from '../../routes'
import ProgressBarLoader from '../ProgressBarLoader'
import PopupModal from '../PopupModal'
import history from '../../history'

import store from '../../store'
import axios from 'axios'
import { connect } from 'react-redux'
import { API_ERROR } from '../../common/constants'

import Login from '../../pages/Login'

const useStyles = makeStyles(() => {
  return {
    app: {
      textAlign: 'center',
      overflow: 'hidden',
      fontSize: '1.914vh',
    },
    routesWrapper: {
      minHeight: '100vh',
      position: 'relative',
    },
  }
})
axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response
  },
  function(error) {
    // Do something with response error
    store.dispatch({
      type: API_ERROR,
      payload: error.response,
    })
    return Promise.reject(error)
  }
)

function App({ loaderToggle, mid }) {
  const classes = useStyles()
  return (
    <Fragment>
      <Typography component="div" className={classes.app} color="textPrimary">
        <Box className={classes.routesWrapper}>
          <Login mid={mid}>
            <Router component={Fragment} history={history}>
              <Fragment>
                <Routes history={history} mid={mid} />
                {loaderToggle && (
                  <PopupModal>
                    <ProgressBarLoader />
                  </PopupModal>
                )}
              </Fragment>
            </Router>
          </Login>
        </Box>
      </Typography>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    loaderToggle: state.get('progressBarLoader').isLoaderOpen,
  }
}

export default connect(mapStateToProps)(App)
