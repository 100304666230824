import { GET_USER_DETAILS_SUCCESS } from '../common/constants'

const INITIAL_STATE = {
  data: {},
  items: [],
}

const userManagementReducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        data: data,
      }
    case `FETCH_REPLACE_WORD_SUCCESS`:
      return { ...state, items: data }
    default:
      return state
  }
}

export default userManagementReducer
