import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      padding: '48px 0px 0px 0px',
      fontSize: 12,
      color: '#333333',
      textAlign: 'left',
      height: '100%',
    },
    addinfluncerbtn: {
      color: '#4b93fd',
      fontWeight: 700,
      marginTop: 20,
    },
    sectionHeading: {
      fontSize: 18,
      fontWeight: 700,
    },
    qtypelable: {
      fontSize: 13,
    },
    minmaxCtrl: {
      display: 'flex',
      flexDirection: 'row',
      width: '50%',
      '& input': {
        fontSize: '18px',
        fontWeight: 700,
      },
    },
    footer: {
      borderTop: 'solid 1px #e5e5e5',
      padding: '20px 30px',
      position: 'absolute',
      width: '100%',
      left: 0,
      bottom: 0,
      textAlign: 'right',
      display: 'flex',
      alignContent: 'flex-end',
      flexDirection: 'row-reverse',
    },
    buttonRound: {
      borderRadius: '25px',
      fontWeight: '400',
      fontSize: 14,
    },
    btnWrapper: {
      position: 'relative',
      marginLeft: 20,
    },
    buttonProgress: {
      color: '#4b93fd',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    myhelptext: {
      color: '#ff0000',
      width: '100%',
    },
    subtitle: {
      fontSize: 12,
      lineHeight: 2.25,
      color: '#393939',
      textAlign: 'left',
      fontStyle: 'italic',
    },
    checkboxIconUnchecked: {
      fontSize: 25,
      color: '#999999',
    },
    checkboxIcon: {
      fontSize: 25,
      color: '#021c4b',
    },
  }
})

export default styles
