import React from 'react'

import { Switch } from '@material-ui/core'

import useStyles from './styles'

function SwitchToggle(props) {
  const classes = useStyles()
  return <Switch classes={classes} {...props} />
}

export default SwitchToggle
