import React, { useState } from 'react'
import deburr from 'lodash/deburr'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { TextField, Paper, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export default function CstAutosuggest({
  label = 'Select Label',
  suggestions = [],
  selectedvalue = '',
  name = '',
  handleChangefn,
}) {
  function renderInputComponent(inputProps) {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps

    return (
      <TextField
        fullWidth
        InputProps={{
          inputRef: node => {
            ref(node)
            inputRef(node)
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    )
  }

  function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.value, query)
    const parts = parse(suggestion.value, matches)

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{ fontWeight: part.highlight ? 500 : 400 }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    )
  }

  function getSuggestions(value) {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const keep =
            count < 5 && suggestion.value.toLowerCase().includes(inputValue)

          if (keep) {
            count += 1
          }

          return keep
        })
  }

  function getSuggestionValue(suggestion) {
    return suggestion.value
  }

  const useStyles = makeStyles(theme => ({
    container: {
      position: 'relative',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
  }))
  const classes = useStyles()
  const [state, setState] = useState('')
  const [stateSuggestions, setSuggestions] = useState([])

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const handleSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const handleChange = () => (event, { newValue, method }) => {
    setState(newValue)
    handleChangefn(newValue, name)
  }

  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  }

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          label: label,
          placeholder: 'Search a client',
          value: state === '' ? selectedvalue : state,
          onChange: handleChange(),
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  )
}
