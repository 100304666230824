import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  UPLOAD_DEMOGRAPHICS,
  DOWNLOAD_DEMOGRAPHICS_TEMPLATE,
  GET_DEMOGRAPHIC_DATA,
  ADD_DEMOGRAPHICS_ARRAY,
  GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE,
  UPDATE_DEMOGRAPHIC,
  ADD_NEW_DEMOGRAPHICS_IN_LIST,
  GET_TAGS,
  GET_BENCHMARK_DATA,
  UPDATE_BENCHMARK_DATA,
  VIEW_UPDATE_SURVEY,
} from './constants'
import request from '../../../utils/request'
import Endpoints from '../../../Endpoints'
import {
  failedUploadDemographics,
  successUploadDemographics,
  failedDownloadDemographicTemplate,
  successGetDemographicData,
  failedGetDemographicData,
  successGetDemographicTypeAndLanguage,
  failedGetDemographicTypeAndLanguage,
  successUpdateDemographic,
  failedUpdateDemographic,
  getDemographicData,
  successAddNewDemographicsInList,
  failedAddNewDemographicsInList,
  successGetTags,
  failedGetTags,
  successGetBenchmarkData,
  failedGetBenchmarkData,
  failedViewUpdateSurvey,
  successViewUpdateSurvey,
  successUpdateBenchmarkDataWithTag,
  failedUpdateBenchmarkDataWithTag,
  getBenchmarkData,
} from './actions'

function* uploadDemographicsFn({ file, surveyId, isGeneric, toValidate }) {
  const formData = new FormData()
  formData.append('file', file)
  try {
    const {
      body: { error },
    } = yield call(request, {
      method: isGeneric ? 'PUT' : 'POST',
      url: isGeneric
        ? Endpoints.uploadDemographics.replace(':surveyId', surveyId)
        : `${Endpoints.uploadBackCodedDemographics.replace(
            ':surveyId',
            surveyId
          )}${toValidate ? '/1' : ''}`,
      payload: formData,
    })
    if (!error) {
      yield put(successUploadDemographics())
    } else {
      yield put(failedUploadDemographics(error))
    }
  } catch (e) {
    yield put(failedUploadDemographics(e.response))
  }
}

function* downloadDemographicTemplateFn({ surveyId }) {
  try {
    const {
      body: { error },
      response,
      response: { data },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.downloadDemographicTemplate.replace(':surveyId', surveyId),
      responseType: 'arraybuffer',
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    if (!error) {
      var filename = response.headers['x-filename']
      filename = filename.replace(/\"/g, '')
      var blob = new Blob([data], { type: response['headers']['content-type'] })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      yield put(failedDownloadDemographicTemplate(error))
    }
  } catch (e) {
    yield put(failedDownloadDemographicTemplate(e.response))
  }
}

function* getDemographicsDataFn({ surveyId, languageId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.getDemographicsData
        .replace(':surveyId', surveyId)
        .replace(':languageId', languageId),
    })
    if (!error) {
      yield put(successGetDemographicData(data))
    } else {
      yield put(failedGetDemographicData(error))
    }
  } catch (e) {
    yield put(failedGetDemographicData(e.response))
  }
}

function* getBenchmarkDataFn({ surveyId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.getBenchmarkData.replace(':surveyId', surveyId), //doubt
    })
    if (!error) {
      yield put(successGetBenchmarkData(data))
    } else {
      yield put(failedGetBenchmarkData(error))
    }
  } catch (e) {
    yield put(failedGetBenchmarkData(e.response))
  }
}

function* addDemographicsArrayFn({ payload, surveyId }) {
  try {
    const {
      body: { error },
    } = yield call(request, {
      method: 'POST',
      url: Endpoints.addDemographicsArray.replace(':surveyId', surveyId),
      payload: payload,
    })
    if (!error) {
      yield put(successUploadDemographics())
    } else {
      yield put(failedUploadDemographics(error))
    }
  } catch (e) {
    yield put(failedUploadDemographics(e.response.data))
  }
}

function* getDemographicTypeAndLanguageFn({ surveyId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.getDemographicTypeLanguage.replace(':surveyId', surveyId),
    })
    if (!error) {
      yield put(successGetDemographicTypeAndLanguage(data))
    } else {
      yield put(failedGetDemographicTypeAndLanguage(error))
    }
  } catch (e) {
    yield put(failedGetDemographicTypeAndLanguage(e.response))
  }
}

function* updateDemographicFn({
  demographic,
  surveyId,
  languageId,
  isBaseLang,
  isContinueAction,
}) {
  try {
    const {
      body: { error },
    } = yield call(request, {
      method: 'PUT',
      url: !isBaseLang
        ? Endpoints.getDemographicsData
            .replace(':surveyId', surveyId)
            .replace(':languageId', languageId)
        : Endpoints.updateDemographicDataForBaseLanguage.replace(
            ':surveyId',
            surveyId
          ),
      payload: demographic,
    })
    if (!error) {
      yield put(successUpdateDemographic())
      if (!isContinueAction) {
        yield put(getDemographicData(surveyId, languageId))
      }
    } else {
      yield put(failedUpdateDemographic(error))
    }
  } catch (e) {
    yield put(failedUpdateDemographic(e.response))
  }
}

function* addNewDemographicInListFn({ payload, surveyId, languageId, isFile }) {
  const formData = new FormData()
  if (isFile) {
    formData.append('file', payload)
  }
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'POST',
      url: isFile
        ? Endpoints.updateDemographicFile.replace(':surveyId', surveyId)
        : Endpoints.addNewDemographicsInList.replace(':surveyId', surveyId),
      payload: isFile ? formData : payload,
    })
    if (!error) {
      yield put(successAddNewDemographicsInList(data))
      yield put(getDemographicData(surveyId, languageId))
    } else {
      yield put(failedAddNewDemographicsInList(error))
    }
  } catch (e) {
    yield put(failedAddNewDemographicsInList(e.response.data))
  }
}

function* getTagsFn({ surveyId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.getTags.replace(':surveyId', surveyId),
    })
    if (!error) {
      yield put(successGetTags(data))
    } else {
      yield put(failedGetTags(error))
    }
  } catch (e) {
    yield put(failedGetTags(e.response))
  }
}

function* upadteBenchmarkDataFn({ surveyId, updatedData }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.getBenchmarkData.replace(':surveyId', surveyId),
      payload: updatedData,
    })
    if (!error) {
      yield put(successUpdateBenchmarkDataWithTag(data))
      yield put(getBenchmarkData(surveyId))
    } else {
      yield put(failedUpdateBenchmarkDataWithTag(error))
    }
  } catch (e) {
    yield put(failedUpdateBenchmarkDataWithTag(e.response))
  }
}

function* viewUpdateSurveyFn({ surveyId, viewSurvey }) {
  const state = yield select()
  const surveyName = state.get('surveyname').toJS()
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.viewUpdateSurvey.replace(':surveyId', surveyId),
    })
    if (!error) {
      yield put(successViewUpdateSurvey(data))
      if (viewSurvey) {
        window.open(surveyName.items.surveyUrl, '_blank')
      }
    } else {
      yield put(failedViewUpdateSurvey(error))
    }
  } catch (e) {
    if (viewSurvey) {
      window.open(surveyName.items.surveyUrl, '_blank')
    }
    yield put(failedViewUpdateSurvey(e.response))
  }
}

const DemographicsSagas = [
  takeLatest(`${UPLOAD_DEMOGRAPHICS}_PENDING`, uploadDemographicsFn),
  takeLatest(
    `${DOWNLOAD_DEMOGRAPHICS_TEMPLATE}_PENDING`,
    downloadDemographicTemplateFn
  ),
  takeLatest(`${GET_DEMOGRAPHIC_DATA}_PENDING`, getDemographicsDataFn),
  takeLatest(`${ADD_DEMOGRAPHICS_ARRAY}_PENDING`, addDemographicsArrayFn),
  takeLatest(
    `${GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE}_PENDING`,
    getDemographicTypeAndLanguageFn
  ),
  takeLatest(`${UPDATE_DEMOGRAPHIC}_PENDING`, updateDemographicFn),
  takeLatest(
    `${ADD_NEW_DEMOGRAPHICS_IN_LIST}_PENDING`,
    addNewDemographicInListFn
  ),
  takeLatest(`${GET_TAGS}_PENDING`, getTagsFn),
  takeLatest(`${GET_BENCHMARK_DATA}_PENDING`, getBenchmarkDataFn),
  takeLatest(`${UPDATE_BENCHMARK_DATA}_PENDING`, upadteBenchmarkDataFn),
  takeLatest(`${VIEW_UPDATE_SURVEY}_PENDING`, viewUpdateSurveyFn),
]

export default DemographicsSagas
