import { call, put, takeLatest, select, all } from 'redux-saga/effects'

import {
  additionalQuestionsFetchData,
  additionalQuestionsFetchFailed,
  fetchIntroDataForAdditionalQuestions,
  fetchIntroDataForLangError,
} from './actions'

import {
  ADDITIONAL_QUESTIONS_FETCH,
  FETCH_ADDITONAL_QUESTIONS_DATA_LANG,
  UPDATE_SURVEY_ADDITIONAL_QUESTIONS,
  REPLACE_EVERYWHERE,
} from './constants'
import Endpoints from '../../Endpoints'
import request from '../../utils/request'
import { setPayloadForQuestions } from './utils'
import {
  toggleSummaryOfEdits,
  toggleToastOnSuccess,
} from '../../common/actions'
import { getSummaryOfEdits } from '../SurveyIntroduction/sagas'

// GET introductionInfo
function* fetchAdditionalQuestionsInfofn({ surveyId }) {
  try {
    const pathname = window.location.pathname.replace(/%20/g, ' ')
    const getUrl = pathname.includes('standard demographics')
      ? Endpoints.standardDemographics
      : Endpoints.additionalQuestion
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: getUrl.replace(':id', surveyId),
    })
    if (!error) {
      yield put(additionalQuestionsFetchData(data))
    } else {
      yield put(additionalQuestionsFetchFailed())
    }
    yield put(toggleToastOnSuccess({ successText: '', openToast: false }))
  } catch (e) {
    yield put(additionalQuestionsFetchFailed(e))
  }
}

function* getAdditonalQuestionsForLang({ surveyId, languageId }) {
  try {
    const pathname = window.location.pathname.replace(/%20/g, ' ')
    const getUrl = pathname.includes('standard demographics')
      ? Endpoints.standardDemographicsLang
      : Endpoints.additionalQuestionLanguage
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: getUrl.replace(':id', surveyId).replace(':languageId', languageId),
    })
    if (!error) {
      yield put(fetchIntroDataForAdditionalQuestions({ data, languageId }))
    }
    yield put(toggleToastOnSuccess({ successText: '', openToast: false }))
    yield put(fetchIntroDataForLangError())
  } catch (e) {
    yield put(fetchIntroDataForLangError(e))
  }
}

function* fetchIntroductionForLang() {
  yield takeLatest(
    FETCH_ADDITONAL_QUESTIONS_DATA_LANG,
    getAdditonalQuestionsForLang
  )
}

function* getAdditionalQuestionsInfofn() {
  yield takeLatest(
    `${ADDITIONAL_QUESTIONS_FETCH}_PENDING`,
    fetchAdditionalQuestionsInfofn
  )
}

function* updateAdditionalQuestionsText({
  editorState,
  surveyId,
  languageId,
  isSync,
}) {
  const state = yield select()
  const originaldata = state.get('surveyAdditionalQuestionsFetch')[
    'originalApiData'
  ]

  var payload = setPayloadForQuestions(originaldata, editorState)
  payload.surveyId = surveyId
  payload.languageId = parseInt(languageId)
  payload.languageName = ''
  payload.isSync = isSync

  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.additionalQuestion.replace(':id', surveyId),
      payload: payload,
    })
    if (!error && isSync) {
      return yield put(
        toggleToastOnSuccess({ successText: data, openToast: true })
      )
    }
  } catch (e) {}
}

function* updateSurveyAdditionalQuestions() {
  yield takeLatest(
    UPDATE_SURVEY_ADDITIONAL_QUESTIONS,
    updateAdditionalQuestionsText
  )
}

function* replaceEveryWhereSaga({
  isCaseSensitive,
  replaceFrom,
  replaceTo,
  langId,
  toggleState,
}) {
  const state = yield select()
  const surveyId = state
    .get('surveyname')
    .get('items')
    .get('id')
  try {
    const {
      body: { error, data },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.replaceEveryWhere.replace(':id', surveyId),
      payload: {
        surveyId: surveyId,
        langId: Number(langId),
        replaceFrom: replaceFrom,
        replaceTo: replaceTo,
        isCaseSensitive: isCaseSensitive,
      },
    })
    if (!error) {
      if (toggleState) {
        yield all([
          setToastOnSuccess({ successText: data, openToast: true }),
          getAdditonalQuestionsForLang({
            surveyId: surveyId,
            languageId: langId,
          }),
        ])
      } else {
        yield all([
          setToastOnSuccess({ successText: data, openToast: true }),
          fetchAdditionalQuestionsInfofn({ surveyId }),
        ])
      }
    }
  } catch (e) {}
}

function* replaceEveryWhereYielder() {
  yield takeLatest(REPLACE_EVERYWHERE, replaceEveryWhereSaga)
}

function* setToastOnSuccess({ successText, openToast }) {
  yield put(toggleToastOnSuccess({ successText, openToast }))
}

export default [
  getAdditionalQuestionsInfofn,
  fetchIntroductionForLang,
  updateSurveyAdditionalQuestions,
  replaceEveryWhereYielder,
]
