import {
  LANGUAGES_FETCH,
  ANALYSTS_FETCH,
  COUNTRIES_FETCH,
  POPULATIONS_FETCH,
  REGIONS_FETCH,
  SURVEYSTYPES_POPULATIONS_FETCH,
  GETRESURVEY_FETCH,
  SURVEYINFO_FETCH,
  SURVEYINFO_CREATE,
  COHESION_DEMO,
  OHI_VERSION_FETCH,
} from './constants'

// SAVE SURVEY INFO
export const surveyInfoSave = payload => ({
  type: `${SURVEYINFO_CREATE}_PENDING`,
  payload,
})

export const surveyInfoSaveSuccess = payload => ({
  type: `${SURVEYINFO_CREATE}_SUCCESS`,
  payload,
})

export const surveyInfoSaveFailed = payload => ({
  type: `${SURVEYINFO_CREATE}_FAILED`,
  payload,
})

// SURVEYINFO_FETCH
export const getSurveyinfoFetch = payload => ({
  type: `${SURVEYINFO_FETCH}_PENDING`,
  payload,
})

export const getSurveyinfoSuccess = payload => ({
  type: `${SURVEYINFO_FETCH}_SUCCESS`,
  payload,
})

export const getSurveyinfoFailed = payload => ({
  type: `${SURVEYINFO_FETCH}_FAILED`,
  payload,
})
// GETRESURVEY_FETCH
export const getResurveyFetch = payload => ({
  type: `${GETRESURVEY_FETCH}_PENDING`,
  payload,
})

export const getResurveySuccess = payload => ({
  type: `${GETRESURVEY_FETCH}_SUCCESS`,
  payload,
})

export const getResurveyFailed = payload => ({
  type: `${GETRESURVEY_FETCH}_FAILED`,
  payload,
})

// SURVEYSTYPES_POPULATIONS_FETCH
export const surveyTypesFetch = payload => ({
  type: `${SURVEYSTYPES_POPULATIONS_FETCH}_PENDING`,
  payload,
})

export const surveyTypesSuccess = payload => ({
  type: `${SURVEYSTYPES_POPULATIONS_FETCH}_SUCCESS`,
  payload,
})

export const surveyTypesFailed = payload => ({
  type: `${SURVEYSTYPES_POPULATIONS_FETCH}_FAILED`,
  payload,
})
// REGIONS_FETCH
export const regionsFetch = payload => ({
  type: `${REGIONS_FETCH}_PENDING`,
  payload,
})

export const regionsFetchSuccess = payload => ({
  type: `${REGIONS_FETCH}_SUCCESS`,
  payload,
})

export const regionsFetchFailed = payload => ({
  type: `${REGIONS_FETCH}_FAILED`,
  payload,
})

// POPULATIONS_FETCH
export const populationsFetch = payload => ({
  type: `${POPULATIONS_FETCH}_PENDING`,
  payload,
})

export const populationsFetchSuccess = payload => ({
  type: `${POPULATIONS_FETCH}_SUCCESS`,
  payload,
})

export const populationsFetchFailed = payload => ({
  type: `${POPULATIONS_FETCH}_FAILED`,
  payload,
})

// COUNTRIES_FETCH
export const countriesFetch = payload => ({
  type: `${COUNTRIES_FETCH}_PENDING`,
  payload,
})

export const countriesFetchSuccess = payload => ({
  type: `${COUNTRIES_FETCH}_SUCCESS`,
  payload,
})

export const countriesFetchFailed = payload => ({
  type: `${COUNTRIES_FETCH}_FAILED`,
  payload,
})

// LANGUAGE_FETCH
export const languageFetch = payload => ({
  type: `${LANGUAGES_FETCH}_PENDING`,
  payload,
})

export const languageFetchSuccess = payload => ({
  type: `${LANGUAGES_FETCH}_SUCCESS`,
  payload,
})

export const languageFetchFailed = payload => ({
  type: `${LANGUAGES_FETCH}_FAILED`,
  payload,
})

// ANALYST_FETCH
export const analystFetch = payload => ({
  type: `${ANALYSTS_FETCH}_PENDING`,
  payload,
})

export const analystFetchSuccess = payload => ({
  type: `${ANALYSTS_FETCH}_SUCCESS`,
  payload,
})

export const analystFetchFailed = payload => ({
  type: `${ANALYSTS_FETCH}_FAILED`,
  payload,
})

export const fetchCohesionDemoPendingAction = payload => ({
  type: `${COHESION_DEMO}_PENDING`,
  payload,
})

export const fetchCohesionDemoSuccessAction = payload => ({
  type: `${COHESION_DEMO}_SUCCESS`,
  payload,
})

export const fetchCohesionDemoFailAction = payload => ({
  type: `${COHESION_DEMO}_FAILED`,
  payload,
})

// OHI_VERSION_FETCH
export const OhiVersionFetch = payload => ({
  type: `${OHI_VERSION_FETCH}_PENDING`,
  payload,
})

export const getOhiVersionSuccess = payload => ({
  type: `${OHI_VERSION_FETCH}_SUCCESS`,
  payload,
})

export const getOhiVersionFailed = payload => ({
  type: `${OHI_VERSION_FETCH}_FAILED`,
  payload,
})

export const getAllLanguagesPending = payload => ({
  type: 'FETCH_ALL_LANG_PENDING',
  payload,
})

export const getAllLanguagesSuccess = payload => ({
  type: 'FETCH_ALL_LANG_SUCCESS',
  payload,
})

export const getAllLanguagesFailed = payload => ({
  type: 'FETCH_ALL_LANG_FAILED',
  payload,
})
