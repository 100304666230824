import {
  GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE,
  GET_DEMOGRAPHIC_DATA,
  UPLOAD_DEMOGRAPHICS,
  RESET_UPLOAD_DEMOGRAPHICS,
  DEMOGRAPHIC_STATE_CHANGE,
  ADD_NEW_DEMOGRAPHICS_IN_LIST,
  RESET_ADD_NEW_DEMOGRAPHICS_IN_LIST,
  GET_TAGS,
  GET_BENCHMARK_DATA,
  RESET_DEMOGRAPHIC_LIST,
  UPDATE_DEMOGRAPHIC,
  RESET_UPDATE_DEMOGRAPHIC_DATA,
} from './constants'
const INITIAL_STATE = {
  demographicsData: {
    isFetched: false,
    list: [],
    isDemograpicsLock: false,
    importFileName: '',
  },

  benchmarkData: {
    isFetched: false,
    list: [],
    isBenchMarkingComplete: false,
  },

  uploadDemographicsData: {
    isSuccess: false,
    isFailed: false,
    error: {},
    errorStatus: '',
  },

  demographicsTypeAndLanguageData: {
    isGeneric: false,
    isDemographicDataExist: false,
    languages: [],
    isFetched: false,
  },

  isDemographicStateChanged: false,

  addNewDemographicsInListData: {
    newIdsAdded: [],
    isSuccess: false,
    isFailed: false,
    error: {},
  },
  tagsData: {
    isFetched: false,
    list: [],
    // isDemograpicsLock: true,
  },

  updateDemographicsData: {
    isSuccess: false,
    isFailed: false,
  },
}

const demographicsReducer = (
  state = INITIAL_STATE,
  { type, data, isDemographicStateChanged }
) => {
  switch (type) {
    case `${UPLOAD_DEMOGRAPHICS}_PENDING`: {
      return {
        ...state,
        uploadDemographicsData: {
          isSuccess: false,
          isFailed: false,
          error: {},
        },
      }
    }

    case `${UPLOAD_DEMOGRAPHICS}_SUCCESS`: {
      return {
        ...state,
        uploadDemographicsData: {
          isSuccess: true,
          isFailed: false,
          error: {},
        },
      }
    }

    case `${UPLOAD_DEMOGRAPHICS}_FAILED`: {
      return {
        ...state,
        uploadDemographicsData: {
          isSuccess: false,
          isFailed: true,
          error: data.data.data,
          errorStatus: data.status,
        },
      }
    }

    case `${GET_DEMOGRAPHIC_DATA}_PENDING`:
      return {
        ...state,
        demographicsData: {
          list: state.demographicsData.list,
          isFetched: false,
          isDemograpicsLock: false,
          importFileName: '',
        },
      }

    case `${GET_DEMOGRAPHIC_DATA}_SUCCESS`:
      return {
        ...state,
        demographicsData: {
          list: data.Questions,
          isFetched: true,
          isDemograpicsLock: data.isDemograpicsLock,
          importFileName: data.importFileName,
        },
      }

    case `${GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE}_PENDING`: {
      return {
        ...state,
        demographicsTypeAndLanguageData: {
          isGeneric: false,
          isDemographicDataExist: false,
          languages: [],
          isFetched: false,
        },
      }
    }

    case `${GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE}_SUCCESS`: {
      let baseLangIndex = 0
      data.languages.filter((item, index) => {
        if (item.isBaseLang) {
          baseLangIndex = index
        }
      })
      const baseLangObject = data.languages.splice(baseLangIndex, 1)
      data.languages.unshift(baseLangObject[0])
      return {
        ...state,
        demographicsTypeAndLanguageData: {
          isGeneric: data.isGeneric,
          isDemographicDataExist: data.isDemographicDataExist,
          languages: data.languages,
          isFetched: true,
          baseLanguage: data.languages.filter(item => item.isBaseLang),
        },
      }
    }

    case `${GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE}_FAILED`: {
      return {
        ...state,
        demographicsTypeAndLanguageData: {
          isFetched: false,
        },
      }
    }

    case RESET_UPLOAD_DEMOGRAPHICS: {
      return {
        ...state,
        uploadDemographicsData: {
          isSuccess: false,
          isFailed: false,
        },
      }
    }

    case DEMOGRAPHIC_STATE_CHANGE: {
      return {
        ...state,
        isDemographicStateChanged: isDemographicStateChanged,
      }
    }

    case `${ADD_NEW_DEMOGRAPHICS_IN_LIST}_PENDING`: {
      return {
        ...state,
        addNewDemographicsInListData: {
          newIdsAdded: [],
          isSuccess: false,
          isFailed: false,
          error: {},
        },
      }
    }

    case `${ADD_NEW_DEMOGRAPHICS_IN_LIST}_SUCCESS`: {
      return {
        ...state,
        addNewDemographicsInListData: {
          newIdsAdded: data.ids,
          isSuccess: true,
          isFailed: false,
          error: {},
        },
      }
    }

    case `${ADD_NEW_DEMOGRAPHICS_IN_LIST}_FAILED`: {
      return {
        ...state,
        addNewDemographicsInListData: {
          isSuccess: false,
          isFailed: true,
          error: data.data,
          newIdsAdded: [],
        },
      }
    }

    case RESET_ADD_NEW_DEMOGRAPHICS_IN_LIST: {
      return {
        ...state,
        addNewDemographicsInListData: {
          newIdsAdded: [],
          isSuccess: false,
          isFailed: false,
        },
      }
    }

    case `${GET_TAGS}_PENDING`: {
      return {
        ...state,
        tagsData: {
          isSuccess: false,
          isFailed: false,
        },
      }
    }

    case `${GET_TAGS}_SUCCESS`: {
      return {
        ...state,
        tagsData: {
          isSuccess: true,
          isFailed: false,
          data: data,
        },
      }
    }

    case `${GET_TAGS}_FAILED`: {
      return {
        ...state,
        tagsData: {
          isSuccess: false,
          isFailed: true,
        },
      }
    }

    case `${GET_BENCHMARK_DATA}_PENDING`:
      return {
        ...state,
        benchmarkData: {
          list: [],
          isFetched: false,
        },
      }

    case `${GET_BENCHMARK_DATA}_SUCCESS`:
      return {
        ...state,
        benchmarkData: {
          list: data,
          isFetched: true,
          isBenchMarkingComplete: data.every(
            dataItem => dataItem.benchmarkId !== 0
          ),
        },
      }

    case RESET_DEMOGRAPHIC_LIST:
      return {
        ...state,
        demographicsData: {
          isFetched: false,
          list: [],
          isDemograpicsLock: state.demographicsData.isDemograpicsLock,
        },
      }

    case `${UPDATE_DEMOGRAPHIC}_PENDING`: {
      return {
        ...state,
        updateDemographicsData: {
          isSuccess: false,
          isFailed: false,
        },
      }
    }

    case `${UPDATE_DEMOGRAPHIC}_SUCCESS`: {
      return {
        ...state,
        updateDemographicsData: {
          isSuccess: true,
          isFailed: false,
        },
      }
    }

    case `${UPDATE_DEMOGRAPHIC}_FAILED`: {
      return {
        ...state,
        updateDemographicsData: {
          isSuccess: false,
          isFailed: true,
        },
      }
    }

    case RESET_UPDATE_DEMOGRAPHIC_DATA: {
      return {
        ...state,
        updateDemographicsData: {
          isSuccess: false,
          isFailed: false,
        },
      }
    }

    default:
      return state
  }
}
export default demographicsReducer
