import {
  FETCH_OHI_OUTCOME_DATA_SUCCESS,
  FETCH_OHI_OUTCOMES_DATA_LANG,
  FETCH_OHI_OUTCOMES_DATA_LANG_ERROR,
  FETCH_OHI_OUTCOMES_DATA_LANG_SUCCESS,
  SET_SELECTED_LANG,
} from './constants'
import { transformData } from './utils'

const INITIAL_STATE = {
  OHIOutcomeData: '',
  initialStateIntroTexts: '',
  occurenceIndices: [],
  findText: '',
  currentPositionToReplace: -1,
  multipleLanguageData: [],
  multipleLangDataError: false,
  languageId: -1,
  originalOHIOutcomeData: null,
  numberOfBlocksElement: 0,
}

const ohiOutcomeDataFetch = (
  state = INITIAL_STATE,
  { type, data, languageId, outcomeId }
) => {
  switch (type) {
    case FETCH_OHI_OUTCOME_DATA_SUCCESS: {
      const dataObj = transformData(data)
      return {
        ...state,
        OHIOutcomeData: dataObj.data,
        numberOfBlocksElement: dataObj.numberOfBlocksElement,
        originalOHIOutcomeData: data,
      }
    }

    case FETCH_OHI_OUTCOMES_DATA_LANG_SUCCESS: {
      const multLangData = JSON.parse(
        JSON.stringify(state.multipleLanguageData)
      )

      const sameLanguageIdFound = multLangData.find(
        data => data.languageId === languageId && data.outcomeId === outcomeId
      )
      if (sameLanguageIdFound) {
        multLangData.forEach(element => {
          if (
            element.languageId === languageId &&
            element.outcomeId === outcomeId
          ) {
            element.value = data
          }
        })
      } else {
        multLangData.push({
          languageId: languageId,
          value: data,
          outcomeId: outcomeId,
        })
      }

      const newData = JSON.parse(JSON.stringify(multLangData))
      const newState = {
        ...state,
        multipleLanguageData: newData,
        originalOHIOutcomeData: data,
      }
      return Object.assign({}, newState)
    }

    case FETCH_OHI_OUTCOMES_DATA_LANG_ERROR: {
      return { ...state, multipleLangDataError: true }
    }

    case FETCH_OHI_OUTCOMES_DATA_LANG: {
      return { ...state, multipleLangDataError: false }
    }

    case SET_SELECTED_LANG: {
      return { ...state, languageId: languageId }
    }

    default:
      return state
  }
}
export default ohiOutcomeDataFetch
