import { TOGGLE_TOAST_ON_SUCCESS } from '../common/constants'

const INITIAL_STATE = {
  openToast: false,
  successText: '',
}

const successToast = (
  state = INITIAL_STATE,
  { type, openToast, successText }
) => {
  switch (type) {
    case TOGGLE_TOAST_ON_SUCCESS:
      return {
        ...state,
        openToast: openToast,
        successText: successText,
      }
    default:
      return state
  }
}

export default successToast
