import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    tarnsformArrow: {
      transform: 'rotate(180deg)',
    },
    menuItemText: {
      fontSize: '1.7vh',
    },
  }
})

export default styles
