import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Fab, Typography, Box } from '@material-ui/core'

import loginMetaPdf from './assets/2022 Okta Setup and Portal Access Guide.pdf'
import { parseJwt } from '../../utils'
import useStyles from './styles'
import Unauthenticated from '../../components/UnAuthenticated/Unauthenticated'
import useInterval from '../../components/useInterval'
import logo from './assets/OHILogo_Login.png'

function Login({ mid, children }) {
  document.title = 'OHI Portal - Login'
  const classes = useStyles()
  const accessToken = localStorage.getItem('accessToken')

  const [loginState, updateState] = useState({
    isAuthed: false,
    isLoading: true,
  })
  const [isUnAuthed, setUnauthed] = useState(false)

  const { isAuthed, isLoading } = loginState

  const checkIsAuth = checkAuth => {
    updateState({ isAuthed: checkAuth, isLoading: false })
  }

  const checkForRefresh = () => {
    const access_token = mid.accessToken()
    const auth_time = localStorage.getItem('auth_time') || 0
    if (access_token && auth_time && moment().unix() - auth_time < 10800) {
      mid.isAuthed().then(isUserLoggedIn => {
        if (isUserLoggedIn) {
          mid.refresh().then(() => {
            const access_token = mid.accessToken()
            // axios.defaults.headers.common[
            //   'Authorization'
            // ] = `Bearer ${access_token}`;
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            userInfo['midToken'] = access_token
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
          })
        }
      })
    }
  }

  useInterval(() => checkForRefresh(), 900000)

  const checkValidateUser = async () => {
    const access_token = mid.accessToken()
    if (access_token) {
      const infoFromToken = parseJwt(access_token)
      const { auth_time = 0 } = infoFromToken
      try {
        const { data } = await axios.request({
          method: 'GET',
          url: '/api/account/ValidateUser',
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const {
          data: userInfoData,
          data: { token = '' },
        } = data
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        localStorage.setItem('auth_time', auth_time)
        localStorage.setItem('accessToken', token)
        userInfoData['midToken'] = access_token
        localStorage.setItem('userInfo', JSON.stringify(userInfoData))
        checkIsAuth(true)
      } catch (error) {
        setUnauthed(true)
      }
    } else {
      checkIsAuth(false)
    }
  }

  useEffect(() => {
    if (accessToken) {
      checkIsAuth(true)
    } else if (window.location.pathname.includes('/implicit/callback')) {
      mid.isAuthed().then(loginStatus => {
        checkIsAuth(loginStatus)
      })
    } else {
      checkIsAuth(false)
    }
    // eslint-disable-next-line
  }, [])

  const makeUserLogin = () => {
    mid.isAuthed().then(loginStatus => {
      if (loginStatus) {
        checkValidateUser()
      } else {
        checkIsAuth(false)
      }
    })
  }

  const submitForm = () => {
    updateState({ ...loginState, isLoading: true })
    const access_token = mid.accessToken()
    const infoFromToken = parseJwt(access_token)
    const { exp = 0 } = infoFromToken
    if (exp - moment().unix() > 0) {
      checkValidateUser()
    } else {
      mid.login().finally(() => {
        makeUserLogin()
      })
    }
  }

  if (isUnAuthed) {
    return <Unauthenticated />
  }

  if (isLoading) {
    return (
      <div className="_text_loader">
        <div className="_text" data-text="Loading...">
          Loading...
        </div>
      </div>
    )
  } else if (isAuthed) {
    return children
  } else {
    return (
      <Box className={classes.LoginCtrl}>
        <Box className={classes.loginSection}>
          <Box className={classes.loginbtnCenter}>
            <Typography
              variant="h1"
              component="h2"
              className={classes.sectionHeading}
            >
              <Box component="img" src={logo} />
            </Typography>
            <Fab
              variant="extended"
              color="primary"
              aria-label="add"
              className={classes.margin}
              onClick={submitForm}
              style={{ width: 200 }}
            >
              Login
            </Fab>
            <p className={classes.loginMeta}>
              If you are not a McKinsey colleague, you will need to log in with
              Okta Verify multi-factor authentication
              <br />
              <br />
              <a href={loginMetaPdf} className={classes.loginMetaPdf} download>
                Click here
              </a>
              for instructions on how to set up your Okta Verify account
            </p>
          </Box>
        </Box>
        <Box className={classes.introSection} />
      </Box>
    )
  }
}
export default Login
