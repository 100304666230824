export const UPLOAD_DEMOGRAPHICS = 'UPLOAD_DEMOGRAPHICS'
export const DOWNLOAD_DEMOGRAPHICS_TEMPLATE = 'DOWNLOAD_DEMOGRAPHICS_TEMPLATE'
export const GET_DEMOGRAPHIC_DATA = 'GET_DEMOGRAPHIC_DATA'
export const ADD_DEMOGRAPHICS_ARRAY = 'ADD_DEMOGRAPHICS_ARRAY'
export const GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE =
  'GET_DEMOGRAPHIC_TYPE_AND_LANGUAGE'
export const GET_DEMOGRAPHICS_OTHER_LANG = 'GET_DEMOGRAPHICS_OTHER_LANG'
export const RESET_UPLOAD_DEMOGRAPHICS = 'RESET_UPLOAD_DEMOGRAPHICS'
export const UPDATE_DEMOGRAPHIC = 'UPDATE_DEMOGRAPHIC'
export const DEMOGRAPHIC_STATE_CHANGE = 'DEMOGRAPHIC_STATE_CHANGE'
export const ADD_NEW_DEMOGRAPHICS_IN_LIST = 'ADD_NEW_DEMOGRAPHICS_IN_LIST'
export const RESET_ADD_NEW_DEMOGRAPHICS_IN_LIST =
  'RESET_ADD_NEW_DEMOGRAPHICS_IN_LIST'
export const GET_TAGS = 'GET_TAGS'
export const GET_BENCHMARK_DATA = 'GET_BENCHMARK_DATA'
export const UPDATE_BENCHMARK_DATA = 'UPDATE_BENCHMARK_DATA'
export const VIEW_UPDATE_SURVEY = 'VIEW_UPDATE_SURVEY'
export const RESET_DEMOGRAPHIC_LIST = 'RESET_DEMOGRAPHIC_LIST'
export const RESET_UPDATE_DEMOGRAPHIC_DATA = 'RESET_UPDATE_DEMOGRAPHIC_DATA'
