import { makeStyles } from '@material-ui/core/styles'
import { relative } from 'path'

const styles = makeStyles(theme => {
  return {
    root: {
      height: '7.8vh',
      backgroundColor: '#021c4b',
      padding: '0px 2vw',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
      width: '100%',
    },
    topBar: {},
    user: {
      width: '6.2vw',
      color: '#fff',
      marginLeft: '2vw',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      height: '100%',
    },
    profilePic: {
      borderRadius: '100%',
      marginLeft: '1vh',
      width: '2.2vw',
      height: '4.4vh',
    },
    logoutbtn: {
      float: 'left',
      cursor: 'pointer',
    },
    toggleText: {
      fontSize: '1.7vh',
      color: '#fff',
    },
    leftContentContainer: {
      height: '100%',
    },
    toggleButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      borderRight: 'solid 1px #0f3270',
      paddingRight: '1.8vw',
    },
    lockText: {
      fontSize: '1.7vh',
      color: '#f28165',
      fontWeight: 'bold',
      marginRight: '1.8vw',
    },
    manageUserText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.8vh',
      color: '#fff',
      fontWeight: 'bold',
      paddingRight: '1.8vw',
      cursor: 'pointer',
      height: '100%',
      borderRight: 'solid 1px #0f3270',
    },
  }
})

export default styles
