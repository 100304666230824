import { fromJS } from 'immutable'
import {
  EMAIL_TEMPLATE_FETCH,
  LOCK_TEMPLATE,
  EMAIL_SINGLE_TEMPLATE_FETCH,
  SEND_TEST_EMAIL,
  DUPLICATE_TEMPLATE,
  UPDATE_TEMPLATE
} from './constants'

// CLIENTINFO FETCH
const INITIAL_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isEmailSent: false,
  isDuplicating: false,
  singletemplate: {},
  isUpdateTemplate: false ,
  items: [],
})
export const emailreducer = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  
  switch (type) {
    case `${EMAIL_TEMPLATE_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${EMAIL_TEMPLATE_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${EMAIL_TEMPLATE_FETCH}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    // SINGLE EMAIL GET
    case `${EMAIL_SINGLE_TEMPLATE_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${EMAIL_SINGLE_TEMPLATE_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      return state
        .updateIn(['singletemplate'], () => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${EMAIL_SINGLE_TEMPLATE_FETCH}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    // LOCK TEMPLATE
    case `${LOCK_TEMPLATE}_PENDING`: {
      return state.setIn(['isLoading'], true)
    }
    case `${LOCK_TEMPLATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
    }
    case `${LOCK_TEMPLATE}_FAILED`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], false)
    }
    // LOCK TEMPLATE
    case `${DUPLICATE_TEMPLATE}_PENDING`: {
      return state.setIn(['isDuplicating'], true)
    }
    case `${DUPLICATE_TEMPLATE}_SUCCESS`: {
      return state.setIn(['isDuplicating'], false)
    }
    case `${DUPLICATE_TEMPLATE}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isDuplicating'], false)
    }
    // SEND EMAIL TEMPLATE
    case `${SEND_TEST_EMAIL}_PENDING`: {
      return state.setIn(['isLoading'], true)
    }
    case `${SEND_TEST_EMAIL}_SUCCESS`: {
      return state.setIn(['isLoading'], false).setIn(['isEmailSent'], true)
    }
    case `${SEND_TEST_EMAIL}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    // EDIT TEMPLATE 
    case `${UPDATE_TEMPLATE}_PENDING`: {
      return state.setIn(['isLoading'], true)
    }
    case `${UPDATE_TEMPLATE}_SUCCESS`: {
      // console.log('payload', payload.respData)
      return state.setIn(['isLoading'], false).setIn(['isUpdateTemplate'], true)
    }
    case `${UPDATE_TEMPLATE}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `CLEAR_EMAIL_STATE`: {
      return state.setIn(['isEmailSent'], false).setIn(['isUpdateTemplate'], false)
    }
    default:
      return state
  }
}

export default emailreducer
