import { all } from 'redux-saga/effects'
import isFunction from 'lodash/fp/isFunction'
import { sagas as surveySagas } from '../pages/ManageSurveys'
import { sagas as SurveyInfoSagas } from '../pages/SurveyInfo'
import { sagas as ClientInfoSagas } from '../pages/ClientInfo'
import { sagas as DesignSurveySaga } from '../pages/DesignSurvey'
import { sagas as SummarySaga } from '../pages/Summary'
import OHIOutcomeSaga from '../pages/SurveyOHI/sagas'
import { sagas as SurveyIntroductionSaga } from '../pages/SurveyIntroduction'
import { sagas as SurveyAdditionalQuestionsSaga } from '../pages/SurveyAdditionalQuestions'
import DeepDiveSaga from '../pages/DeepDive/saga'
import RevertToLastUpdateSaga from '../pages/RevertToLastUpdate/sagas'
import commonSagas from '../common/sagas'
import DemographicsSagas from '../pages/Demographics/redux/sagas'
import emailsagas from '../pages/TestEmail/sagas'

export const runSagas = sagas => {
  if (Array.isArray(sagas)) {
    return sagas.map(saga => saga())
  }
  if (isFunction(sagas)) {
    return Array.of(sagas())
  }
  throw new TypeError(`${sagas} should be an Array or Function`)
}
export default function* rootSaga() {
  yield all([
    ...surveySagas,
    ...SurveyInfoSagas,
    ...ClientInfoSagas,
    ...DesignSurveySaga,
    ...SummarySaga,
    ...runSagas(SurveyIntroductionSaga),
    ...runSagas(OHIOutcomeSaga),
    ...runSagas(SurveyAdditionalQuestionsSaga),
    ...runSagas(DeepDiveSaga),
    ...runSagas(RevertToLastUpdateSaga),
    ...runSagas(commonSagas),
    ...DemographicsSagas,
    ...emailsagas,
  ])
}
