export const SURVEYSNAME_FETCH = 'SURVEYSNAME/FETCH'
export const SURVEYSNAME_CREATE = 'SURVEYSNAME/CREATE'
export const TOGGLE_SUMMARY_OF_EDITS = 'TOGGLE_SUMMARY_OF_EDITS'
export const TOGGLE_CONFIRMATION_POP_UP = 'TOGGLE_CONFIRMATION_POP_UP'
export const PROGRESS_BAR_LOADER = 'PROGRESS_BAR_LOADER'
export const SAVE_SUMMARY_OF_EDITS = 'SAVE_SUMMARY_OF_EDITS'
export const SAVE_SUMMARY_OF_EDITS_FOR_DIFFERENT_LANG =
  'SAVE_SUMMARY_OF_EDITS_FOR_DIFFERENT_LANG'
export const ADMIN_LOCK_UNLOCK_SURVEY = 'ADMIN_LOCK_UNLOCK_SURVEY'
export const UNLOCK_USER_LOCKED_SURVEY = 'UNLOCK_USER_LOCKED_SURVEY'
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE'
export const API_ERROR = 'API_ERROR'
export const TOGGLE_TOAST_ON_SUCCESS = 'TOGGLE_TOAST_ON_SUCCESS'
export const GET_GSDB = 'GET_GSDB'
export const GET_GSDB_SUCCESS = 'GET_GSDB_SUCCESS'
export const POST_GSDB = 'POST_GSDB'
export const GET_USER_DETAILS = 'GET_USER_DETAILS'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const DELETE_USER = 'DELETE_USER'
export const RESET_GSDB_DETAILS = 'RESET_GSDB_DETAILS'
export const UPLOAD_USER_TEMPLATE = 'UPLOAD_USER_TEMPLATE'
export const DOWNLOAD_USER_TEMPLATE = 'DOWNLOAD_USER_TEMPLATE'
export const EXPORT_USERS = 'EXPORT_USERS'
export const SUCCESS_UPLOAD = 'SUCCESS_UPLOAD'
export const GET_FILTER = 'GET_FILTER'
export const FETCH_SURVEYS = 'FETCH_SURVEYS'
export const UPLOAD_BACKCODING = 'UPLOAD_BACKCODING'
export const DOWNLOAD_MAPPING = 'DOWNLOAD_MAPPING'
export const GENERATE_LOGS = 'GENERATE_LOGS'
export const TASK_LOGS = 'TASK_LOGS'
export const RESET_DATA = 'RESET_DATA'
export const RESET_TASK_DATA = 'RESET_TASK_DATA'
export const RESET_TASK_ID = 'RESET_TASK_ID'
