import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      minHeight: '100vh',
      paddingTop: '17.3vh',
    },
    main: {
      // border: '2px solid red',
    },
    sidebar: {},
    formWrapper: {
      // border: '1px solid blue',
    },
  }
})

export default styles
