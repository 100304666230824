import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {},
    input: {
      fontSize: 16,
    },
    inputLabel: {
      '& + div:before': {
        left: '0',
        right: '0',
        bottom: '0',
        content: '""',
        position: 'absolute',
        transition:
          'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        pointerEvents: 'none',
        borderBottomColor: '#e7e7e7',
      },
    },
  }
})

export default styles
