import { makeStyles } from '@material-ui/core/styles'
const styles = makeStyles(theme => {
  return {
    modaltitle: {
      fontSize: '16px',
      color: '#333333',
    },
  }
})

export default styles
