import { call, put, takeLatest, select, all } from 'redux-saga/effects'

import {
  introductionFetchSuccess,
  introductionFetchFailed,
  sidePanelDataSuccess,
  sidePanelDataError,
  fetchIntroDataForLangSuccess,
  fetchIntroDataForLangError,
  saveSurveyIntroductionFailure,
  fetchSummaryOfEdits,
  importFileFailure,
  toggleImportFileFailureContainer,
  fetchIntroDataForLang,
  introductionFetch,
  failedSendEmail,
  successSendEmail,
} from './actions'

import {
  toggleSummaryOfEdits,
  saveSummaryOfEdits,
  saveSummaryOfEditsForDifferentLang,
  toggleToastOnSuccess,
} from '../../common/actions'

import {
  fetchDataForOHIOutcomesLang,
  fetchOHIOutcomeData,
} from '../SurveyOHI/actions'

import { deepDiveLangFetch, deepDiveFetch } from '../DeepDive/actions'

import {
  fetchIntroDataForLang as additionalQuestionsLangFetch,
  additionalQuestionsFetch,
} from '../SurveyAdditionalQuestions/actions'

import {
  INTRODUCTION_FETCH,
  FETCH_SIDE_PANEL_DATA,
  FETCH_INTRO_DATA_LANG,
  SAVE_SURVEY_INTRODUCTION,
  FETCH_SUMMARY_OF_EDITS,
  IMPORT_FILE,
  EXPORT_FILE,
  REPLACE_EVERYWHERE,
  SEND_EMAIL,
} from './constants'

import Endpoints from '../../Endpoints'
import request from '../../utils/request'
import { setPayloadForIntroduction } from './utils'

// GET introductionInfo
function* fetchIntroductionInfofn({ surveyId }) {
  try {
    const url = window.location.pathname
    const getUrl = url.includes('instructions')
      ? Endpoints.instructions
      : Endpoints.introduction
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: getUrl.replace(':id', surveyId),
    })
    if (!error) {
      yield put(introductionFetchSuccess(data))
    }
    yield put(introductionFetchFailed())
    yield put(toggleToastOnSuccess({ successText: '', openToast: false }))
  } catch (e) {
    const message = `We're unable to process your request. Please try again after sometime.`
    yield put(introductionFetchFailed(message))
  }
}

function* fetchIntroduction() {
  yield takeLatest(`${INTRODUCTION_FETCH}_PENDING`, fetchIntroductionInfofn)
}

function* getSidePanelData({ surveyId }) {
  try {
    const {
      body: { data, error },
      response,
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.sideBarNavigation.replace(':id', surveyId),
    })
    if (!error) {
      return yield put(sidePanelDataSuccess(data))
    } else if (response.status === 401) {
      // auth.logout()
    }
    return yield put(sidePanelDataError('error'))
  } catch (e) {
    return yield put(sidePanelDataError('error'))
  }
}

function* fetchSidePanelData() {
  yield takeLatest(FETCH_SIDE_PANEL_DATA, getSidePanelData)
}

function* getIntroductionForLang({ surveyId, languageId }) {
  try {
    const url = window.location.pathname
    const getUrl = url.includes('instructions')
      ? Endpoints.instructionsLanguage
      : Endpoints.introductionLanguage

    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: getUrl.replace(':id', surveyId).replace(':id', languageId),
    })
    if (!error) {
      yield put(fetchIntroDataForLangSuccess({ data, languageId }))
    }
    yield put(fetchIntroDataForLangError())
    yield put(toggleToastOnSuccess({ successText: '', openToast: false }))
  } catch (e) {
    yield put(fetchIntroDataForLangError(e))
  }
}

function* fetchIntroductionForLang() {
  yield takeLatest(FETCH_INTRO_DATA_LANG, getIntroductionForLang)
}

function* saveSurveyIntroductionInfo({
  surveyId,
  languageId,
  dataFromEditorState,
  isSync,
  isMultiLanguage,
}) {
  const state = yield select()
  const originalData = state.get('sureveyIntroductionFetch').introductionTexts
    .surveyIntroductions
  const introDuctionClassNames = state.get('sureveyIntroductionFetch')
    .introDuctionClassNames
  const url = window.location.pathname
  const getUrl = url.includes('instructions')
    ? Endpoints.instructions
    : Endpoints.introduction

  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'PUT',
      url: getUrl.replace(':id', surveyId),
      payload: {
        surveyId: surveyId,
        isSync: isSync,
        languageId: languageId,
        surveyIntroductions: setPayloadForIntroduction(
          originalData,
          dataFromEditorState,
          isMultiLanguage,
          introDuctionClassNames
        ),
      },
    })
    if (!error && isSync) {
      return yield put(
        toggleToastOnSuccess({ successText: data, openToast: true })
      )
    }
    return yield put(saveSurveyIntroductionFailure())
  } catch (e) {
    yield put(saveSurveyIntroductionFailure(e))
  }
}

function* saveSurveyIntroductionFn() {
  yield takeLatest(SAVE_SURVEY_INTRODUCTION, saveSurveyIntroductionInfo)
}

export function* getSummaryOfEdits({ languageId, isSync }) {
  const state = yield select()
  const surveyId = state
    .get('surveyname')
    .get('items')
    .get('id')

  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: languageId
        ? Endpoints.summaryOfEdits
            .replace('surveyId', surveyId)
            .replace('langId', languageId)
        : Endpoints.summaryOfEditsDefault.replace('surveyId', surveyId),
    })
    if (!error && !languageId) {
      return yield all([
        put(saveSummaryOfEdits(data, isSync)),
        put(toggleSummaryOfEdits()),
      ])
    } else {
      return yield put(
        saveSummaryOfEditsForDifferentLang(data, languageId, isSync)
      )
    }
  } catch (e) {
    console.log(e)
  }
}

function* summaryOfEditsGetter() {
  yield takeLatest(FETCH_SUMMARY_OF_EDITS, getSummaryOfEdits)
}

function* importFileToServer({
  file,
  surveyId,
  pathName,
  toggleState,
  object,
}) {
  const formData = new FormData()
  formData.append('file', file)
  try {
    const response = yield call(request, {
      method: 'PUT',
      url: Endpoints.importFile.replace(':id', surveyId),
      payload: formData,
    })
    const {
      body: { data, error },
    } = response
    if (!error) {
      if (toggleState) {
        if (pathName.toLowerCase().includes('introduction')) {
          yield put(
            fetchIntroDataForLang({
              surveyId: surveyId,
              languageId: object.selectedLanguageId,
            })
          )
        } else if (
          pathName
            .toLowerCase()
            .toLowerCase()
            .includes('ohi')
        ) {
          yield put(
            fetchDataForOHIOutcomesLang({
              surveyId: surveyId,
              outcomeId: object.outcomeId,
              languageId: object.selectedLanguageId,
            })
          )
        } else if (pathName.toLowerCase().includes('add-ons')) {
          yield put(
            deepDiveLangFetch({
              surveyId: surveyId,
              outcomeId: object.outcomeId,
              languageId: object.selectedLanguageId,
            })
          )
        } else if (pathName.toLowerCase().includes('additional questions')) {
          yield put(
            additionalQuestionsLangFetch({
              surveyId: surveyId,
              languageId: object.selectedLanguageId,
            })
          )
        }
      } else {
        if (pathName.toLowerCase().includes('introduction')) {
          yield put(introductionFetch(surveyId))
        } else if (pathName.toLowerCase().includes('ohi')) {
          yield put(fetchOHIOutcomeData(surveyId, object.outcomeId))
        } else if (pathName.toLowerCase().includes('add-ons')) {
          yield put(
            deepDiveFetch({ surveyId: surveyId, outcomeId: object.outcomeId })
          )
        } else if (pathName.toLowerCase().includes('additional questions')) {
          yield put(additionalQuestionsFetch(surveyId))
        }
      }
      return yield put(fetchSummaryOfEdits(true, undefined))
    }

    if (data.errors.length !== 0) {
      return yield all([
        put(importFileFailure(data)),
        put(toggleImportFileFailureContainer()),
      ])
    }
  } catch (e) {
    if (e.response) {
      if (e.response.data.data.errors.length !== 0) {
        return yield all([
          put(
            importFileFailure(
              "We're unable to process your request. Please try again after sometime."
            )
          ),
          put(toggleImportFileFailureContainer()),
        ])
      }
    } else {
      return yield all([
        put(
          importFileFailure(
            "We're unable to process your request. Please try again after sometime."
          )
        ),
        put(toggleImportFileFailureContainer()),
      ])
    }
  }
}

function* importFileFn() {
  yield takeLatest(IMPORT_FILE, importFileToServer)
}

function* yieldCaller(surveyId, key) {
  const toSendPayload = {}
  toSendPayload.langId = key
  toSendPayload.surveyId = surveyId
  toSendPayload.fileFormat = 1

  try {
    const {
      body: { error },
      response,
      response: { data },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.exportFile.replace(':id', surveyId),
      payload: toSendPayload,
      responseType: 'arraybuffer',
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    if (!error) {
      var filename = response.headers['x-filename']
      filename = filename.replace(/\"/g, '')
      var blob = new Blob([data], { type: response['headers']['content-type'] })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (e) {}
}

function* exportFileFunction({ payload, surveyId }) {
  const { languageKey } = payload
  if (Array.isArray(payload.languageKey)) {
    return yield all(
      languageKey.map(key => {
        return yieldCaller(surveyId, key)
      })
    )
  } else {
    return yield all([yieldCaller(surveyId, languageKey)])
  }
}

function* exportFileFn() {
  yield takeLatest(EXPORT_FILE, exportFileFunction)
}

function* replaceEveryWhereSaga({
  isCaseSensitive,
  replaceFrom,
  replaceTo,
  langId,
  toggleState,
}) {
  const state = yield select()
  const surveyId = state
    .get('surveyname')
    .get('items')
    .get('id')
  try {
    const {
      body: { error, data },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.replaceEveryWhere.replace(':id', surveyId),
      payload: {
        surveyId: surveyId,
        langId: Number(langId),
        replaceFrom: replaceFrom,
        replaceTo: replaceTo,
        isCaseSensitive: isCaseSensitive,
      },
    })
    if (!error) {
      if (toggleState) {
        yield all([
          setToastOnSuccess({ successText: data, openToast: true }),
          getIntroductionForLang({ surveyId: surveyId, languageId: langId }),
        ])
      } else {
        yield all([
          setToastOnSuccess({ successText: data, openToast: true }),
          fetchIntroductionInfofn({ surveyId }),
        ])
      }
    }
  } catch (e) {}
}

function* replaceEveryWhereYielder() {
  yield takeLatest(REPLACE_EVERYWHERE, replaceEveryWhereSaga)
}

function* setToastOnSuccess({ successText, openToast }) {
  yield put(toggleToastOnSuccess({ successText, openToast }))
}

function* sendEmailSaga({ surveyId }) {
  try {
    const {
      body: { error },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.emailSurvey.replace(':surveyId', surveyId),
      payload: { id: surveyId, comment: '', isIntial: true },
    })
    if (!error) {
      yield put(successSendEmail())
    }
  } catch (e) {
    yield put(failedSendEmail(e))
  }
}
function* sendEmail() {
  yield takeLatest(`${SEND_EMAIL}_PENDING`, sendEmailSaga)
}

export default [
  fetchIntroduction,
  fetchSidePanelData,
  fetchIntroductionForLang,
  saveSurveyIntroductionFn,
  summaryOfEditsGetter,
  importFileFn,
  exportFileFn,
  replaceEveryWhereYielder,
  sendEmail,
]
