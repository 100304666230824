import { call, put, takeLatest, select, all } from 'redux-saga/effects'

import {
  OHIOutcomeDataSuccess,
  OHIOutcomeDataError,
  fetchDataForOHIOutcomesLangSuccess,
  fetchDataForOHIOutcomesLangError,
} from './actions'

import {
  FETCH_OHI_OUTCOME_DATA,
  FETCH_OHI_OUTCOMES_DATA_LANG,
  UPDATE_SURVEY,
  REPLACE_EVERYWHERE,
} from './constants'

import Endpoints from '../../Endpoints'
import request from '../../utils/request'
import { setPayloadForOHITexts } from './utils'
import {
  toggleSummaryOfEdits,
  toggleToastOnSuccess,
} from '../../common/actions'
import { getSummaryOfEdits } from '../SurveyIntroduction/sagas'

function* getOHIOutcomeData({ surveyId, outcomeId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.ohiOutcome
        .replace(':id', surveyId)
        .replace(':outcomeId', outcomeId),
    })
    if (!error) {
      yield put(OHIOutcomeDataSuccess(data))
    }
    yield put(OHIOutcomeDataError('error'))
    yield put(toggleToastOnSuccess({ successText: '', openToast: false }))
  } catch (e) {
    return yield put(OHIOutcomeDataError('error'))
  }
}

function* getDataForOHIOutcomesLang({ surveyId, outcomeId, languageId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.ohiOutcomesLanguage
        .replace(':id', surveyId)
        .replace(':outcomeId', outcomeId)
        .replace(':languageId', languageId),
    })
    if (!error) {
      yield put(
        fetchDataForOHIOutcomesLangSuccess({ data, languageId, outcomeId })
      )
    }
    yield put(fetchDataForOHIOutcomesLangError())
    yield put(toggleToastOnSuccess({ successText: '', openToast: false }))
  } catch (e) {
    yield put(fetchDataForOHIOutcomesLangError(e))
  }
}

function* fetchOHIOutcomeData() {
  yield takeLatest(FETCH_OHI_OUTCOME_DATA, getOHIOutcomeData)
}

function* fetchDataForOHIOutcomesLang() {
  yield takeLatest(FETCH_OHI_OUTCOMES_DATA_LANG, getDataForOHIOutcomesLang)
}

function* updateOHITexts({
  editorStateForOhi,
  surveyId,
  languageId,
  isSync,
  outcomeId,
}) {
  const state = yield select()
  const originalOHITexts = state.get('ohiOutcomeDataFetch')[
    'originalOHIOutcomeData'
  ]

  var payload = setPayloadForOHITexts(originalOHITexts, editorStateForOhi)
  payload.surveyId = surveyId
  payload.languageId = languageId
  payload.outcomeId = outcomeId
  payload.isSync = isSync

  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.ohiOutcomesPost.replace(':id', surveyId),
      payload: payload,
    })
    if (!error && isSync) {
      return yield put(
        toggleToastOnSuccess({ successText: data, openToast: true })
      )
    }
  } catch (e) {}
}

function* updateSurvey() {
  yield takeLatest(UPDATE_SURVEY, updateOHITexts)
}

function* replaceEveryWhereSaga({
  isCaseSensitive,
  replaceFrom,
  replaceTo,
  langId,
  toggleState,
  outcomeId,
}) {
  const state = yield select()
  const surveyId = state
    .get('surveyname')
    .get('items')
    .get('id')
  try {
    const {
      body: { error, data },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.replaceEveryWhere.replace(':id', surveyId),
      payload: {
        surveyId: surveyId,
        langId: Number(langId),
        replaceFrom: replaceFrom,
        replaceTo: replaceTo,
        isCaseSensitive: isCaseSensitive,
      },
    })
    if (!error) {
      if (toggleState) {
        yield all([
          setToastOnSuccess({ successText: data, openToast: true }),
          getDataForOHIOutcomesLang({
            surveyId: surveyId,
            outcomeId,
            languageId: langId,
          }),
        ])
      } else {
        yield all([
          setToastOnSuccess({ successText: data, openToast: true }),
          getOHIOutcomeData({ surveyId, outcomeId }),
        ])
      }
    }
  } catch (e) {}
}

function* replaceEveryWhereYielder() {
  yield takeLatest(REPLACE_EVERYWHERE, replaceEveryWhereSaga)
}

function* setToastOnSuccess({ successText, openToast }) {
  yield put(toggleToastOnSuccess({ successText, openToast }))
}

export default [
  fetchOHIOutcomeData,
  fetchDataForOHIOutcomesLang,
  updateSurvey,
  replaceEveryWhereYielder,
]
