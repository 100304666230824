import React, { Fragment, memo, useEffect, useState } from 'react'
import {
  Box,
  createMuiTheme,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core'
import useStyles from '../EditInclusion/styles'

import { ThemeProvider } from '@material-ui/styles'
import {
  CheckBoxOutlineBlank,
  CheckBoxRounded,
  IndeterminateCheckBoxRounded,
} from '@material-ui/icons'

//component
const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#021c4b',
    },
    secondary: {
      main: '#4b93fd',
    },
  },
})
const TestModule = ({
  hideOhiTestModal,
  ohiTestModuleData,
  isInfluencer,
  isInclusion,
  updateTestModule,
  onUpdateTestModule,
  ohiUpdateDataStatus,
  isTestModuleGet,
  setOHIdata,
  ohiData,
}) => {
  const classes = useStyles()
  const [skipModule, setSkipModule] = useState(false)
  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const [open, setOpen] = useState(false)
  const {
    items: { surveyId },
  } = ohiTestModuleData.toJS()
  const { isLoading } = ohiUpdateDataStatus.toJS()

  useEffect(() => {
    if (ohiTestModuleData.get('isFetched')) {
      const valuesObject = ohiTestModuleData.toJS().items.Values
      const ifSkipSelected = getNameSelected(
        'Skip adding OHI 4.0 Questions',
        true,
        valuesObject
      )
      setSkipModule(false)
      if (ifSkipSelected) {
        setSkipModule(true)
      }
      setOHIdata(valuesObject)
    }
  }, [ohiTestModuleData])

  useEffect(() => {
    if (ohiUpdateDataStatus.get('isFetched') && isTestModuleGet) {
      setOpen(false)
    }
  }, [ohiUpdateDataStatus])

  const saveTestModule = () => {
    const payload = {
      surveyId,
      Values: ohiData,
    }
    onUpdateTestModule(true)
    updateTestModule(payload)
  }

  function renderBottom() {
    function cancelButton() {
      hideOhiTestModal()
      setTimeout(function() {
        setOHIdata(ohiTestModuleData.toJS().items.Values)
      }, 200)
    }
    return (
      <Box className={classes.footer} style={{ marginTop: '10px' }}>
        <Box className={classes.btnWrapper}>
          <Button
            color="primary"
            variant="contained"
            disabled={isLoading}
            className={classes.buttonRound}
            onClick={() => {
              if (skipModule) {
                setOpen(true)
              } else {
                saveTestModule()
              }
            }}
          >
            Save
          </Button>
          {isLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonRound}
            onClick={cancelButton}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    )
  }

  const handleCheckboxEvent = (event, id, pId, moduleName, index) => {
    const {
      target: { checked },
    } = event
    const stateCopy = [...ohiData]
    if (moduleName === 'Skip adding OHI 4.0 Questions') {
      stateCopy[index].isSelected = checked
      resetSelection(stateCopy, moduleName, checked)
    } else {
      setSkipModule(false)
      if (
        pId === 0 &&
        stateCopy.filter(({ parentId = 0 }) => id === parentId).length
      ) {
        stateCopy[index].isSelected = checked
        stateCopy
          .filter(({ parentId = 0 }) => id === parentId)
          .map(({ id: mId = '' }, i) => {
            const findIndex = stateCopy.findIndex(({ id: sId }) => sId === mId)
            stateCopy[findIndex].isSelected = checked
          })
      } else {
        checkParent(pId, checked)
        stateCopy[index].isSelected = checked
      }
      const findIndex = stateCopy.findIndex(
        ({ name = '' }) => name === 'Skip adding OHI 4.0 Questions'
      )
      stateCopy[findIndex].isSelected = false
      setOHIdata(stateCopy)
    }
  }

  const resetSelection = (stateCopy, moduleName, checked) => {
    setSkipModule(false)
    if (checked) {
      setSkipModule(true)
    }
    stateCopy
      .filter(({ name = '' }) => name !== moduleName)
      .map(({ id: mId = '' }, index) => {
        const findIndex = stateCopy.findIndex(({ id: sId }) => sId === mId)
        stateCopy[findIndex].isSelected = false
      })
    setOHIdata(stateCopy)
  }

  const handleInput = (event, id) => {
    const {
      target: { value },
    } = event

    const stateCopy = [...ohiData]
    const findIndex = stateCopy.findIndex(
      ({ id: skipId = '' }) => id === skipId
    )
    stateCopy[findIndex].reason = value
    setOHIdata(stateCopy)
  }

  const checkIfInterminate = (mId, pId) => {
    const checkLengthofChild = checkChildLengthFn(mId)
    const checkLengthOfSelectedChild = checkLengthOfSelectedChildFn(mId)
    if (checkLengthofChild === 0) return false
    if (checkLengthOfSelectedChild === 0) {
      return false
    } else if (checkLengthofChild !== checkLengthOfSelectedChild) {
      return true
    }
    return false
  }

  const checkChildLengthFn = pId =>
    ohiData.filter(({ parentId = '' }) => parentId === pId).length

  const checkLengthOfSelectedChildFn = pId =>
    ohiData.filter(
      ({ parentId = '', isSelected = false }) =>
        parentId === pId && isSelected === true
    ).length

  const skipDataObj = () =>
    ohiData.find(({ name = '' }) => name === 'Skip adding OHI 4.0 Questions')

  const checkParent = (pId, checked) => {
    const stateCopy = [...ohiData]
    if (pId !== 0) {
      const checkLengthofChild = checkChildLengthFn(pId)
      const checkLengthOfSelectedChild = checkLengthOfSelectedChildFn(pId)
      const findIndex = stateCopy.findIndex(({ id = '' }) => id === pId)
      if (checkLengthofChild !== 0) {
        if (checked) {
          stateCopy[findIndex].isSelected = true
        } else {
          stateCopy[findIndex].isSelected =
            checkLengthOfSelectedChild - 1 !== 0 ? true : false
        }
      }
    }
  }

  const getNameSelected = (moduleName, flag, array) =>
    array
      ? array.filter(
          ({ name = '', isSelected = false }) =>
            name === moduleName && isSelected === flag
        ).length
      : 0

  const showMessage = () => {
    let modulename = '',
      leadership = 'Inclusive leadership',
      work = 'Inclusive work environment'

    if (!isInclusion) return ''
    if (
      getNameSelected(leadership, true, ohiData) &&
      getNameSelected(work, true, ohiData)
    ) {
      modulename = '"Inclusive Leadership" and "Inclusive Work Environment"'
    } else if (
      getNameSelected(leadership, true, ohiData) &&
      getNameSelected(work, false, ohiData)
    ) {
      modulename = '"Inclusive Leadership"'
    } else if (
      getNameSelected(leadership, false, ohiData) &&
      getNameSelected(work, true, ohiData)
    ) {
      modulename = '"Inclusive Work Environment"'
    }
    return modulename
  }

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      <ThemeProvider theme={outerTheme}>
        <Box className="overflowAuto">
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionHeading}
          >
            OHI 4.0 Test Module:
          </Typography>
          <Box lineHeight={2}>
            Select the sections you want to include questions from.
          </Box>
          <Box className={classes.subtitle} color="orange">
            {showMessage() ? (
              <Typography hy style={{ color: 'orange', fontSize: 12 }}>
                Warning: You have already selected inclusion module. Are you
                sure, you want to select {showMessage()} ?
              </Typography>
            ) : (
              ''
            )}
          </Box>

          <Box className={classes.subtitle} color="orange">
            {isInfluencer &&
            getNameSelected('Individual Experience', true, ohiData) ? (
              <Typography style={{ color: 'orange', fontSize: 12 }}>
                Warning: You have already selected Employee Experience module.
                Are you sure, you want to select "Individual Experience"?
              </Typography>
            ) : (
              ''
            )}
          </Box>
          <br />
          {ohiData && ohiData.length
            ? ohiData?.map(
                (
                  { parentId = 0, id = 0, isSelected = false, name = '' },
                  i
                ) => {
                  if (parentId === 0) {
                    return (
                      <Fragment key={`${id}_fragement`}>
                        <div style={{ clear: 'both' }} key={`${id}_`}></div>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          style={{
                            borderBottom:
                              i === 0 ? 'solid 1px #e5e5e5' : 'none',
                            borderTop: i === 0 ? 'none' : 'solid 1px #e5e5e5',
                            height:
                              name === 'Skip adding OHI 4.0 Questions'
                                ? '100px'
                                : 'inital',
                          }}
                          key={`p_${id}`}
                        >
                          <FormControlLabel
                            label={
                              <Typography
                                style={{ fontSize: 14, fontWeight: 550 }}
                              >
                                {name}
                              </Typography>
                            }
                            key={id}
                            control={
                              <Checkbox
                                onChange={e =>
                                  handleCheckboxEvent(e, id, parentId, name, i)
                                }
                                sx={{
                                  '& .Mui-checked': {
                                    color: '#021c4b',
                                  },
                                }}
                                checked={isSelected}
                                indeterminate={checkIfInterminate(
                                  id,
                                  parentId,
                                  i
                                )}
                                classes={{
                                  checked: classes.checked,
                                  indeterminate: classes.checked,
                                }}
                                key={`${id}_parent`}
                                indeterminateIcon={
                                  <IndeterminateCheckBoxRounded
                                    className={classes.checkboxIcon}
                                  />
                                }
                                icon={
                                  <CheckBoxOutlineBlank
                                    className={classes.checkboxIconUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <CheckBoxRounded
                                    className={classes.checkboxIcon}
                                  />
                                }
                              />
                            }
                          />
                        </Box>
                      </Fragment>
                    )
                  } else {
                    return (
                      <div
                        style={{
                          float: 'left',
                          width: '50%',
                          overflowX: 'hidden',
                        }}
                        key={`_${id}`}
                      >
                        <FormControlLabel
                          label={
                            <Typography style={{ fontSize: 14 }}>
                              {name}
                            </Typography>
                          }
                          key={`c_${id}`}
                          control={
                            <Checkbox
                              checked={isSelected}
                              key={`${id}_child`}
                              classes={{
                                checked: classes.checked,
                                indeterminate: classes.checked,
                              }}
                              onChange={e =>
                                handleCheckboxEvent(e, id, parentId, name, i)
                              }
                              icon={
                                <CheckBoxOutlineBlank
                                  className={classes.checkboxIconUnchecked}
                                />
                              }
                              checkedIcon={
                                <CheckBoxRounded
                                  className={classes.checkboxIcon}
                                />
                              }
                            />
                          }
                        />
                      </div>
                    )
                  }
                }
              )
            : null}
        </Box>
      </ThemeProvider>
      {renderBottom()}
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Typography variant="h6">Skip adding OHI 4.0 Questions</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography varaint="body2">
              Please provide a reason for skipping the OHI 4.0 questions
            </Typography>
            <br />
            <TextField
              varaint="standard"
              fullWidth
              placeholder="Write reason here"
              onChange={e => {
                if (e.target.value) {
                  setError('')
                }
                setValue(e.target.value)
                handleInput(e, skipDataObj().id)
              }}
            ></TextField>
            <Typography variant="caption" style={{ color: 'red' }}>
              {error}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start' }}>
          <Box display="flex" justifyContent="flex-start">
            <Box mr={3}>
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading}
                className={classes.buttonRound}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Box>
            <Box mb={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                className={classes.buttonRound}
                onClick={() => {
                  if (value) {
                    setError('')
                    saveTestModule()
                  } else {
                    setError('Please provide response')
                  }
                }}
              >
                Submit{' '}
                {isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default memo(TestModule)
