import React, { useEffect, useState, memo } from 'react'
import useStyles from './styles'
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  createMuiTheme,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core'
import { SwitchToggleSet } from '../../../../components'
import validateInfluencer from '../../../../validations/influencer'
import { ThemeProvider } from '@material-ui/styles'

//component
const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#021c4b',
    },
    secondary: {
      main: '#4b93fd',
    },
  },
})

function EditInfluencer({
  hideDeepdiveEdit,
  influencer,
  influencerStatus,
  onUpdateInfluencer,
}) {
  const classes = useStyles()

  const [influencerdata, setInfluencerdata] = useState({
    isOptout: '2',
    surveyId: '',
    isRequired: '2',
    nominateMin: 0,
    nominateMax: 0,
    additionalQuestionText: '',
  })

  const [influencerQues, setInfluencerQues] = useState(false)
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = useState({})
  const [msg, setMsg] = useState('')

  useEffect(() => {
    if (influencer.get('isFetched')) {
      setInfluencerdata(influencer.toJS().items)
    }
  }, [influencer])
  useEffect(() => {
    if (influencerStatus.get('isFetched')) {
      setMsg(influencerStatus.get('items'))
      setLoading(false)
      /* setLoading(false)
        addToast(influencerStatus.get('items').get('data'), {
          appearance: influencerStatus.get('items').get('error')
            ? 'error'
            : 'success',
          autoDismiss: true,
        }) */

      if (!influencerStatus.get('items').get('error')) {
        hideDeepdiveEdit()
      }
    }
  }, [influencerStatus])

  function handleInputChangeinf(name) {
    if (name === 'nominateMin') {
      return function(event) {
        setInfluencerdata({
          ...influencerdata,
          [name]: event.target.value,
          nominateMax:
            influencerdata.nominateMax < event.target.value
              ? event.target.value
              : influencerdata.nominateMax,
        })
      }
    }
    /* if(name === 'nominateMax'){
      return function(event) {
        setInfluencerdata({
          ...influencerdata, [name]: event.target.value >= influencerdata.nominateMin ? event.target.value: influencerdata.nominateMax
         })
      }
    } */
    return function(event) {
      setInfluencerdata({ ...influencerdata, [name]: event.target.value })
    }
  }
  function handleCheckboxChange(name) {
    return function(event) {
      // console.log(event.target, event.target.checked)
      setInfluencerdata({
        ...influencerdata,
        [name]: event.target.checked ? 2 : 1,
      })
    }
  }
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      <ThemeProvider theme={outerTheme}>
        <Box className="overflowAuto" style={{ paddingBottom: 20 }}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionHeading}
          >
            Influencer
          </Typography>
          <Box style={{ marginBottom: 20 }}>
            <FormLabel component="legend" className={classes.qtypelable}>
              Influencer Type
            </FormLabel>
            <Box>
              <SwitchToggleSet
                isChecked={influencerdata.type === 1 ? false : true}
                onChange={handleCheckboxChange}
                name="type"
                text={{
                  txtOn: 'Insights',
                  txtOff: 'Classic',
                  ariaLabel: 'influencer type',
                }}
              />
            </Box>
          </Box>
          <FormLabel component="legend" className={classes.qtypelable}>
            Show opt-out option? (Respondents can have a choice to opt-out of
            the results)
          </FormLabel>
          <RadioGroup
            aria-label="isOptout"
            name="isOptout"
            value={`${influencerdata.isOptout}`}
            style={{ marginBottom: 20 }}
            onChange={handleInputChangeinf('isOptout')}
            row
          >
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              value="1"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="Yes"
              labelPlacement="end"
            />
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              value="2"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="No"
              labelPlacement="end"
            />
            {errors.isOptout && (
              <FormHelperText
                id="my-helper-text-isOptout"
                className={classes.myhelptext}
              >
                {errors.isOptout}
              </FormHelperText>
            )}
          </RadioGroup>
          <FormLabel component="legend" className={classes.qtypelable}>
            Will respondents be required to answer the Influencer
          </FormLabel>
          <RadioGroup
            aria-label="isRequired"
            name="isRequired"
            value={`${influencerdata.isRequired}`}
            style={{ marginBottom: 20 }}
            onChange={handleInputChangeinf('isRequired')}
            row
          >
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              value="1"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="Yes"
              labelPlacement="end"
            />
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              value="2"
              control={
                <Radio
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                />
              }
              label="No"
              labelPlacement="end"
            />
            {errors.isRequired && (
              <FormHelperText
                id="my-helper-text-isRequired"
                className={classes.myhelptext}
              >
                {errors.isRequired}
              </FormHelperText>
            )}
          </RadioGroup>
          <FormLabel
            component="legend"
            className={classes.qtypelable}
            style={{ marginBottom: 15 }}
          >
            Number to Nominate
          </FormLabel>
          <Box>
            <Box className={classes.minmaxCtrl}>
              <FormControl
                fullWidth
                className={classes.mb40}
                style={{ marginRight: 20 }}
              >
                <InputLabel htmlFor="minimum" className={classes.inputLabel}>
                  Minimum
                </InputLabel>
                <Input
                  type="number"
                  id="minimum"
                  className={classes.input}
                  value={influencerdata.nominateMin || ''}
                  placeholder="Minimum"
                  onChange={handleInputChangeinf('nominateMin')}
                  fullWidth
                />
              </FormControl>
              <FormControl fullWidth className={classes.mb40}>
                <InputLabel htmlFor="maximum" className={classes.inputLabel}>
                  Maximum
                </InputLabel>
                <Input
                  type="number"
                  id="maximum"
                  className={classes.input}
                  value={influencerdata.nominateMax || ''}
                  onChange={handleInputChangeinf('nominateMax')}
                  placeholder="Maximum"
                  fullWidth
                />
              </FormControl>
            </Box>
            {errors.numbertonominate && (
              <FormHelperText
                id="my-helper-text-numbertonominate"
                className={classes.myhelptext}
              >
                {errors.numbertonominate}
              </FormHelperText>
            )}
          </Box>

          {influencerQues || influencerdata.additionalQuestionText !== '' ? (
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel
                htmlFor="additionalQuestionText"
                className={classes.inputLabel}
              >
                Enter question text for second Influencer question
              </InputLabel>
              <Input
                id="additionalQuestionText"
                className={classes.input}
                value={influencerdata.additionalQuestionText || ''}
                placeholder="Enter question text for second Influencer question"
                onChange={handleInputChangeinf('additionalQuestionText')}
                fullWidth
              />
            </FormControl>
          ) : null}
          {!influencerQues && influencerdata.additionalQuestionText === '' ? (
            <Button
              className={classes.addinfluncerbtn}
              size="small"
              onClick={() => setInfluencerQues(true)}
            >
              Add another Influencer question
            </Button>
          ) : (
            <Box>
              For adding more Influencer questions/customization, please reach
              out to OHI Helpdesk
            </Box>
          )}
        </Box>
      </ThemeProvider>
      {renderBottom()}
    </Box>
  )

  function renderBottom() {
    function cancelButton() {
      hideDeepdiveEdit()
      setTimeout(function() {
        setInfluencerdata(influencer.toJS().items)
      }, 200)
    }
    function saveInfluencer() {
      setErrors(validateInfluencer(influencerdata))
      if (Object.keys(validateInfluencer(influencerdata)).length === 0) {
        setLoading(true)
        onUpdateInfluencer(influencerdata)
      }
    }
    return (
      <Box className={classes.footer}>
        <Box className={classes.btnWrapper}>
          <Button
            color="primary"
            disabled={loading}
            variant="contained"
            className={classes.buttonRound}
            onClick={saveInfluencer}
          >
            Save
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonRound}
            onClick={cancelButton}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    )
  }
}

export default memo(EditInfluencer)
