import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      padding: '48px 0px 0px 0px',
      fontSize: 12,
      color: '#333333',
      textAlign: 'left',
      height: '100%',
    },
    questionListupdate: {
      display: 'flex',
      marginBottom: 18,
      borderBottom: '1px solid rgba(151, 151, 151, 0.37)',
      position: 'relative',
      width: '45%',
      float: 'left',
      '&:nth-child(even)': {
        marginLeft: '10%',
      },
      '& img': {
        marginRight: 14,
        marginTop: 14,
        width: 13,
      },
    },
    sectionHeading: {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: '38px',
    },
    reorderIcon: {
      width: 50,
    },
    qEdit: {
      width: 37,
      padding: 0,
      backgroundColor: 'transparent',
      border: 0,
      position: 'absolute',
      right: 3,
      top: 5,
      '& img': {
        margin: 0,
        maxWidth: '100%',
      },
      '&:focus': {
        outline: 'none',
      },
    },

    questionText: {
      flex: '10 1 0%',
      position: 'relative',
    },
    questionTitle: {
      fontSize: 16,
      color: '#333333',
      padding: '5px 0',
      fontWeight: 400,
    },
    questionListType: {
      fontSize: 11,
      color: '#333333',
    },
    minmaxCtrl: {
      display: 'flex',
      flexDirection: 'row',
      width: '50%',
      '& input': {
        fontSize: '18px',
        fontWeight: 700,
      },
    },
    footer: {
      borderTop: 'solid 1px #e5e5e5',
      padding: '20px 30px',
      position: 'absolute',
      width: '100%',
      left: 0,
      bottom: 0,
      textAlign: 'right',
      display: 'flex',
      alignContent: 'flex-end',
      flexDirection: 'row-reverse',
    },
    buttonRound: {
      borderRadius: '25px',
      fontWeight: '400',
      fontSize: 14,
    },
    btnWrapper: {
      position: 'relative',
      marginLeft: 20,
    },
    buttonProgress: {
      color: '#4b93fd',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    srtHelper: {
      zIndex: 9999,
      margin: '0 !important',
    },
    addnewBtn: {
      fontSize: 14,
      fontWeight: 700,
      textTransform: 'uppercase',
      display: 'flex',
      backgroundColor: 'transparent',
      border: 'none',
      float: 'right',
      marginTop: 5,
      color: '#4b93fd',
      '& img': {
        marginRight: 8,
        marginTop: -3,
        width: 15,
      },
    },
    myhelptext: {
      color: '#ff0000',
      width: '100%',
    },
  }
})

export default styles
