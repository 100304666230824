import { GET_GSDB_SUCCESS, RESET_GSDB_DETAILS } from '../common/constants'

const INITIAL_STATE = {
  GSDBData: {},
}

const GSDBReducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case GET_GSDB_SUCCESS:
      return {
        ...state,
        GSDBData: data,
      }

    case RESET_GSDB_DETAILS: {
      return {
        ...state,
        GSDBData: {},
      }
    }
    default:
      return state
  }
}

export default GSDBReducer
