import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { parseJwt } from '../../utils'

const CallbackComponent = ({ mid, history }) => {
  const checkValidateUser = async () => {
    const access_token = mid.accessToken()
    if (access_token) {
      const infoFromToken = parseJwt(access_token)
      const { auth_time = 0 } = infoFromToken
      try {
        const { data } = await axios.request({
          method: 'GET',
          url: '/api/account/ValidateUser',
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const {
          data: userInfoData,
          data: { token = '' },
        } = data
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        localStorage.setItem('auth_time', auth_time)
        localStorage.setItem('accessToken', token)
        userInfoData['midToken'] = access_token
        localStorage.setItem('userInfo', JSON.stringify(userInfoData))
        history.push('/')
      } catch (error) {
        localStorage.clear()
        sessionStorage.clear()
        window.location.reload()
      }
    } else {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    }
  }

  useEffect(() => {
    const access_token = mid.accessToken()
    if (access_token) {
      checkValidateUser()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="_text_loader">
      <div className="_text" data-text="Loading...">
        Loading...
      </div>
    </div>
  )
}

CallbackComponent.propTypes = {
  mid: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default CallbackComponent
