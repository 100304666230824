import React from 'react'

import DateRangePicker from 'react-bootstrap-daterangepicker'
import {
  Input,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
} from '@material-ui/core'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'

import useStyles from './styles'
import { DateIcon } from '../IconComponents'

function DatePicker({
  onApply,
  label,
  id,
  mindateValue,
  values: { startDate },
}) {
  const classes = useStyles()
  //const inputValue = endDate ? `${startDate} - ${endDate}` : ''

  return (
    <Box display="flex" width="100%" className={classes.root}>
      <DateRangePicker
        className={classes.inputSet}
        containerStyles={{
          display: 'flex',
          width: '100%',
        }}
        minDate={mindateValue}
        singleDatePicker
        onApply={onApply}
      >
        <FormControl className={classes.datepickerwrapper}>
          <InputLabel htmlFor={id} className={classes.inputLabel} shrink={true}>
            {label}
          </InputLabel>
          <Input
            id={id}
            disableUnderline
            readOnly
            fullWidth
            value={startDate}
            placeholder="Select date"
            classes={{ root: classes.input }}
            endAdornment={
              <InputAdornment position="end">
                <DateIcon fontSize="inherit" />
              </InputAdornment>
            }
          />
        </FormControl>
      </DateRangePicker>
    </Box>
  )
}

export default DatePicker
