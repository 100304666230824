import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {},
    inputElem: {
      fontSize: 11,
      '&:before': {
        borderBottom: 'none',
      },
    },
  }
})

export default styles
