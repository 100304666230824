import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import {
  DEEP_DIVE,
  DEEP_DIVE_LANG,
  UPDATE_SURVEY_DEEP_DIVE,
  REPLACE_EVERYWHERE,
} from './constants'
import {
  deepDiveSuccess,
  deepDiveFetchError,
  deepDiveLangSuccess,
  deepDiveFetchLangError,
} from './actions'
import Endpoints from '../../Endpoints'
import request from '../../utils/request'
import { setPayloadForQuestions } from '../SurveyAdditionalQuestions/utils'
import {
  // toggleSummaryOfEdits,
  toggleToastOnSuccess,
} from '../../common/actions'
// import { getSummaryOfEdits } from '../SurveyIntroduction/sagas'
import { OHI_4_SECTIONS, Optional_Sections } from '../../utils/constants'

function* getDeepDiveData({ surveyId, outcomeId }) {
  try {
    const urlSplit = window.location.pathname.replace(/%20/g, ' ').split('/')
    const makeurl = urlSplit.includes(OHI_4_SECTIONS)
      ? Endpoints.ohi4Sections
          .replace(':id', surveyId)
          .replace(':defaultid', 10)
          .replace(':langid', 0)
          .replace(':id', outcomeId)
      : urlSplit.includes(Optional_Sections)
      ? Endpoints.optionalQuestion
          .replace(':id', surveyId)
          .replace(':outcomeid', outcomeId)
      : Endpoints.deepDive.replace(':id', surveyId).replace(':id', outcomeId)
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: makeurl,
    })
    if (!error) {
      yield put(deepDiveSuccess(data))
    }
    yield put(deepDiveFetchError())
    yield put(toggleToastOnSuccess({ successText: '', openToast: false }))
  } catch (e) {
    return yield put(deepDiveFetchError(e))
  }
}

function* getDeepDiveLangData({ surveyId, outcomeId, languageId }) {
  try {
    const urlSplit = window.location.pathname.replace(/%20/g, ' ').split('/')
    const makeurl = urlSplit.includes(OHI_4_SECTIONS)
      ? Endpoints.ohi4Sections
          .replace(':id', surveyId)
          .replace(':defaultid', 10)
          .replace(':langid', languageId)
          .replace(':id', outcomeId)
      : urlSplit.includes(Optional_Sections)
      ? Endpoints.optionalQuestionLanguage
          .replace(':id', surveyId)
          .replace(':outcomeid', outcomeId)
          .replace(':languageId', languageId)
      : Endpoints.deepDiveLanguage
          .replace(':id', surveyId)
          .replace(':id', outcomeId)
          .replace(':languageId', languageId)
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: makeurl,
    })
    if (!error) {
      yield put(deepDiveLangSuccess({ data, languageId, outcomeId }))
    }
    yield put(deepDiveFetchLangError())
    yield put(toggleToastOnSuccess({ successText: '', openToast: false }))
  } catch (e) {
    return yield put(deepDiveFetchLangError(e))
  }
}

function* fetchDeepDiveData() {
  yield takeLatest(DEEP_DIVE, getDeepDiveData)
}

function* fetchDeepDiveDataLang() {
  yield takeLatest(DEEP_DIVE_LANG, getDeepDiveLangData)
}

function* updateDeepDiveTexts({
  editorState,
  surveyId,
  languageId,
  isSync,
  outcomeId,
}) {
  const state = yield select()
  const originaldata = state.get('deepDive')['originalApiData']

  var payload = setPayloadForQuestions(originaldata, editorState)
  payload.surveyId = surveyId
  payload.languageId = parseInt(languageId)
  payload.languageName = ''
  payload.isSync = isSync
  const urlSplit = window.location.pathname.replace(/%20/g, ' ').split('/')
  const makeurl = urlSplit.includes(OHI_4_SECTIONS)
    ? Endpoints.ohi4Sections
        .replace(':id', surveyId)
        .replace(':defaultid', 10)
        .replace('/:langid', '')
        .replace('/:id', '')
    : urlSplit.includes(Optional_Sections)
    ? Endpoints.optionalQuestion
        .replace(':id', surveyId)
        .replace('/:outcomeid', '')
    : Endpoints.deepDive.replace(':id', surveyId).replace(':id', outcomeId)

  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'PUT',
      url: makeurl,
      payload: payload,
    })
    if (!error && isSync) {
      return yield put(
        toggleToastOnSuccess({ successText: data, openToast: true })
      )
    }
  } catch (e) {}
}

function* updateSurveyDeepDive() {
  yield takeLatest(UPDATE_SURVEY_DEEP_DIVE, updateDeepDiveTexts)
}

function* replaceEveryWhereSaga({
  isCaseSensitive,
  replaceFrom,
  replaceTo,
  langId,
  toggleState,
  outcomeId,
}) {
  const state = yield select()
  const surveyId = state
    .get('surveyname')
    .get('items')
    .get('id')
  try {
    const {
      body: { error, data },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.replaceEveryWhere.replace(':id', surveyId),
      payload: {
        surveyId: surveyId,
        langId: Number(langId),
        replaceFrom: replaceFrom,
        replaceTo: replaceTo,
        isCaseSensitive: isCaseSensitive,
      },
    })
    if (!error) {
      if (toggleState) {
        yield all([
          setToastOnSuccess({ successText: data, openToast: true }),
          getDeepDiveLangData({
            surveyId: surveyId,
            outcomeId,
            languageId: langId,
          }),
        ])
      } else {
        yield all([
          setToastOnSuccess({ successText: data, openToast: true }),
          getDeepDiveData({ surveyId, outcomeId }),
        ])
      }
    }
  } catch (e) {}
}

function* replaceEveryWhereYielder() {
  yield takeLatest(REPLACE_EVERYWHERE, replaceEveryWhereSaga)
}

function* setToastOnSuccess({ successText, openToast }) {
  yield put(toggleToastOnSuccess({ successText, openToast }))
}

export default [
  fetchDeepDiveData,
  fetchDeepDiveDataLang,
  updateSurveyDeepDive,
  replaceEveryWhereYielder,
]
