import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Menu, MenuItem, Button, Fade } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import useStyles from './styles'
const ParentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #f5f9ff;
  margin-bottom: ${props =>
    props.customClassName === 'addtional-question-editor' ? '0' : '3.6vh'};
  height: 3.6vh;
`
const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.5vw;
  font-family: Lato;
  font-size: 12px;
  line-height: 1.75;
  color: #000000;
`

export default function RightSplitBar({
  availableLangauge,
  multipleLanguageData,
  fetchIntroDataForLang,
  surveyId,
  setSelectedLang,
  fetchIntroDataForAdditionalQuestions,
  fetchDataForOHIOutcomesLang,
  customClassName,
  outcomeId,
  hasEditorStateChanged,
  confirmationPopupToggle,
}) {
  const classes = useStyles()
  const key = availableLangauge[0].key
  const defaultLang = availableLangauge[0].value
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [language, setLanguage] = React.useState(defaultLang)
  const [languageId, setLanguageId] = React.useState(key.toString())
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (languageId !== -1) {
      switch (customClassName) {
        case 'survey-introduction-editor':
          fetchIntroDataForLang({ surveyId, languageId })
          break
        case 'addtional-question-editor':
          fetchIntroDataForAdditionalQuestions({
            surveyId,
            languageId,
            outcomeId,
          })
          break
        case 'survey-ohi-editor':
          fetchDataForOHIOutcomesLang({ surveyId, outcomeId, languageId })
          break
        default:
          fetchIntroDataForLang({ surveyId, languageId })
          break
      }
    }
    setSelectedLang(languageId)
  }, [languageId, outcomeId])

  const setMultipleStates = ({ innerText, slectLanguageValue, anchor }) => {
    setLanguage(innerText)
    setLanguageId(slectLanguageValue)
    setAnchorEl(anchor)
  }

  const handleOnClick = e => {
    const slectLanguageValue = e.target.dataset.myValue
    if (hasEditorStateChanged) {
      confirmationPopupToggle(setMultipleStates, {
        innerText: e.target.innerText,
        slectLanguageValue: slectLanguageValue,
        anchor: null,
      })
    } else {
      setLanguage(e.target.innerText)
      setLanguageId(slectLanguageValue)
      setAnchorEl(null)
    }
  }

  return (
    <ParentContainer customClassName={customClassName}>
      <LanguageContainer>
        <Button
          aria-controls="fade-menu"
          aria-haspopup="true"
          className={classes.menuItemText}
          onClick={handleClick}
        >
          {language}
          <ExpandMoreIcon
            className={clsx({
              [classes.tarnsformArrow]: open,
            })}
          />
        </Button>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          className={classes.menuItemText}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {availableLangauge.map((item, index) => {
            const currentKey = item.key
            const currentLang = item.value
            return (
              <MenuItem
                data-my-value={currentKey}
                key={index}
                onClick={e => handleOnClick(e)}
              >
                {currentLang}
              </MenuItem>
            )
          })}
        </Menu>
      </LanguageContainer>
    </ParentContainer>
  )
}
