import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      fontSize: 12,
      color: '#333333',
      position: 'relative',
      flexDirection: 'column',
    },
    rootResize: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },

    surveyinfo: {
      display: 'flex',
      flex: '3 1 0%',
      flexDirection: 'column',
    },
    fullWidth: {
      width: '100%',
    },
    fdcolumn: {
      flexDirection: 'column',
      paddingLeft: 53,
      paddingBottom: 0,
    },
    indicator: {
      backgroundColor: '#f28165',
      height: 5,
      borderRadius: 2.5,
    },
    tbroot: {
      width: '100%',
    },
    tbbutton: {
      width: '40%',
      fontSize: 20,
      fontWeight: 400,
      textTransform: 'capitalize',
      '&:focus': {
        outline: 'none',
      },
      '&:first-child': {
        marginRight: '9%',
      },
      '&:last-child': {
        marginLeft: '9%',
      },
    },
    tbselected: {
      fontWeight: 700,
    },
    tabwrapper: {
      paddingTop: 38,
      paddingLeft: 48,
      paddingRight: 48,
      borderBottom: 'solid 1px #e7e7e7',
      display: 'flex',
      flexDirection: 'row',
    },
    designSurvey: {
      display: 'flex',
      flex: '4 1 0%',
      backgroundColor: '#fafafa',
      padding: 48,
      flexDirection: 'column',
    },
    tabpanelctr: {
      textAlign: 'left',
      paddingLeft: 42,
    },
    summaryLabel: {
      fontSize: 12,
    },
    itemValue: {
      marginBottom: 32,
    },
    sectionHeading: {
      fontSize: 20,
      fontWeight: 900,
      marginBottom: theme.spacing(3),
    },
    designSurveyTitle: {
      fontSize: 20,
      fontWeight: 700,
      textAlign: 'left',
      marginBottom: 14,
    },
    cstexpanded: {
      margin: 0,
    },
    heading: {
      fontSize: 14,
      fontWeight: 700,
    },
    addedList: {
      listStyle: 'none',
      margin: 0,
      padding: '0 0 20px',
      '&:empty': {
        display: 'none',
      },
      '& > li': {
        paddingLeft: 15,
        fontSize: 14,
        marginBottom: 8,
        position: 'relative',
        '& ul': {
          color: '#999',
        },
        '& strong': {
          color: '#333333',
          fontWeight: 400,
        },
        '&:before': {
          content: '""',
          width: 5,
          height: 5,
          borderRadius: '100%',
          backgroundColor: '#6db620',
          position: 'absolute',
          top: 9,
          left: 0,
          margin: 'auto',
        },
      },
    },
    removed: {
      listStyle: 'none',
      margin: 0,
      padding: '0 0 20px',
      '&:empty': {
        display: 'none',
      },
      '& li': {
        paddingLeft: 15,
        fontSize: 14,
        marginBottom: 8,
        position: 'relative',
        '&:before': {
          content: '""',
          width: 5,
          height: 5,
          borderRadius: '100%',
          backgroundColor: '#c72323',
          position: 'absolute',
          top: 5,
          left: 0,
          margin: 'auto',
        },
      },
    },

    acordContent: {
      paddingLeft: 20,
    },
    formBottom: {
      borderTop: '1px solid #dddddd',
      padding: theme.spacing(4, 5),
    },
    buttonRound: {
      borderRadius: '25px',
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: 14,
    },
    surveylinkLabel: {
      fontSize: 14,
      fontWeight: 900,
      whiteSpace: 'normal',
      width: 125,
      textAlign: 'left',
      paddingTop: 9,
    },
    surveyLink: {
      width: '100%',
      padding: '0 20px',
      borderRadius: '22.5px',
      height: '40px',
      lineHeight: '40px',
      fontSize: '16px',
      color: '#333333',
      border: 'solid 1px #4b93fd',
      position: 'relative',
      '& input': {
        width: '100%',
        height: 'calc(100% - 2px)',
        border: 'none',
        '&:focus': {
          outline: 'none',
        },
      },
      '& button': {
        display: 'block',
        right: '20px',
        top: 0,
        bottom: 0,
        marginTop: 'auto',
        marginBottom: 'auto',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 700,
        textTransform: 'uppercase',
        color: '#4b93fd',
        position: 'absolute',
        background: 'none',
        border: 'none',
      },
    },
    copytextshow: {
      color: 'rgb(3, 143, 47)',
      display: 'block',
      left: 0,
      textAlign: 'left',
      lineHeight: 'normal',
      marginTop: 5,
      '& span': {
        verticalAlign: 'middle',
      },
    },
    surveylinkinfo: {
      bottom: '-34px',
      fontSize: '12px',
      left: 0,
      color: '#999',
      lineHeight: 'normal',
      whiteSpace: 'nowrap',
      marginBottom: '10px',
      marginTop: 5,
      textAlign: 'left',
    },
    commentWrap: {
      padding: '10px 35px',
      borderTop: 'solid 1px #ddd',
      backgroundColor: '#fafafa',
    },
    marginRight: {
      marginRight: '150px',
    },
  }
})

export default styles
