import {
  Button,
  Box,
  Paper,
  TextField,
  Typography,
  TableHead,
  TableRow,
  TableBody,
  Slide,
  TableCell,
  Table,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
import React, { Fragment, memo, useEffect, useState } from 'react'
import { SurveyHeader } from '../../components'
import useStyles from '../ManageSurveys/styles'
import { themeStyles } from './theme'
import uploadIcon from '../../pages/Demographics/assets/uploadGroupIcon.svg'
import { useToasts } from 'react-toast-notifications'
import { ProjectInfoTable, RecordFileTable } from './ProjectInfoTable'

let task_status_interval = ''
const Backcoding = props => {
  const {
    history,
    uploadBackcodingPendingAction,
    uploadData,
    isFetched,
    downloadMappingFilePending,
    downloadMappingData,
    statuscode,
    generateLogsPendingAction,
    taskState,
    taskData,
    GenerateTaskLogsPendingAction,
    resetErrorState,
    ResetBackcodingData,
    ResetLogsData,
    ResetTaskId,
  } = props

  const classes = useStyles()
  const commonStyles = themeStyles()
  const [state, setState] = useState({
    pid: '',
    files: '',
    filename: '',
  })
  const { addToast } = useToasts()
  const [data, setData] = useState([])
  const [open, setOpen] = useState(taskData.isDataFetched)
  const [isStatus, setIsStatus] = useState(false)

  useEffect(() => {
    return function() {
      clearInterval(task_status_interval)
      ResetBackcodingData()
      ResetLogsData()
      ResetTaskId()
    }
  }, [])

  useEffect(() => {
    if (isFetched) {
      const { data: objectData } = uploadData
      setData(objectData)
    }
  }, [isFetched])

  useEffect(() => {
    if (taskState.items !== '') {
      const ifprocessdone =
        taskData.isDataFetched && taskData.items.length
          ? taskData.items.filter(
              ({ detail = '' }) => detail === 'Process Completed.'
            )
          : []
      if (
        taskData.isDataFetched &&
        (!taskData.items.length || ifprocessdone?.length)
      ) {
        setIsStatus(false)
        clearInterval(task_status_interval)
        return
      } else {
        clearInterval(task_status_interval)
        setIsStatus(true)
        task_status_interval = setInterval(() => {
          GenerateTaskLogsPendingAction({ taskId: taskState.items })
        }, 5000)
      }
    }
  }, [taskState.items, taskData.items])

  useEffect(() => {
    if (
      statuscode &&
      statuscode.get('errorstatus') &&
      statuscode.get('errorstatus') !== ''
    ) {
      if (statuscode.toJS()?.errorstatus?.payload?.data?.data?.message) {
        setIsStatus(true)
        clearInterval(task_status_interval)
        resetErrorState()
      }
    }
  }, [statuscode])

  const handleFile = e => {
    const {
      target: { files, name },
    } = e
    const { name: fileName } = files[0]
    if (!['xls', 'xlsx', 'csv'].includes(fileName.split('.')[1])) {
      addToast('Please select a valid file', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      setState({
        ...state,
        files: files[0],
        filename: fileName,
      })
    }
  }

  const submitHandler = e => {
    const { pid, filename } = state
    if (!pid || !filename) {
      addToast('Please enter values in all fields', {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      //do
      uploadBackcodingPendingAction(state)
    }
  }

  const handleInput = e => {
    const {
      target: { value, name },
    } = e
    setState({
      ...state,
      [name]: value,
    })
  }

  const downloadFile = () => {
    const { downloadFile, pid } = data
    downloadMappingFilePending({
      pid,
      fileName: downloadFile,
      type: 'mapping',
    })
  }

  const generateData = () => {
    const { pid, hrFileName, extension, tempGuid } = data
    setOpen(true)
    generateLogsPendingAction({
      pid,
      hrFileName,
      extension,
      tempGuid,
    })
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <Box className={classes.root}>
      <SurveyHeader history={history} statuscode={statuscode} />
      <Box
        display="flex"
        alignItems="center"
        px={4}
        py={2}
        justifyContent="space-between"
      >
        <Box>
          <Typography
            className={classes.pageHeading}
            component="h1"
            variant="h6"
          >
            Backcoding Tool
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Paper style={{ width: '35vw', padding: '2vh 2vh' }}>
          <Typography variant={`h6`}>
            OHI Backcoding Tool: Upload HR File
          </Typography>
          <Box
            component={`div`}
            display="flex"
            flexDirection="column"
            margin={3}
          >
            <TextField
              label="Project Id"
              name="pid"
              id="pid"
              placeholder="Enter pid"
              style={{
                marginBottom: '20px',
              }}
              onChange={handleInput}
            />
            <br />
            <img
              src={uploadIcon}
              name="uploadIcon"
              alt="uploadIcon"
              width="200"
              style={{ marginBottom: '5px' }}
            />
            <input
              type="file"
              name="backcodingfile"
              id="files"
              size="60"
              accept="xlsx"
              onChange={handleFile}
            />
            <br />
            <Button
              className={commonStyles.buttonClass}
              variant="contained"
              color="primary"
              onClick={submitHandler}
            >
              CREATE
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box margin={5}>
        {Object.keys(data).length ? (
          <Fragment>
            <Box display={`flex`} justifyContent={`flex-start`}>
              <Typography variant={`h6`}>Project Information:</Typography>
            </Box>
            <ProjectInfoTable
              classes={classes}
              commonStyles={commonStyles}
              data={data}
            />
            <Box mt={4} component={`div`}>
              <Box display={`flex`} justifyContent={`space-between`}>
                <Typography variant={`h6`}>
                  Total HR File Record Count: {data.totalCount || '0'}
                </Typography>
                <Typography
                  variant={`body2`}
                  classes={{
                    root: commonStyles.typographyClass,
                  }}
                  onClick={() => downloadFile()}
                >
                  Download Mapping file
                </Typography>
              </Box>
              {data.lstResult.length ? (
                <RecordFileTable
                  data={data}
                  classes={classes}
                  commonStyles={commonStyles}
                />
              ) : null}
            </Box>
            <Box
              component={`div`}
              display={`flex`}
              justifyContent={`flex-start`}
              mt={3}
            >
              <Button
                className={commonStyles.buttonClass}
                variant="contained"
                color="primary"
                onClick={generateData}
              >
                Generate
              </Button>
              &nbsp;&nbsp;&nbsp;
              {taskData.items?.length ? (
                <Button
                  className={commonStyles.buttonClass}
                  variant="contained"
                  color="primary"
                  onClick={setOpen}
                >
                  Logs
                </Button>
              ) : null}
            </Box>
          </Fragment>
        ) : null}
        {/* Logs */}
        <Dialog
          open={open}
          fullWidth
          classes={{
            paperWidthSm: commonStyles.paperWidthDialog,
          }}
        >
          <DialogTitle>
            {taskState?.items ? `Task ID: ${taskState.items}` : ''}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>
                {isStatus ? (
                  <span>
                    {' '}
                    Generating Logs <CircularProgress size={20} thickness={5} />
                  </span>
                ) : (
                  ''
                )}
              </Typography>
              <Table className={classes.surveyTable} size="medium">
                <TableHead>
                  <TableRow key={`trowkey1`}>
                    <TableCell>Time</TableCell>
                    <TableCell>Task</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taskData.items.length ? (
                    taskData.items?.map(
                      ({ insertDate = '', detail = '' }, i) => (
                        <TableRow key={`tkeyrow_${i}`}>
                          <TableCell key={`tkeyc_${i}`}>
                            {insertDate || '-'}
                          </TableCell>
                          <TableCell key={Math.random(0, 12)}>
                            {detail || '-'}
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>NO LOGS FOUND</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disable={
                taskData.items?.message?.filter(
                  ({ Detail }) => Detail !== 'Process Completed.'
                ).length
              }
              color="primary"
              variant="contained"
              className={commonStyles.buttonClass}
              onClick={closeModal}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  )
}

export default memo(Backcoding)
