import { makeStyles } from '@material-ui/core'

export const themeStyles = makeStyles(theme => ({
  buttonClass: {
    background: 'navy',
  },
  tabelCell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    width: '50%',
  },
  typographyClass: {
    color: 'blue',
    cursor: 'pointer',
    fontSize: '16px',
  },
  paperWidthDialog: {
    maxWidth: '800px',
  },
}))
