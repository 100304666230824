import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import styled from 'styled-components'
import { Base_Route, IconObj, OUTCOMEID } from './constants'
import lockIcon from './assets/Lock.svg'
import downArrow from '../../assets/down_arrow.svg'
import UnOrderList from '../UnOrderList/UnOrderList'

const SideLinkIcon = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0.3vw;
  width: 1.6vw;
  height: 2.7vh;
  background-image: url(${props => props.Icon});
`
const UploadIcon = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  width: 1vw;
  height: 2vh;
  background-image: url(${props => props.Icon});
`
function Links({
  classes,
  currentPath,
  onGo,
  paramID,
  isDisabled,
  link,
  index,
  isTextUpdateComponent,
  location,
  hasEditorStateChanged,
  confirmationPopupToggle,
  history,
}) {
  const [closeUL, setCloseUL] = useState(false)
  const queryParam = location.search
  const showExpandIcon = link.isSubMenu && !isDisabled
  const route = isTextUpdateComponent
    ? `${Base_Route}/${link.menuName.toLowerCase()}/${paramID}`
    : `${link.route}/${paramID}`
  const expandMenu =
    queryParam.indexOf(OUTCOMEID) > -1 && location.pathname === route
  const showULList = expandMenu && showExpandIcon && !closeUL
  const linkLabel = isTextUpdateComponent ? link.menuName : link.label
  return (
    <Fragment>
      <Box
        key={index}
        display="flex"
        style={{ position: 'relative' }}
        className={clsx({
          [classes.listItem]: true,
          [classes.listItemActive]: route === currentPath,
          [classes.disablemenu]: isDisabled,
        })}
      >
        <SideLinkIcon
          Icon={isTextUpdateComponent ? IconObj[link.menuIcon] : link.icon}
        />
        <Box
          className={clsx(
            classes.listItemText,
            classes.fullWidth,
            classes.alignLeft
          )}
          onClick={handleClick(
            route,
            isDisabled,
            showExpandIcon,
            linkLabel,
            isTextUpdateComponent,
            expandMenu
          )}
        >
          {linkLabel}
          {showExpandIcon && (
            <UploadIcon
              Icon={downArrow}
              className={clsx({
                [classes.lockiconcss]: true,
                [classes.tarnsformArrow]: showULList,
              })}
            />
          )}
        </Box>
        {isDisabled ? (
          <Box component="img" src={lockIcon} className={classes.lockiconcss} />
        ) : null}
      </Box>
      <Box className={classes.ulListContainer}>
        <UnOrderList
          data={link.MenuData}
          isVisible={showULList}
          classes={classes}
          route={currentPath}
          queryParam={queryParam}
          confirmationPopupToggle={confirmationPopupToggle}
          hasEditorStateChanged={hasEditorStateChanged}
          history={history}
        />
      </Box>
    </Fragment>
  )
  function handleClick(
    route,
    isDisabled,
    showExpandIcon,
    linkLabel,
    isTextUpdateComponent,
    expandMenu
  ) {
    return function() {
      setCloseUL(!closeUL)
      let updatedRoute = route
      if (showExpandIcon && isTextUpdateComponent) {
        updatedRoute = `${route.toLowerCase()}${OUTCOMEID}=${
          link.MenuData[0].id
        }`
      }
      if (!isDisabled && !expandMenu && hasEditorStateChanged) {
        confirmationPopupToggle(onGo, updatedRoute)
      } else if (!isDisabled) {
        onGo(updatedRoute)
      }
    }
  }
}

Links.propTypes = {
  classes: PropTypes.object,
  currentPath: PropTypes.string,
  onGo: PropTypes.func,
  paramID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
  link: PropTypes.object,
  index: PropTypes.number,
  location: PropTypes.object.isRequired,
  hasEditorStateChanged: PropTypes.bool,
}

Links.defaultProps = {
  onGo: () => {},
  location: {},
}

export default Links
