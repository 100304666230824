import React from 'react'
import { Box } from '@material-ui/core'
import useStyles from './styles'

function Page404(props) {
  document.title = 'OHI Portal - 404 Page Not Found'
  const classes = useStyles()

  // const { history, location } = props

  return (
    <Box className={classes.notfound}>
      <div className={classes.bgimg}></div>
      <div className={classes.bg}></div>
      <div className={classes.ctrl}>
        <div className={classes.logo}></div>
        <h4>
          The page you are looking for doesn't exist.
          <br />
          you may have mistyped the address or the page may have moved.
        </h4>
      </div>
    </Box>
  )
}
export default Page404
