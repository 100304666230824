import React, { useState } from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import useStyles from './styles'
import { OUTCOMEID } from '../../../components/SurveySidebar/constants'
import { createRoutesArray } from '../utils'

function IntroductionBottom({
  history,
  match,
  updateSurvey,
  editorState,
  hasEditorStateChanged,
  selectedLanguageId,
  surveyId,
  outcomeId,
  // fetchSummaryOfEdits,
  confirmationPopupToggle,
  hasUserSelcetdMultiLangOption,
  sidePanelData,
  isButtonToBeDisabled,
  setHasEditorStateChanged,
}) {
  const isSync = false
  const classes = useStyles()
  const matchPreviousPath = ({ match, history }) => {
    if (match.path.includes('update-survey-text/introduction')) {
      return history.push(`/define/client-info/${match.params.id}`)
    }
    history.goBack()
  }
  const currentRoute = outcomeId
    ? window.location.pathname.concat(`${OUTCOMEID}=${outcomeId}`)
    : window.location.pathname
  const routes = createRoutesArray(sidePanelData, match, OUTCOMEID)
  const isLastPage = currentRoute.includes(routes[routes.length - 1])

  const matchContinuePath = ({ currentRoute, routes }) => {
    const currentIndex = routes.indexOf(currentRoute)
    if (isLastPage) {
      history.push(`/survey/demographics/${surveyId}`)
    } else {
      history.push(routes[currentIndex + 1])
    }
  }

  const onContinueClick = () => {
    return function() {
      if (hasEditorStateChanged) {
        confirmationPopupToggle(matchContinuePath, { currentRoute, routes })
      } else {
        matchContinuePath({ currentRoute, routes })
      }
    }
  }

  const onPreviousClick = () => {
    if (hasEditorStateChanged) {
      confirmationPopupToggle(matchPreviousPath, { match, history })
    } else {
      matchPreviousPath({ match, history })
    }
  }

  return (
    <Box>
      <Box display="flex" className={classes.formBottom}>
        <Box
          style={{
            textAlign: 'left',
            color: '#999999',
            marginTop: -15,
            fontSize: '12px',
          }}
        >
          *Kindly adjust webpage Zoom if buttons are overlapping. Currently
          webpage is not optimized for smaller screens.
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonRound}
            onClick={() => {
              onPreviousClick()
            }}
          >
            Previous
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={
              !isButtonToBeDisabled && hasEditorStateChanged
                ? classes.buttonRound
                : classes.buttonRoundDisabled
            }
            onClick={() => {
              updateSurvey(
                editorState,
                surveyId,
                selectedLanguageId,
                isSync,
                hasUserSelcetdMultiLangOption,
                outcomeId
              )
              setHasEditorStateChanged(false)
            }}
          >
            Save
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonRound}
            onClick={onContinueClick()}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default IntroductionBottom
