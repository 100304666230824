export const INTRODUCTION_FETCH = 'INTRODUCTION/FETCH'
export const FETCH_SIDE_PANEL_DATA = 'FETCH_SIDE_PANEL_DATA'
export const FETCH_SIDE_PANEL_DATA_SUCCESS = 'FETCH_SIDE_PANEL_DATA_SUCCESS'
export const FETCH_SIDE_PANEL_DATA_ERROR = 'FETCH_SIDE_PANEL_DATA_ERROR'
export const FETCH_INTRO_DATA_LANG = 'FETCH_INTRODUCTION_DATA_LANGUAGE'
export const FETCH_INTRO_DATA_LANG_SUCCESS =
  'FETCH_INTRODUCTION_DATA_LANGUAGE_SUCCESS'
export const FETCH_INTRO_DATA_LANG_ERROR =
  'FETCH_INTRODUCTION_DATA_LANGUAGE_ERROR'
export const SET_SELECTED_LANG = 'SET_SELECTED_LANG'
export const SAVE_SURVEY_INTRODUCTION = 'SAVE_SURVEY_INTRODUCTION'
export const SAVE_SURVEY_INTRODUCTION_SUCCESS =
  'SAVE_SURVEY_INTRODUCTION_SUCCESS'
export const SAVE_SURVEY_INTRODUCTION_ERROR = 'SAVE_SURVEY_INTRODUCTION_ERROR'
export const HAS_EDITOR_STATE_CHANGED = 'HAS_EDITOR_STATE_CHANGED'
export const TOGGLE_CONFIRMATION_POP_UP = 'TOGGLE_CONFIRMATION_POP_UP'
export const IMPORT_FILE = 'IMPORT_FILE'
export const EXPORT_FILE = 'EXPORT_FILE'
export const FETCH_SUMMARY_OF_EDITS = 'FETCH_SUMMARY_OF_EDITS'
export const REPLACE_EVERYWHERE = 'REPLACE_EVERYWHERE'
export const IMPORT_FILE_FAILURE = 'IMPORT_FILE_FAILURE'
export const TOGGLE_IMPORT_FILE_FAILURE_CONTAINER =
  'TOGGLE_IMPORT_FILE_FAILURE_CONTAINER'
export const SEND_EMAIL = 'SEND_EMAIL'
