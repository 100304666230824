import React from 'react'
import { connect } from 'react-redux'
import Backcoding from './Backcoding'
import {
  uploadBackcodingPendingAction,
  downloadMappingFilePending,
  generateLogsPendingAction,
  GenerateTaskLogsPendingAction,
  resetErrorState,
  ResetBackcodingData,
  ResetLogsData,
  ResetTaskId,
} from '../../common/actions'

const mapStateToProps = state => ({
  uploadData: state.get('backcoding'),
  isFetched: state.get('backcoding').isUploaded,
  downloadMappingData: state.get('downloadMappingReducer'),
  statuscode: state.get('statuscode'),
  taskState: state.get('GenerateLogsReducer'),
  taskData: state.get('TaskLogsReducer'),
})

const mapDispatchToProps = {
  uploadBackcodingPendingAction,
  downloadMappingFilePending,
  generateLogsPendingAction,
  GenerateTaskLogsPendingAction,
  resetErrorState,
  ResetBackcodingData,
  ResetLogsData,
  ResetTaskId,
}

export default connect(mapStateToProps, mapDispatchToProps)(Backcoding)
