import axios from 'axios'
import Endpoints from '../../Endpoints'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}

const api = new APIs('/api')
// SAVE SURVEYINFO

export const saveSurveyInfo = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveys/updateSurvey`),
    data,
  })
  return result
}

// SURVEYINFO
export const fetchSurveyinfo = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveys/${data}`),
  })
  return result
}
// resurvey
export const fetchResurvey = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveys/getresurveyinformation/${data}`),
  })
  return result
}
// SURVEY_TYPE_POPULATIONS
export const fetchSurveyTypes = async () => {
  const result = await axios({
    method: 'get',
    url: api.getURL('dropdownvalues?dropDownType=2'),
    //params: { dropDownType: 2 },
  })
  return result
}
// REGIONS
export const fetchRegions = async () => {
  const result = await axios({
    method: 'get',
    url: api.getURL('Region'),
  })
  return result
}

// POPULATIONS
export const fetchPopulations = async () => {
  const result = await axios({
    method: 'get',
    url: api.getURL('populations'),
  })
  return result
}
// COUNTRIES
export const fetchCountries = async () => {
  const result = await axios({
    method: 'get',
    url: api.getURL('countries'),
  })
  return result
}
// LANGUAGES
export const fetchLanguages = async () => {
  const result = await axios({
    method: 'get',
    url: api.getURL('languages'),
  })
  return result
}
// ANALYSTS
export const fetchAnalysts = async () => {
  const result = await axios({
    method: 'get',
    url: api.getURL('analyst'),
  })
  return result
}

//COHESION DEMO
export const fetchCohesionDemoUrl = async surveyId => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveyquestions/demo-list/${surveyId}`),
  })
  return result
}

// OHI Version
export const fetchOhiVersionUrl = async surveyId => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveys/ohi-version-list`),
  })
  return result
}

// OHI Version language

// LANGUAGES
export const fetchOhiVersionLanguages = async id => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`ohi-version-languages/${id}`),
  })
  return result
}
