class Storage {
  constructor() {
    if (!Storage.instance) {
      this._local = localStorage
      Storage.instance = this
    }
    return Storage.instance
  }

  set clientInfo(val) {
    this._local.setItem('clientInfo', val)
  }

  set surveyInfo(val) {
    this._local.setItem('surveyInfo', val)
  }

  set designSurvey(val) {
    this._local.setItem('designSurvey', val)
  }

  set summary(val) {
    this._local.setItem('summary', val)
  }

  get designSurvey() {
    return this._local.getItem('designSurvey')
  }

  get summary() {
    return this._local.getItem('summary')
  }

  get surveyInfo() {
    return this._local.getItem('surveyInfo')
  }

  get clientInfo() {
    return this._local.getItem('clientInfo')
  }

  delete() {
    this._local.removeItem('designSurvey')
    this._local.removeItem('summary')
    this._local.removeItem('clientInfo')
    this._local.removeItem('surveyInfo')
  }
}

const StorageInstance = new Storage()
Object.freeze(StorageInstance)

export default StorageInstance
