import { Base_Route, OUTCOMEID } from '../../components/SurveySidebar/constants'
import { ALLOWED_FILE_TYPES } from '../../utils/constants'
const headingStart = '<h2>'
const headingEnd = '</h2>'
const paraStart = '<p>'
const paraEnd = '</p>'

const transformData = data => {
  const newDataWithTags = data.map(obj => {
    const transformedHeading =
      obj.heading !== undefined
        ? transformComponent(obj.heading, headingStart, headingEnd)
        : ''
    const transformedParagraph = transformComponent(
      obj.text,
      paraStart,
      paraEnd
    )
    const subSection = transformedHeading.concat(transformedParagraph)
    return subSection
  })
  return newDataWithTags.join('')
}

const transformComponent = (component, startTag, endTag) => {
  const positionStart = 0
  const componentWithStartingTag = [
    component.slice(0, positionStart),
    startTag,
    component.slice(positionStart),
  ].join('')
  const positionEnd = componentWithStartingTag.length
  const componentWithTags = [
    componentWithStartingTag.slice(0, positionEnd),
    endTag,
    componentWithStartingTag.slice(positionEnd),
  ].join('')
  return componentWithTags
}

function remove_linebreaks(introductionData) {
  if (introductionData && introductionData.length > 0) {
    return introductionData.replace(/[\r\n]+/gm, ' ')
  }
  return ''
}

function transform_payloadData(data) {
  data = data
    .split('<p>')
    .slice(1)
    .map(dataItem => {
      return dataItem.substring(0, dataItem.lastIndexOf('</p>'))
    })
  return data
}
function getNumberOfDivsBetwEachDiv(
  currentId,
  isMultiLanguage,
  introDuctionClassNames
) {
  const elementToCheck = isMultiLanguage ? 1 : 0
  const currentElemt = document.querySelectorAll('.' + currentId)[
    elementToCheck
  ]
  const nextElment = currentElemt.nextElementSibling
  let numberOfDiv = 0
  function getNumberOfDiv(nextElement = nextElment) {
    const className = nextElement.className
    if (
      !className ||
      (className && checkClassName(className, introDuctionClassNames))
    ) {
      numberOfDiv++
      getNumberOfDiv(nextElement.nextElementSibling)
    }
    return numberOfDiv
  }
  return getNumberOfDiv()
}
function checkClassName(className, introDuctionClassNames) {
  const result = introDuctionClassNames.some(item => {
    return item.indexOf(className) > -1
  })
  return result
}
function getParseText(text) {
  let updatedText
  if (text) {
    updatedText = text
  } else {
    updatedText = '<br>'
  }
  return updatedText
}
function setPayloadForIntroduction(
  originalData,
  dataFromEditorState,
  isMultiLanguage,
  introDuctionClassNames
) {
  const numberOfDivs = []
  if (originalData.length > 1) {
    for (var i = 0; i < introDuctionClassNames.length - 1; i++) {
      const count = getNumberOfDivsBetwEachDiv(
        introDuctionClassNames[i],
        isMultiLanguage,
        introDuctionClassNames
      )
      numberOfDivs.push(count)
    }
  }
  let transformedData = transform_payloadData(dataFromEditorState)
  let transformedDataFromEditorState = JSON.parse(JSON.stringify(originalData))
  if (originalData.length > 1) {
    transformedDataFromEditorState = transformedDataFromEditorState.map(
      (textItem, index) => {
        let text = ''
        if (index === numberOfDivs.length) {
          for (var i = 0; i < transformedData.length; i++) {
            text = text + getParseText(transformedData[i])
          }
        } else {
          for (var i = 0; i < numberOfDivs[index] + 1; i++) {
            text = text + getParseText(transformedData[i])
          }
        }
        textItem.text = text
        transformedData.splice(0, numberOfDivs[index] + 1)
        return textItem
      }
    )
  } else {
    let text = ''
    transformedDataFromEditorState[0].text =
      text + getParseText(transformedData[0])
  }
  return transformedDataFromEditorState
}

function createRoutesArray(sidePanelData, match) {
  let routes = []
  let route = ''
  sidePanelData.map(item => {
    if (item.MenuData.length > 0) {
      item.MenuData.map(i => {
        route = `${Base_Route}/${item.menuName
          .toLowerCase()
          .replaceAll(' ', '%20')}/${match.params.id}${OUTCOMEID}=${i.id}`
        routes.push(route)
      })
    } else {
      route = `${Base_Route}/${item.menuName
        .toLowerCase()
        .replaceAll(' ', '%20')}/${match.params.id}`
      routes.push(route)
    }
  })

  return routes
}

function validateExtension(fileName) {
  const regex = new RegExp(/(\.[^.]*)$/g)
  const fileType = regex.exec(fileName)[0]
  if (ALLOWED_FILE_TYPES.indexOf(fileType) !== -1) {
    return true
  }
  return false
}

export {
  transformData,
  remove_linebreaks,
  transform_payloadData,
  setPayloadForIntroduction,
  createRoutesArray,
  validateExtension,
}
