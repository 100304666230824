import axios from 'axios'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}

const api = new APIs('/api')
//GET OUTCOME SCALE
export const fetchOutcmeScale = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL('dropdownvalues?dropDownType=3'),
  })
  return result
}
//GET OUTCOME SCALE
export const fetchPracticeScale = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL('dropdownvalues?dropDownType=4'),
  })
  return result
}

//GET DISIGN SURVEY
export const fetchDesignSurvey = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL('DesignSurvey'),
    params: { ...data },
  })
  return result
}

//UPDATE DISIGN SURVEY
export const updateDesignSurvey = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`designsurvey/updatedesignsurvey/${data.surveyId}`),
    data,
  })
  return result
}

//UPDATE DISIGN SURVEY
export const saveQuestion = async data => {
  const result = await axios({
    method: 'post',
    url: api.getURL('SurveyQuestions'),
    data,
  })
  return result
}

//GET QUESTION LIST
export const fetchQuestionList = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveyquestions/${data}`),
  })
  return result
}

//UPDATE QUESTION LIST
export const updateQuestionList = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveyquestions/list/${data.surveyId}`),
    data,
  })
  return result
}

//UPDATE QUESTION LIST
export const updateQuestion = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveyquestions/${data.id}`),
    data,
  })
  return result
}

//GET INFLUENCER
export const fetchInfluencer = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveys/addondetails/${data.surveyId}/${data.questionId}`),
  })
  return result
}

export const fetchOhiTestModule = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveys/addondetails/${data.surveyId}/${data.questionId}`),
  })
  return result
}
//UPDATE INFLUENCER
export const updateInfluencer = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveys/addoninfluencer/${data.surveyId}`),
    data,
  })
  return result
}

export const updateOhiTestModule = async data => {
  const result = await axios({
    method: 'PUT',
    url: api.getURL(`surveys/addon-ohi-test-module/${data.surveyId}`),
    data,
  })
  return result
}

//UPDATE VALUES
export const updateValues = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveys/addonvalues/${data.surveyId}`),
    data,
  })
  return result
}

//UPDATE INCLUSION
export const updateInclusion = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveys/addoninclusion/${data.surveyId}`),
    data,
  })
  return result
}
//UPDATE INCLUSION
export const updateWordExapi = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveys/addon-employee-experience/${data.surveyId}`),
    data,
  })
  return result
}

//GET OHI 4.0 Modules
export const fetchOhi4module = async data => {
  const result = await axios({
    method: 'GET',
    url: api.getURL(`surveys/addondetails/${data.surveyId}/${data.questionId}`),
  })
  return result
}

//UPDATE OHI 4.0 Modules
export const updateOhi4module = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveys/addon-ohi-v4-module/${data.surveyId}`),
    data,
  })
  return result
}
