import React, { Component } from 'react'
// import RichTextEditor from 'react-rte'
import styled from 'styled-components'
import isEqual from 'lodash/fp/isEqual'
import Revert_to_last_update from '../../assets/revert_to_last_update.svg'
import ImportIcon from '../../assets/export.svg'
import ExportIcon from '../../assets/ExportCopy.svg'
import FindI from '../../assets/Findreplace.svg'
import LinkIcon from '../../assets/link.svg'
import UnlinkIcon from '../../assets/unlink.svg'
import ToolTip from '../../assets/tooltip-icon-grey.png'
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  Button,
  Modal,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import closeModalIcon from '../../pages/ClientInfo/assets/Cancel_Icon.png'
import LanguageToggleButton from '../../pages/SurveyIntroduction/components/LanguageToggleButton'
import LeftSplitBar from '../LeftSplitBar'
import RightSplitBar from '../RightSplitBar'
import {
  remove_linebreaks,
  validateExtension,
} from '../../pages/SurveyIntroduction/utils'
import PopupModal from '../PopupModal'
import ModalContent from './ModalContent'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import {
  EditorState,
  SelectionState,
  Modifier,
  ContentState,
  RichUtils,
  KeyBindingUtil,
  convertToRaw,
  getDefaultKeyBinding,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { transformData } from '../../pages/SurveyAdditionalQuestions/utils'
import { transformData as transformOHIData } from '../../pages/SurveyOHI/utils'
import {
  ohiClassNames,
  surveyOhiEditor,
  surveyIntroductionEditor,
  BorderClass,
  IntroductionBorderClass,
  addtionalQuestionEditor,
} from './constants'
import { removeBrs } from '../../utils'
import RevertToLastUpdateContainer from '../../pages/RevertToLastUpdate/RevertToLastUpdate'
import { ModifiedSelect } from '../index'
import ImportFailureContainer from './ImportFailureContainer'
import { UPDATE_SURVEY_TEXT, ALLOWED_FILE_TYPES } from '../../utils/constants'

const toolbar = {
  // options: ['history', 'inline', 'list'],
  options: ['history', 'inline'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline'],
    bold: { className: undefined },
    italic: { className: undefined },
    underline: { className: undefined },
  },
  // list: {
  //   inDropdown: false,
  //   className: undefined,
  //   component: undefined,
  //   dropdownClassName: undefined,
  //   options: ['unordered', 'ordered'],
  //   unordered: { className: undefined },
  //   ordered: { className: undefined },
  // },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
    undo: { className: undefined },
    redo: { className: undefined },
  },
}

const PeripheralContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 12.2vw;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  right: 0;
  background-color: #fafafa;
  @media (max-width: 1279.95px) and (min-width: 600px) {
    height: 90%;
  }
`

const ImportExportContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
`

const UploadIcon = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
  width: 1vw;
  height: 1.8vh;
  background-image: url(${props => props.Icon});
`

const FindIcon = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 0.5vw 0;
  width: 1.4vw;
  height: 3vh;
  background-image: url(${props => props.Icon});
`

const Icon = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  margin: ${props => props.margin};
  width: ${props => props.width};
  height: ${props => props.height};
  background-image: url(${props => props.icon});
`

const Line = styled.div`
  display: inherit;
  height: 6vh;
  justify-content: center;
  align-items: center;
  width: 1px;
  opacity: 0.7;
  background-color: #ccc;
`

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  color: ${props => props.color};
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  font-style: ${props => props.fontstyle};
  width: ${props => props.width};
`

const FindAndReplace = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7vh;
  border-right: 0.1px solid #ccc;
  width: 50%;
  .setMarging {
    @media (max-width: 1279.95px) and (min-width: 600px) {
      margin: 10px 0;
    }
  }
`

const RevertToLastUpdate = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: inherit;
  width: 50%;
  justify-content: center;
  align-items: center;
  height: 6vh;
  .setMarging {
    @media (max-width: 1279.95px) and (min-width: 600px) {
      margin: 10px 0;
    }
  }
`

// const UpdateSurveyButton = styled.div`
//   display: inherit;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
// `

const MyButton = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6vw;
  height: 5vh;
  border-radius: 22.5px;
  border: solid 1px #f28165;
  color: #f28165;
  font-size: 1.6vh;
  font-weight: bold;
  margin-bottom: 0.6vh;
`

const CurrentReplaceOccurence = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: flex-end;
  align-items: center;
`
const ToolTipIcon = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  height: 1.8vh;
  width: 1vw;
  background-image: url(${props => props.Icon});
`

// const ViewSurveyButton = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: column;
// `

const ViewSurveyHREF = styled.div`
  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6vw;
  height: 5vh;
  border-radius: 22.5px;
  border: solid 1px #4b93fd;
  color: #4b93fd;
  font-size: 1.6vh;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 0.6vh;
`

// const SurveyActionsHandlerContainer = styled.div`
// display: inherit;
// flex-direction: column;
// justify-content: space-between;
// `;

const LinkUnlinkContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
  width: 100%;
  height: 6vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #ccc;
`
const IconContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinkIcons = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  width: ${props => props.width};
  height: ${props => props.height};
  background-image: url(${props => props.Icon});
`

const IntroText = styled.div`
  text-align: left;
  margin-bottom: 3vh;
  font-style: italic;
  font-size: 1.5vh;
`

const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText()
  let matchArr, start, end
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    end = start + matchArr[0].length
    callback(start, end)
  }
}

function SearchHighlight(props) {
  return <span className="search-and-replace-highlight">{props.children}</span>
}

const generateDecorator = (highlightTerm, ignoreCase) => {
  let regex
  if (ignoreCase) {
    regex = new RegExp(highlightTerm, 'gi')
  } else {
    regex = new RegExp(highlightTerm, 'g')
  }
  return {
    strategy: function(contentBlock, callback) {
      if (highlightTerm !== '') {
        findWithRegex(regex, contentBlock, callback)
      }
    },
    component: SearchHighlight,
  }
}

export default class RichTextTool extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.richTextContent !== prevState.richTextContent) {
      const contentBlock = htmlToDraft(nextProps.richTextContent)
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const keys = []
      contentState.getBlockMap()._list.map(item => {
        keys.push(item[0])
      })
      RichTextTool.currentLength = 0

      //check if not undefined null or false
      if (EditorState.createWithContent(contentState).getCurrentContent()) {
        nextProps.setEditorState(
          draftToHtml(
            convertToRaw(
              EditorState.createWithContent(contentState).getCurrentContent()
            )
          )
        )
      }

      return {
        richTextContent: nextProps.richTextContent,
        editorState: EditorState.createWithContent(contentState),
        fixedEditorState: EditorState.createWithContent(contentState),
        defaultKeys: keys,
      }
    } else if (
      nextProps.multipleLanguageData &&
      nextProps.multipleLanguageData.length &&
      (nextProps.selectedLanguageId != prevState.selectedLanguageId ||
        !isEqual(
          nextProps.multipleLanguageData,
          prevState.multipleLanguageData
        ) ||
        nextProps.outcomeId !== prevState.outcomeId)
    ) {
      let langData = ''
      let contentBlock
      let contentState
      let multiLangKeys = []
      nextProps.multipleLanguageData.map(item => {
        if (
          item.languageId === nextProps.selectedLanguageId &&
          item.outcomeId === nextProps.outcomeId
        ) {
          switch (nextProps.customClassName) {
            case surveyIntroductionEditor:
              item.value.surveyIntroductions.map(item => {
                langData =
                  langData +
                  '<div>' +
                  removeBrs(remove_linebreaks(item.text.replace('\r\n', ' '))) +
                  '</div>'
              })
              break
            case addtionalQuestionEditor:
              langData = transformData(item.value).data
              break
            case surveyOhiEditor:
              langData = transformOHIData(item.value).data
              RichTextTool.currentLength = 0
              break
            default:
              item.value.surveyIntroductions.map(item => {
                langData =
                  langData +
                  '<div>' +
                  removeBrs(remove_linebreaks(item.text.replace('\r\n', ' '))) +
                  '</div>'
              })
              break
          }
          contentBlock = htmlToDraft(langData)
          contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          )
          contentState.getBlockMap()._list.map(item => {
            multiLangKeys.push(item[0])
          })
        }
      })
      if (langData) {
        nextProps.setHasEditorStateChanged(false)
        return {
          multipleLangState: EditorState.createWithContent(contentState),
          selectedLanguageId: nextProps.selectedLanguageId,
          outcomeId: nextProps.outcomeId,
          multipleLanguageData: nextProps.multipleLanguageData,
          multiLangDefaultKeys: multiLangKeys,
        }
      }
      return null
    }
    return null
  }
  static currentLength = 0
  constructor(props) {
    super(props)
    const contentBlock = htmlToDraft(props.richTextContent)
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )
    const keys = []
    contentState.getBlockMap()._list.map(item => {
      keys.push(item[0].replace('\r\n', '&nbsp;'))
    })
    this.state = {
      richTextContent: props.richTextContent,
      open: false,
      findText: '',
      replaceText: '',
      editorState: EditorState.createWithContent(contentState),
      multipleLangState: EditorState.createWithContent(contentState),
      fixedEditorState: EditorState.createWithContent(contentState),
      currentSelectionStateIndex: 0,
      selectionsToReplace: [],
      firstClickDone: false,
      toggleState: false,
      ignoreCase: true,
      selectedLanguageId: -1,
      multipleLanguageData: props.multipleLanguageData,
      defaultKeys: keys,
      multiLangDefaultKeys: [],
      objKeyMap: {},
      popupIsVisible: false,
      isEditable: false,
      currentEditorState: [],
      searchTerm: '',
      showRevertToLastPopUp: false,
      openExport: false,
      languageRadioValue: 'baseLanguage',
      fileFormatRadioValue: 'Excel',
      exportPayload: {},
      updateSurveyConfirmationPopOpen: false,
      openLinkModal: false,
      urlValue: '',
      isLinkClick: false,
    }
  }

  componentDidUpdate() {
    const { editorState, multipleLangState, toggleState } = this.state
    const {
      customClassName,
      numberOfBlocksElement,
      setEditorState,
      introDuctionClassNames,
    } = this.props

    const stateToSend = toggleState
      ? draftToHtml(convertToRaw(multipleLangState.getCurrentContent()))
      : draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setEditorState(stateToSend)

    switch (customClassName) {
      case surveyIntroductionEditor:
        introDuctionClassNames.forEach(item => {
          const elements = document.getElementsByClassName(item)
          this.setHeight(elements)
        })
        break
      case addtionalQuestionEditor:
      case surveyOhiEditor:
        for (var i = 0; i < numberOfBlocksElement; i++) {
          const elements = document.getElementsByClassName(
            `${ohiClassNames}${i}`
          )
          this.setHeight(elements)
        }
        break
      default:
        return
    }
  }

  setHeight = elements => {
    if (elements.length > 1) {
      const heightOfBaseLang = elements[0].offsetHeight
      const heightOfDynamicLang = elements[1].offsetHeight
      if (heightOfBaseLang >= heightOfDynamicLang) {
        elements[1].style.minHeight = `auto`
      } else {
        elements[0].style.minHeight = `auto`
      }
    }
  }

  isSoftNewlineEvent = e => {
    return e.which === 13
  }

  handleReturn = event => {
    const { customClassName } = this.props
    if (
      this.isSoftNewlineEvent(event) &&
      customClassName === 'survey-introduction-editor'
    ) {
      const { editorState, multipleLangState, toggleState } = this.state
      const stateToManipulate = toggleState ? multipleLangState : editorState
      this.onEditorStateChange(RichUtils.insertSoftNewline(stateToManipulate))
    } else {
      event.preventDefault()
    }
    return 'handled'
  }

  checkKeyCodeForDelete = event => {
    return event.keyCode === 8 || event.keyCode === 46
  }

  keyBindingFn = event => {
    const {
      editorState,
      multipleLangState,
      toggleState,
      defaultKeys,
      multiLangDefaultKeys,
      textToTrimFromWindowSelection,
    } = this.state
    if (
      KeyBindingUtil.hasCommandModifier(event) &&
      (event.keyCode === 65 || event.keyCode === 97)
    ) {
      alert('ctrl + A not allowed')
      event.preventDefault()
      return false
    }
    if (!this.checkKeyCodeForDelete(event)) {
      return getDefaultKeyBinding(event)
    }

    const stateToManipulate = toggleState ? multipleLangState : editorState
    const defaultKeyToCheck = toggleState ? multiLangDefaultKeys : defaultKeys
    const selectedKey = stateToManipulate.getSelection()
    const currentOffSet = selectedKey.get('anchorOffset')
    const anchorKey = selectedKey.get('anchorKey')
    const focusKey = selectedKey.get('focusKey')
    const focusOffSet = selectedKey.get('focusOffset')
    let currentText =
      textToTrimFromWindowSelection ||
      stateToManipulate
        .getCurrentContent()
        .get('blockMap')
        .get(anchorKey)
        .get('text')
        .trim()
    let length = currentText.length
    const location = window.location
    if (
      this.checkKeyCodeForDelete(event) &&
      focusKey !== anchorKey &&
      focusOffSet === 0 &&
      currentOffSet === 0
    ) {
      if (
        location.pathname.includes('introduction') ||
        location.pathname.includes('instructions')
      ) {
        alert(
          'When selecting text, you have to be careful when selecting the entire paragraph to delete. Do not triple click to highlight the entire paragraph.\n' +
            '\n' +
            'To delete the entire paragraph either : ' +
            '\n' +
            '1) Drag carefully to select all or 2) Double click to delete certain words'
        )
      }
      if (
        location.pathname.includes('ohi') ||
        location.pathname.includes('add-ons') ||
        location.pathname.includes('additional') ||
        location.pathname.includes('standard') ||
        location.pathname.includes('ohi')
      ) {
        currentText =
          textToTrimFromWindowSelection ||
          stateToManipulate
            .getCurrentContent()
            .get('blockMap')
            .get(anchorKey)
            .get('text')
        length = currentText.length
        const updatedSelection = new SelectionState({
          anchorKey: anchorKey,
          anchorOffset: 0,
          focusKey: anchorKey,
          focusOffset: length,
        })
        const contentState = stateToManipulate.getCurrentContent()
        const replaceText = ''
        const updateContentState = Modifier.replaceText(
          contentState,
          updatedSelection,
          replaceText
        )
        if (toggleState) {
          this.setState({
            multipleLangState: EditorState.push(
              multipleLangState,
              updateContentState
            ),
          })
        } else {
          this.setState({
            editorState: EditorState.push(editorState, updateContentState),
          })
        }
      }
      return false
    }
    if (
      focusKey !== anchorKey &&
      this.checkKeyCodeForDelete(event) &&
      defaultKeyToCheck.indexOf(anchorKey) > -1
    ) {
      alert(
        'When selecting text, you have to be careful when selecting the entire paragraph to delete. Do not triple click to highlight the entire paragraph.\n' +
          '\n' +
          'To delete the entire paragraph either : ' +
          '\n' +
          '1) Drag carefully to select all or 2) Double click to delete certain words'
      )
      event.preventDefault()
      return false
    }
    if (
      this.checkKeyCodeForDelete(event) &&
      currentOffSet === 0 &&
      length < focusOffSet &&
      defaultKeyToCheck.indexOf(anchorKey) > -1
    ) {
      alert(
        'When selecting text, you have to be careful when selecting the entire paragraph to delete. Do not triple click to highlight the entire paragraph.\n' +
          '\n' +
          'To delete the entire paragraph either : ' +
          '\n' +
          '1) Drag carefully to select all or 2) Double click to delete certain words'
      )
      event.preventDefault()
      return false
    }
    if (
      event.keyCode === 8 &&
      anchorKey === focusKey &&
      focusOffSet === 0 &&
      currentOffSet === 0
    ) {
      alert(
        'When selecting text, you have to be careful when selecting the entire paragraph to delete. Do not triple click to highlight the entire paragraph.\n' +
          '\n' +
          'To delete the entire paragraph either : ' +
          '\n' +
          '1) Drag carefully to select all or 2) Double click to delete certain words'
      )
      event.preventDefault()
      return false
    }
    if (
      event.keyCode === 46 &&
      anchorKey === focusKey &&
      currentOffSet === length
    ) {
      alert(
        'When selecting text, you have to be careful when selecting the entire paragraph to delete. Do not triple click to highlight the entire paragraph.\n' +
          '\n' +
          'To delete the entire paragraph either : ' +
          '\n' +
          '1) Drag carefully to select all or 2) Double click to delete certain words'
      )
      event.preventDefault()
      return false
    }
    return getDefaultKeyBinding(event)
  }

  blockStyleFn = contentBlock => {
    const { customClassName, numberOfBlocksElement } = this.props

    const type = contentBlock.getType()
    const key = contentBlock.getKey()
    const { objKeyMap } = this.state
    const matchIndx =
      this.state.multiLangDefaultKeys.indexOf(key) === -1
        ? this.state.defaultKeys.indexOf(key)
        : this.state.multiLangDefaultKeys.indexOf(key)
    if (matchIndx > -1 && this.props.ids) {
      objKeyMap[key] = this.props.ids[matchIndx]
      this.setState({ objKeyMap: objKeyMap })
      if (type === 'unstyled') {
        return `${this.props.ids[matchIndx]} ${IntroductionBorderClass}`
      }
    }

    if (RichTextTool.currentLength === numberOfBlocksElement) {
      RichTextTool.currentLength = 0
    }
    if (
      customClassName === addtionalQuestionEditor &&
      type === 'unordered-list-item'
    ) {
      ++RichTextTool.currentLength
      return `unordered-list-padding-top ${ohiClassNames}${RichTextTool.currentLength} ${BorderClass}`
    }

    const firstNullDiv =
      contentBlock.getType() === 'unstyled' &&
      typeof contentBlock.getDepth() === 'undefined' &&
      contentBlock.getText() === ''
    if (
      (customClassName === surveyOhiEditor ||
        customClassName === addtionalQuestionEditor) &&
      numberOfBlocksElement > 0 &&
      type &&
      !firstNullDiv
    ) {
      ++RichTextTool.currentLength
      return `${ohiClassNames}${RichTextTool.currentLength} ${BorderClass}`
    }
  }

  handleToggle = toggle => {
    const { editorState } = this.state
    const {
      setHasEditorStateChanged,
      setHasUserSelcetdMultiLangOption,
    } = this.props
    setHasEditorStateChanged(false)
    this.setState({ toggleState: !toggle, fixedEditorState: editorState })

    setHasUserSelcetdMultiLangOption(!toggle)
  }

  forceSelection = () => {
    const { editorState } = this.state
    return EditorState.forceSelection(editorState, editorState.getSelection())
  }

  onChangeSearch = (e, searchTerm) => {
    let search = ''
    if (e) {
      search = e.target.value
    }

    if (search === '' && searchTerm === undefined) {
      search = ''
    } else {
      search = search || searchTerm
    }

    this.setState({ searchTerm: search })
    const {
      editorState,
      multipleLangState,
      toggleState,
      ignoreCase,
    } = this.state
    let regex

    if (ignoreCase) {
      regex = new RegExp(search, 'gi')
    } else {
      regex = new RegExp(search, 'g')
    }

    const selectionsToReplace = []
    let contentState = !toggleState
      ? editorState.getCurrentContent()
      : multipleLangState.getCurrentContent()
    const blockMap = contentState.getBlockMap()

    if (search !== '') {
      blockMap.forEach(contentBlock => {
        const type = contentBlock.get('type')
        if (type === 'header-two' || type === 'header-five') {
          return 0
        }
        return findWithRegex(regex, contentBlock, (start, end) => {
          const blockKey = contentBlock.getKey()
          const blockSelection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: start,
            focusOffset: end,
          })
          selectionsToReplace.push(blockSelection)
        })
      })
    }

    if (!toggleState) {
      this.setState({
        findText: search,
        editorState: editorState,
        decorator: generateDecorator(search, ignoreCase),
        selectionsToReplace: selectionsToReplace,
      })
    } else {
      this.setState({
        findText: search,
        multipleLangState: multipleLangState,
        decorator: generateDecorator(search, ignoreCase),
        selectionsToReplace: selectionsToReplace,
      })
    }
  }

  onChangeReplace = e => {
    this.setState({
      replaceText: e.target.value,
    })
  }

  onReplace = type => {
    const {
      editorState,
      multipleLangState,
      findText,
      replaceText,
      ignoreCase,
      toggleState,
      currentSelectionStateIndex,
    } = this.state
    const { setHasEditorStateChanged } = this.props
    const { showPopup } = this.props
    let regex
    if (ignoreCase) {
      regex = new RegExp(findText, 'gi')
    } else {
      regex = new RegExp(findText, 'g')
    }
    if (findText !== '' && findText !== replaceText) {
      if (type !== 'everywhere') {
        const selectionsToReplace = []
        let contentState = !toggleState
          ? editorState.getCurrentContent()
          : multipleLangState.getCurrentContent()
        const blockMap = contentState.getBlockMap()
        if (findText !== '') {
          blockMap.forEach(contentBlock => {
            const type = contentBlock.get('type')
            if (type === 'header-two' || type === 'header-five') {
              return 0
            }
            return findWithRegex(regex, contentBlock, (start, end) => {
              const blockKey = contentBlock.getKey()
              const blockSelection = SelectionState.createEmpty(blockKey).merge(
                {
                  anchorOffset: start,
                  focusOffset: end,
                }
              )
              selectionsToReplace.push(blockSelection)
            })
          })
        }

        let globalSelectionsToReplace = []

        const getSelecteState = defaultContentState => {
          let currentContentState = !toggleState
            ? editorState.getCurrentContent()
            : multipleLangState.getCurrentContent()
          let contentState = defaultContentState
            ? defaultContentState
            : currentContentState
          const blockMap = contentState.getBlockMap()
          if (findText !== '') {
            blockMap.forEach(contentBlock => {
              return findWithRegex(regex, contentBlock, (start, end) => {
                const blockKey = contentBlock.getKey()
                const blockSelection = SelectionState.createEmpty(
                  blockKey
                ).merge({
                  anchorOffset: start,
                  focusOffset: end,
                })
                globalSelectionsToReplace.push(blockSelection)
              })
            })
          }
        }

        getSelecteState()

        if (selectionsToReplace.length > 0) {
          if (type === 'Global') {
            var count = globalSelectionsToReplace.length
            while (globalSelectionsToReplace.length > 0 && count > 0) {
              contentState = Modifier.replaceText(
                contentState,
                globalSelectionsToReplace[
                  globalSelectionsToReplace.length - count
                ],
                replaceText
              )
              --count
              globalSelectionsToReplace = []
              getSelecteState(contentState)
            }
            this.setState({
              editorState: EditorState.createWithContent(contentState),
              findText: '',
              replaceText: '',
              selectionsToReplace: [],
            })
            setHasEditorStateChanged(true)
            showPopup('Text replaced successfully', 'success')
          }

          this.setState({
            currentSelectionStateIndex:
              currentSelectionStateIndex > selectionsToReplace.length - 1
                ? 0
                : currentSelectionStateIndex,
          })
        } else {
          showPopup('No occurrence found', 'error')
        }

        if (this.state.toggleState) {
          this.setState({
            multipleLangState: EditorState.push(
              multipleLangState,
              contentState
            ),
          })
        } else {
          this.setState({
            editorState: EditorState.push(editorState, contentState),
          })
        }
      } else {
        const { toggleState, searchTerm } = this.state
        if (searchTerm) {
          const {
            replaceEverywhere,
            selectedLanguageId,
            outcomeId,
          } = this.props
          replaceEverywhere({
            ignoreCase,
            selectedLanguageId,
            replaceText,
            findText,
            toggleState,
            outcomeId,
          })
        }
      }
    }
  }

  trimContent = (editorState, { SyntheticEventType }) => {
    const { toggleState, isLinkClick } = this.state
    const currentSelectionState = editorState.getSelection()
    const selectedKey = currentSelectionState.getAnchorKey()
    let currentContent = editorState.getCurrentContent()
    const blockText = currentContent
      .getBlockMap()
      .get(selectedKey)
      .getText()
    const trimmedTextEnd = this.state.textToTrimFromWindowSelection

    let newSelection

    switch (SyntheticEventType) {
      case 'paste': {
        if (!isLinkClick) {
          newSelection = new SelectionState({
            anchorKey: selectedKey,
            anchorOffset: currentSelectionState.getAnchorOffset(),
            focusKey: selectedKey,
            focusOffset: currentSelectionState.getAnchorOffset(),
          })
          currentContent = Modifier.insertText(
            currentContent,
            newSelection,
            trimmedTextEnd
          )
        }
        break
      }
      case 'cut': {
        if (!isLinkClick) {
          const trimPositionStart = blockText.indexOf(trimmedTextEnd)
          newSelection = new SelectionState({
            anchorKey: selectedKey,
            anchorOffset: trimPositionStart,
            focusKey: selectedKey,
            focusOffset: trimPositionStart + trimmedTextEnd.length,
          })
          currentContent = Modifier.replaceText(
            currentContent,
            newSelection,
            ''
          )
        }
        break
      }
      default:
        break
    }

    let newEditorState = EditorState.push(editorState, currentContent)
    if (SyntheticEventType === 'paste' && !isLinkClick) {
      const currentSelectionOfEditor = new SelectionState({
        anchorKey: selectedKey,
        anchorOffset:
          currentSelectionState.getAnchorOffset() + trimmedTextEnd.length,
        focusKey: selectedKey,
        focusOffset:
          currentSelectionState.getAnchorOffset() + trimmedTextEnd.length,
      })
      newEditorState = EditorState.forceSelection(
        newEditorState,
        currentSelectionOfEditor
      )
    }
    if (toggleState) {
      this.setState({
        multipleLangState: newEditorState,
      })
    } else {
      this.setState({
        editorState: newEditorState,
      })
    }
  }

  onPasteInBlock = () => {
    const {
      editorState,
      textToTrimFromWindowSelection,
      multipleLangState,
      toggleState,
    } = this.state
    if (textToTrimFromWindowSelection) {
      this.trimContent(toggleState ? multipleLangState : editorState, {
        SyntheticEventType: 'paste',
      })
    }
  }

  onCopyInBlock = () => {
    this.setState({
      textToTrimFromWindowSelection: window
        .getSelection()
        .toString()
        .trim(),
    })
  }

  onCutInBlock = e => {
    RichTextTool.currentLength++
    e.preventDefault()
    const {
      editorState,
      multipleLangState,
      toggleState,
      textToTrimFromWindowSelection,
    } = this.state
    this.setState({
      textToTrimFromWindowSelection: window
        .getSelection()
        .toString()
        .trim(),
    })
    if (textToTrimFromWindowSelection) {
      setTimeout(() => {
        this.trimContent(toggleState ? multipleLangState : editorState, {
          SyntheticEventType: 'cut',
        })
      }, 1)
    }
  }

  cutEventCapture = e => {
    e.preventDefault()
  }

  onTripleClick = e => {
    if (e.detail === 3) {
      this.setState({
        textToTrimFromWindowSelection: window
          .getSelection()
          .toString()
          .trim(),
      })
    }
  }

  onEditorStateChange = editorState => {
    const {
      customClassName,
      setEditorState,
      setHasEditorStateChanged,
    } = this.props
    setHasEditorStateChanged(true)

    // this.setState({
    //   currentEditorState: draftToHtml(
    //     convertToRaw(editorState.getCurrentContent())
    //   ),
    // })
    // setEditorState(this.state.currentEditorState)

    const selectedKey = editorState.getSelection().get('anchorKey')
    const typeOfBlock = editorState
      .getCurrentContent()
      .getBlockMap()
      .get(selectedKey)
      .getType()

    if (
      this.state.objKeyMap[selectedKey] === 'introConfidentiality' ||
      this.state.objKeyMap[selectedKey] === 'introConfidentialityINF' ||
      this.state.objKeyMap[selectedKey] === 'inclusionConfidentiality' ||
      this.state.objKeyMap[selectedKey] ===
        'inclusionInfluencerConfidentiality' ||
      this.state.objKeyMap[selectedKey] === 'CombinedAllConfidentiality' ||
      this.state.objKeyMap[selectedKey] === 'txtIntro'
    ) {
      this.state.isEditable
        ? this.setState({ popupIsVisible: false })
        : this.setState({ popupIsVisible: true })
    }

    if (
      (customClassName === surveyOhiEditor ||
        customClassName === addtionalQuestionEditor) &&
      (typeOfBlock === 'header-two' || typeOfBlock === 'header-five')
    ) {
      return false
    }
    if (this.state.toggleState) {
      this.setState({
        multipleLangState: editorState,
      })
    } else {
      this.setState({
        editorState,
      })
    }
  }

  find = direction => {
    const { currentSelectionStateIndex, selectionsToReplace } = this.state
    this.setState({ firstClickDone: true })
    let currPosition = currentSelectionStateIndex
    if (direction === 'next') {
      if (currentSelectionStateIndex < selectionsToReplace.length) {
        currPosition = currPosition + 1
        this.setState({ currentSelectionStateIndex: currPosition })
      }
    } else {
      if (currentSelectionStateIndex > 0) {
        currPosition = currPosition - 1
        this.setState({ currentSelectionStateIndex: currPosition })
      }
    }
  }

  matchCaseCheckBox = () => {
    const { ignoreCase, searchTerm } = this.state

    this.setState({ ignoreCase: !ignoreCase }, () => {
      this.onChangeSearch(null, searchTerm)
    })
  }

  handleOnClick = event => {
    event.target.className === 'button-round no-button'
      ? this.setState({ popupIsVisible: false })
      : this.setState({ isEditable: true, popupIsVisible: false })
  }

  handleClickOnReview = () => {
    const { fetchSummaryOfEdits, setEditorState } = this.props
    const { toggleState, editorState, multipleLangState } = this.state
    const stateToSend = toggleState
      ? draftToHtml(convertToRaw(multipleLangState.getCurrentContent()))
      : draftToHtml(convertToRaw(editorState.getCurrentContent()))
    setEditorState(stateToSend)
    fetchSummaryOfEdits(false, undefined)
  }

  openUpdateSurveyConfirmationPop = () => {
    this.setState({ updateSurveyConfirmationPopOpen: true })
  }

  revertToLastUpdate = () => {
    this.setState({ showRevertToLastPopUp: true })
  }

  setIsVisible = value => {
    this.setState({ showRevertToLastPopUp: value })
  }

  handleLanguageRadioChange = event => {
    this.setState({ languageRadioValue: event.target.value })
  }

  handleFileFormatRadioChange = event => {
    this.setState({ fileFormatRadioValue: event.target.value })
  }

  onClickImport = e => {
    const { toggleState } = this.state
    const {
      surveyId,
      importFile,
      showPopup,
      match,
      outcomeId,
      selectedLanguageId,
    } = this.props
    if (validateExtension(e.target.files[0].name)) {
      importFile(surveyId, e.target.files[0], match.path, toggleState, {
        outcomeId,
        selectedLanguageId,
      })
    } else {
      showPopup('Invalid File Type', 'error')
    }
    this.upload.value = ''
    e.preventDefault()
  }

  handleSelectChange = value => {
    const { exportPayload } = this.state
    exportPayload.languageKey = value.map(val => val.key)
    this.setState({ exportPayload: exportPayload })
  }

  handleClickOnExport = () => {
    const {
      exportPayload,
      fileFormatRadioValue,
      languageRadioValue,
    } = this.state
    const { exportFile, surveyId } = this.props
    if (languageRadioValue === 'baseLanguage') {
      exportPayload.languageKey = undefined
    }
    exportPayload.fileFormat = fileFormatRadioValue
    exportFile(exportPayload, surveyId)
    this.setState({
      exportPayload: {},
      languageRadioValue: 'baseLanguage',
      fileFormatRadioValue: 'Excel',
      openExport: false,
    })
  }

  promptForLink = () => {
    const { editorState, multipleLangState, toggleState } = this.state
    if (toggleState) {
      const selection = multipleLangState.getSelection()
      if (!selection.isCollapsed()) {
        const contentState = multipleLangState.getCurrentContent()
        const startKey = multipleLangState.getSelection().getStartKey()
        const startOffset = multipleLangState.getSelection().getStartOffset()
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

        let url = ''
        if (linkKey) {
          const linkInstance = contentState.getEntity(linkKey)
          url = linkInstance.getData().url.substring(7)
        }

        this.setState({
          openLinkModal: true,
          urlValue: url,
          isLinkClick: true,
        })
      }
    } else {
      const selection = editorState.getSelection()
      if (!selection.isCollapsed()) {
        const contentState = editorState.getCurrentContent()
        const startKey = editorState.getSelection().getStartKey()
        const startOffset = editorState.getSelection().getStartOffset()
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

        let url = ''
        if (linkKey) {
          const linkInstance = contentState.getEntity(linkKey)
          url = linkInstance.getData().url.substring(7)
        }

        this.setState({
          openLinkModal: true,
          urlValue: url,
          isLinkClick: true,
        })
      }
    }
  }

  confirmLink = () => {
    const { editorState, multipleLangState, toggleState, urlValue } = this.state
    if (toggleState) {
      const contentState = multipleLangState.getCurrentContent()
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: 'mailTo:' + urlValue }
      )
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
      const newEditorState = EditorState.set(multipleLangState, {
        currentContent: contentStateWithEntity,
      })
      this.setState({
        multipleLangState: RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        ),
        openLinkModal: false,
        urlValue: '',
        isLinkClick: false,
      })
    } else {
      const contentState = editorState.getCurrentContent()
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: 'mailTo:' + urlValue }
      )
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      })
      this.setState({
        editorState: RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        ),
        openLinkModal: false,
        urlValue: '',
        isLinkClick: false,
      })
    }
  }

  removeLink = () => {
    const { editorState, multipleLangState, toggleState } = this.state
    if (toggleState) {
      const selection = multipleLangState.getSelection()
      if (!selection.isCollapsed()) {
        this.setState({
          multipleLangState: RichUtils.toggleLink(
            multipleLangState,
            selection,
            null
          ),
        })
      }
    } else {
      const selection = editorState.getSelection()
      if (!selection.isCollapsed()) {
        this.setState({
          editorState: RichUtils.toggleLink(editorState, selection, null),
        })
      }
    }
  }

  onURLChange = e => this.setState({ urlValue: e.target.value })

  render() {
    const {
      open,
      findText,
      replaceText,
      toggleState,
      multipleLanguageData,
      ignoreCase,
      selectionsToReplace,
      currentSelectionStateIndex,
      popupIsVisible,
      openExport,
      languageRadioValue,
      fileFormatRadioValue,
      updateSurveyConfirmationPopOpen,
      openLinkModal,
      urlValue,
      editorState,
      multipleLangState,
    } = this.state
    const {
      customClassName,
      isMultiLanguage,
      availableLangauge,
      surveyId,
      setSelectedLang,
      baseLanguageName,
      fetchIntroDataForLang,
      fetchIntroDataForAdditionalQuestions,
      fetchDataForOHIOutcomesLang,
      outcomeId,
      hasEditorStateChanged,
      confirmationPopupToggle,
      isButtonToBeDisabled,
      baseLanguageId,
      viewSurveyURL,
      importFileFailureData,
      toggleImportFileFailureContainer,
      importFailureContainerIsOpened,
      selectedLanguageId,
      hasUserSelcetdMultiLangOption,
      updateSurvey,
      setHasEditorStateChanged,
      introText,
    } = this.props

    const wrapperClassNameForBaseLang =
      baseLanguageId == 3 || baseLanguageId == 77
        ? 'rightToLeft'
        : 'wrapperClassName'
    const wrapperClassNameForMultiLang =
      this.state.selectedLanguageId == 3 || this.state.selectedLanguageId == 77
        ? 'rightToLeft'
        : 'wrapperClassName'
    const textDirectionForMulti =
      this.state.selectedLanguageId == 3 || this.state.selectedLanguageId == 77
        ? 'RTL'
        : 'LTR'
    const textDirectionForBase =
      baseLanguageId == 3 || baseLanguageId == 11 ? 'RTL' : 'LTR'

    return (
      <div
        className={`rightContainer ${customClassName}`}
        onClick={this.onTripleClick}
        onPaste={this.onPasteInBlock}
        onCut={this.onCutInBlock}
        onCopy={this.onCopyInBlock}
        onCutCapture={this.cutEventCapture}
      >
        {importFailureContainerIsOpened && (
          <ImportFailureContainer
            importFileFailureData={importFileFailureData}
            toggleImportFileFailureContainer={toggleImportFileFailureContainer}
          />
        )}
        {popupIsVisible && (
          <PopupModal>
            <ModalContent handleOnClick={this.handleOnClick} />
          </PopupModal>
        )}
        <PeripheralContainer>
          {/* <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Text size="0.8rem" color="#000000">Text Edit options</Text>
          </Box> */}
          <LinkUnlinkContainer className="link-unlink-container">
            <IconContainer onClick={this.promptForLink}>
              <LinkIcons Icon={LinkIcon} width="1.2vw" height="3vh" />
            </IconContainer>
            <Line />
            <IconContainer onClick={this.removeLink}>
              <LinkIcons Icon={UnlinkIcon} width="1.4vw" height="3.1vh" />
            </IconContainer>
          </LinkUnlinkContainer>
          <Box
            display="flex"
            className="find-replace-container"
            justifyContent="space-between"
            alignItems="center"
            width="12.2vw"
            style={{
              marginTop: '15vh',
              borderBottom: '1px solid #ccc',
              height: '7vh',
            }}
          >
            <FindAndReplace
              className={isButtonToBeDisabled ? 'disabled' : null}
              onClick={() => this.setState({ open: true, isLinkClick: true })}
            >
              <FindIcon Icon={FindI} />
              <Text
                color="#000000"
                size="1.2vh"
                weight="normal"
                className="setMarging"
              >
                Find and Replace
              </Text>
            </FindAndReplace>
            <RevertToLastUpdate
              className={isButtonToBeDisabled ? 'disabled' : null}
            >
              <FindIcon Icon={Revert_to_last_update} />
              <Text
                color="#000000"
                size="1.2vh"
                weight="normal"
                className="setMarging"
                onClick={this.revertToLastUpdate}
              >
                Revert to last update
              </Text>
            </RevertToLastUpdate>
          </Box>
          <ImportExportContainer
            className={isButtonToBeDisabled ? 'disabled' : null}
          >
            <div
              className="import_container"
              onClick={() => {
                this.upload.click()
              }}
            >
              <input
                className="importFileHidden"
                type="file"
                ref={ref => (this.upload = ref)}
                onChange={e => this.onClickImport(e)}
                accept={ALLOWED_FILE_TYPES}
              />
              <UploadIcon Icon={ImportIcon} />
              <Text color="#4b93fd" size="1.7vh" weight="bold">
                IMPORT
              </Text>
            </div>
            <Line />
            <div
              className="import_container"
              onClick={() => this.setState({ openExport: true })}
            >
              <UploadIcon Icon={ExportIcon} />
              <Text color="#4b93fd" size="1.7vh" weight="bold">
                EXPORT
              </Text>
            </div>
          </ImportExportContainer>
          <Box
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            width="12.2vw"
            className={'scrollbar-hidden'}
          >
            <ol className="surveys-steps">
              <li className="surveys-steps-list">
                <Text
                  color="#000000"
                  size="1.8vh"
                  style={{ justifyContent: 'flex-start', fontWeight: 400 }}
                >
                  Edit
                </Text>
                <Text
                  color="#979797"
                  size="1.3vh"
                  style={{
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    fontWeight: 400,
                  }}
                >
                  via Portal or Excel Export/Import
                </Text>
              </li>
              <li className="surveys-steps-list">
                {/* <UpdateSurveyButton
                  className={isButtonToBeDisabled ? 'disabled' : null}
                > */}
                <ViewSurveyHREF
                  className="updMargin"
                  onClick={() => this.handleClickOnReview()}
                >
                  REVIEW
                </ViewSurveyHREF>
                <Text
                  color="#979797"
                  size="1.3vh"
                  style={{ textAlign: 'left', fontWeight: 400 }}
                >
                  the changes before sending to survey
                </Text>
                {/* </UpdateSurveyButton> */}
              </li>
              <li className="surveys-steps-list">
                {/* <UpdateSurveyButton
                  className={isButtonToBeDisabled ? 'disabled' : null}
                > */}
                <MyButton
                  className="updMargin"
                  onClick={() => this.openUpdateSurveyConfirmationPop()}
                >
                  UPDATE
                </MyButton>
                <Text
                  weight="normal"
                  size="1.3vh"
                  style={{ justifyContent: 'flex-start', fontWeight: 400 }}
                  color="#979797"
                >
                  and apply the changes
                </Text>
                {/* </UpdateSurveyButton> */}
              </li>
              <li className="surveys-steps-list">
                {/* <ViewSurveyButton> */}
                <ViewSurveyHREF>
                  <a
                    className="viewSurveyAnchor"
                    href={viewSurveyURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ backgroundColor: 'transparent !important' }}
                  >
                    VIEW
                  </a>
                </ViewSurveyHREF>
                <Text
                  weight="normal"
                  size="1.3vh"
                  style={{ justifyContent: 'flex-start', fontWeight: 400 }}
                  color="#979797"
                >
                  your survey link directly
                </Text>
                {/* </ViewSurveyButton> */}
              </li>
            </ol>
          </Box>
        </PeripheralContainer>
        <LanguageToggleButton
          toggle={toggleState}
          onToggle={this.handleToggle}
          isMultiLanguage={isMultiLanguage}
          baseLanguageName={baseLanguageName}
          hasEditorStateChanged={hasEditorStateChanged}
          confirmationPopupToggle={confirmationPopupToggle}
        />
        {introText && (
          <IntroText
            dangerouslySetInnerHTML={{
              __html: introText,
            }}
          />
        )}
        {toggleState ? (
          <div className="splitContainer-left">
            <LeftSplitBar
              baseLanguageName={baseLanguageName}
              customClassName={customClassName}
            />
            <Editor
              toolbarClassName={
                isMultiLanguage
                  ? 'toolbarClassName'
                  : 'toolbarClassName toolbarClassNameHeightAdjusted'
              }
              wrapperClassName={`${wrapperClassNameForBaseLang} ${customClassName}`}
              editorClassName="editorClassName"
              onEditorStateChange={() => {}}
              ref={elem => {
                this.editor = elem
              }}
              editorState={this.state.fixedEditorState}
              toolbar={toolbar}
              className={`richTextClassName ${wrapperClassNameForBaseLang}`}
              handleReturn={this.handleReturn}
              keyBindingFn={this.keyBindingFn}
              blockStyleFn={this.blockStyleFn}
              textDirectionality={textDirectionForBase}
              stripPastedStyles={true}
            />
          </div>
        ) : null}
        <div
          className={
            toggleState
              ? 'splitContainer-right'
              : `${
                  isMultiLanguage ? 'wrapper' : 'wrapper wrapperHeightAdjusted'
                }`
          }
        >
          {toggleState && (
            <RightSplitBar
              availableLangauge={availableLangauge}
              multipleLanguageData={multipleLanguageData}
              fetchIntroDataForLang={fetchIntroDataForLang}
              fetchIntroDataForAdditionalQuestions={
                fetchIntroDataForAdditionalQuestions
              }
              fetchDataForOHIOutcomesLang={fetchDataForOHIOutcomesLang}
              surveyId={surveyId}
              outcomeId={outcomeId}
              setSelectedLang={setSelectedLang}
              customClassName={customClassName}
              hasEditorStateChanged={hasEditorStateChanged}
              confirmationPopupToggle={confirmationPopupToggle}
            />
          )}
          <Editor
            toolbarClassName={
              isMultiLanguage
                ? 'toolbarClassName'
                : 'toolbarClassName toolbarClassNameHeightAdjusted'
            }
            wrapperClassName={`${
              toggleState
                ? wrapperClassNameForMultiLang
                : wrapperClassNameForBaseLang
            }`}
            editorClassName={`editorClassName  ${customClassName} ${
              toggleState
                ? wrapperClassNameForMultiLang
                : wrapperClassNameForBaseLang
            }`}
            onEditorStateChange={this.onEditorStateChange}
            ref={elem => {
              this.editor = elem
            }}
            editorState={
              toggleState
                ? this.state.multipleLangState
                : this.state.editorState
            }
            toolbar={toolbar}
            className={'richTextClassName'}
            handleReturn={this.handleReturn}
            keyBindingFn={this.keyBindingFn}
            blockStyleFn={this.blockStyleFn}
            textDirectionality={
              toggleState ? textDirectionForMulti : textDirectionForBase
            }
            stripPastedStyles={true}
          />
        </div>
        {open && (
          <div
            aria-labelledby="simple-find-replace-modal"
            aria-describedby="find-replace-in-whole-section"
            open={open}
            className={'findAndReplaceModal'}
            onClose={() => this.setState({ open: false })}
          >
            <div className="find_replace_modalStyle paper">
              <Box
                onClick={() =>
                  this.setState({ open: false, isLinkClick: false })
                }
                className={'closeModal'}
                component="img"
                src={closeModalIcon}
              ></Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                className={'inputSet'}
              >
                <div className="find_replace_items">
                  <FormControl fullWidth style={{ marginBottom: 0 }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      className={'find_container'}
                    >
                      <InputLabel htmlFor="find" className={'inputLabel'}>
                        Find
                      </InputLabel>
                      <Input
                        id="find"
                        value={findText}
                        className="input-Label"
                        style={{ fontWeight: 700 }}
                        onChange={this.onChangeSearch}
                        fullWidth
                      />
                      {selectionsToReplace.length > 0 && (
                        <CurrentReplaceOccurence>
                          <Text size="10px" weight="normal">
                            {selectionsToReplace.length ===
                            currentSelectionStateIndex
                              ? currentSelectionStateIndex
                              : currentSelectionStateIndex + 1}
                          </Text>
                          <Text size="10px" weight="normal">
                            of
                          </Text>
                          <Text size="10px" weight="normal">
                            {selectionsToReplace.length}
                          </Text>
                        </CurrentReplaceOccurence>
                      )}
                    </Box>
                  </FormControl>
                  <FormControl fullWidth style={{ marginBottom: 0 }}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      className={'find_container'}
                    >
                      <InputLabel htmlFor="replace" className={'inputLabel'}>
                        Replace with
                      </InputLabel>
                      <Input
                        id="replace"
                        value={replaceText}
                        className="input-Label"
                        style={{ fontWeight: 700 }}
                        onChange={e => this.onChangeReplace(e)}
                        fullWidth
                      />
                      {findText !== '' && findText === replaceText && (
                        <FormHelperText
                          id="my-helper-text-populationPart"
                          style={{ fontSize: '1.5vh', color: 'red' }}
                        >
                          {'Find and Replace cannot be the same.'}
                        </FormHelperText>
                      )}
                    </Box>
                  </FormControl>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Checkbox
                      disabled={false}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      style={{ fontSize: 20 }}
                      inputProps={{
                        'aria-label': 'Direction',
                      }}
                      onChange={this.matchCaseCheckBox}
                      checked={!ignoreCase}
                    />
                    <Text size="10px" weight="normal">
                      Match Case
                    </Text>
                  </Box>
                  <Button
                    style={{
                      width: '13.5vw',
                      borderRadius: 22.5,
                      height: '5.7vh',
                      fontSize: '1.6vh',
                    }}
                    color="primary"
                    variant="contained"
                    className={'buttonRound'}
                    onClick={() => {
                      this.onReplace('everywhere')
                      this.setState({
                        open: false,
                        findText: '',
                        replaceText: '',
                        selectionsToReplace: [],
                        isLinkClick: false,
                      })
                    }}
                  >
                    Replace All Survey Wide
                  </Button>
                  {/* <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginBottom: 20, marginTop: 24, width: '100%' }}
                >
                  <Button
                    style={{ width: '100%', borderRadius: 22.5 }}
                    color="primary"
                    variant="contained"
                    className={'buttonRound'}
                    onClick={() => this.onReplace('single')}
                  >
                    Replace Once
                  </Button>
                  <Icon
                    icon={UpArrow}
                    height="25px"
                    margin="0 5px"
                    width="25px"
                    onClick={() => this.find('previous')}
                  />
                  <Icon
                    icon={DownArrow}
                    height="25px"
                    margin="0 5px"
                    width="25px"
                    onClick={() => this.find('next')}
                  />
                </Box> */}
                  <Button
                    style={{
                      width: '13.5vw',
                      borderRadius: 22.5,
                      marginBottom: 20,
                      height: '5.7vh',
                      fontSize: '1.6vh',
                    }}
                    color="primary"
                    variant="contained"
                    className={'buttonRound'}
                    onClick={() => this.onReplace('Global')}
                  >
                    Replace All Current Page
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        )}
        <RevertToLastUpdateContainer
          isVisble={this.state.showRevertToLastPopUp}
          surveyId={this.props.surveyId}
          setIsVisible={this.setIsVisible}
        />

        <Modal
          aria-labelledby="export-modal"
          aria-describedby="export-modal"
          open={openExport}
          className={'exportModal'}
          onClose={() => this.setState({ openExport: false })}
        >
          <div className="export_modalStyle modal-paper">
            <Box
              onClick={() => this.setState({ openExport: false })}
              className={'closeModal'}
              component="img"
              src={closeModalIcon}
            ></Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              className={'inputSet'}
            >
              <div className="export-modal-container">
                <Box
                  className="subtitleTextContainer"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <div className="subtitle-text format">FORMAT</div>
                  <div className="subtitle-text tooltip-excel">
                    <ToolTipIcon Icon={ToolTip} />
                    Only Excel file can be re-uploaded
                  </div>
                </Box>

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControl>
                    <RadioGroup
                      aria-label="file-format"
                      name="file-format"
                      value={fileFormatRadioValue}
                      row={true}
                      onChange={e => this.handleFileFormatRadioChange(e)}
                    >
                      <FormControlLabel
                        value="Excel"
                        style={{ fontSize: '1vh' }}
                        control={<Radio color="default" />}
                        label="Excel"
                      />
                      {/* <FormControlLabel
                        value="Word"
                        disabled
                        control={<Radio color="default" />}
                        label="Word"
                      />
                      <FormControlLabel
                        value="PDF"
                        disabled
                        control={<Radio color="default" />}
                        label="PDF"
                      /> */}
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  className="language-radioButton-container"
                >
                  <div className="subtitle-text language">Language</div>
                  <FormControl>
                    <RadioGroup
                      aria-label="language"
                      name="language"
                      value={languageRadioValue}
                      onChange={e => this.handleLanguageRadioChange(e)}
                    >
                      <FormControlLabel
                        value="baseLanguage"
                        control={<Radio color="default" />}
                        label="Base Language Only"
                      />
                      {isMultiLanguage && (
                        <FormControlLabel
                          value="multiLanguage"
                          control={<Radio color="default" />}
                          label="Multiple Languages"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                  {languageRadioValue === 'multiLanguage' && (
                    <Box className="multiple-language-dropdown" display="flex">
                      <FormControl fullWidth>
                        <ModifiedSelect
                          options={availableLangauge}
                          label="Select Language"
                          inputId="languageSelect"
                          placeholder="Select Languages"
                          name="Language select"
                          optionValue="key"
                          optionLabel="value"
                          selectevalue={''}
                          handleChangefn={this.handleSelectChange}
                          isMulti
                        />
                      </FormControl>
                    </Box>
                  )}
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  className={'exportButton'}
                  onClick={this.handleClickOnExport}
                >
                  EXPORT
                </Button>
                <Box className="subtitleTextContainer">
                  <div className="subtitle-text export-instructions">
                    Export includes the saved changes of the OHI Portal that
                    might not be updated in the survey.
                  </div>
                </Box>
              </div>
            </Box>
          </div>
        </Modal>
        <Modal
          aria-labelledby="link-modal"
          aria-describedby="link-modal"
          open={openLinkModal}
          className={'linkModal'}
          onClose={() => this.setState({ openLinkModal: false })}
        >
          <div className="export_modalStyle modal-paper">
            <Box
              onClick={() => this.setState({ openLinkModal: false })}
              className={'closeModal'}
              component="img"
              src={closeModalIcon}
            />

            <div className="link-modal-content">
              <FormControl fullWidth style={{ marginBottom: 0 }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  className={'find_container'}
                >
                  <InputLabel htmlFor="email" className={'inputLabel'}>
                    Enter Email
                  </InputLabel>
                  <Input
                    id="email"
                    value={urlValue}
                    className="input-Label"
                    // style={{fontSize: '2vh'}}
                    onChange={e => this.onURLChange(e)}
                    fullWidth
                  />
                </Box>
              </FormControl>
              <Button
                color="primary"
                variant="contained"
                className={'confirmButton'}
                onClick={this.confirmLink}
              >
                CONFIRM
              </Button>
            </div>
          </div>
        </Modal>
        {updateSurveyConfirmationPopOpen && (
          <PopupModal>
            <Box>
              <h4
                className="modal-title"
                style={{
                  marginBottom: '4.2vh',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Your survey will now be updated, please know that all languages
                will be updated. Are you sure you want to continue?
              </h4>
              <Box display="flex" flex="1" justifyContent="center">
                <button
                  className="button-round no-button"
                  onClick={() =>
                    this.setState({ updateSurveyConfirmationPopOpen: false })
                  }
                >
                  No, go back
                </button>
                <button
                  className="button-round yes-button"
                  style={{ width: '10.5vw' }}
                  onClick={() => {
                    const stateToSend = toggleState
                      ? draftToHtml(
                          convertToRaw(multipleLangState.getCurrentContent())
                        )
                      : draftToHtml(
                          convertToRaw(editorState.getCurrentContent())
                        )
                    window.popupMsg = UPDATE_SURVEY_TEXT
                    updateSurvey(
                      stateToSend,
                      surveyId,
                      selectedLanguageId,
                      true,
                      hasUserSelcetdMultiLangOption,
                      outcomeId
                    )
                    setHasEditorStateChanged(false)
                    this.setState({ updateSurveyConfirmationPopOpen: false })
                  }}
                >
                  Yes, Save and Update
                </button>
              </Box>
            </Box>
          </PopupModal>
        )}
      </div>
    )
  }
}
