import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Input,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import tabstyles from '../Optionalquestion/styles'
import { ExpandMore } from '@material-ui/icons'
import { cloneDeep } from 'lodash'

export default function StandardDemoquestions({ data, dataHandler, useForm }) {
  const classes = tabstyles()
  const [options, setOptions] = React.useState({
    standardQuestions: [],
  })

  React.useEffect(() => {
    if (data?.length) {
      setOptions({ standardQuestions: data })
    }
  }, [data])

  const changeOptionalquestionData = (event, itemIndex) => {
    event.stopPropagation()
    const clone = cloneDeep(options)
    const useFormclone = cloneDeep(useForm)
    clone.standardQuestions[itemIndex].isSelected = event.target.checked
    useFormclone.standardDemographics = clone.standardQuestions
    dataHandler(useFormclone)
    setOptions(clone)
  }

  return (
    <Box
      sx={{ width: '100%' }}
      classes={{
        root: classes.root,
      }}
    >
      {options?.standardQuestions?.map((chkitem, itemIndex) => {
        return chkitem.questionText[0] ===
          'When did you start working at the organization? (Numeric field)' ? (
          <Grid
            classes={{ root: classes.accordionSummaryRoot }}
            key={itemIndex}
          >
            <FormControlLabel
              classes={{
                label: classes.label,
              }}
              control={
                <Checkbox
                  disabled={false}
                  classes={{
                    checked: classes.checked,
                  }}
                  checked={chkitem.isSelected}
                  icon={
                    <CircleUnchecked
                      className={classes.checkboxIconUnchecked}
                    />
                  }
                  checkedIcon={
                    <CircleCheckedFilled className={classes.checkboxIcon} />
                  }
                  onClick={e => {
                    changeOptionalquestionData(e, itemIndex)
                  }}
                  style={{ fontSize: 20 }}
                  inputProps={{
                    'aria-label': chkitem.name,
                  }}
                />
              }
              label={<Box>{chkitem.name}</Box>}
            />
          </Grid>
        ) : (
          <Accordion
            classes={{ root: classes.accordionRoot }}
            elevation={0}
            key={`${itemIndex + 1}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{
                root: classes.accordionSummaryRoot,
              }}
            >
              <FormControlLabel
                classes={{
                  label: classes.label,
                }}
                control={
                  <Checkbox
                    disabled={false}
                    classes={{
                      checked: classes.checked,
                    }}
                    checked={chkitem.isSelected}
                    disableRipple
                    icon={
                      <CircleUnchecked
                        className={classes.checkboxIconUnchecked}
                      />
                    }
                    checkedIcon={
                      <CircleCheckedFilled className={classes.checkboxIcon} />
                    }
                    onClick={e => {
                      changeOptionalquestionData(e, itemIndex)
                    }}
                    style={{ fontSize: 20, background: 'none' }}
                    inputProps={{
                      'aria-label': chkitem.name,
                    }}
                  />
                }
                label={
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography>{chkitem.questionText[0]}</Typography>
                    <Typography
                      style={{ fontSize: '11px', fontStyle: 'italic' }}
                    >
                      {chkitem.qid === 'Tenure'
                        ? 'This is an open text question'
                        : ''}
                    </Typography>
                  </Box>
                }
              />
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetail }}>
              <Grid container spacing={0} className={classes.checkboxContainer}>
                {chkitem?.AnswerText?.length
                  ? chkitem?.AnswerText?.map((option, optionIndex) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          align="left"
                          className={classes.checkboxOptionName}
                          key={optionIndex}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'start',
                            }}
                          >
                            <span className="mr-2">
                              <FiberManualRecordRoundedIcon
                                className={classes.bulletIcon}
                              />
                            </span>
                            <span style={{ fontSize: 15 }}>{option}</span>
                          </div>
                        </Grid>
                      )
                    })
                  : null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}
