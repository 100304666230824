import { SUMMARYINFO_FETCH } from './constants'

export const summaryFetch = payload => ({
  type: `${SUMMARYINFO_FETCH}_PENDING`,
  payload,
})

export const summaryFetchSuccess = payload => ({
  type: `${SUMMARYINFO_FETCH}_SUCCESS`,
  payload,
})

export const summaryFetchFailed = payload => ({
  type: `${SUMMARYINFO_FETCH}_FAILED`,
  payload,
})
