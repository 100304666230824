import { fromJS } from 'immutable'
//import { useAuth0 } from '../react-auth0-wrapper'

const ignoreActions = [
  'DEMO_ACTION_IGNORED',
  'DEMO_ACTION_IGNORED_1',
  'SURVEYS/FETCH_PENDING',
  'SURVEYS/FETCH_SUCCESS',
]

const actionIgnored = actionType => {
  let isIgnored = false
  ignoreActions.forEach(ignoreAction => {
    if (~actionType.indexOf(ignoreAction)) {
      isIgnored = true
    }
  })
  return isIgnored
}

const INITIAL_STATE = fromJS({
  count: 0,
})
const loader = (state = INITIAL_STATE, { type }) => {
  //console.log(type)
  //const {logout } = useAuth0()
  if (actionIgnored(type)) return state

  const requestStatus = type.split('_').reverse()[0]
  switch (requestStatus) {
    case 'PENDING':
      return state.update('count', count => count + 1)
    case 'SUCCESS':
    case 'FAILED':
      return state.update('count', count => count - 1)
    default:
      return state
  }
}

export default loader
