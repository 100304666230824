import { makeStyles } from '@material-ui/core/styles'
import { relative } from 'path'

const styles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(6),
      fontSize: 12,
      color: '#333333',
      paddingBottom: '0px',
    },
    sectionHeading: {
      fontSize: 20,
      fontWeight: 900,
      marginBottom: theme.spacing(3),
    },
    languageForm: {
      '& .baselg:before': {
        content: '""',
        width: '10px',
        height: '10px',
        borderRadius: '100%',
        backgroundColor: '#f28165',
        marginLeft: '8px',
        marginRight: '-5px',
      },
      '& .css-tlfecz-indicatorContainer': {
        display: 'none !important',
      },
      '& .css-1okebmr-indicatorSeparator + .css-tlfecz-indicatorContainer': {
        display: 'flex !important',
      },
      '& .css-1gtu0rj-indicatorContainer': {
        display: 'none !important',
      },
      '& .css-1okebmr-indicatorSeparator + .css-1gtu0rj-indicatorContainer': {
        display: 'flex !important',
      },
      '& .languageChip:first-child': {
        '&:before': {
          content: '""',
          width: '10px',
          height: '10px',
          borderRadius: '100%',
          backgroundColor: '#f28165',
          marginLeft: '8px',
          marginRight: '-5px',
        },
      },
    },

    myhelptext: {
      color: '#ff0000',
    },
    myhelptextLanguage: {
      color: '#999',
      position: 'absolute',
      right: 0,
      top: 0,
      marginTop: 0,
    },
    leftSection: {
      marginRight: theme.spacing(10),
    },
    inputSet: {
      marginBottom: theme.spacing(5),
    },
    inputSet2: {
      marginBottom: 30,
    },
    tooltipicongray: {
      marginRight: '10px',
      verticalAlign: 'bottom',
      width: '11px',
    },
    inputLabel: {
      color: '#999999',
      marginBottom: theme.spacing(1),
    },
    pd30: {
      paddingLeft: '30px',
    },
    langualeLabel: {
      whiteSpace: 'nowrap',
      '& div': {
        display: 'inline-block',
        marginLeft: '20vw',
      },
    },
    formBottom: {
      borderTop: '1px solid #dddddd',
      padding: theme.spacing(4, 5),
    },
    buttonRound: {
      borderRadius: '25px',
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: 14,
    },
    buttonRoundDisabled: {
      borderRadius: '25px',
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: 14,
      pointerEvents: 'none',
      opacity: 0.4,
    },
    fullWidth: {
      width: '100%',
    },
    tooltipicon: {
      width: '11px',
      marginLeft: '10px',
    },
    tooltipiconSl: {
      width: '15px',
      marginLeft: '10px',
    },
    input: {
      //   border: '2px solid red',
    },
    chip: {
      marginRight: theme.spacing(1),
      color: '#333',
      border: '1px solid #333',
    },
    activeChip: {
      backgroundColor: '#f28165',
      color: '#fff',
      border: '1px solid #f28165',
    },
    modaltitle: {
      fontSize: '16px',
      color: '#333333',
    },
    linkmodal: {
      '& .buttonRound': {
        marginLeft: '0 !important',
        marginTop: '30 !important',
      },
    },
    surveyLink: {
      width: '100%',
      padding: '0 20px',
      borderRadius: '22.5px',
      height: '40px',
      lineHeight: '40px',
      fontSize: '16px',
      color: '#333333',
      border: 'solid 1px #4b93fd',
      position: 'relative',
      overflow: 'hidden',
      '& input': {
        width: '100%',
        height: 'calc(100% - 2px)',
        border: 'none',
        '&:focus': {
          outline: 'none',
        },
      },
      '& span': {
        display: 'block',
        right: '0',
        backgroundColor: '#b4b4b4',
        paddingRight: 10,
        top: 0,
        bottom: 0,
        marginTop: 'auto',
        paddingLeft: '10px',
        marginBottom: 'auto',
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'uppercase',
        color: '#fff',
        position: 'absolute',
      },
    },
    buttonProgress: {
      color: '#4b93fd',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    dateTooltip: {
      position: 'absolute',
      top: -28,
      right: 0,
    },
    surveyExpertName: {
      backgroundColor: '#e7e7e7',
      padding: '0 15px',
      marginLeft: 10,
      borderRadius: 15,
    },
    expertCtrl: {
      display: 'inline-flex',
      position: 'relative',
      marginTop: 20,
      fontSize: 16,
      height: 35,
      lineHeight: '30px',
      paddingBottom: 5,
    },
    tooltilink: {
      color: '#4b93fd !important',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    noteLabel: {
      color: '#999999',
      textAlign: 'left',
      marginTop: '2px',
      fontStyle: 'italic',
    },
  }
})

export default styles
