import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import {
  fetchDesignSurvey,
  updateDesignSurvey,
  saveQuestions,
  getOutcomeFetch,
  getPracticeFetch,
  getQuestionListFetch,
  updateQuestionListFetch,
  updateQuestionFetch,
  influencerFetch,
  updateInfluencer,
  valuesFetch,
  updateValue,
  inclusionFetch,
  updateInclusion,
  resetQuestionList,
  updateWorkEx,
  workexFetch,
  ohiTestModuleFetch,
  updateTestModule,
  TextUpdateAllowed,
  ohi4ModuleFetch,
  updateohi4Module,
} from './actions'
import { surveysStatusFetch } from '../ManageSurveys/actions'
import { surveynameFetch, surveynameUpdate } from '../ClientInfo/actions'
import { SurveyHeader, SurveyProgress, SurveySidebar } from '../../components'
import useStyles from './styles'
import { Checkboxs } from './Components'
import LoaderModal from './Components/Modal'
import { survey_Status_For_Disabling_Post_Req } from '../../utils/constants'
import { isTextUpdateEnabled } from '../../common/arrayFunctions'
import { getSurveyinfoFetch } from '../SurveyInfo/actions'
// import SurveyForm from './components/SurveyForm'

function DesignSurvey({
  match,
  history,
  fetchDesignSurvey,
  designsurveyget,
  surveyname,
  surveynameFetch,
  surveynameUpdate,
  surveynameupdateStatus,
  updateDesignSurvey,
  designupdateStatus,
  questionpoststatus,
  saveQuestions,
  outcomescales,
  getOutcomeFetch,
  practicescales,
  getPracticeFetch,
  questionlist,
  getQuestionListFetch,
  updateQuestionListFetch,
  updatequestionlistStatus,
  updateQuestionFetch,
  updatequestionStatus,
  loader,
  influencer,
  influencerFetch,
  influencerStatus,
  updateInfluencer,
  valuesdata,
  valuesFetch,
  valuesdataStatus,
  updateValue,
  inclusiondata,
  inclusionFetch,
  inlusiondataStaus,
  updateInclusion,
  surveysStatusFetch,
  surveyStatus,
  statuscode,
  resetQuestionList,
  updateWorkEx,
  workexFetch,
  ohiTestModuleFetch,
  ohiTestModuleData,
  updateTestModule,
  ohiUpdateDataStatus,
  TextUpdateAllowed,
  ohi4ModuleFetch,
  ohi4ModuleData,
  updateohi4Module,
  updateohi4ModuleStatus,
  surveyinfo,
  getSurveyinfoFetch,
}) {
  document.title = 'OHI Portal - Design Survey'
  const classes = useStyles()
  const surveyStatusReturned = surveyname.get('items').get('status')
  const isSurveyUserLocked = surveyname.get('items').get('isUserLock')
  const isSurveyAdminLocked = surveyname.get('items').get('isAdminLock')
  const sendHelpDeskEmailInitial = surveyname
    .get('items')
    .get('sendHelpDeskEmailInitial')
  const surveyType = surveyname.get('items').get('surveyType')
  const ohiVersionId = surveyname.get('items').get('ohiVersionId')
  const isButtonToBeDisabled =
    survey_Status_For_Disabling_Post_Req.indexOf(surveyStatusReturned) !== -1 ||
    isSurveyAdminLocked ||
    isSurveyUserLocked

  React.useEffect(() => {
    const params = match.params.id
    getSurveyinfoFetch(params)
  }, [])

  function switchComponent(name) {
    //console.log(name)
  }
  const [isDisabled, setIsdisable] = useState()
  function getpidfn(pid) {
    //console.log(pid)
    if (pid === '') {
      setIsdisable(true)
    } else {
      setIsdisable(false)
    }
  }

  function textEnabled() {
    const flag = isTextUpdateEnabled(
      sendHelpDeskEmailInitial,
      designupdateStatus,
      surveyType,
      designsurveyget,
      ohiTestModuleData
    )
    return flag
  }

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      {designupdateStatus &&
      designupdateStatus.get('isLoading') &&
      designupdateStatus.get('isLaunchSet') ? (
        <LoaderModal />
      ) : null}
      <SurveyHeader
        history={history}
        statuscode={statuscode}
        surveyId={match.params.id}
      />
      <SurveyProgress
        onSurveynameFetch={surveynameFetch}
        onSurveynameUpdate={surveynameUpdate}
        surveyname={surveyname}
        params={match.params.id}
        surveynameupdateStatus={surveynameupdateStatus}
        statusList={surveyStatus}
        surveysStatusFetch={surveysStatusFetch}
        ongetpidfn={getpidfn}
        isButtonToBeDisabled={isButtonToBeDisabled}
        textUpdate={textEnabled}
      />
      <Box display="flex" flex={1} className={classes.main}>
        <Box display="flex" flex={1} className={classes.sidebar}>
          <SurveySidebar
            params={match.params}
            surveyname={surveyname}
            isDisabled={isDisabled}
          />
        </Box>
        <Box
          display="flex"
          flex={5}
          className={`${classes.formWrapper} scrollbar-class`}
        >
          <Box style={{ width: '100%' }}>
            <Checkboxs
              switchComponent={switchComponent}
              onFetchDesignSurvey={fetchDesignSurvey}
              designsurveyget={designsurveyget}
              params={match.params.id}
              ohiVersionId={ohiVersionId}
              onUpdateDesignSurvey={updateDesignSurvey}
              designupdateStatus={designupdateStatus}
              history={history}
              onSaveQuestion={saveQuestions}
              questionpoststatus={questionpoststatus}
              outcomescales={outcomescales}
              onFetchOutcomeScale={getOutcomeFetch}
              practicescales={practicescales}
              onFetchPracticeScale={getPracticeFetch}
              questionlist={questionlist}
              onGetquestionlist={getQuestionListFetch}
              updatequestionlistStatus={updatequestionlistStatus}
              onUpdateQuestionListFetch={updateQuestionListFetch}
              updatequestionStatus={updatequestionStatus}
              updateQuestionFetch={updateQuestionFetch}
              loader={loader}
              influencer={influencer}
              influencerStatus={influencerStatus}
              influencerFetch={influencerFetch}
              updateInfluencer={updateInfluencer}
              valuesdata={valuesdata}
              valuesFetch={valuesFetch}
              valuesdataStatus={valuesdataStatus}
              updateValue={updateValue}
              inclusiondata={inclusiondata}
              inclusionFetch={inclusionFetch}
              workexFetch={workexFetch}
              inlusiondataStaus={inlusiondataStaus}
              updateInclusion={updateInclusion}
              updateWorkEx={updateWorkEx}
              statuscode={statuscode}
              isButtonToBeDisabled={isButtonToBeDisabled}
              resetQuestionList={resetQuestionList}
              ohiTestModuleFetch={ohiTestModuleFetch}
              ohiTestModuleData={ohiTestModuleData}
              updateTestModule={updateTestModule}
              ohiUpdateDataStatus={ohiUpdateDataStatus}
              TextUpdateAllowed={TextUpdateAllowed}
              ohi4ModuleFetch={ohi4ModuleFetch}
              ohi4ModuleData={ohi4ModuleData}
              updateohi4Module={updateohi4Module}
              updateohi4ModuleStatus={updateohi4ModuleStatus}
              surveyinfo={surveyinfo}
            ></Checkboxs>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  designsurveyget: state.get('designsurveyget'),
  surveyname: state.get('surveyname'),
  surveynameupdateStatus: state.get('surveynameupdateStatus'),
  designupdateStatus: state.get('designupdateStatus'),
  questionpoststatus: state.get('questionpoststatus'),
  outcomescales: state.get('outcomescales'),
  practicescales: state.get('practicescales'),
  questionlist: state.get('questionlist'),
  updatequestionStatus: state.get('updatequestionStatus'),
  updatequestionlistStatus: state.get('updatequestionlistStatus'),
  loader: state.get('loader'),
  influencer: state.get('influencer'),
  influencerStatus: state.get('influencerStatus'),
  valuesdata: state.get('valuesdata'),
  valuesdataStatus: state.get('valuesdataStatus'),
  inclusiondata: state.get('inclusiondata'),
  inlusiondataStaus: state.get('inlusiondataStaus'),
  surveyStatus: state.get('surveyStatus'),
  statuscode: state.get('statuscode'),
  ohiTestModuleData: state.get('ohiTestModuleReducer'),
  ohiUpdateDataStatus: state.get('ohiUpdateStatus'),
  ohi4ModuleData: state.get('ohi4ModuleData'),
  updateohi4ModuleStatus: state.get('updateohi4ModuleStatus'),
  surveyinfo: state.get('surveyinfo'),
})

const mapDispatchToProps = {
  fetchDesignSurvey,
  surveynameFetch,
  surveynameUpdate,
  updateDesignSurvey,
  saveQuestions,
  getOutcomeFetch,
  getPracticeFetch,
  getQuestionListFetch,
  updateQuestionListFetch,
  updateQuestionFetch,
  influencerFetch,
  updateInfluencer,
  valuesFetch,
  updateValue,
  inclusionFetch,
  updateInclusion,
  surveysStatusFetch,
  resetQuestionList,
  updateWorkEx,
  workexFetch,
  ohiTestModuleFetch,
  updateTestModule,
  TextUpdateAllowed,
  ohi4ModuleFetch,
  updateohi4Module,
  getSurveyinfoFetch,
}
export default connect(mapStateToProps, mapDispatchToProps)(DesignSurvey)
