import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }
  // return {
  //   switchBase: {
  //     color: '#fff',
  //     '&$checked': {
  //       color: '#fff',
  //     },
  //     '&$checked + $track': {
  //       backgroundColor: '#4b93fd',
  //     },
  //   },
  //   checked: {},
  //   track: {},
  // }
})

export default styles
