import { Box, makeStyles, Modal } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import React, { useEffect, useState } from 'react'
import useStyles from './styles'

function LoaderModal() {
  const progress = React.useRef(() => {})
  const [completed, setCompleted] = React.useState(0)
  const [buffer, setBuffer] = React.useState(10)

  useEffect(() => {
    progress.current = () => {
      if (completed > 100) {
        setCompleted(0)
        setBuffer(10)
      } else {
        const diff = Math.random() * 10
        const diff2 = Math.random() * 10
        setCompleted(completed + diff)
        setBuffer(completed + diff + diff2)
      }
    }
  })

  useEffect(() => {
    function tick() {
      progress.current()
    }
    const timer = setInterval(tick, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  const classes = useStyles()

  function getModalStyle() {
    const top = 50

    return {
      top: `${top}%`,
      left: `${top}%`,
      transform: `translate(-${top}%, -${top}%)`,
    }
  }

  const useModalStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(6),
      outline: 'none',
    },
  }))

  const [modalStyle] = useState(getModalStyle)
  const modalClasses = useModalStyles()
  const [open, setOpen] = useState(true)

  return (
    <Box>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {}}
      >
        <div style={modalStyle} className={modalClasses.paper}>
          <Box>
            <h4 className={classes.modaltitle} style={{ marginBottom: 35 }}>
              Please wait while we generate your link - this action is being
              performed behind the scenes and could take up to 3 minutes. Thank
              you!
            </h4>
            <LinearProgress style={{ height: '7px', borderRadius: '10px' }} />
          </Box>
        </div>
      </Modal>
    </Box>
  )
}

export default LoaderModal
