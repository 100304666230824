import {
  DOWNLOAD_MAPPING,
  GENERATE_LOGS,
  TASK_LOGS,
  RESET_TASK_DATA,
  RESET_TASK_ID,
} from '../common/constants'
const inital_state = {
  isDownloaded: false,
  error: false,
  data: {},
  loading: false,
}

export const DownloadMappingFileReducer = (state = inital_state, action) => {
  switch (action.type) {
    case `${DOWNLOAD_MAPPING}_PENDING`:
      return { ...state, loading: true }
    case `${DOWNLOAD_MAPPING}_SUCCESS`:
      return { ...state, loading: false, data: action.data }
    case `${DOWNLOAD_MAPPING}_FAILED`:
      return { ...state, loading: false, error: true, data: action.error }
    default:
      return state
  }
}

const INITIAL_STATE = {
  isDataFetched: false,
  loading: true,
  items: '',
  error: false,
}

export const GenerateLogsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GENERATE_LOGS}_PENDING`:
      return { ...state, loading: true }
    case `${GENERATE_LOGS}_SUCCESS`:
      return { ...state, isDataFetched: true, items: action.data }
    case `${GENERATE_LOGS}_FAILED`:
      return { ...state, isDataFetched: false, items: '', error: true }
    case `${RESET_TASK_ID}`:
      return {
        ...state,
        items: '',
        error: false,
        loading: false,
        isDataFetched: false,
      }
    default:
      return state
  }
}

const TASK_STATE = {
  items: [],
  isDataFetched: false,
  loading: false,
}

export const TaskLogsReducer = (state = TASK_STATE, action) => {
  switch (action.type) {
    case `${TASK_LOGS}_PENDING`:
      return { ...state, loading: true }
    case `${TASK_LOGS}_SUCCESS`:
      return { ...state, isDataFetched: true, items: action.data }
    case `${TASK_LOGS}_FAILED`:
      return {
        ...state,
        isDataFetched: false,
        items: [],
        error: true,
        loading: false,
      }
    case `${RESET_TASK_DATA}`:
      return {
        ...state,
        isDataFetched: false,
        items: [],
        error: false,
        loading: false,
      }
    default:
      return state
  }
}
