export default function validateclient(values, allclients) {
  let errors = {}
  //console.log(allclients)
  let matchname = true
  if (values.clientName !== '' && allclients !== undefined) {
    if (allclients.some(client => client.value === values.clientName)) {
      matchname = true
    } else {
      matchname = false
    }
  }
  if (!values.clientName) {
    errors.clientName = 'This field is required'
  } else if (!matchname) {
    errors.clientName =
      "This client doesn't exist in database, please click on (ADD NEW) button to add new client"
  }
  if (!values.RegionName && values.opportunityName) {
    errors.opportunityName =
      'Please click on the search button to validate the opportunity name.'
  }
  if (!values.opportunityName && !values.isTestSurvey) {
    errors.opportunityName = 'This field is required'
  }
  return errors
}
