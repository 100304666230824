import axios from 'axios'
import store from '../store'
import { progressBarLoaderToggle } from '../common/actions'
import { DEFAULT_LOADER_TEXT } from './constants'
var count = 0
const request = opt => {
  const config = {
    method: opt.method,
    timeout: '300000',
    url: opt.url,
    data: opt.payload,
    responseType: opt.responseType,
    headers: {
      Accept: opt.Accept || 'application/json;charset=UTF-8',
    },
  }
  return new Promise((resolve, reject) => {
    ++count
    store.dispatch(progressBarLoaderToggle(true, window.popupMsg))
    axios(config)
      .then(response => {
        window.popupMsg = ''
        --count
        if (count <= 0) {
          store.dispatch(progressBarLoaderToggle(false, DEFAULT_LOADER_TEXT))
        }
        if (response.data) {
          let parsedData = response.data
          return resolve({
            body: parsedData,
            response,
          })
        }
        return reject({
          body: response.error,
          statusCode: response.status,
        })
      })
      .catch(error => {
        window.popupMsg = ''
        --count
        if (count <= 0) {
          store.dispatch(progressBarLoaderToggle(false, DEFAULT_LOADER_TEXT))
        }
        reject(error)
      })
  })
}

export default request
