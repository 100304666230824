export const ADDITIONAL_QUESTIONS_FETCH = 'ADDITIONAL_QUESTIONS/FETCH'
export const FETCH_ADDITONAL_QUESTIONS_DATA_LANG_SUCCESS =
  'FETCH_ADDITONAL_QUESTIONS_DATA_LANG_SUCCESS'
export const FETCH_ADDITONAL_QUESTIONS_DATA_LANG_ERROR =
  'FETCH_ADDITONAL_QUESTIONS_DATA_LANG_ERROR'
export const FETCH_ADDITONAL_QUESTIONS_DATA_LANG =
  'FETCH_ADDITONAL_QUESTIONS_DATA_LANG'
export const SET_SELECTED_LANG = 'SET_SELECTED_LANG'
export const UPDATE_SURVEY_ADDITIONAL_QUESTIONS =
  'UPDATE_SURVEY_ADDITIONAL_QUESTIONS'

export const REPLACE_EVERYWHERE = 'REPLACE_EVERYWHERE_ADDITIONAL_QUESTIONS'

export const QUESTION_TYPE = {
  WITH_SCALE: 3,
  OPEN_ENDED: 1,
  NORMAL: 2,
}
