import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  createMuiTheme,
  CircularProgress,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import searchIcon from '../../assets/seearch.png'
import useStyles from './styles'
import backIcon from '../../assets/Back_Design_Survey.svg'
import AddAdditionalQuestion from '../Addadditionalquestion/AddAdditionalQuestion'
import QuestionOrdering from '../Questionordering'
import EditInfluencer from '../EditInfluencer'
import EditInclusion from '../EditInclusion'
import EditWorkEx from '../EditWorkEx'
import ValueModal from '../ValueModal'
import designSurvey from '../../../../validations/designSurvey'
import editIcon from '../../assets/Edit_Design_Survey.svg'
import editIconNoShadow from '../../assets/Edit-Icon.svg'
import tooltipicongray from '../../assets/tooltip-icon.svg'
import { useToasts } from 'react-toast-notifications'
import AddIcon from '../../assets/Add_Design_Survey.svg'
import clsx from 'clsx'
import {
  ADDITIONAL_QUESTION_SUBTITLE_ANCHOR,
  Emp_Exp_Mandate,
  Emp_exp_mandate_text,
  Title_exp,
} from '../../../../utils/constants'
import ProgressBarLoader from '../../../../components/ProgressBarLoader'
import PopupModal from '../../../../components/PopupModal'
import TestModule from '../OHI-TestModule/TestModule'
import Optionalquestions from '../Optionalquestion'
import StandardDemoquestions from '../StandardDemographics'
import { ExpandMore } from '@material-ui/icons'
import { cloneDeep } from 'lodash'
import StorageInstance from '../../../../common/Storage'

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#021c4b',
    },
  },
})

function Checkboxs({
  onFetchDesignSurvey,
  designsurveyget,
  params,
  onUpdateDesignSurvey,
  designupdateStatus,
  history,
  onSaveQuestion,
  questionpoststatus,
  outcomescales,
  onFetchOutcomeScale,
  practicescales,
  onFetchPracticeScale,
  questionlist,
  onGetquestionlist,
  onUpdateQuestionListFetch,
  updatequestionlistStatus,
  updatequestionStatus,
  updateQuestionFetch,
  influencer,
  influencerFetch,
  influencerStatus,
  updateInfluencer,
  valuesdata,
  valuesFetch,
  valuesdataStatus,
  updateValue,
  inclusiondata,
  inclusionFetch,
  inlusiondataStaus,
  updateInclusion,
  isButtonToBeDisabled,
  resetQuestionList,
  updateWorkEx,
  workexFetch,
  ohiTestModuleFetch,
  ohiTestModuleData,
  updateTestModule,
  ohiUpdateDataStatus,
  TextUpdateAllowed,
  ohiVersionId,
  ohi4ModuleFetch,
  ohi4ModuleData,
  updateohi4Module,
  updateohi4ModuleStatus,
  surveyinfo,
}) {
  const classes = useStyles()

  const [addquestion, setAddquestion] = useState(false)
  const [questionOrder, setQuestioorder] = useState(false)
  const [isEditInfluencer, SetisEditInfluencer] = useState(false)
  const [isEmployeeExperience, SetisEmployeeExperience] = useState(false)
  const [isEditInclusion, setIsEditInclusion] = useState(false)
  const [isEditValues, SetisEditValues] = useState(false)
  const [isEditTestModule, setIsEditTestModule] = useState(false)
  const [isExModule, setIsExModule] = useState(false)
  const [isInclusion, setIsInclusion] = useState(false)
  const [isTestModule, setIsTestModule] = useState(false)
  const [btnLoading, setBtnLoading] = React.useState(false)
  const [isAllselectedOutcome, SetisAllselectedOutcome] = useState(false)
  const [isAllselectedPractice, SetisAllselectedPractice] = useState(false)
  const [isAllselectedDeepdive, SetisAllselectedDeepdive] = useState(false)
  const [isAllselectedQuestion, SetisAllselectedQuestion] = useState(false)
  const [updateqListClear, setUpdateqListClear] = useState(false)
  const [postqClear, setPostqClear] = useState(false)
  const [updateqClear, setUpdateqClear] = useState(false)
  const [errors, setErrors] = useState({})
  const [open, setOpen] = useState(false)
  const [ohiData, setOHIdata] = useState([])
  const [reason, setReason] = useState({
    open: false,
    reason: '',
    id: '',
    name: '',
  })

  const { addToast } = useToasts()
  const [prshow, setPrshow] = useState(3)
  const [useForm, setUseForm] = useState({
    surveyId: 2,
    isLaunch: false,
    outcomes: [
      {
        id: 1,
        name: 'Direction',
        isSelected: false,
      },
      {
        id: 2,
        name: 'Work Environment',
        isSelected: false,
      },
      {
        id: 3,
        name: 'Accountability',
        isSelected: false,
      },
      {
        id: 4,
        name: 'Coordination & Control',
        isSelected: false,
      },
      {
        id: 5,
        name: 'Capabilities',
        isSelected: false,
      },
      {
        id: 6,
        name: 'Motivation',
        isSelected: false,
      },
      {
        id: 7,
        name: 'Innovation & Learning',
        isSelected: false,
      },
      {
        id: 8,
        name: 'External Orientation',
        isSelected: false,
      },
      {
        id: 9,
        name: 'Leadership',
        isSelected: false,
      },
    ],
    practices: [
      {
        id: 1,
        name: 'Shared vision',
        outcomeId: 1,
        isSelected: false,
        outcomeName: 'Direction',
      },
      {
        id: 2,
        name: 'Strategic clarity',
        outcomeId: 1,
        isSelected: false,
        outcomeName: 'Direction',
      },
      {
        id: 3,
        name: 'Employee involvement',
        outcomeId: 1,
        isSelected: false,
        outcomeName: 'Direction',
      },
      {
        id: 4,
        name: 'Open and trusting',
        outcomeId: 2,
        isSelected: false,
        outcomeName: 'Work Environment',
      },
      {
        id: 5,
        name: 'Internally competitive',
        outcomeId: 2,
        isSelected: false,
        outcomeName: 'Work Environment',
      },
      {
        id: 6,
        name: 'Operationally disciplined',
        outcomeId: 2,
        isSelected: false,
        outcomeName: 'Work Environment',
      },
      {
        id: 7,
        name: 'Creative & entrepreneurial',
        outcomeId: 2,
        isSelected: false,
        outcomeName: 'Work Environment',
      },
      {
        id: 8,
        name: 'Role clarity',
        outcomeId: 3,
        isSelected: false,
        outcomeName: 'Accountability',
      },
      {
        id: 9,
        name: 'Performance contracts',
        outcomeId: 3,
        isSelected: false,
        outcomeName: 'Accountability',
      },
      {
        id: 10,
        name: 'Consequence management',
        outcomeId: 3,
        isSelected: false,
        outcomeName: 'Accountability',
      },
      {
        id: 11,
        name: 'Personal ownership',
        outcomeId: 3,
        isSelected: false,
        outcomeName: 'Accountability',
      },
      {
        id: 12,
        name: 'People performance review',
        outcomeId: 4,
        isSelected: false,
        outcomeName: 'Coordination & Control',
      },
      {
        id: 13,
        name: 'Operational management',
        outcomeId: 4,
        isSelected: false,
        outcomeName: 'Coordination & Control',
      },
      {
        id: 14,
        name: 'Financial management',
        outcomeId: 4,
        isSelected: false,
        outcomeName: 'Coordination & Control',
      },
      {
        id: 15,
        name: 'Professional standards',
        outcomeId: 4,
        isSelected: false,
        outcomeName: 'Coordination & Control',
      },
      {
        id: 16,
        name: 'Risk management',
        outcomeId: 4,
        isSelected: false,
        outcomeName: 'Coordination & Control',
      },
      {
        id: 17,
        name: 'Talent acquisition',
        outcomeId: 5,
        isSelected: false,
        outcomeName: 'Capabilities',
      },
      {
        id: 18,
        name: 'Talent development',
        outcomeId: 5,
        isSelected: false,
        outcomeName: 'Capabilities',
      },
      {
        id: 19,
        name: 'Process based capabilities',
        outcomeId: 5,
        isSelected: false,
        outcomeName: 'Capabilities',
      },
      {
        id: 20,
        name: 'Outsourced expertise',
        outcomeId: 5,
        isSelected: false,
        outcomeName: 'Capabilities',
      },
      {
        id: 21,
        name: 'Meaningful values',
        outcomeId: 6,
        isSelected: false,
        outcomeName: 'Motivation',
      },
      {
        id: 22,
        name: 'Inspirational leaders',
        outcomeId: 6,
        isSelected: false,
        outcomeName: 'Motivation',
      },
      {
        id: 23,
        name: 'Career opportunities',
        outcomeId: 6,
        isSelected: false,
        outcomeName: 'Motivation',
      },
      {
        id: 24,
        name: 'Financial incentives',
        outcomeId: 6,
        isSelected: false,
        outcomeName: 'Motivation',
      },
      {
        id: 25,
        name: 'Rewards & recognition',
        outcomeId: 6,
        isSelected: false,
        outcomeName: 'Motivation',
      },
      {
        id: 26,
        name: 'Top-down innovation',
        outcomeId: 7,
        isSelected: false,
        outcomeName: 'Innovation & Learning',
      },
      {
        id: 27,
        name: 'Bottom-up innovation',
        outcomeId: 7,
        isSelected: false,
        outcomeName: 'Innovation & Learning',
      },
      {
        id: 28,
        name: 'Knowledge sharing',
        outcomeId: 7,
        isSelected: false,
        outcomeName: 'Innovation & Learning',
      },
      {
        id: 29,
        name: 'Capturing external ideas',
        outcomeId: 7,
        isSelected: false,
        outcomeName: 'Innovation & Learning',
      },
      {
        id: 30,
        name: 'Customer focus',
        outcomeId: 8,
        isSelected: false,
        outcomeName: 'External Orientation',
      },
      {
        id: 31,
        name: 'Competitive Insights',
        outcomeId: 8,
        isSelected: false,
        outcomeName: 'External Orientation',
      },
      {
        id: 32,
        name: 'Business partnerships',
        outcomeId: 8,
        isSelected: false,
        outcomeName: 'External Orientation',
      },
      {
        id: 33,
        name: 'Government & community relations',
        outcomeId: 8,
        isSelected: false,
        outcomeName: 'External Orientation',
      },
      {
        id: 34,
        name: 'Authoritative leadership',
        outcomeId: 9,
        isSelected: false,
        outcomeName: 'Leadership',
      },
      {
        id: 35,
        name: 'Consultative leadership',
        outcomeId: 9,
        isSelected: false,
        outcomeName: 'Leadership',
      },
      {
        id: 36,
        name: 'Supportive leadership',
        outcomeId: 9,
        isSelected: false,
        outcomeName: 'Leadership',
      },
      {
        id: 37,
        name: 'Challenging leadership',
        outcomeId: 9,
        isSelected: false,
        outcomeName: 'Leadership',
      },
    ],
    deepDive: [
      {
        id: 1,
        name: 'Engagement',
        additionalCustomisation: 0,
        isSelected: false,
      },
      {
        id: 2,
        name: 'Leadership',
        additionalCustomisation: 0,
        isSelected: false,
      },
      {
        id: 3,
        name: 'Influencer',
        additionalCustomisation: 1,
        isSelected: false,
      },
      {
        id: 4,
        name: 'Values',
        additionalCustomisation: 1,
        isSelected: false,
      },
    ],
    question: [
      {
        id: 1,
        name: 'Describe @clientName@ in three words or less',
        isSelected: false,
        qid: '',
      },
      {
        id: 2,
        name: 'What strengths should @clientname@ build upon in the future?',
        isSelected: false,
        qid: '',
      },
      {
        id: 3,
        name: 'What weaknesses should @clientname@ build upon in the future?',
        isSelected: false,
        qid: '',
      },
      {
        id: 4,
        name:
          'Please take a few moments to add any additional thoughts of suggestions',
        isSelected: false,
        qid: '',
      },
    ],
  })

  const [searchval, setSearchval] = useState('')
  const [cstpractis, setCstpractice] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [editQuestiondata, setQuestiondata] = useState(null)
  const [backtoedit, setBacktoedit] = useState(false)
  const [addquestionLoading, setAddquestionLoading] = useState(false)

  const [isInfluencerget, setIsInfluencerget] = useState(false)
  const [isInclusionget, setIsInclusionget] = useState(false)
  const [isTestModuleGet, setIsTestModuleGet] = useState(false)
  const [isWorkExget, setIsWorkExget] = useState(false)
  const [isValuesget, setIsValuesget] = useState(false)
  const [moveToNxtPage, setMoveToNxtPage] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [reset, setReset] = useState(false)
  const [ohi4Module, setOhi4Module] = useState([])
  const empExperienceFlag = ohiVersionId === 3
  // console.log('surveyType', useForm.surveyType)
  // console.log('ohiVersionId', ohiVersionId)
  // console.log('empExperienceFlag', empExperienceFlag)
  // console.log('empExperienceFlag', empExperienceFlag)
  const ifOhi4Selcted = ohi4Module.filter(
    ({ name = '', isSelected = '' }, index) =>
      name === 'Employee Experience' && !isSelected
  ).length

  useEffect(() => {
    onFetchDesignSurvey({ id: params })
    !outcomescales.get('isFetched') && onFetchOutcomeScale()
    !practicescales.get('isFetched') && onFetchPracticeScale()
    influencerFetch({ surveyId: params, questionId: 3 })
    workexFetch({ surveyId: params, questionId: 1 })
    valuesFetch({ surveyId: params, questionId: 4 })
    inclusionFetch({ surveyId: params, questionId: 6 })
  }, [])

  useEffect(() => {
    if (ohiVersionId) {
      ohiVersionId === 3 || ohiVersionId === 2
        ? ohi4ModuleFetch({ surveyId: params, questionId: 10 })
        : ohiTestModuleFetch({ surveyId: params, questionId: 9 })
    }
  }, [ohiVersionId])

  useEffect(() => {
    setReset(true)
    if (ohi4ModuleData.get('isFetched')) {
      setOhi4Module(ohi4ModuleData.toJS()?.items?.Values)
    }
  }, [ohi4ModuleData])

  useEffect(() => {
    if (valuesdataStatus.get('isFetched') && isValuesget) {
      setIsValuesget(false)
      valuesFetch({ surveyId: params, questionId: 4 })
    }
  }, [valuesdataStatus])

  useEffect(() => {
    if (inlusiondataStaus.get('isFetched') && isInclusionget) {
      setIsInclusionget(false)
      setIsWorkExget(false)
      inclusionFetch({ surveyId: params, questionId: 6 })
      workexFetch({ surveyId: params, questionId: 1 })
    }
  }, [inlusiondataStaus])

  useEffect(() => {
    if (influencerStatus.get('isFetched') && isInfluencerget) {
      setAddquestionLoading(false)
      influencerFetch({ surveyId: params, questionId: 3 })
    }
  }, [influencerStatus])

  useEffect(() => {
    if (ohiUpdateDataStatus.get('isFetched') && isTestModuleGet) {
      setIsTestModuleGet(false)
      setTimeout(() => {}, 300)
      setIsEditTestModule(false)
      ohiTestModuleFetch({ surveyId: params, questionId: 9 })
    }
  }, [ohiUpdateDataStatus])

  useEffect(() => {
    setLoading(updatequestionStatus.get('isLoading'))
    if (updatequestionStatus.get('isFetched') && updateqClear) {
      setUpdateqClear(false)
      onFetchDesignSurvey({ id: params })
      onGetquestionlist(params)
    }
  }, [updatequestionStatus])

  useEffect(() => {
    if (updatequestionlistStatus.get('isFetched') && updateqListClear) {
      setUpdateqListClear(false)
      onFetchDesignSurvey({ id: params })
      onGetquestionlist(params)
    }
  }, [updatequestionlistStatus])

  useEffect(() => {
    if (questionpoststatus.get('isFetched') && postqClear) {
      setPostqClear(true)
      onFetchDesignSurvey({ id: params })
      onGetquestionlist(params)
    }
  }, [questionpoststatus])

  useEffect(() => {
    if (designupdateStatus.get('isFetched') && (btnLoading || saveLoading)) {
      if (designupdateStatus.get('items').get('error')) {
        addToast(designupdateStatus.get('items').get('data'), {
          appearance: designupdateStatus.get('items').get('error')
            ? 'error'
            : 'success',
          autoDismiss: true,
        })
      } else {
        if (moveToNxtPage) {
          StorageInstance.designSurvey = true
          history.push(`../summary/${params}`)
        }
      }
      setUseForm({
        ...useForm,
        isLaunch: false,
      })
      if (!moveToNxtPage) {
        setMoveToNxtPage(true)
        setBtnLoading(false)
        setSaveLoading(false)
      }
    }
  }, [designupdateStatus])

  useEffect(() => {
    if (moveToNxtPage) {
      StorageInstance.designSurvey = true
      history.push(`../summary/${params}`)
    }
  }, [moveToNxtPage])

  const getNameAndFlag = (mId, array) => {
    return array?.deepDive?.filter(
      ({ id = '', isSelected = false }) => id === mId && isSelected === true
    ).length
  }

  useEffect(() => {
    const objectArray = designsurveyget.toJS().items
    if (getNameAndFlag(7, objectArray)) {
      setIsExModule(true)
    }
    if (getNameAndFlag(6, objectArray)) {
      setIsInclusion(true)
    }
    if (getNameAndFlag(9, objectArray)) {
      setIsTestModule(true)
      setOpen(false)
    }
    if (designsurveyget.get('isFetched')) {
      setUseForm({
        ...designsurveyget.toJS().items,
      })
    }
  }, [designsurveyget])

  useEffect(() => {
    useForm.outcomes && selectAllFn('outcomes', useForm.outcomes)
    useForm.practices && selectAllFn('practices', useForm.practices)
    useForm.deepDive && selectAllFn('deepdive', useForm.deepDive)
    useForm.question && selectAllFn('question', useForm.question)
    if (useForm.isLaunch) {
      onUpdateDesignSurvey(useForm, moveToNxtPage)
    }
  }, [useForm])

  useEffect(() => {
    updatequestionlistStatus &&
      setLoading(updatequestionlistStatus.get('isLoading'))
  }, [updatequestionlistStatus])

  useEffect(() => {
    questionlist && setLoading(questionlist.get('isLoading'))
  }, [questionlist])

  useEffect(() => {
    if (searchval.trim() !== '') {
      setCstpractice(
        useForm.outcomes.filter(item => {
          let name = item.name.toLowerCase()
          let sval = searchval.toLowerCase()

          let child = useForm.practices.filter(citem => {
            if (citem.outcomeName.toLowerCase() === name) {
              let cname = citem.name.toLowerCase()
              return cname.indexOf(sval) !== -1
            }
          })
          return name.indexOf(sval) !== -1 || child.length > 0
        })
      )
    } else {
      setCstpractice(useForm.outcomes)
    }
  }, [searchval])

  const handleSensitive = e => {
    const {
      target: { checked },
    } = e
    const copy = cloneDeep(useForm)
    copy.sensitiveDemographics = checked
    setUseForm(copy)
  }

  function selectAllFn(name, arr) {
    const selectedvalue = arr.filter(x => x.isSelected === true)
    if (arr.length === selectedvalue.length) {
      switch (name) {
        case 'outcomes':
          SetisAllselectedOutcome(true)
          break
        case 'practices':
          SetisAllselectedPractice(true)
          break
        case 'deepdive':
          SetisAllselectedDeepdive(true)
          break
        case 'question':
          SetisAllselectedQuestion(true)
          break
        default:
          break
      }
    } else {
      switch (name) {
        case 'outcomes':
          SetisAllselectedOutcome(false)
          break
        case 'practices':
          SetisAllselectedPractice(false)
          break
        case 'deepdive':
          SetisAllselectedDeepdive(false)
          break
        case 'question':
          SetisAllselectedQuestion(false)
          break
        default:
          break
      }
    }
  }

  const delectQuestion = (parent, child) => {
    const clone = cloneDeep(useForm)
    const findIndex = useForm.optionalQuestion.findIndex(
      ({ name }) => name === parent
    )
    if (findIndex !== -1) {
      const childIndex = useForm.optionalQuestion[
        findIndex
      ].groupDetails.findIndex(({ name }) => name === child)
      useForm.optionalQuestion[findIndex].groupDetails[childIndex].data.forEach(
        (item, i) => {
          clone.optionalQuestion[findIndex].groupDetails[childIndex].data[
            i
          ].isSelected = false
        }
      )
      setUseForm(clone)
    }
  }

  const getNameSelected = (moduleName, flag, array) =>
    array.filter(
      ({ name = '', isSelected = false }) =>
        name === moduleName && isSelected === flag
    ).length

  function showmorebtnHnadle(value) {
    setPrshow(value)
  }

  function handleSearhChange() {
    return function(event) {
      setSearchval(event.target.value)
    }
  }
  function handleQuestionPost(data) {
    if (editQuestiondata == null) {
      setPostqClear(true)
      onSaveQuestion(data)
    } else {
      setUpdateqClear(true)
      updateQuestionFetch(data)
      setQuestiondata(null)
      setQuestioorder(true)
    }
  }
  function handleUpdateQuestionlist(data) {
    setUpdateqListClear(true)
    onUpdateQuestionListFetch(data)
    setQuestioorder(false)
    setQuestiondata(null)
    setBacktoedit(false)
  }
  function getEditQuestion(data) {
    setQuestiondata(data)
    setTimeout(function() {
      setQuestioorder(false)
      setAddquestion(true)
      setBacktoedit(true)
    }, 300)
  }
  function onUpdateInfluencerFn(data) {
    setIsInfluencerget(true)
    updateInfluencer(data)
  }
  function onUpdateInclusionFn(data) {
    setIsInclusionget(true)
    updateInclusion(data)
  }
  function onUpdateworkexFn(data) {
    setIsWorkExget(true)
    setIsInclusionget(true)
    updateWorkEx(data)
  }
  function onUpdateValuesFn(data) {
    setIsValuesget(true)
    updateValue(data)
  }

  function resetOhiTestModule() {
    const data = ohiTestModuleData.toJS().items.Values
    if (data && data.filter(({ isSelected }) => isSelected === true).length) {
      data.map((item, i) => (data[i].isSelected = false))
      const payload = {
        surveyId: params,
        Values: data,
      }
      setOHIdata(data)
      setIsTestModuleGet(true)
      updateTestModule(payload)
    }
  }

  if (
    (designsurveyget && designsurveyget.get('isLoading')) ||
    (questionpoststatus && questionpoststatus.get('isLoading'))
  ) {
    return (
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        className={classes.root}
      >
        <Box style={{ position: 'relative', minHeight: 250 }}>
          <PopupModal>
            <ProgressBarLoader />
          </PopupModal>
        </Box>
      </Box>
    )
  } else if (addquestion && designupdateStatus.get('isLoading')) {
    return (
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        className={classes.root}
      >
        <Box style={{ position: 'relative', minHeight: 250 }}>
          <PopupModal>
            <ProgressBarLoader />
          </PopupModal>
        </Box>
      </Box>
    )
  }
  return <Box>{renderAll()}</Box>

  function questionorderScreen() {
    setBacktoedit(false)
    setQuestioorder(false)
  }

  function renderAll() {
    return (
      <Box>
        {addquestion ? renderAddQScreen() : null}
        {!addquestion && !questionOrder ? renderFirstscreen() : null}
        {!addquestion && !questionOrder ? renderFormBottom() : null}
        {questionOrder ? (
          isLoading ? (
            <Box style={{ position: 'relative', minHeight: 250 }}>
              <PopupModal>
                <ProgressBarLoader />
              </PopupModal>
            </Box>
          ) : (
            <Box style={{ position: 'relative' }}>
              <QuestionOrdering
                questionlist={questionlist}
                onGetquestionlist={onGetquestionlist}
                params={params}
                onUpdatelist={handleUpdateQuestionlist}
                getEditQuestion={getEditQuestion}
                onUpdatequestionStatus={updatequestionStatus}
                resetQuestionList={resetQuestionList}
              ></QuestionOrdering>
              <Box
                component="button"
                className={classes.backbtn}
                onClick={questionorderScreen}
                style={{ backgroundImage: `url(${backIcon})`, top: 44 }}
              ></Box>
            </Box>
          )
        ) : null}
      </Box>
    )
  }

  function renderAddQScreen() {
    function backtoquestionlist() {
      if (editQuestiondata == null) {
        setAddquestion(false)
        setQuestiondata(null)
        setTimeout(() => {}, 200)
      } else {
        setAddquestion(false)
        setQuestiondata(null)
        setQuestioorder(true)
      }
    }

    return (
      <Box style={{ position: 'relative' }}>
        <AddAdditionalQuestion
          params={params}
          onPostQuestion={handleQuestionPost}
          backtoquestionlist={backtoquestionlist}
          outcomescales={outcomescales}
          practicescales={practicescales}
          editQuestiondata={editQuestiondata}
        ></AddAdditionalQuestion>
        <Box
          component="button"
          className={classes.backbtn}
          onClick={backtoquestionlist}
          style={{ backgroundImage: `url(${backIcon})` }}
        ></Box>
      </Box>
    )
  }
  function renderFirstscreen() {
    function hideDeepdiveEdit() {
      SetisEditInfluencer(false)
    }
    function hideworkexEdit() {
      SetisEmployeeExperience(false)
    }
    function hideValuesModalFn() {
      SetisEditValues(false)
    }
    function hideInclusionModalFn() {
      setIsEditInclusion(false)
    }
    function hideOhiTestModuleFn() {
      setIsEditTestModule(false)
    }

    return (
      <Box>
        <Box
          className={clsx({
            [classes.editDeepdive]: true,
            [classes.EditInclusionActive]: isEditInclusion,
          })}
        >
          <EditInclusion
            hideDeepdiveEdit={hideInclusionModalFn}
            inclusiondata={inclusiondata}
            inlusiondataStaus={inlusiondataStaus}
            onUpdateInclusion={onUpdateInclusionFn}
          ></EditInclusion>
        </Box>
        <Box
          className={clsx({
            [classes.editDeepdive]: true,
            [classes.EditInclusionActive]: isEmployeeExperience,
          })}
        >
          <EditWorkEx
            hideworkexEdit={hideworkexEdit}
            inclusiondata={inclusiondata}
            inlusiondataStaus={inlusiondataStaus}
            updateWorkEx={onUpdateworkexFn}
            params={params}
          ></EditWorkEx>
        </Box>
        <Box
          className={clsx({
            [classes.editDeepdive]: true,
            [classes.editDeepdiveActive]: isEditInfluencer,
          })}
        >
          <EditInfluencer
            hideDeepdiveEdit={hideDeepdiveEdit}
            influencer={influencer}
            influencerStatus={influencerStatus}
            onUpdateInfluencer={onUpdateInfluencerFn}
          ></EditInfluencer>
        </Box>
        <Box
          className={clsx({
            [classes.editDeepdive]: true,
            [classes.vluesmodal]: true,
            [classes.editValuesActive]: isEditValues,
          })}
        >
          <ValueModal
            params={params}
            valuesdata={valuesdata}
            valuesFetch={valuesFetch}
            valuesdataStatus={valuesdataStatus}
            onUpdateValue={onUpdateValuesFn}
            hideValuesModal={hideValuesModalFn}
          ></ValueModal>
        </Box>
        {/* Test Module */}
        <Box
          className={clsx({
            [classes.editDeepdive]: true,
            [classes.EditTestModule]: isEditTestModule,
          })}
        >
          <TestModule
            useForm={useForm}
            valuesdataStatus={valuesdataStatus}
            designsurveyget={designsurveyget}
            onUpdateTestModule={setIsTestModuleGet}
            isInclusion={isInclusion}
            isInfluencer={isExModule}
            hideOhiTestModal={hideOhiTestModuleFn}
            ohiTestModuleData={ohiTestModuleData}
            updateTestModule={updateTestModule}
            ohiUpdateDataStatus={ohiUpdateDataStatus}
            isTestModuleGet={isTestModuleGet}
            setOHIdata={setOHIdata}
            ohiData={ohiData}
          ></TestModule>
        </Box>
        {useForm.outcomes !== undefined ? renderOutcomes() : null}
        {useForm.practices !== undefined ? renderPractices() : null}
        {ohiVersionId === 3 || ohiVersionId === 2 ? renderohi4Module() : null}
        {useForm.deepDive ? renderDeepDive() : null}
        {ohiVersionId === 3 || ohiVersionId === 2
          ? renderOptionalquestions()
          : null}
        {useForm.question ? renderAdditionalquestion() : null}
        {ohiVersionId === 3 || ohiVersionId === 2
          ? renderStandardQuestion()
          : null}
        {ohiVersionId === 3 || ohiVersionId === 2
          ? renderSensitiveDemographics()
          : null}
      </Box>
    )
  }
  function renderOutcomes() {
    const handlOutcomesChange = itemIndex => {
      const toggledItem = { ...useForm.outcomes[itemIndex] }
      toggledItem.isSelected = !toggledItem.isSelected
      setUseForm({
        ...useForm,
        outcomes: [
          ...useForm.outcomes.slice(0, itemIndex),
          toggledItem,
          ...useForm.outcomes.slice(itemIndex + 1),
        ],
      })
    }

    const selectalloutcome = () => {
      let slall = []
      if (isAllselectedOutcome) {
        slall = useForm.outcomes.map(function(x) {
          x.isSelected = false
          return x
        })
      } else {
        slall = useForm.outcomes.map(function(x) {
          x.isSelected = true
          return x
        })
      }
      setUseForm({ ...useForm, outcomes: slall })
    }

    return (
      <ThemeProvider theme={outerTheme}>
        <Box className={classes.root + ' design-survey-box-wrapper'}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionTitle}
          >
            Outcomes
            {useForm.surveyType && useForm.surveyType === 5 ? (
              <Button
                variant={isAllselectedOutcome ? 'contained' : 'outlined'}
                color="primary"
                size="small"
                className={clsx({
                  [classes.allselected]: isAllselectedOutcome,
                  [classes.selectAll]: true,
                })}
                onClick={selectalloutcome}
              >
                {isAllselectedOutcome ? 'Unselect All' : 'Select All'}
              </Button>
            ) : (
              <Box className={classes.nodataOP}>
                <img
                  src={tooltipicongray}
                  alt="tooltip"
                  className={classes.tooltipicon}
                />
                {useForm.surveyType === 4 ? (
                  <em>
                    For this survey type (Full OHI), all outcomes/practices are
                    selected by default.
                  </em>
                ) : null}
                {useForm.surveyType === 6 ? (
                  <em>
                    For this survey type (Deep Dive Module(s) Only), no
                    outcomes/practices are selected by default.
                  </em>
                ) : null}
              </Box>
            )}
          </Typography>
          <Grid container spacing={3} className={classes.checkboxContainer}>
            {useForm.surveyType &&
              useForm.surveyType === 5 &&
              useForm.outcomes.map((chkitem, index) => {
                return (
                  <Grid
                    item
                    xs={4}
                    align="left"
                    className={classes.checkboxGrid}
                    key={chkitem.id.toString()}
                  >
                    <FormControlLabel
                      classes={{
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          disabled={useForm.surveyType === 4}
                          onChange={() => handlOutcomesChange(index)}
                          classes={{
                            checked: classes.checked,
                          }}
                          checked={useForm.outcomes[index].isSelected || false}
                          icon={
                            <CircleUnchecked
                              className={classes.checkboxIconUnchecked}
                            />
                          }
                          checkedIcon={
                            <CircleCheckedFilled
                              className={classes.checkboxIcon}
                            />
                          }
                          disableRipple
                          style={{ fontSize: 20 }}
                          inputProps={{
                            'aria-label': 'Direction',
                          }}
                        />
                      }
                      label={chkitem.name}
                    />
                  </Grid>
                )
              })}
          </Grid>
        </Box>
      </ThemeProvider>
    )
  }
  function renderPractices() {
    const handlPracticesChange = itemIndex => {
      const toggledItem = { ...useForm.practices[itemIndex] }
      toggledItem.isSelected = !toggledItem.isSelected
      setUseForm({
        ...useForm,
        practices: [
          ...useForm.practices.slice(0, itemIndex),
          toggledItem,
          ...useForm.practices.slice(itemIndex + 1),
        ],
      })
    }
    const selectallpractices = () => {
      let slall = []
      if (!isAllselectedPractice) {
        slall = useForm.practices.map(function(x) {
          x.isSelected = true
          return x
        })
      } else {
        slall = useForm.practices.map(function(x) {
          x.isSelected = false
          return x
        })
      }
      setUseForm({ ...useForm, practices: slall })
    }

    return (
      <ThemeProvider theme={outerTheme}>
        <Box className={classes.root + ' design-survey-box-wrapper'}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionTitleP}
          >
            Practices
            {useForm.surveyType && useForm.surveyType === 5 ? (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                className={clsx({
                  [classes.allselected]: isAllselectedPractice,
                  [classes.selectAll]: true,
                })}
                onClick={selectallpractices}
              >
                {isAllselectedPractice ? 'Unselect All' : 'Select All'}
              </Button>
            ) : (
              <Box className={classes.nodataOP}>
                <img
                  src={tooltipicongray}
                  alt="tooltip"
                  className={classes.tooltipicon}
                />
                {useForm.surveyType === 4 ? (
                  <em>
                    For this survey type (Full OHI), all outcomes/practices are
                    selected by default.
                  </em>
                ) : null}
                {useForm.surveyType === 6 ? (
                  <em>
                    For this survey type (Deep Dive Module(s) Only), no
                    outcomes/practices are selected by default.
                  </em>
                ) : null}
              </Box>
            )}
            {useForm.surveyType && useForm.surveyType === 5 ? (
              <Box component="span" className={classes.headingbtnsection}>
                <img src={searchIcon} style={{ width: 26 }} />
                <FormControl fullWidth>
                  <input
                    className={classes.searchinput}
                    type="search"
                    value={searchval || ''}
                    onChange={handleSearhChange()}
                  />
                </FormControl>
              </Box>
            ) : null}
          </Typography>
          {useForm.surveyType &&
            useForm.surveyType === 5 &&
            cstpractis.map((chkitem, index) => {
              if (index < prshow) {
                return (
                  <Grid
                    item
                    xs={12}
                    align="left"
                    className={classes.checkboxGrid}
                    key={chkitem.id.toString()}
                  >
                    <div className={classes.outcomeTitle}>{chkitem.name}</div>
                    <Grid
                      container
                      spacing={3}
                      className={classes.practiceContainer}
                    >
                      {useForm.practices.map((practice, prindex) => {
                        if (chkitem.id == practice.outcomeId) {
                          return (
                            <Grid
                              item
                              xs={4}
                              align="left"
                              className={classes.checkboxGrid}
                              key={practice.id}
                            >
                              <FormControlLabel
                                classes={{
                                  label: classes.label,
                                }}
                                control={
                                  <Checkbox
                                    disabled={useForm.surveyType === 4}
                                    onChange={() =>
                                      handlPracticesChange(prindex)
                                    }
                                    classes={{
                                      checked: classes.checked,
                                    }}
                                    checked={
                                      useForm.practices[prindex].isSelected ||
                                      false
                                    }
                                    icon={
                                      <CircleUnchecked
                                        className={
                                          classes.checkboxIconUnchecked
                                        }
                                      />
                                    }
                                    checkedIcon={
                                      <CircleCheckedFilled
                                        className={classes.checkboxIcon}
                                      />
                                    }
                                    disableRipple
                                    style={{ fontSize: 20 }}
                                    inputProps={{
                                      'aria-label': 'Direction',
                                    }}
                                  />
                                }
                                label={practice.name}
                              />
                            </Grid>
                          )
                        }
                      })}
                    </Grid>
                  </Grid>
                )
              }
            })}
          {useForm.surveyType && useForm.surveyType === 5 ? (
            <Box>
              {prshow == 3 ? (
                <div
                  className={classes.showmorebtn}
                  onClick={() => showmorebtnHnadle(999)}
                >
                  View more
                </div>
              ) : (
                <div
                  className={classes.showmorebtn}
                  onClick={() => showmorebtnHnadle(3)}
                >
                  View less
                </div>
              )}
            </Box>
          ) : null}
        </Box>
      </ThemeProvider>
    )
  }

  function changeOhi4ModuleData(itemIndex, item) {
    setReset(true)
    if (!ifOhi4Selcted) {
      setOpen(false)
    }
    const toggledItem = { ...ohi4Module[itemIndex] }
    toggledItem.isSelected = !toggledItem.isSelected
    const data = [
      ...ohi4Module.slice(0, itemIndex),
      toggledItem,
      ...ohi4Module.slice(itemIndex + 1),
    ]

    setOhi4Module([...data])
    const payload = {
      surveyId: params,
      isSubmitted: false,
      Values: [data[itemIndex]],
    }
    updateohi4Module(payload)
  }

  function updateReasonOhi4ModuleData() {
    setReason({ ...reason, open: false })
    const { reason: text, id } = reason
    const data = cloneDeep(ohi4Module)
    data[id].reason = text
    const sortedData = data[id]
    const payload = {
      surveyId: params,
      isSubmitted: true,
      Values: [sortedData],
    }
    updateohi4Module(payload)
  }

  function renderohi4Module() {
    return (
      <ThemeProvider theme={outerTheme}>
        <Box className={classes.root + ' design-survey-box-wrapper'}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionTitleP}
          >
            OHI 4.0 Sections
          </Typography>
          <Grid container spacing={3} className={classes.checkboxContainer}>
            {ohi4Module?.map((chkitem, index) => {
              return (
                <Grid
                  item
                  xs={4}
                  align="left"
                  className={classes.checkboxGrid}
                  key={index}
                >
                  <FormControlLabel
                    classes={{
                      label: classes.label,
                    }}
                    control={
                      <Checkbox
                        disableRipple
                        disabled={false}
                        classes={{
                          checked: classes.checked,
                        }}
                        checked={chkitem.isSelected}
                        icon={
                          <CircleUnchecked
                            className={classes.checkboxIconUnchecked}
                          />
                        }
                        checkedIcon={
                          <CircleCheckedFilled
                            className={classes.checkboxIcon}
                          />
                        }
                        onChange={e => {
                          if (empExperienceFlag) {
                            // console.log('ka')
                            if (
                              !e.target.checked &&
                              chkitem.name ===
                                'Workplace Flexibility Demographics'
                            ) {
                              ohi4Module[index].isSelected = e.target.checked

                              delectQuestion(
                                'Workplace Flexibility Demographics',
                                'Intent to Leave - Reasons'
                              )
                              setOhi4Module(ohi4Module)
                              setReason({
                                reason: '',
                                id: index,
                                open: true,
                                name: chkitem.name,
                              })
                              // changeOhi4ModuleData(index, chkitem.name)
                            } else {
                              changeOhi4ModuleData(index, chkitem.name)
                            }
                          } else {
                            if (
                              ((!e.target.checked &&
                                chkitem.name === 'Employee Experience') ||
                                (!e.target.checked &&
                                  chkitem.name ===
                                    'Workplace Flexibility Demographics')) &&
                              useForm.surveyType == 4
                            ) {
                              // console.log('kkaaa', e.target.checked)
                              ohi4Module[index].isSelected = e.target.checked
                              delectQuestion(
                                'Employee Experience',
                                'Intent to Leave - Reasons'
                              )
                              setOhi4Module(ohi4Module)
                              setReason({
                                reason: '',
                                id: index,
                                open: useForm.surveyType === 4,
                                name: chkitem.name,
                              })

                              // changeOhi4ModuleData(index, chkitem.name)
                            } else {
                              changeOhi4ModuleData(index, chkitem.name)
                            }
                          }
                        }}
                        style={{ fontSize: 20 }}
                        inputProps={{
                          'aria-label': chkitem.name,
                        }}
                      />
                    }
                    label={<Box>{chkitem.name}</Box>}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </ThemeProvider>
    )
  }

  function renderDeepDive() {
    const handlDeepDiveChange = (itemIndex, name) => {
      const toggledItem = { ...useForm.deepDive[itemIndex] }
      toggledItem.isSelected = !toggledItem.isSelected
      setUseForm({
        ...useForm,
        deepDive: [
          ...useForm.deepDive.slice(0, itemIndex),
          toggledItem,
          ...useForm.deepDive.slice(itemIndex + 1),
        ],
      })

      if (name === 'Influencer' && !useForm.deepDive[itemIndex].isSelected) {
        SetisEditInfluencer(true)
      } else if (
        name === 'Influencer' &&
        useForm.deepDive[itemIndex].isSelected
      ) {
        SetisEditInfluencer(false)
      }
      if (
        name === 'Employee Experience (3.1 Version)' &&
        !useForm.deepDive[itemIndex].isSelected
      ) {
        SetisEmployeeExperience(true)
      } else if (
        name === 'Employee Experience (3.1 Version)' &&
        useForm.deepDive[itemIndex].isSelected
      ) {
        SetisEmployeeExperience(false)
      }

      if (name === 'Inclusion' && !useForm.deepDive[itemIndex].isSelected) {
        setIsEditInclusion(true)
      } else if (
        name === 'Inclusion' &&
        useForm.deepDive[itemIndex].isSelected
      ) {
        setIsEditInclusion(false)
      }

      if (name === 'Values' && !useForm.deepDive[itemIndex].isSelected) {
        SetisEditValues(true)
      } else if (name === 'Values' && useForm.deepDive[itemIndex].isSelected) {
        SetisEditValues(false)
      }

      if (
        name === 'OHI 4.0 Test Module' &&
        !useForm.deepDive[itemIndex].isSelected
      ) {
        setIsEditTestModule(true)
      } else if (
        name === 'OHI 4.0 Test Module' &&
        useForm.deepDive[itemIndex].isSelected
      ) {
        setIsEditTestModule(false)
      }
    }

    const selectallDeepDive = () => {
      let slall = []
      if (!isAllselectedDeepdive) {
        setReset(false)
        slall = useForm.deepDive.map(function(x) {
          x.isSelected = true
          return x
        })
      } else {
        setIsExModule(false)
        setIsInclusion(false)
        setIsTestModule(false)
        onUpdateInfluencerFn({
          additionalQuestionText: '',
          isOptout: 0,
          isRequired: 0,
          nominateMax: 10,
          nominateMin: 1,
          surveyId: params,
          type: 1,
        })
        setReset(true)
        onUpdateworkexFn({ surveyId: Number(params), type: 0 })
        onUpdateInclusionFn({ surveyId: params, type: 0 })
        resetOhiTestModule()
        slall = useForm.deepDive.map(function(x) {
          x.isSelected = false
          return x
        })
      }

      setUseForm({ ...useForm, deepDive: slall })
    }

    return (
      <ThemeProvider theme={outerTheme}>
        <Box className={classes.root + ' design-survey-box-wrapper'}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionTitle}
            style={{ position: 'relative' }}
            id="deepdivemodules"
          >
            Deep Dive Modules
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={clsx({
                [classes.allselected]: isAllselectedDeepdive,
                [classes.selectAll]: true,
              })}
              onClick={selectallDeepDive}
            >
              {isAllselectedDeepdive ? 'Unselect All' : 'Select All'}
            </Button>
          </Typography>
          <Box className={classes.subtitle}>
            If you do not see your modules here, then it is not standard. Please
            add the questions as additional questions using the section below
          </Box>
          <Grid container spacing={3} className={classes.checkboxContainer}>
            {useForm.deepDive.map((chkitem, index) => {
              if (ohiVersionId === 1 && chkitem.id !== 10) {
                return (
                  <Grid
                    item
                    xs={4}
                    align="left"
                    className={classes.checkboxGrid}
                    key={index}
                  >
                    <FormControlLabel
                      classes={{
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          disabled={
                            chkitem.name === 'OHI 4.0 Test Module'
                              ? ohiUpdateDataStatus.toJS().isLoading
                              : false
                          }
                          onChange={e => {
                            const ohiData =
                              ohiTestModuleData.toJS().items.Values || []
                            const {
                              target: { checked },
                            } = e
                            const leadership = 'Inclusive leadership',
                              work = 'Inclusive work environment'
                            //influencer
                            if (chkitem.name === 'Influencer') {
                              if (!checked) {
                                setReset(true)
                                onUpdateInfluencerFn({
                                  additionalQuestionText: '',
                                  isOptout: 0,
                                  isRequired: 0,
                                  nominateMax: 10,
                                  nominateMin: 1,
                                  surveyId: params,
                                  type: 1,
                                })
                              } else {
                                setReset(false)
                              }
                            }
                            //inclusion
                            if (chkitem.name === 'Inclusion') {
                              setIsInclusion(checked)
                              if (!checked) {
                                setReset(true)
                                onUpdateInclusionFn({
                                  surveyId: params,
                                  type: 0,
                                })
                              } else {
                                setReset(false)
                                const flag =
                                  checked &&
                                  (getNameSelected(leadership, true, ohiData) ||
                                    getNameSelected(work, true, ohiData))
                                if (flag && ohiVersionId === 1) {
                                  addToast(
                                    'Similar questions already selected under "OHI 4.0 test module". Are you sure you want to still proceed with selections made?',
                                    { appearance: 'error', autoDismiss: true }
                                  )
                                }
                              }
                            }
                            if (
                              chkitem.name ===
                              'Employee Experience (3.1 Version)'
                            ) {
                              setIsExModule(e.target.checked)
                              if (!checked) {
                                setReset(true)
                                onUpdateworkexFn({
                                  surveyId: Number(params),
                                  type: 0,
                                })
                              } else {
                                setReset(false)
                                if (
                                  checked &&
                                  getNameSelected(
                                    'Individual Experience',
                                    true,
                                    ohiData
                                  ) &&
                                  ohiVersionId === 1
                                ) {
                                  addToast(
                                    'Similar questions already selected under "OHI 4.0 test module"',
                                    { appearance: 'error', autoDismiss: true }
                                  )
                                }
                              }
                            }
                            if (chkitem.name === 'OHI 4.0 Test Module') {
                              setIsTestModule(e.target.checked)
                              // if OHI test module radio is unchecked
                              if (!e.target.checked) {
                                setReset(true)
                                resetOhiTestModule()
                              } else {
                                setReset(false)
                              }
                            }
                            handlDeepDiveChange(index, chkitem.name)
                          }}
                          classes={{
                            checked: classes.checked,
                          }}
                          checked={useForm.deepDive[index].isSelected || false}
                          icon={
                            <CircleUnchecked
                              className={classes.checkboxIconUnchecked}
                            />
                          }
                          checkedIcon={
                            <CircleCheckedFilled
                              className={classes.checkboxIcon}
                            />
                          }
                          style={{ fontSize: 20 }}
                          inputProps={{
                            'aria-label': chkitem.name,
                          }}
                        />
                      }
                      label={
                        <Box>
                          {chkitem.name}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Influencer' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}

                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Values' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name ==
                            'Employee Experience (3.1 Version)' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Inclusion' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'OHI 4.0 Test Module' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}

                          {errors.isOptout &&
                          useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Influencer' ? (
                            <FormHelperText
                              id="my-helper-text-isOptout"
                              className={classes.myhelptext}
                            >
                              {errors.isOptout}
                            </FormHelperText>
                          ) : null}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'OHI 4.0 Test Module' ? (
                            <FormHelperText
                              id="my-helper-text-type"
                              className={classes.myhelptext}
                            >
                              {errors.type}
                            </FormHelperText>
                          ) : null}

                          {errors.type &&
                          useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Inclusion' ? (
                            <FormHelperText
                              id="my-helper-text-type"
                              className={classes.myhelptext}
                            >
                              {errors.type}
                            </FormHelperText>
                          ) : null}
                        </Box>
                      }
                    />
                  </Grid>
                )
              } else if (
                (ohiVersionId === 3 || ohiVersionId === 2) &&
                chkitem.id !== 10 &&
                chkitem.id !== 9
              ) {
                return (
                  <Grid
                    item
                    xs={4}
                    align="left"
                    className={classes.checkboxGrid}
                    key={index}
                  >
                    <FormControlLabel
                      classes={{
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          disableRipple
                          disabled={
                            chkitem.name === 'OHI 4.0 Test Module'
                              ? ohiUpdateDataStatus.toJS().isLoading
                              : false
                          }
                          onChange={e => {
                            const ohiData =
                              ohiTestModuleData.toJS().items.Values || []
                            const {
                              target: { checked },
                            } = e
                            const leadership = 'Inclusive leadership',
                              work = 'Inclusive work environment'
                            //influencer
                            if (chkitem.name === 'Influencer') {
                              if (!checked) {
                                setReset(true)
                                onUpdateInfluencerFn({
                                  additionalQuestionText: '',
                                  isOptout: 0,
                                  isRequired: 0,
                                  nominateMax: 10,
                                  nominateMin: 1,
                                  surveyId: params,
                                  type: 1,
                                })
                              } else {
                                setReset(false)
                              }
                            }
                            //inclusion
                            if (chkitem.name === 'Inclusion') {
                              setIsInclusion(checked)
                              if (!checked) {
                                setReset(true)
                                onUpdateInclusionFn({
                                  surveyId: params,
                                  type: 0,
                                })
                              } else {
                                setReset(false)
                                const flag =
                                  checked &&
                                  (getNameSelected(leadership, true, ohiData) ||
                                    getNameSelected(work, true, ohiData))
                                if (flag && ohiVersionId === 1) {
                                  addToast(
                                    'Similar questions already selected under "OHI 4.0 test module". Are you sure you want to still proceed with selections made?',
                                    {
                                      appearance: 'error',
                                      autoDismiss: true,
                                    }
                                  )
                                }
                              }
                            }
                            if (
                              chkitem.name ===
                              'Employee Experience (3.1 Version)'
                            ) {
                              setIsExModule(e.target.checked)
                              if (!checked) {
                                setReset(true)
                                onUpdateworkexFn({
                                  surveyId: Number(params),
                                  type: 0,
                                })
                              } else {
                                setReset(false)
                                if (
                                  checked &&
                                  getNameSelected(
                                    'Individual Experience',
                                    true,
                                    ohiData
                                  ) &&
                                  ohiVersionId === 1
                                ) {
                                  addToast(
                                    'Similar questions already selected under "OHI 4.0 test module"',
                                    {
                                      appearance: 'error',
                                      autoDismiss: true,
                                    }
                                  )
                                }
                              }
                            }
                            if (chkitem.name === 'OHI 4.0 Test Module') {
                              setIsTestModule(e.target.checked)
                              // if OHI test module radio is unchecked
                              if (!e.target.checked) {
                                setReset(true)
                                resetOhiTestModule()
                              } else {
                                setReset(false)
                              }
                            }
                            handlDeepDiveChange(index, chkitem.name)
                          }}
                          classes={{
                            checked: classes.checked,
                          }}
                          disableRipple
                          checked={useForm.deepDive[index].isSelected || false}
                          icon={
                            <CircleUnchecked
                              className={classes.checkboxIconUnchecked}
                            />
                          }
                          checkedIcon={
                            <CircleCheckedFilled
                              className={classes.checkboxIcon}
                            />
                          }
                          style={{ fontSize: 20 }}
                          inputProps={{
                            'aria-label': chkitem.name,
                          }}
                        />
                      }
                      label={
                        <Box>
                          {chkitem.name}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Influencer' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}

                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Values' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name ==
                            'Employee Experience (3.1 Version)' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Inclusion' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'OHI 4.0 Test Module' ? (
                            <Box
                              component="button"
                              className={classes.deepdiveEdit}
                              onClick={() => handleEditDeepdive(chkitem.name)}
                            >
                              <Box component="img" src={editIconNoShadow}></Box>
                            </Box>
                          ) : null}

                          {errors.isOptout &&
                          useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Influencer' ? (
                            <FormHelperText
                              id="my-helper-text-isOptout"
                              className={classes.myhelptext}
                            >
                              {errors.isOptout}
                            </FormHelperText>
                          ) : null}
                          {useForm.deepDive[index].isSelected &&
                          chkitem.name == 'OHI 4.0 Test Module' ? (
                            <FormHelperText
                              id="my-helper-text-type"
                              className={classes.myhelptext}
                            >
                              {errors.type}
                            </FormHelperText>
                          ) : null}

                          {errors.type &&
                          useForm.deepDive[index].isSelected &&
                          chkitem.name == 'Inclusion' ? (
                            <FormHelperText
                              id="my-helper-text-type"
                              className={classes.myhelptext}
                            >
                              {errors.type}
                            </FormHelperText>
                          ) : null}
                        </Box>
                      }
                    />
                  </Grid>
                )
              }
            })}
          </Grid>
        </Box>
      </ThemeProvider>
    )
    function handleEditDeepdive(name) {
      if (name === 'Influencer') {
        SetisEditInfluencer(true)
      } else if (name === 'Values') {
        SetisEditValues(true)
      } else if (name === 'Inclusion') {
        setIsEditInclusion(true)
      } else if (name === 'Employee Experience (3.1 Version)') {
        SetisEmployeeExperience(true)
      } else if (name === 'OHI 4.0 Test Module') {
        setIsEditTestModule(true)
      }
    }
  }
  function addQuestion(cname) {
    onUpdateDesignSurvey(useForm)
    setAddquestion(true)
    /* return function() {

    } */
  }

  function renderOptionalquestions() {
    return (
      <ThemeProvider theme={outerTheme}>
        <Box className={classes.root + ' design-survey-box-wrapper'}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionTitle}
            style={{ position: 'relative' }}
            id="additionalquestion"
          >
            Optional Questions
          </Typography>
          {useForm.optionalQuestion && useForm.optionalQuestion.length ? (
            <Grid container spacing={0} className={classes.checkboxContainer}>
              <Optionalquestions
                data={useForm}
                dataHandler={setUseForm}
                ohi4Module={ohi4Module}
              />
            </Grid>
          ) : (
            <Grid container spacing={0} className={classes.checkboxContainer}>
              <Typography>No optional questions found.</Typography>
            </Grid>
          )}
        </Box>
      </ThemeProvider>
    )
  }

  function renderStandardQuestion() {
    return (
      <ThemeProvider theme={outerTheme}>
        <Box className={classes.root + ' design-survey-box-wrapper'}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionTitle}
            style={{ position: 'relative' }}
            id="additionalquestion"
          >
            Standard Demographics
          </Typography>
          {useForm.optionalQuestion && useForm.optionalQuestion.length ? (
            <Grid container spacing={0} className={classes.checkboxContainer}>
              <StandardDemoquestions
                data={useForm?.standardDemographics}
                dataHandler={setUseForm}
                useForm={useForm}
              />
            </Grid>
          ) : (
            <Grid container spacing={0} className={classes.checkboxContainer}>
              <Typography>No standard demographics found.</Typography>
            </Grid>
          )}
        </Box>
      </ThemeProvider>
    )
  }

  function renderSensitiveDemographics() {
    return (
      <ThemeProvider theme={outerTheme}>
        <Box className={classes.root + ' design-survey-box-wrapper'}>
          <Grid container spacing={0} className={classes.checkboxContainer}>
            <Grid
              item
              xs={12}
              align="left"
              className={classes.checkboxGrid}
              key={1}
            >
              <Accordion classes={{ root: classes.accordionRoot }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{ root: classes.accordionSummaryRoot }}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{ gap: '10px' }}
                  >
                    <Checkbox
                      disabled={false}
                      checked={Boolean(useForm.sensitiveDemographics)}
                      onChange={handleSensitive}
                      disableRipple
                      classes={{
                        checked: classes.checked,
                      }}
                      icon={
                        <CheckBoxOutlineBlankIcon
                          className={classes.checkboxIconUnchecked}
                        />
                      }
                      checkedIcon={
                        <CheckBoxIcon className={classes.checkboxIcon} />
                      }
                      style={{
                        fontSize: 20,
                        height: 0,
                        width: 0,
                        padding: 16,
                        background: 'none',
                      }}
                      inputProps={{
                        'aria-label': 'Preview',
                      }}
                    />
                    <Typography>
                      In order to include sensitive demographics in this survey,
                      please mark it here. The sensitive demographics will be
                      created on the separate page at the end of the survey,
                      including the following instruction:
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetail }}>
                  <Grid
                    container
                    spacing={0}
                    className={classes.checkboxContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      align="left"
                      className={classes.sensitiveDDataPreview}
                      key={2}
                      style={{ color: '#999', marginBottom: '10px' }}
                    >
                      <i>
                        The following questions are entirely optional and may
                        collect sensitive personal information. We are
                        collecting this data to better understand the
                        experiences of colleagues with different identities and
                        backgrounds. If you prefer not to answer these
                        questions, you can select “Prefer not to answer” and
                        proceed to the next question.
                      </i>
                      <br />
                    </Grid>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{ gap: '10px' }}
                    >
                      <Checkbox
                        classes={{
                          checked: classes.checked,
                        }}
                        disabled={true}
                        disableRipple
                        icon={
                          <CheckBoxOutlineBlankIcon
                            className={classes.checkboxIconUnchecked}
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon className={classes.checkboxIcon} />
                        }
                        style={{ fontSize: 20, background: 'none' }}
                        inputProps={{
                          'aria-label': 'Sensitive Demographics in a survey',
                        }}
                      />
                      <Typography>
                        <i style={{ color: '#999' }}>
                          By clicking this box, I consent to the processing of
                          my sensitive personal data as included in the above
                          questions for the purposes described in the Survey
                          Notice.
                        </i>
                      </Typography>
                    </Box>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    )
  }

  function renderAdditionalquestion() {
    const handlAddQuestionChange = itemIndex => {
      const toggledItem = { ...useForm.question[itemIndex] }
      toggledItem.isSelected = !toggledItem.isSelected
      setUseForm({
        ...useForm,
        question: [
          ...useForm.question.slice(0, itemIndex),
          toggledItem,
          ...useForm.question.slice(itemIndex + 1),
        ],
      })
    }
    const selectallQuestion = () => {
      let slall = []
      if (!isAllselectedQuestion) {
        slall = useForm.question.map(function(x) {
          x.isSelected = true
          return x
        })
      } else {
        slall = useForm.question.map(function(x) {
          x.isSelected = false
          return x
        })
      }

      setUseForm({ ...useForm, question: slall })
    }
    function handleQuestionOrder() {
      //setAddquestionLoading(true)
      onUpdateDesignSurvey(useForm)
      setQuestioorder(true)
    }

    return (
      <ThemeProvider theme={outerTheme}>
        <Box className={classes.root + ' design-survey-box-wrapper'}>
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionTitle}
            style={{ position: 'relative' }}
            id="additionalquestion"
          >
            Additional Questions
            <Button
              variant="outlined"
              color="primary"
              size="small"
              className={clsx({
                [classes.allselected]: isAllselectedQuestion,
                [classes.selectAll]: true,
              })}
              onClick={selectallQuestion}
            >
              {isAllselectedQuestion ? 'Unselect All' : 'Select All'}
            </Button>
            <Box
              component="span"
              className={
                isButtonToBeDisabled
                  ? classes.headingbtnsectionCDisabled
                  : classes.headingbtnsectionC
              }
              style={{ right: '50px' }}
              onClick={() => handleQuestionOrder()}
            >
              <Box
                component="img"
                src={editIcon}
                className={classes.circleicon}
                color="inherit"
              />
            </Box>
            <Box
              component="span"
              className={
                isButtonToBeDisabled
                  ? classes.headingbtnsectionCDisabled
                  : classes.headingbtnsectionC
              }
              style={{ boxShadow: 'none' }}
              onClick={() => addQuestion('additional')}
            >
              <Box
                component="img"
                src={AddIcon}
                className={classes.circleicon}
                color="inherit"
              />
            </Box>
          </Typography>
          <Box className={classes.subtitle}>
            Please refer to our question bank for suggestions (by category)
            <a
              href={ADDITIONAL_QUESTION_SUBTITLE_ANCHOR}
              target="_blank"
              className={classes.subtitleAnchorTag}
            >
              HERE
            </a>
          </Box>
          <Grid container spacing={3} className={classes.checkboxContainer}>
            {useForm.question.map((chkitem, index) => {
              const questionlist = chkitem && chkitem.questionText
              const aa =
                questionlist &&
                questionlist.map((item, index) => {
                  return questionlist.length > 1 ? (
                    <Box key={index}>
                      0{index + 1}. {item}
                    </Box>
                  ) : (
                    <Box key={index}>
                      {item}
                      <div>
                        <em style={{ fontSize: 11, color: '#333' }}>
                          {chkitem.questionType == 1 &&
                          (chkitem.qid === 'single_Reflection' ||
                            chkitem.qid === 'open_ReflectionWhy')
                            ? 'Open Ended - Resurvey Question '
                            : chkitem.questionType == 1 &&
                              (chkitem.qid !== 'single_Reflection' ||
                                chkitem.qid !== 'open_ReflectionWhy')
                            ? 'Open Ended '
                            : null}
                          {chkitem.questionType == 2 &&
                          (chkitem.qid === 'single_Reflection' ||
                            chkitem.qid === 'open_ReflectionWhy')
                            ? 'Single Select - Resurvey Question '
                            : chkitem.questionType == 2 &&
                              (chkitem.qid !== 'single_Reflection' ||
                                chkitem.qid !== 'open_ReflectionWhy')
                            ? 'Single Select '
                            : null}
                          {chkitem.questionType == 3
                            ? 'Matrix/Likert Scale '
                            : null}
                          {chkitem.questionType == 4 ? 'Instructions' : null}
                          {chkitem.questionType == 4 && chkitem.qid === 'i67'
                            ? ' - Legal Text '
                            : null}
                          {chkitem.typeSuffix}
                        </em>
                      </div>
                    </Box>
                  )
                })

              return (
                <Grid
                  item
                  xs={12}
                  align="left"
                  className={`${classes.checkboxGrid} ${classes.questionlbl}`}
                  key={chkitem.id}
                >
                  <FormControlLabel
                    classes={{
                      label: classes.label,
                    }}
                    control={
                      <Checkbox
                        onChange={() => handlAddQuestionChange(index)}
                        checked={useForm.question[index].isSelected || false}
                        classes={{
                          checked: classes.checked,
                        }}
                        icon={
                          <CircleUnchecked
                            className={classes.checkboxIconUnchecked}
                          />
                        }
                        checkedIcon={
                          <CircleCheckedFilled
                            className={classes.checkboxIcon}
                          />
                        }
                        disableRipple
                        style={{ fontSize: 20, background: 'none' }}
                        inputProps={{
                          'aria-label': 'Direction',
                        }}
                      />
                    }
                    label={aa}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </ThemeProvider>
    )
  }

  function renderFormBottom() {
    function saveNContinue(moveToNextPage) {
      setOpen(false)
      TextUpdateAllowed()
      setErrors(
        designSurvey(
          useForm,
          influencer.toJS().items,
          inclusiondata.toJS().items,
          inclusiondata.toJS().workitems,
          ohiTestModuleData.toJS().items.Values
        )
      )
      if (
        Object.keys(
          designSurvey(
            useForm,
            influencer.toJS().items,
            inclusiondata.toJS().items,
            inclusiondata.toJS().workitems,
            ohiTestModuleData.toJS().items.Values
          )
        ).length === 0
      ) {
        if (!moveToNextPage) {
          setMoveToNxtPage(false)
          setSaveLoading(true)
        } else {
          setBtnLoading(true)
        }
        return setUseForm({
          ...useForm,
          isLaunch: true,
        })
      } else {
        addToast('Some required fields has not been answered.', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }

    function goToClientinfo() {
      history.push(`../survey-info/${params}`)
    }

    return (
      <Box className={classes.root}>
        <Box display="flex" className={classes.formBottom}>
          <Box
            style={{
              textAlign: 'left',
              color: '#999999',
              marginTop: -30,
              marginLeft: -40,
              fontSize: '12px',
            }}
          >
            *Kindly adjust webpage Zoom if buttons are overlapping. Currently
            webpage is not optimized for smaller screens.
          </Box>
          <Box display="flex" flex="1" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonRound}
              onClick={goToClientinfo}
            >
              Previous
            </Button>
            <Box style={{ position: 'relative' }}>
              <Button
                color="primary"
                variant="contained"
                disabled={saveLoading}
                className={
                  isButtonToBeDisabled
                    ? classes.buttonRoundDisabled
                    : classes.buttonRound
                }
                onClick={() => {
                  if (
                    empExperienceFlag &&
                    ifOhi4Selcted &&
                    useForm.surveyType == 4
                  ) {
                    setOpen(true)
                  } else {
                    saveNContinue(false)
                  }
                }}
              >
                Save
              </Button>
              {saveLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
            <Box style={{ position: 'relative' }}>
              <Button
                color="primary"
                variant="contained"
                disabled={btnLoading}
                className={
                  isButtonToBeDisabled
                    ? classes.buttonRoundDisabled
                    : classes.buttonRound
                }
                onClick={() => {
                  if (
                    empExperienceFlag &&
                    ifOhi4Selcted &&
                    useForm.surveyType == 4
                  ) {
                    setOpen(true)
                  } else {
                    saveNContinue(false)
                  }
                }}
              >
                Save & Continue
              </Button>
              {btnLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Box>
        </Box>
        {/* dialog */}
        <Dialog open={open}>
          <DialogContent>
            <DialogContentText>
              <Typography>{Emp_exp_mandate_text}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-evenly' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(false)}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {reset &&
          (influencer.get('isLoading') ||
            influencerStatus.get('isLoading') ||
            inlusiondataStaus.get('isLoading') ||
            inclusiondata.get('isLoading') ||
            ohiTestModuleData.get('isLoading') ||
            ohiUpdateDataStatus.get('isLoading') ||
            ohi4ModuleData.get('isLoading') ||
            updateohi4ModuleStatus.get('isLoading')) && (
            <PopupModal>
              <ProgressBarLoader />
            </PopupModal>
          )}
        {/* reason popup */}
        <Dialog open={reason.open} fullWidth>
          <DialogTitle>
            <Typography fontSize={15} component={'cite'} fontWeight={500}>
              {Title_exp(reason.name)}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box display="flex" flexDirection="column" gap={5}>
                <Typography variant="body2">
                  {Emp_Exp_Mandate(reason.name)}
                </Typography>
                <TextField
                  variant="standard"
                  placeholder="Write reason here"
                  name="reason"
                  onChange={({ target: { name, value } }) => {
                    setReason({ ...reason, [name]: value })
                  }}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonRound}
              disabled={!reason.reason}
              onClick={() => updateReasonOhi4ModuleData()}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonRound}
              onClick={() => {
                const clone = cloneDeep(ohi4Module)
                clone[reason.id].isSelected = true
                setOhi4Module(clone)
                setReason({ open: false, reason: '', id: '' })
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }
}

export default React.memo(Checkboxs)
