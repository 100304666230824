import { fromJS } from 'immutable'

import {
  LANGUAGES_FETCH,
  ANALYSTS_FETCH,
  COUNTRIES_FETCH,
  POPULATIONS_FETCH,
  REGIONS_FETCH,
  SURVEYSTYPES_POPULATIONS_FETCH,
  GETRESURVEY_FETCH,
  SURVEYINFO_FETCH,
  SURVEYINFO_CREATE,
  COHESION_DEMO,
  OHI_VERSION_FETCH,
} from './constants'

const INITIAL_SAVESURVEYINFO_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const savesurveyinforeducer = (
  state = INITIAL_SAVESURVEYINFO_STATE,
  { type, payload }
) => {
  //console.log(payload)
  switch (type) {
    case `${SURVEYINFO_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${SURVEYINFO_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${SURVEYINFO_CREATE}_FAILED`: {
      //const { data } = payload
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

//  sd

const INITIAL_SURVEYINFO_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const surveyinforeducer = (
  state = INITIAL_SURVEYINFO_STATE,
  { type, payload }
) => {
  //console.log(payload)
  switch (type) {
    case `${SURVEYINFO_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${SURVEYINFO_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${SURVEYINFO_FETCH}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// RESURVEY
const INITIAL_RESURVEY_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const resurveyreducer = (
  state = INITIAL_RESURVEY_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${GETRESURVEY_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${GETRESURVEY_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log('reducer', data)
      return (
        state
          /* .updateIn(['items'], items => {
         return items.concat(fromJS(data))
        }) */
          .setIn(['items'], fromJS(data))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${GETRESURVEY_FETCH}_FAILED`: {
      //const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}
// POPULATIONS
const INITIAL_survey_types_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const surveytypesreducer = (
  state = INITIAL_survey_types_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${SURVEYSTYPES_POPULATIONS_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${SURVEYSTYPES_POPULATIONS_FETCH}_SUCCESS`: {
      const { data } = payload
      return (
        state
          /* .updateIn(['items'], items => {
          return items.concat(fromJS(data.items))
        }) */
          .setIn(['items'], fromJS(data.items))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${SURVEYSTYPES_POPULATIONS_FETCH}_FAILED`: {
      //const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// POPULATIONS
const INITIAL_REGIONS_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const regionsreducer = (
  state = INITIAL_REGIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${REGIONS_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${REGIONS_FETCH}_SUCCESS`: {
      const { data } = payload
      return (
        state
          /* .updateIn(['items'], items => {
          return items.concat(fromJS(data.items))
        }) */
          .setIn(['items'], fromJS(data.items))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${REGIONS_FETCH}_FAILED`: {
      //const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// POPULATIONS
const INITIAL_POPULATIONS_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const populationsreducer = (
  state = INITIAL_POPULATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${POPULATIONS_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${POPULATIONS_FETCH}_SUCCESS`: {
      const { data } = payload
      return (
        state
          /* .updateIn(['items'], items => {
          return items.concat(fromJS(data.items))
        }) */
          .setIn(['items'], fromJS(data.items))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${POPULATIONS_FETCH}_FAILED`: {
      // const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// COUNTRIES
const INITIAL_COUNTRIES_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const countriesreducer = (
  state = INITIAL_COUNTRIES_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${COUNTRIES_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${COUNTRIES_FETCH}_SUCCESS`: {
      const { data } = payload
      return (
        state
          /* .updateIn(['items'], items => {
          return items.concat(fromJS(data.items))
        }) */
          .setIn(['items'], fromJS(data.items))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${COUNTRIES_FETCH}_FAILED`: {
      //const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}
// LANGUAGES
const INITIAL_LANGUAGEs_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
const reducer = (state = INITIAL_LANGUAGEs_STATE, { type, payload }) => {
  switch (type) {
    case `${LANGUAGES_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${LANGUAGES_FETCH}_SUCCESS`: {
      const { data } = payload
      return (
        state
          /* .updateIn(['items'], items => {
          return items.concat(fromJS(data.items))
        }) */
          .setIn(['items'], fromJS(data.items))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${LANGUAGES_FETCH}_FAILED`: {
      //const { data } = payload
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}
// ANALYSTS
const INITIAL_ANALYSTS_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const analystsreducer = (
  state = INITIAL_ANALYSTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${ANALYSTS_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${ANALYSTS_FETCH}_SUCCESS`: {
      const { data } = payload
      return (
        state
          /* .updateIn(['items'], items => {
          return items.concat(fromJS(data.items))
        }) */
          .setIn(['items'], fromJS(data.items))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${ANALYSTS_FETCH}_FAILED`: {
      // const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

const INITIAL_COHESION_DEMO = fromJS({
  isFetched: false,
  isLoading: false,
  items: [],
})

export const cohesionDemoReducer = (state = INITIAL_COHESION_DEMO, action) => {
  switch (action.type) {
    case `${COHESION_DEMO}_PENDING`:
      return state.setIn(['isLoading'], false)
    case `${COHESION_DEMO}_SUCCESS`:
      return state
        .setIn(['isLoading'], false)
        .setIn(['isFetched'], true)
        .setIn(['items'], action.payload)
    case `${COHESION_DEMO}_FAILED`:
      return state
        .setIn(['isLoading'], false)
        .setIn(['isFetched'], true)
        .setIn(['items'], [])
    default:
      return state
  }
}

// OHI Version List
const INITIAL_OHI_VERSION_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const OhiVersionreducer = (
  state = INITIAL_OHI_VERSION_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${OHI_VERSION_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${OHI_VERSION_FETCH}_SUCCESS`: {
      const { data } = payload
      return state
        .setIn(['items'], fromJS(data))
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${OHI_VERSION_FETCH}_FAILED`: {
      // const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

const INITIAL_FETCH_ALL_LANG_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const ClosingProtocolLanguagesReducer = (
  state = INITIAL_FETCH_ALL_LANG_STATE,
  { type, payload }
) => {
  switch (type) {
    case `FETCH_ALL_LANG_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `FETCH_ALL_LANG_SUCCESS`: {
      const { data } = payload
      return state
        .setIn(['items'], fromJS(data.items))
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `FETCH_ALL_LANG_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

export default reducer
