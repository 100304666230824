import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'

export const ProjectInfoTable = ({ data, classes, commonStyles }) => {
  return (
    <Table className={classes.surveyTable} size="medium">
      <TableBody>
        <TableRow key={`trow_1`}>
          <TableCell
            classes={{
              body: commonStyles.tabelCell,
            }}
            key={`tcell_1`}
          >
            Project Id:
          </TableCell>
          <TableCell>{data.pid || '-'}</TableCell>
        </TableRow>
        <TableRow key={`trow_2`}>
          <TableCell
            classes={{
              body: commonStyles.tabelCell,
            }}
            key={`tcell_2`}
          >
            Project Name:
          </TableCell>
          <TableCell>{data.projectName || '-'}</TableCell>
        </TableRow>
        <TableRow key={`trow_3`}>
          <TableCell
            classes={{
              body: commonStyles.tabelCell,
            }}
            key={`tcell_3`}
          >
            Project Status:
          </TableCell>
          <TableCell>{data.porjectStatus || '-'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export const RecordFileTable = ({ data, classes, commonStyles }) => {
  return (
    <Table className={classes.surveyTable} size="medium">
      <TableHead>
        <TableRow>
          <TableCell key={`thead_1`}>S. No.</TableCell>
          <TableCell key={`thead_2`}>Variable/Excel column header</TableCell>
          <TableCell key={`thead_3`}>Comments</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.lstResult.map(({ Key = '', Value = '' }, i) => (
          <TableRow key={i}>
            <TableCell key={i + 1}>{i + 1}</TableCell>
            <TableCell key={Key}>{Key || '-'}</TableCell>
            <TableCell key={Value}>{Value || '-'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
