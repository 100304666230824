import {
  DEEP_DIVE,
  DEEP_DIVE_SUCCESS,
  DEEP_DIVE_ERORR,
  DEEP_DIVE_LANG,
  DEEP_DIVE_LANG_SUCCESS,
  DEEP_DIVE_LANG_ERORR,
  UPDATE_SURVEY_DEEP_DIVE,
  REPLACE_EVERYWHERE,
} from './constants'

export const deepDiveFetch = ({ surveyId, outcomeId }) => {
  return {
    type: DEEP_DIVE,
    surveyId,
    outcomeId,
  }
}

export const deepDiveSuccess = data => ({
  type: DEEP_DIVE_SUCCESS,
  data,
})

export const deepDiveFetchError = () => ({
  type: DEEP_DIVE_ERORR,
})

export const deepDiveLangFetch = ({ surveyId, outcomeId, languageId }) => {
  return {
    type: DEEP_DIVE_LANG,
    surveyId,
    outcomeId,
    languageId,
  }
}

export const deepDiveLangSuccess = ({ data, languageId, outcomeId }) => ({
  type: DEEP_DIVE_LANG_SUCCESS,
  data,
  languageId,
  outcomeId,
})

export const deepDiveFetchLangError = error => ({
  type: DEEP_DIVE_LANG_ERORR,
  error,
})

export const updateSurvey = (
  editorState,
  surveyId,
  languageId,
  isSync,
  isMultiLanguage,
  outcomeId
) => {
  return {
    type: UPDATE_SURVEY_DEEP_DIVE,
    editorState,
    surveyId,
    languageId,
    isSync,
    outcomeId,
    isMultiLanguage,
  }
}

export function replaceEverywhere({
  ignoreCase,
  selectedLanguageId,
  replaceText,
  findText,
  toggleState,
  outcomeId,
}) {
  return {
    type: REPLACE_EVERYWHERE,
    isCaseSensitive: !ignoreCase,
    replaceFrom: findText,
    replaceTo: replaceText,
    langId: selectedLanguageId,
    toggleState,
    outcomeId,
  }
}
