import {
  SURVEYSNAME_FETCH,
  SURVEYSNAME_CREATE,
  TOGGLE_SUMMARY_OF_EDITS,
  TOGGLE_CONFIRMATION_POP_UP,
  PROGRESS_BAR_LOADER,
  SAVE_SUMMARY_OF_EDITS_FOR_DIFFERENT_LANG,
  SAVE_SUMMARY_OF_EDITS,
  ADMIN_LOCK_UNLOCK_SURVEY,
  UNLOCK_USER_LOCKED_SURVEY,
  RESET_ERROR_STATE,
  TOGGLE_TOAST_ON_SUCCESS,
  GET_GSDB,
  GET_GSDB_SUCCESS,
  POST_GSDB,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS,
  DELETE_USER,
  RESET_GSDB_DETAILS,
  UPLOAD_USER_TEMPLATE,
  DOWNLOAD_USER_TEMPLATE,
  EXPORT_USERS,
  GET_FILTER,
  FETCH_SURVEYS,
  UPLOAD_BACKCODING,
  DOWNLOAD_MAPPING,
  GENERATE_LOGS,
  TASK_LOGS,
  RESET_DATA,
  RESET_TASK_DATA,
  RESET_TASK_ID,
} from './constants'

export const surveynameFetch = payload => ({
  type: `${SURVEYSNAME_FETCH}_PENDING`,
  payload,
})

export const surveynameFetchSuccess = payload => ({
  type: `${SURVEYSNAME_FETCH}_SUCCESS`,
  payload,
})

export const surveynameFetchFailed = () => ({
  type: `${SURVEYSNAME_FETCH}_FAILED`,
})

// SURVEY NAME UPDATE
export const surveynameCreate = payload => ({
  type: `${SURVEYSNAME_CREATE}_PENDING`,
  payload,
})

export const surveynameCreateSuccess = payload => ({
  type: `${SURVEYSNAME_CREATE}_SUCCESS`,
  payload,
})

export const surveynameCreateFailed = () => ({
  type: `${SURVEYSNAME_CREATE}_FAILED`,
})

export const saveSummaryOfEdits = (data, isSync) => ({
  type: SAVE_SUMMARY_OF_EDITS,
  summary: data,
  isSync,
})

export const toggleSummaryOfEdits = () => ({
  type: TOGGLE_SUMMARY_OF_EDITS,
})

export const saveSummaryOfEditsForDifferentLang = (
  data,
  languageId,
  isSync
) => ({
  type: SAVE_SUMMARY_OF_EDITS_FOR_DIFFERENT_LANG,
  summary: data,
  languageId,
  isSync,
})

export const confirmationPopupToggle = (
  methodToExecute,
  methodArgumentToPass
) => {
  return {
    type: TOGGLE_CONFIRMATION_POP_UP,
    methodToExecute,
    methodArgumentToPass,
  }
}

export const progressBarLoaderToggle = (loaderState, text) => {
  return {
    type: PROGRESS_BAR_LOADER,
    loaderState,
    text,
  }
}

export const adminLockUnLockSurvey = (surveyId, lockState) => {
  return {
    type: ADMIN_LOCK_UNLOCK_SURVEY,
    surveyId,
    lockState,
  }
}

export const unlockUserLockedSurvey = () => {
  return {
    type: UNLOCK_USER_LOCKED_SURVEY,
  }
}

export const resetErrorState = () => {
  return {
    type: RESET_ERROR_STATE,
  }
}

export const toggleToastOnSuccess = ({ successText, openToast }) => ({
  type: TOGGLE_TOAST_ON_SUCCESS,
  openToast,
  successText,
})

export const getGSDBDetails = surveyId => {
  return {
    type: GET_GSDB,
    surveyId,
  }
}

export const getGSDBDetailsSuccess = data => {
  return {
    type: GET_GSDB_SUCCESS,
    data,
  }
}

export const postGSDBDetails = (payload, surveyId) => {
  return {
    type: POST_GSDB,
    payload,
    surveyId,
  }
}

export const getUserDetails = () => {
  return {
    type: GET_USER_DETAILS,
  }
}

export const getUserDetailsSuccess = data => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    data,
  }
}

export const failedGetUserDetails = data => ({
  type: `${GET_USER_DETAILS}_FAILED`,
  data,
})

export const updateUserDetails = payload => ({
  type: `${UPDATE_USER_DETAILS}_PENDING`,
  payload,
})

export const successUpdateUserDetails = data => ({
  type: `${UPDATE_USER_DETAILS}_SUCCESS`,
  data,
})

export const failedUpdateUserDetails = data => ({
  type: `${UPDATE_USER_DETAILS}_FAILED`,
  data,
})

export const deleteUser = userId => ({
  type: `${DELETE_USER}_PENDING`,
  userId,
})

export const resetGSDBDetails = () => ({
  type: RESET_GSDB_DETAILS,
})

export const uploadUsers = file => ({
  type: `${UPLOAD_USER_TEMPLATE}_PENDING`,
  file,
})

export const successUploadUsers = () => ({
  type: `${UPLOAD_USER_TEMPLATE}_SUCCESS`,
})

export const failedUploadUsers = data => ({
  type: `${UPLOAD_USER_TEMPLATE}_FAILED`,
  data,
})

export const downloadUserTemplate = () => ({
  type: `${DOWNLOAD_USER_TEMPLATE}_PENDING`,
})

export const successDownloadUserTemplate = () => ({
  type: `${DOWNLOAD_USER_TEMPLATE}_SUCCESS`,
})

export const failedDownloadUserTemplate = data => ({
  type: `${DOWNLOAD_USER_TEMPLATE}_FAILED`,
  data,
})

export const exportUsers = () => ({
  type: `${EXPORT_USERS}_PENDING`,
})

export const exportUsersSuccess = () => ({
  type: `${EXPORT_USERS}_SUCCESS`,
})

export const exportUsersFailed = data => ({
  type: `${EXPORT_USERS}_FAILED`,
  data,
})

export const fetchReplaceWordPending = () => ({
  type: `FETCH_REPLACE_WORD_PENDING`,
})

export const fetchReplaceWordSuccess = data => ({
  type: `FETCH_REPLACE_WORD_SUCCESS`,
  data,
})

export const fetchFilterAction = () => ({
  type: `${GET_FILTER}_PENDING`,
})

export const fetchFilterSuccessAction = data => ({
  type: `${GET_FILTER}_SUCCESS`,
  data,
})

export const getSurveyDataPendingAction = filterParams => ({
  type: `${FETCH_SURVEYS}_PENDING`,
  filterParams,
})

export const getSurveyDataSuccessAction = data => ({
  type: `${FETCH_SURVEYS}_SUCCESS`,
  data,
})

export const getSurveyDataFailedAction = data => ({
  type: `${FETCH_SURVEYS}_FAILED`,
  data,
})

export const uploadBackcodingPendingAction = payload => ({
  type: `${UPLOAD_BACKCODING}_PENDING`,
  payload,
})

export const uploadBckcodingSuccessAction = data => ({
  type: `${UPLOAD_BACKCODING}_SUCCESS`,
  data,
})

export const uploadBckcodingFailedAction = error => ({
  type: `${UPLOAD_BACKCODING}_FAILED`,
  error,
})

export const downloadMappingFilePending = payload => ({
  type: `${DOWNLOAD_MAPPING}_PENDING`,
  payload,
})

export const downloadMappingFileSuccess = data => ({
  type: `${DOWNLOAD_MAPPING}_SUCCESS`,
  data,
})

export const downloadMappingFileFailed = error => ({
  type: `${DOWNLOAD_MAPPING}_FAILED`,
  error,
})

export const generateLogsPendingAction = payload => ({
  type: `${GENERATE_LOGS}_PENDING`,
  payload,
})

export const generateLogsSuccessAction = data => ({
  type: `${GENERATE_LOGS}_SUCCESS`,
  data,
})

export const generateLogsFaileAction = data => ({
  type: `${GENERATE_LOGS}_FAILED`,
  data,
})

export const GenerateTaskLogsPendingAction = payload => ({
  type: `${TASK_LOGS}_PENDING`,
  payload,
})

export const GenerateTaskLogsSuccessAction = data => ({
  type: `${TASK_LOGS}_SUCCESS`,
  data,
})

export const ResetBackcodingData = () => ({
  type: `${RESET_DATA}`,
})

export const ResetLogsData = () => ({
  type: `${RESET_TASK_DATA}`,
})

export const ResetTaskId = () => ({
  type: `${RESET_TASK_ID}`,
})
