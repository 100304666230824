import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import useStyles from './styles'
import { connect } from 'react-redux'

const ProgressBarLoader = ({ loaderText }) => {
  const classes = useStyles()

  const progress = React.useRef(() => {})
  const [completed, setCompleted] = React.useState(0)
  const [buffer, setBuffer] = React.useState(10)

  useEffect(() => {
    progress.current = () => {
      if (completed > 100) {
        setCompleted(0)
        setBuffer(10)
      } else {
        const diff = Math.random() * 10
        const diff2 = Math.random() * 10
        setCompleted(completed + diff)
        setBuffer(completed + diff + diff2)
      }
    }
  })

  useEffect(() => {
    function tick() {
      progress.current()
    }
    const timer = setInterval(tick, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <Box>
      <h4 className={classes.modalTitle}>{loaderText}</h4>
      <LinearProgress style={{ height: '0.85vh', borderRadius: '10px' }} />
      <h4 className={classes.loadingText}>Loading...</h4>
    </Box>
  )
}

const mapStateToProps = state => {
  return {
    loaderText: state.get('progressBarLoader').loaderText,
  }
}

export default connect(mapStateToProps)(ProgressBarLoader)
