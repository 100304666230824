import { fromJS } from 'immutable'
import {
  CLIENTS_FETCH,
  CLIENTS_SURVEY_FETCH,
  CLIENTINFO_FETCH,
  SAVECLIENTIFO_CREATE,
  SURVEYNAME_FETCH,
  SURVEYNAMEUPDATE_CREATE,
  CLIENTS_CREATE,
  GETOPPORTUNITY_FETCH,
} from './constants'

// CLIENTINFO FETCH
const INITIAL_OPPORTUNITY_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const getopportunityReducer = (
  state = INITIAL_OPPORTUNITY_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${GETOPPORTUNITY_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${GETOPPORTUNITY_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${GETOPPORTUNITY_FETCH}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// creare new survey
const INITIAL_CREATENEWCLIENT_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const createnewclienteducer = (
  state = INITIAL_CREATENEWCLIENT_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${CLIENTS_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${CLIENTS_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${CLIENTS_CREATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// SURVE NAME FETCH
const INITIAL_SURVEYNAME_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const surveynamereducer = (
  state = INITIAL_SURVEYNAME_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${SURVEYNAME_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${SURVEYNAME_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${SURVEYNAME_FETCH}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}
// SURVEY NAME UPDATE
const INITIAL_SURVEYNAMEUPDATE_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const surveynameupdatereducer = (
  state = INITIAL_SURVEYNAMEUPDATE_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${SURVEYNAMEUPDATE_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${SURVEYNAMEUPDATE_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload

      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${SURVEYNAMEUPDATE_CREATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}

// SAVE CLIENT IFNO
const INITIAL_SAVECLIENTINFO_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const saveclientinforeducer = (
  state = INITIAL_SAVECLIENTINFO_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${SAVECLIENTIFO_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${SAVECLIENTIFO_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log('kausar', data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${SAVECLIENTIFO_CREATE}_FAILED`: {
      // const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}

// CLIENTINFO FETCH
const INITIAL_CLIENTINFO_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const clientinforeducer = (
  state = INITIAL_CLIENTINFO_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${CLIENTINFO_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${CLIENTINFO_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${CLIENTINFO_FETCH}_FAILED`: {
      // const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// CLIENT FETCH
const INITIAL_CLIENTS_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
const reducer = (state = INITIAL_CLIENTS_STATE, { type, payload }) => {
  switch (type) {
    case `${CLIENTS_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${CLIENTS_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      return state
        .updateIn(['items'], items => {
          return fromJS(data.items)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${CLIENTS_FETCH}_FAILED`: {
      // const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

const INITIAL_CLIENTS_SURVEY_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})

export const surveyReducer = (
  state = INITIAL_CLIENTS_SURVEY_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${CLIENTS_SURVEY_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${CLIENTS_SURVEY_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${CLIENTS_SURVEY_FETCH}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

export default reducer
