import React, { useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import crossIcon from '../../assets/crossIcon.svg'

import { confirmationPopupToggle } from '../../common/actions'
import { resetUpdateDemographicData } from '../../pages/Demographics/redux/actions'

const ConfirmationPopupComponent = props => {
  const {
    confirmationPopupToggle,
    methodArgumentToPass,
    methodToExecute,
    updateSurvey,
    editorState,
    surveyId,
    selectedLanguageId,
    hasUserSelcetdMultiLangOption,
    outcomeId,
    isDemographicPage,
    demographicData,
    updateDemographic,
    isBaseLang,
    isBenchMarkSection,
    updateDemographicsData,
    resetUpdateDemographicData,
  } = props

  const isSync = false

  useEffect(() => {
    if (isDemographicPage && updateDemographicsData.isSuccess) {
      methodToExecute(methodArgumentToPass)
      confirmationPopupToggle()
      resetUpdateDemographicData()
    }
  }, [updateDemographicsData])

  const handleClickOnCross = () => {
    confirmationPopupToggle()
  }

  const handleClickOnLeaveSection = () => {
    confirmationPopupToggle()
    methodToExecute(methodArgumentToPass)
  }

  const handleClickOnSaveNContinue = () => {
    isDemographicPage
      ? updateDemographic(
          demographicData,
          surveyId,
          selectedLanguageId,
          isBaseLang,
          true
        )
      : updateSurvey(
          editorState,
          surveyId,
          selectedLanguageId,
          isSync,
          hasUserSelcetdMultiLangOption,
          outcomeId
        )
    if (!isDemographicPage) {
      methodToExecute(methodArgumentToPass)
      confirmationPopupToggle()
    }
  }
  return (
    <div className="confirmation_popup">
      <img
        className="cross_icon"
        src={crossIcon}
        alt=""
        onClick={handleClickOnCross}
      />
      <span className="confirmation_popup_subtext">
        {!isBenchMarkSection
          ? 'Are you sure you want to leave this section?'
          : 'Are you sure you want to leave benchmarking?'}
      </span>
      <span className="confirmation_popup_subtext">
        {!isBenchMarkSection
          ? 'Changes you made may not be saved.'
          : 'Changing sections now will result in the loss of benchmarking tags.'}
      </span>
      <div
        className={`button_container ${
          isBenchMarkSection ? 'custom_width' : ''
        }`}
      >
        <Button
          variant="outlined"
          color="primary"
          className="round_button"
          onClick={
            !isBenchMarkSection ? handleClickOnLeaveSection : handleClickOnCross
          }
        >
          {!isBenchMarkSection ? 'Leave Section' : 'Cancel'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="round_button round_button_container"
          onClick={
            !isBenchMarkSection
              ? handleClickOnSaveNContinue
              : handleClickOnLeaveSection
          }
        >
          {!isBenchMarkSection ? 'Save and Continue' : 'Go, Ahead'}
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    methodArgumentToPass: state.get('confirmationPop')['methodArgumentToPass'],
    methodToExecute: state.get('confirmationPop')['methodToExecute'],
    updateDemographicsData: state.get('demographicsReducer')
      .updateDemographicsData,
  }
}

const mapDispatchToProps = {
  confirmationPopupToggle,
  resetUpdateDemographicData,
}

const ConfirmationPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationPopupComponent)

export default ConfirmationPopup
