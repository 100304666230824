import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(6),
      fontSize: 12,
      color: '#333333',
      paddingBottom: '110px',
      position: 'relative',
    },
    tooltipicon: {
      width: '11px',
      marginLeft: '10px',
      marginTop: -3,
    },
    myhelptextLanguage: {
      color: '#999',
      position: 'absolute',
      right: 0,
      top: 0,
      marginTop: 0,
    },
    leftSection: {
      marginRight: theme.spacing(10),
    },
    inputSet: {
      marginBottom: theme.spacing(5),
    },
    inputLabel: {
      color: '#999999',
      marginBottom: theme.spacing(1),
    },
    fullWidth: {
      width: '100%',
    },
    formBottom: {
      borderTop: '1px solid #dddddd',
      padding: theme.spacing(4, 5),
      position: 'absolute',
      width: 'calc(100% - 96px)',
      bottom: 0,
      left: '48px',
    },
    buttonRound: {
      borderRadius: '25px',
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: 14,
    },
    buttonRoundDisabled: {
      borderRadius: '25px',
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: 14,
      pointerEvents: 'none',
      opacity: 0.4,
    },
    icon: {
      fontSize: '18px',
      marginRight: '5px',
    },
    addnewbtn: {
      position: 'absolute',
      right: 0,
      top: '9px',
      fontWeight: 700,
      color: '#4b93fd',
      fontSize: '14px;',
      backgroundColor: '#fff',
      '&:focus': {
        outline: 'none',
      },
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
    myhelptext: {
      color: '#ff0000',
    },
    buttonProgress: {
      color: '#4b93fd',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    clearBtn: {
      position: 'absolute',
      right: '115px',
      top: '6px',
      bottom: 0,
      margin: 'auto',
      height: '20px',
      width: '20px',
      cursor: 'pointer',
    },
    closeModal: {
      position: 'absolute',
      right: -12,
      top: -12,
      cursor: 'pointer',
    },
    dialogItem: {
      borderTop: 'solid 1px #ddd',
      '& *': {
        fontSize: 14,
      },
    },
    dialogTitle: {
      '& h6': {
        textAlign: 'center',
        lineHeight: '1.3',
        fontSize: 16,
      },
    },
  }
})

export default styles
