import { call, put, takeLatest } from 'redux-saga/effects'

import {
  getEmailTemplateSuccess,
  getEmailTemplateFailed,
  lockEmailTemplateSuccess,
  lockEmailTemplateFailed,
  getSingleEmailTemplateSuccess,
  getSingleEmailTemplateFailed,
  updatetemplateSuccess,
  updatetemplateFailed,
  duplicatempFailed,
  duplicatempSuccess,
  sendEmailSuccess,
  sendEmailFailed

} from './actions'
import {
  EMAIL_TEMPLATE_FETCH,
  LOCK_TEMPLATE,
  EMAIL_SINGLE_TEMPLATE_FETCH,
  UPDATE_TEMPLATE,
  DUPLICATE_TEMPLATE,
  SEND_TEST_EMAIL
} from './constants'
import {
  emailTemplateapi,
  lockTemplateapi,
  singleEmailTemplateapi,
  updateTemplateapi,
  duplicatetemplateapi,
  sendPreviewEmailapi
} from './apis'


function* getemailTemplatefn({ payload }) {
  try {
    const response = yield call(emailTemplateapi, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(getEmailTemplateSuccess(data))
    } else {
      yield put(getEmailTemplateFailed(response))
    }
  } catch (e) {
    yield put(getEmailTemplateFailed(e.response))
  }
}
function* getSingleemailTemplatefn({ payload }) {
  try {
    const response = yield call(singleEmailTemplateapi, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(getSingleEmailTemplateSuccess(data))
    } else {
      yield put(getSingleEmailTemplateFailed(response))
    }
  } catch (e) {
    yield put(getSingleEmailTemplateFailed(e.response))
  }
}
function* locktemplatefn({ payload }) {
  const lockpostdata = payload.postdata
  const getemailpayload = payload.surveyId
  try {
    const response = yield call(lockTemplateapi, lockpostdata)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(lockEmailTemplateSuccess(data))
      try {
        const response = yield call(emailTemplateapi, getemailpayload)
        const data = { respData: response.data }
        if (response.status === 200) {
          yield put(getEmailTemplateSuccess(data))
        } else {
          yield put(getEmailTemplateFailed(response))
        }
      } catch (e) {
        yield put(getEmailTemplateFailed(e.response))
      }
    } else {
      yield put(lockEmailTemplateFailed(response))
    }
  } catch (e) {
    yield put(lockEmailTemplateFailed(e.response))
  }
}
function* duplicateTempfn({ payload }) {
  const duplicatedata = payload.postdata
  const getemailpayload = payload.surveyId
  try {
    const response = yield call(duplicatetemplateapi, duplicatedata)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(duplicatempSuccess(data))
      try {
        const response = yield call(emailTemplateapi, getemailpayload)
        const data = { respData: response.data }
        if (response.status === 200) {
          yield put(getEmailTemplateSuccess(data))
        } else {
          yield put(getEmailTemplateFailed(response))
        }
      } catch (e) {
        yield put(getEmailTemplateFailed(e.response))
      }
    }else{
      yield put(duplicatempFailed(data))
    }
  } catch (e) {
    yield put(duplicatempFailed(e.response))
  }
}
function* updatetemplatefn({ payload }) {
  try {
    const response = yield call(updateTemplateapi, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(updatetemplateSuccess(data))
    } else {
      yield put(updatetemplateFailed(response))
    }
  } catch (e) {
    yield put(updatetemplateFailed(e.response))
  }
}
function* sendEmailfn({ payload }) {
  try {
    const response = yield call(sendPreviewEmailapi, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(sendEmailSuccess(data))
    } else {
      yield put(sendEmailFailed(response))
    }
  } catch (e) {
    yield put(sendEmailFailed(e.response))
  }
}

const emailsagas = [
  takeLatest(`${EMAIL_TEMPLATE_FETCH}_PENDING`, getemailTemplatefn),
  takeLatest(`${LOCK_TEMPLATE}_PENDING`, locktemplatefn),
  takeLatest(`${EMAIL_SINGLE_TEMPLATE_FETCH}_PENDING`, getSingleemailTemplatefn),
  takeLatest(`${UPDATE_TEMPLATE}_PENDING`, updatetemplatefn),
  takeLatest(`${DUPLICATE_TEMPLATE}_PENDING`, duplicateTempfn),
  takeLatest(`${SEND_TEST_EMAIL}_PENDING`, sendEmailfn),
]
export default emailsagas
