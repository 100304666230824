import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ParentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #f5f9ff;
  margin-bottom: ${props =>
    props.customClassName === 'addtional-question-editor' ? '0' : '3.6vh'};
  height: 3.6vh;
`
const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 13px;
  font-family: Lato;
  font-size: 1.7vh;
  line-height: 1.75;
  color: #000000;
`

function LeftSplitBar({ baseLanguageName, customClassName }) {
  return (
    <ParentContainer customClassName={customClassName}>
      <LanguageContainer>{baseLanguageName}</LanguageContainer>
    </ParentContainer>
  )
}

LeftSplitBar.propTypes = {
  baseLanguageName: PropTypes.string,
}

export default LeftSplitBar
