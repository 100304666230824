import React, { useEffect, useState, memo } from 'react'
import useStyles from './styles'
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  Button,
  Typography,
  createMuiTheme,
  Modal,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { ThemeProvider } from '@material-ui/styles'
import { makeStyles } from '@material-ui/core/styles'
import addIcon from '../../assets/Add_New_Icon.svg'
import reorderIcon from '../../assets/Reorder_Value.svg'
import deleteIcon from '../../assets/Cancel.svg'

//component
const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#021c4b',
    },
    secondary: {
      main: '#4b93fd',
    },
  },
})

function ValueModal({
  params,
  hideValuesModal,
  valuesdata,
  valuesdataStatus,
  onUpdateValue,
}) {
  function getModalStyle() {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    }
  }

  const useModalStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 564,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'none',
    },
    mdltitle: {
      borderBottom: 'solid 1px #f3f3f3',
      fontSize: 20,
      fontWeight: 700,
      paddingLeft: '32px',
      paddingRight: '32px',
      marginLeft: '-32px',
      marginRight: '-32px',
      paddingBottom: '25px',
    },
  }))

  const [modalStyle] = useState(getModalStyle)
  const modalClasses = useModalStyles()
  const classes = useStyles()
  const [useValues, setUseValues] = useState('')
  const [errors, setErrors] = useState({})
  const valuedatar = [
    {
      id: 4,
      name: 'Accountability',
      precode: null,
      isSelected: false,
    },
    {
      id: 5,
      name: 'Arrogant',
      precode: null,
      isSelected: true,
    },
    {
      id: 6,
      name: 'Autonomy',
      precode: null,
      isSelected: true,
    },
    {
      id: 7,
      name: 'Being collaborative',
      precode: null,
      isSelected: true,
    },
    {
      id: 8,
      name: 'Being competitive',
      precode: null,
      isSelected: true,
    },
    {
      id: 9,
      name: 'Being of service to others',
      precode: null,
      isSelected: true,
    },
    {
      id: 10,
      name: 'Being passionate',
      precode: null,
      isSelected: true,
    },
    {
      id: 11,
      name: 'Bureaucracy',
      precode: null,
      isSelected: true,
    },
    {
      id: 12,
      name: 'Caring',
      precode: null,
      isSelected: true,
    },
    {
      id: 13,
      name: 'Conflict',
      precode: null,
      isSelected: true,
    },
    {
      id: 14,
      name: 'Continuous improvement',
      precode: null,
      isSelected: true,
    },
    {
      id: 15,
      name: 'Contributing to the greater good',
      precode: null,
      isSelected: true,
    },
    {
      id: 16,
      name: 'Customer focus',
      precode: null,
      isSelected: true,
    },
    {
      id: 17,
      name: 'Efficiency',
      precode: null,
      isSelected: true,
    },
    {
      id: 18,
      name: 'Employee focus',
      precode: null,
      isSelected: true,
    },
    {
      id: 19,
      name: 'Empowerment',
      precode: null,
      isSelected: true,
    },
    {
      id: 20,
      name: 'Excellence',
      precode: null,
      isSelected: true,
    },
    {
      id: 21,
      name: 'Fear',
      precode: null,
      isSelected: true,
    },
    {
      id: 22,
      name: 'Focus on coaching/mentoring',
      precode: null,
      isSelected: true,
    },
    {
      id: 23,
      name: 'Fulfilling work',
      precode: null,
      isSelected: true,
    },
    {
      id: 24,
      name: 'Fun',
      precode: null,
      isSelected: true,
    },
    {
      id: 25,
      name: 'Having a noble purpose',
      precode: null,
      isSelected: true,
    },
    {
      id: 26,
      name: 'Hierarchical',
      precode: null,
      isSelected: true,
    },
    {
      id: 27,
      name: 'Inconsistent',
      precode: null,
      isSelected: true,
    },
    {
      id: 28,
      name: 'Innovation',
      precode: null,
      isSelected: true,
    },
    {
      id: 29,
      name: 'Inspirational',
      precode: null,
      isSelected: true,
    },
    {
      id: 30,
      name: 'Internal politics',
      precode: null,
      isSelected: true,
    },
    {
      id: 31,
      name: 'Lack of shared purpose',
      precode: null,
      isSelected: true,
    },
    {
      id: 32,
      name: 'Making a difference',
      precode: null,
      isSelected: true,
    },
    {
      id: 33,
      name: 'Openness',
      precode: null,
      isSelected: true,
    },
    {
      id: 34,
      name: 'Personal growth',
      precode: null,
      isSelected: true,
    },
    {
      id: 35,
      name: 'Professional growth',
      precode: null,
      isSelected: true,
    },
    {
      id: 36,
      name: 'Respect for people',
      precode: null,
      isSelected: true,
    },
    {
      id: 37,
      name: 'Sense of community',
      precode: null,
      isSelected: true,
    },
    {
      id: 38,
      name: 'Short-term orientation',
      precode: null,
      isSelected: true,
    },
    {
      id: 39,
      name: 'Silos',
      precode: null,
      isSelected: true,
    },
    {
      id: 40,
      name: 'Slow-moving',
      precode: null,
      isSelected: true,
    },
    {
      id: 41,
      name: 'Status oriented',
      precode: null,
      isSelected: true,
    },
    {
      id: 42,
      name: 'Taking initiative',
      precode: null,
      isSelected: true,
    },
    {
      id: 43,
      name: 'Trust',
      precode: null,
      isSelected: true,
    },
    {
      id: 44,
      name: 'Values-driven',
      precode: null,
      isSelected: true,
    },
    {
      id: 45,
      name: 'Visionary',
      precode: null,
      isSelected: true,
    },
    {
      id: 46,
      name: 'Well organized',
      precode: null,
      isSelected: true,
    },
    {
      id: 47,
      name: 'Willingness to listen',
      precode: null,
      isSelected: true,
    },
    {
      id: 48,
      name: 'temp value 1',
      precode: null,
      isSelected: true,
    },
    {
      id: 49,
      name: 'temp value 2',
      precode: null,
      isSelected: true,
    },
  ]

  const [valuestate, setValuestate] = useState(valuedatar)
  const [valuespostdata, setValuespostdata] = useState({
    surveyId: params,
    Values: [],
  })
  const [isModalope, setIsModalope] = useState(false)
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    if (valuesdata.get('isFetched')) {
      setValuestate(valuesdata.toJS().items.Values)
    }
  }, [valuesdata])

  useEffect(() => {
    setValuespostdata({ ...valuespostdata, Values: valuestate })
  }, [valuestate])
  //console.log(valuesdata.toJS().items.Values)
  useEffect(() => {
    if (valuesdataStatus.get('isFetched')) {
      setLoading(false)
      hideValuesModal()
      /*  setMsg(influencerStatus.get('items'))
      

      if (!influencerStatus.get('items').get('error')) {
        hideDeepdiveEdit()
      } */
    }
  }, [valuesdataStatus])
  function handleDelete(index) {
    const toggledItem = { ...valuestate[index] }
    toggledItem.isSelected = false
    setValuestate([
      ...valuestate.slice(0, index),
      toggledItem,
      ...valuestate.slice(index + 1),
    ])
  }
  //console.log(valuestate)
  const DragHandle = sortableHandle(() => (
    <Box>
      <Box component="img" src={reorderIcon}></Box>
    </Box>
  ))
  const SortableItem = SortableElement(({ listindex, value }) => (
    <Box display="flex" className={classes.questionListupdate}>
      <DragHandle className={classes.reOrder}></DragHandle>
      <Box className={classes.questionTitle}>{value.name}</Box>
      <Box
        component="button"
        className={classes.qEdit}
        onClick={() => handleDelete(listindex)}
      >
        <Box component="img" src={deleteIcon}></Box>
      </Box>
    </Box>
  ))

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setValuestate(arrayMove(valuestate, oldIndex, newIndex))
    //arrayMove(valuedata, oldIndex, newIndex)
    //console.log(questList)
  }

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Box>
        {items.map((value, index) =>
          value.isSelected ? (
            <SortableItem
              key={`item-${index}`}
              index={index}
              listindex={index}
              value={value}
            />
          ) : null
        )}
      </Box>
    )
  })

  function handleClose() {
    setIsModalope(false)
    setUseValues('')
  }
  function handleValueChange() {
    return function(event) {
      setUseValues(event.target.value)
    }
  }
  function handleSave() {
    setErrors({})
    setValuestate([...valuestate, { id: 0, name: useValues, isSelected: true }])
    handleClose()
  }
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      <ThemeProvider theme={outerTheme}>
        <Box className="overflowAuto">
          <Typography
            variant="h1"
            component="h2"
            className={classes.sectionHeading}
          >
            <Box
              component="button"
              className={classes.addnewBtn}
              onClick={() => setIsModalope(true)}
            >
              <Box component="img" src={addIcon}></Box> Add New
            </Box>
            Values
            <Box
              component="p"
              style={{
                fontSize: 12,
                paddingTop: 10,
                fontWeight: 400,
                marginBottom: 0,
              }}
            >
              List will appear in alphabatical order in the survey.
            </Box>
          </Typography>

          {valuestate && (
            <SortableList
              items={valuestate}
              onSortEnd={onSortEnd}
              axis="xy"
              helperClass={classes.srtHelper}
              useDragHandle={true}
            />
          )}
          {errors.lenght && (
            <FormHelperText
              id="my-helper-text-lenght"
              className={classes.myhelptext}
            >
              {errors.lenght}
            </FormHelperText>
          )}
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modalclass"
            open={isModalope}
            onClose={handleClose}
          >
            <div style={modalStyle} className={modalClasses.paper}>
              <h4 className={modalClasses.mdltitle}>Add New Value</h4>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                className={classes.inputSet}
              >
                <FormControl fullWidth style={{ marginBottom: 0 }}>
                  <InputLabel
                    htmlFor="newValues"
                    className={classes.inputLabel}
                  >
                    Enter Value
                  </InputLabel>
                  <Input
                    id="newValues"
                    className={classes.input}
                    value={useValues || ''}
                    style={{ fontSize: 18, fontWeight: 700 }}
                    onChange={handleValueChange()}
                    fullWidth
                  />

                  <Button
                    style={{
                      width: 132,
                      marginTop: 40,
                      marginLeft: 0,
                      backgroundColor: '#4b93fd',
                    }}
                    color="primary"
                    variant="contained"
                    className={classes.buttonRound}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </FormControl>
              </Box>
            </div>
          </Modal>
        </Box>
      </ThemeProvider>

      {renderBottom()}
    </Box>
  )

  function renderBottom() {
    function cancelButton() {
      hideValuesModal()
      setTimeout(function() {
        setValuestate(valuesdata.toJS().items.Values)
      }, 200)
    }
    function saveValueshandler() {
      //console.log(valuespostdata.Values)
      const selectedValues = valuespostdata.Values.filter(
        x => x.isSelected === true
      )
      if (selectedValues.length > 0) {
        setLoading(true)
        onUpdateValue(valuespostdata)
        setErrors({})
      } else {
        setErrors({ lenght: 'You need to add at least one value' })
      }
    }
    return (
      <Box className={classes.footer}>
        <Box className={classes.btnWrapper}>
          <Button
            color="primary"
            disabled={loading}
            variant="contained"
            className={classes.buttonRound}
            onClick={saveValueshandler}
          >
            Save
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonRound}
            onClick={cancelButton}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    )
  }
}

export default memo(ValueModal)
