export const LANGUAGES_FETCH = 'LANGUAGE/FETCH'
export const ANALYSTS_FETCH = 'ANALYSTS/FETCH'
export const COUNTRIES_FETCH = 'COUNTRIES/FETCH'
export const POPULATIONS_FETCH = 'POPULATIONS/FETCH'
export const REGIONS_FETCH = 'REGIONS/FETCH'
export const SURVEYSTYPES_POPULATIONS_FETCH = 'SURVEYSTYPES_POPULATIONS/FETCH'
export const GETRESURVEY_FETCH = 'GETRESURVEY/FETCH'
export const SURVEYINFO_FETCH = 'SURVEYINFO/FETCH'
export const SURVEYINFO_CREATE = 'SAVESURINFO/CREATE'
export const COHESION_DEMO = 'COHESION_DEMO'
export const OHI_VERSION_FETCH = 'OHI_VERSION_FETCH'
