import React, { useEffect, useState, memo } from 'react'
import useStyles from './styles'
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  Button,
  Modal,
  FormHelperText,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { SwitchToggleSet, CstAutosuggest } from '../../../../components'
import { makeStyles } from '@material-ui/core/styles'
import validateclient from '../../../../validations/clientformvalidation'
import validateNewClient from '../../../../validations/addnewclient'
import { useToasts } from 'react-toast-notifications'
import tooltipblue from '../../assets/tooltip-icon_blue.png'
import cancelicon from '../../assets/Cancel.svg'
import closeModalIcon from '../../assets/Cancel_Icon.png'
import moment from 'moment'
import ProgressBarLoader from '../../../../components/ProgressBarLoader'
import PopupModal from '../../../../components/PopupModal'
import StorageInstance from '../../../../common/Storage'

//component
function ClientForm({
  clients,
  onClientsFetch,
  params,
  onClientCreate,
  onClientInfoFetch,
  clientinfo,
  history,
  onClientSave,
  saveclientStatus,
  createclientstaus,
  loader,
  opportunityFetch,
  opportunityDetails,
  statuscode,
  isButtonToBeDisabled,
}) {
  const classes = useStyles()

  const [values, setState] = useState({
    isTestSurvey: true,
    opportunityName: '',
    regionName: '-',
    opportunityClientName: '-',
    clientName: [],
    claientNameval: 'ad',
    isModal: false,
    newClientName: '',
    isSubscribed: false,
    expertEmail: '',
    expertName: '',
    opportunityId: '',
    legalCheck: false,
  })

  const [open, setOpen] = useState(false)
  const [btnLoading, setBtnLoading] = React.useState(false)
  //const [isSubscribed, setSubscribe] = useState(false)
  const [clientLoading, setClientloading] = useState(false)
  const { addToast } = useToasts()
  function getModalStyle() {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    }
  }
  const dateFormat = 'Do MMM, YYYY'
  const useModalStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 564,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'none',
    },
    mdltitle: {
      borderBottom: 'solid 1px #f3f3f3',
      fontSize: 20,
      fontWeight: 700,
      paddingLeft: '32px',
      paddingRight: '32px',
      marginLeft: '-32px',
      marginRight: '-32px',
      paddingBottom: '25px',
    },
  }))

  const [modalStyle] = useState(getModalStyle)
  const modalClasses = useModalStyles()
  const [useForm, setUseform] = useState({
    McKinseySectorLevel1Id: 0,
    McKinseySectorLevel1: '',
    McKinseySectorLevel2Id: 0,
    McKinseySectorLevel2: '',
    RegionId: '',
    RegionName: '',
    clientId: 0,
    clientName: '',
    isTestSurvey: false,
    opportunityClientName: '',
    opportunityName: '',
    surveyId: params,
    expertName: '',
    geoRestriction: '',
    dataRestriction: '',
    benchMarkingAllowed: '',
    opportunityId: '',
    legalCheck: false,
  })
  const [errors, setErrors] = useState({})
  const [errorsnew, setErrorsnew] = useState({})
  const [opendialog, setOpendialog] = useState(false)
  const [disablednewbtn, setDisablednewbtn] = useState(
    useForm.regionName !== '' ? true : false
  )
  const [oportunitylist, setOportunitylist] = useState([])
  const [dialogopenState, setDialogopenState] = useState(false)

  useEffect(() => {
    if (useForm.isTestSurvey) {
      setErrors({
        ...errors,
        opportunityName: '',
      })
    }
  }, [useForm.isTestSurvey])
  useEffect(() => {
    if (createclientstaus.get('isFetched') && clientLoading) {
      setClientloading(false)
      handleClose()
      onClientsFetch()
      addToast(values.newClientName + ' Added Successfully', {
        appearance: 'success',
        autoDismiss: true,
      })
    }
  }, [createclientstaus])

  useEffect(() => {
    if (dialogopenState && opportunityDetails.get('isFetched')) {
      if (
        opportunityDetails &&
        opportunityDetails.get('items') &&
        opportunityDetails.get('items').get('data') &&
        opportunityDetails.get('items').get('data').size === 1
      ) {
        setDialogopenState(false)
        setOportunitylist({})
        if (opportunityDetails.get('items').get('isFound')) {
          setOpendialog(false)

          const opportunity = opportunityDetails.get('items').toJS().data[0]
          setUseform({
            ...useForm,
            opportunityClientName: opportunity.oppClientName,
            RegionName: opportunity.region,
            McKinseySectorLevel1: opportunity.sectorLevel1,
            McKinseySectorLevel2: opportunity.sectorLevel2,
            expertEmail: opportunity.expertEmail,
            expertName: opportunity.expertName,
            geoRestriction: opportunity.geoRestriction,
            dataRestriction: opportunity.dataRestriction,
            benchMarkingAllowed: opportunity.benchMarkingAllowed,
            opportunityId: opportunity.opportunityId,
            legalCheck: opportunity.legalCheck,
          })
          setErrors({
            ...errors,
            opportunityName: '',
          })
        } else {
          setUseform({
            ...useForm,
            opportunityClientName: '',
            RegionName: '',
            McKinseySectorLevel1: '',
            McKinseySectorLevel2: '',
            expertEmail: '',
            expertName: '',
            geoRestriction: '',
            dataRestriction: '',
            benchMarkingAllowed: '',
            opportunityId: '',
            legalCheck: false,
          })
          setErrors({
            ...errors,
            opportunityName:
              'The opportunity name not matching with the database, please reach out to an expert.',
          })
        }
      } else if (
        opportunityDetails &&
        opportunityDetails.get('items') &&
        opportunityDetails.get('items').get('data') &&
        opportunityDetails.get('items').get('data').size > 1
      ) {
        if (opportunityDetails.get('items').get('isFound')) {
          const opportunity = opportunityDetails.get('items').toJS().data
          setOportunitylist(opportunity)
          setOpendialog(true)
          setDialogopenState(false)
        }
      }
    }
  }, [opportunityDetails])

  useEffect(() => {
    if (saveclientStatus.get('isFetched') && btnLoading) {
      setBtnLoading(false)
      if (saveclientStatus.get('items').get('error')) {
        addToast(saveclientStatus.get('items').get('data'), {
          appearance: saveclientStatus.get('items').get('error')
            ? 'error'
            : 'success',
          autoDismiss: true,
        })
      } else {
        StorageInstance.clientInfo = true
        history.push(`../survey-info/${params}`)
      }
    }
  }, [saveclientStatus])

  useEffect(() => {
    if (
      statuscode &&
      statuscode.get('errorstatus') &&
      statuscode.get('errorstatus') !== ''
    ) {
      if (statuscode.toJS().errorstatus.payload.status === 500) {
        setBtnLoading(false)
      }
    }
  }, [statuscode])

  useEffect(() => {
    onClientInfoFetch(params)
    onClientsFetch()
  }, [])

  useEffect(() => {
    setUseform({ ...clientinfo.toJS().items })
  }, [saveclientStatus, clientinfo])

  useEffect(() => {
    const allclientslist = clients.toJS()
    if (
      allclientslist &&
      allclientslist.items &&
      allclientslist.items.length > 0
    ) {
      const matchclientname = allclientslist.items.filter(
        x =>
          x.value.slice(0, useForm.clientName.length).toLowerCase() ===
          useForm.clientName.toLowerCase()
      )
      if (matchclientname.length < 1) {
        setDisablednewbtn(false)
      } else {
        setDisablednewbtn(true)
      }
    }
  }, [useForm.clientName])

  const handleClose = () => {
    setOpen(false)
    setState({ ...values, newClientName: '' })
  }

  if (loader.get('count') !== 0 && loader.get('count') >= 0) {
    return (
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        className={classes.root}
      >
        <Box style={{ position: 'relative', minHeight: 250 }}>
          <PopupModal>
            <ProgressBarLoader />
          </PopupModal>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      {renderFirstSet()}
      {renderSecondSet()}
      {renderFormBottom()}
      {modalView()}
    </Box>
  )

  function modalView() {
    function handleListItemClick(item) {
      setOpendialog(false)
      setUseform({
        ...useForm,
        opportunityClientName: item.oppClientName,
        RegionName: item.region,
        McKinseySectorLevel1: item.sectorLevel1,
        McKinseySectorLevel2: item.sectorLevel2,
        expertEmail: item.expertEmail,
        expertName: item.expertName,
        geoRestriction: item.geoRestriction,
        dataRestriction: item.dataRestriction,
        benchMarkingAllowed: item.benchMarkingAllowed,
        opportunityId: item.opportunityId,
        legalCheck: item.legalCheck,
      })
      setOportunitylist({})
    }

    return (
      <Dialog aria-labelledby="simple-dialog-title" open={opendialog}>
        <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>
          More than one opportunity found, please select the appropriate
          opportunity.
        </DialogTitle>
        <List>
          {oportunitylist.length &&
            oportunitylist.map((item, index) => (
              <ListItem
                button
                onClick={() => handleListItemClick(item)}
                key={index}
                className={classes.dialogItem}
              >
                <ListItemText
                  primary={item.opportunityName}
                  secondary={`Start Date: ${moment(
                    item.startDate,
                    'DD/MM/YYYY'
                  ).format(dateFormat)}`}
                />
              </ListItem>
            ))}
        </List>
      </Dialog>
    )
  }

  function renderFirstSet() {
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex">{renderLeft()}</Box>
      </Box>
    )

    function renderLeft() {
      return (
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          className={classes.leftSection}
        >
          {renderIsTestSurvey()}
        </Box>
      )
    }
  }

  function renderSecondSet() {
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          {renderLeft()}
          {renderRight()}
        </Box>
      </Box>
    )

    function renderLeft() {
      return (
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          className={classes.leftSection}
        >
          {renderOpportunityName()}
          {renderClientName()}
          {renderRegion()}
        </Box>
      )
    }

    function renderRight() {
      return (
        <Box display="flex" flex="1" flexDirection="column">
          {renderOpportunityClientName()}
          {renderMckSectorLevel1()}
          {renderMckSectorLevel2()}
        </Box>
      )
    }
  }

  function renderIsTestSurvey() {
    return (
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <Box className={classes.inputLabel}>
          Is this a test survey?
          <Tooltip
            className={classes.csttooltip}
            interactive={true}
            placement="right"
            title={
              <p style={{ fontSize: 12, marginBottom: 0 }}>
                <em>
                  An opportunity is needed to proceed as a confirmed survey. If
                  you need to proceed and do not have an opportunity name,
                  please create this as a test survey by clicking "Yes" and you
                  may come back later to enter the opportunity name and turn off
                  the test survey selection.
                </em>
              </p>
            }
          >
            <img
              src={tooltipblue}
              alt="tooltip"
              className={classes.tooltipicon}
            />
          </Tooltip>
        </Box>
        <Box>
          <SwitchToggleSet
            isChecked={useForm.isTestSurvey || false}
            onChange={handleCheckboxChange}
            name="isTestSurvey"
            text={{
              txtOn: 'Yes',
              txtOff: 'No',
              ariaLabel: 'Is test survey checkbox',
            }}
          />
        </Box>
        <Box
          style={{
            textAlign: 'left',
            color: '#999999',
            marginTop: 10,
            width: '49%',
          }}
        >
          Please Note: You cannot submit your survey to the Helpdesk for launch
          until you have entered an opportunity name and deselected “Test”.
        </Box>
      </Box>
    )
  }

  function renderOpportunityName() {
    function searchopportunity() {
      setDialogopenState(true)
      opportunityFetch({ name: useForm.opportunityName })
    }
    function clearform() {
      setUseform({
        ...useForm,
        RegionName: '',
        opportunityClientName: '',
        opportunityName: '',
        McKinseySectorLevel1: '',
        McKinseySectorLevel2: '',
        expertEmail: '',
        expertName: '',
        geoRestriction: '',
        dataRestriction: '',
        benchMarkingAllowed: '',
        opportunityId: '',
        legalCheck: false,
      })
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <FormControl fullWidth>
          <InputLabel htmlFor="opportunityName" className={classes.inputLabel}>
            Opportunity Name
          </InputLabel>
          <Input
            id="opportunityName"
            className={classes.input}
            disabled={useForm.RegionName !== ''}
            value={useForm.opportunityName || ''}
            onChange={handleInputChange('opportunityName')}
            fullWidth
          />
          {useForm.RegionName != '' ? (
            <div className={classes.clearBtn} onClick={clearform}>
              <Box component="img" src={cancelicon} />
            </div>
          ) : null}

          <Button onClick={searchopportunity} className={classes.addnewbtn}>
            <Icon className={classes.icon} color="primary">
              search
            </Icon>
            Search
          </Button>
          {errors.opportunityName && (
            <FormHelperText
              id="my-helper-text-opportunityName"
              className={classes.myhelptext}
            >
              {errors.opportunityName}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    )
  }

  function renderClientName() {
    const handleOpen = () => {
      setOpen(true)
      setState({ ...values, newClientName: useForm.opportunityClientName })
    }

    function handleAutosuggestion(value, name) {
      setUseform({
        ...useForm,
        [name]: value,
      })
    }

    const handleSave = () => {
      setErrorsnew(validateNewClient(values, clients.toJS().items))

      if (
        Object.keys(validateNewClient(values, clients.toJS().items)).length ===
        0
      ) {
        onClientCreate({ name: values.newClientName })
        setClientloading(true)
      }
    }
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <Box display="flex" className={classes.fullWidth}>
          <FormControl fullWidth>
            <CstAutosuggest
              label={
                <Box component="span">
                  Client Name
                  <Tooltip
                    className={classes.csttooltipwithLabel}
                    interactive={true}
                    placement="right"
                    title={
                      <p style={{ fontSize: 12, marginBottom: 0 }}>
                        <em>Name of client from our database (MIDAS)</em>
                      </p>
                    }
                  >
                    <img
                      src={tooltipblue}
                      alt="tooltip"
                      className={classes.tooltipicon}
                    />
                  </Tooltip>
                </Box>
              }
              suggestions={clients.toJS().items}
              selectedvalue={useForm.clientName}
              name="clientName"
              handleChangefn={handleAutosuggestion}
            />

            <Button onClick={handleOpen} className={classes.addnewbtn}>
              <Icon className={classes.icon} color="primary">
                add_circle
              </Icon>
              Add New
            </Button>
            {errors.clientName && (
              <FormHelperText
                id="my-helper-text-clientName"
                className={classes.myhelptext}
              >
                {errors.clientName}
              </FormHelperText>
            )}
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={open}
              onClose={handleClose}
            >
              <div style={modalStyle} className={modalClasses.paper}>
                <h4 className={modalClasses.mdltitle}>Add New Client</h4>
                <Box
                  onClick={() => setOpen(false)}
                  className={classes.closeModal}
                  component="img"
                  src={closeModalIcon}
                />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  className={classes.inputSet}
                >
                  <FormControl fullWidth style={{ marginBottom: 0 }}>
                    <InputLabel
                      htmlFor="newClientName"
                      className={classes.inputLabel}
                    >
                      Client Name
                    </InputLabel>
                    <Input
                      id="newClientName"
                      readOnly={!useForm.isTestSurvey}
                      className={classes.input}
                      value={values.newClientName || ''}
                      style={{ fontSize: 18, fontWeight: 700 }}
                      onChange={handleInputChange2('newClientName')}
                      fullWidth
                    />
                    {!useForm.isTestSurvey && (
                      <FormHelperText
                        id="my-helper-text-newClientName"
                        className={classes.myhelptext}
                      >
                        {
                          'Please add an opportunity name first and the client name will be populated here from SF or if you do not have an opportunity name, toggle the survey to test to type in a client name.'
                        }
                      </FormHelperText>
                    )}
                    {errorsnew.newClientName && (
                      <FormHelperText
                        id="my-helper-text-newClientName"
                        className={classes.myhelptext}
                      >
                        {errorsnew.newClientName}
                      </FormHelperText>
                    )}
                    <Button
                      style={{ width: 132, marginTop: 40, marginLeft: 0 }}
                      color="primary"
                      variant="contained"
                      className={classes.buttonRound}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </FormControl>
                </Box>
              </div>
            </Modal>
          </FormControl>
        </Box>
      </Box>
    )
  }

  function renderRegion() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <FormControl fullWidth>
          <InputLabel htmlFor="regionName" className={classes.inputLabel}>
            Region<sup>*</sup>
          </InputLabel>
          <Input
            id="regionName"
            className={classes.input}
            value={useForm.RegionName || '-'}
            onChange={handleInputChange('regionName')}
            fullWidth
            disabled
            disableUnderline
          />
        </FormControl>
      </Box>
    )
  }

  function renderOpportunityClientName() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        <FormControl fullWidth>
          <InputLabel
            htmlFor="opportunityClientName"
            className={classes.inputLabel}
          >
            Opportunity Client Name <sup>*</sup>
          </InputLabel>
          <Input
            id="opportunityClientName"
            className={classes.input}
            value={useForm.opportunityClientName || '-'}
            onChange={handleInputChange('opportunityClientName')}
            fullWidth
            disabled
            disableUnderline
          />
        </FormControl>
      </Box>
    )
  }

  function renderMckSectorLevel1() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        {/* <Box className={classes.inputLabel}></Box> Mckinsey Sector Level 1 */}
        <Box display="flex" className={classes.fullWidth}>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="McKinseySectorLevel1"
              className={classes.inputLabel}
            >
              Mckinsey Sector Level 1 <sup>*</sup>
            </InputLabel>
            <Input
              id="McKinseySectorLevel1"
              className={classes.input}
              value={useForm.McKinseySectorLevel1 || '-'}
              onChange={handleInputChange('McKinseySectorLevel1')}
              fullWidth
              disabled
              disableUnderline
            />
          </FormControl>
        </Box>
      </Box>
    )
  }

  function renderMckSectorLevel2() {
    function searchopportunity() {
      setDialogopenState(true)
      opportunityFetch({ name: useForm.opportunityName })
    }
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        className={classes.inputSet}
      >
        {/* <Box className={classes.inputLabel}></Box> */}
        <Box display="flex" className={classes.fullWidth}>
          <FormControl fullWidth>
            <InputLabel
              htmlFor="McKinseySectorLevel2"
              className={classes.inputLabel}
            >
              Mckinsey Sector Level 2 <sup>*</sup>
            </InputLabel>
            <Input
              id="McKinseySectorLevel2"
              className={classes.input}
              value={useForm.McKinseySectorLevel2 || '-'}
              onChange={handleInputChange('McKinseySectorLevel2')}
              fullWidth
              disabled
              disableUnderline
            />
          </FormControl>
        </Box>
        <Box className={classes.inputLabel}>Legal Checks</Box>
        <Box display="flex" className={classes.fullWidth}>
          <Box style={{ display: 'flex' }}>
            <SwitchToggleSet
              isChecked={useForm.legalCheck || false}
              onChange={() => {
                return false
              }}
              disabled
              name="legalCheck"
              text={{
                txtOn: 'Yes',
                txtOff: 'No',
                ariaLabel: 'Legal Checks',
              }}
            />
          </Box>
          {useForm.opportunityName != '' ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={searchopportunity}
              className={classes.buttonRound}
              startIcon={<Icon>sync</Icon>}
            >
              Sync
            </Button>
          ) : null}
        </Box>
      </Box>
    )
  }

  function renderFormBottom() {
    return (
      <Box display="flex" className={classes.formBottom}>
        <Box
          style={{
            textAlign: 'left',
            color: '#999999',
            position: 'absolute',
            marginLeft: -40,
            marginTop: -54,
          }}
        >
          *This information is pulled directly from Salesforce.
        </Box>
        <Box
          style={{
            textAlign: 'left',
            color: '#999999',
            marginTop: -30,
            marginLeft: -40,
          }}
        >
          *Kindly adjust webpage Zoom if buttons are overlapping. Currently
          webpage is not optimized for smaller screens.
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonRound}
            onClick={goToManagesurvey()}
          >
            Previous
          </Button>
          <Box style={{ position: 'relative' }}>
            <Button
              color="primary"
              variant="contained"
              disabled={btnLoading}
              className={
                isButtonToBeDisabled
                  ? classes.buttonRoundDisabled
                  : classes.buttonRound
              }
              onClick={saveClientInfo()}
            >
              Save & Continue
            </Button>
            {btnLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Box>
        </Box>
      </Box>
    )
  }
  function saveClientInfo() {
    return function() {
      setErrors(validateclient(useForm, clients.toJS().items))

      if (
        Object.keys(validateclient(useForm, clients.toJS().items)).length === 0
      ) {
        setBtnLoading(true)
        onClientSave(useForm)
        // history.push(`../survey-info/${params}`)
      }
      //history.push(`../survey-info/${params}`)
    }
  }
  function goToManagesurvey() {
    return function() {
      history.push(`/manage-surveys`)
    }
  }

  function handleCheckboxChange(name) {
    return function(event) {
      // console.log(event.target, event.target.checked)
      setUseform({ ...useForm, [name]: event.target.checked })
    }
  }

  function handleInputChange(name) {
    return function(event) {
      // console.log(event.target, event.target.value)
      setUseform({ ...useForm, [name]: event.target.value })
    }
  }
  function handleInputChange2(name) {
    return function(event) {
      // console.log(event.target, event.target.value)
      setState({ ...values, [name]: event.target.value })
    }
  }
}

export default memo(ClientForm)
