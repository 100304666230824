import { fromJS } from 'immutable'
import { orderBy } from 'lodash'
import {
  DESIGNSURVEY_FETCH,
  UPDATEDESIGNSURVEY_CREATE,
  SAVEQUESTION_CREATE,
  OUTCOMESCALE_FETCH,
  PRACTICESCALE_FETCH,
  GETQUESTIONLIST_FETCH,
  UPDATEQUESTIONLIST_CREATE,
  UPDATEQUESTION_CREATE,
  INFLUENCERS_FETCH,
  UPDATEINFLUENCERS_CREATE,
  VALUES_FETCH,
  UPDATEVALUES_CREATE,
  INCLUSION_FETCH,
  UPDATEINCLUSION_CREATE,
  REST_QUESTION_LIST,
  UPDATE_WORK_EX,
  GET_WORK_EX,
  TEST_MODULE_FETCH,
  TEST_MODULE_UPDATE,
  OHI_4_MODULE_FETCH,
  OHI_4_MODULE_UPDATE,
} from './constants'

// DESIGNSURVEY FETCH
const INITIAL_DESIGNSURVEY_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
const reducer = (state = INITIAL_DESIGNSURVEY_STATE, { type, payload }) => {
  switch (type) {
    case `${DESIGNSURVEY_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${DESIGNSURVEY_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      const updatedData = {
        ...data,
        question: orderBy(data.question, 'typeSuffix', 'desc'),
      }
      return state
        .updateIn(['items'], items => {
          return fromJS(updatedData)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${DESIGNSURVEY_FETCH}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// UPDATE DESIGN SURVEY
const INITIAL_DESIGNSURVEYUPDATE_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
  isLaunchSet: false,
  textUpdate: false,
})
export const designupdatereducer = (
  state = INITIAL_DESIGNSURVEYUPDATE_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${UPDATEDESIGNSURVEY_CREATE}_PENDING`: {
      return state
        .setIn(['isFetched'], false)
        .setIn(['isLoading'], true)
        .setIn(['isLaunchSet'], payload.isLaunch)
    }
    case `${UPDATEDESIGNSURVEY_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${UPDATEDESIGNSURVEY_CREATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    case 'TEXT_UPDATE_ACTION': {
      // const { payload: flag = false } = payload;
      // console.log(payload, 132323423)
      return state.setIn(['textUpdate'], true)
    }
    default:
      return state
  }
}

// SAVE QUESTION
const INITIAL_SAVEQUESTION_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const savequestionreducer = (
  state = INITIAL_SAVEQUESTION_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${SAVEQUESTION_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${SAVEQUESTION_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${SAVEQUESTION_CREATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}
// OUTCOME SCALE
const INITIAL_OUTCOMESCALE_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})

export const outcomescalereducer = (
  state = INITIAL_OUTCOMESCALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${OUTCOMESCALE_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${OUTCOMESCALE_FETCH}_SUCCESS`: {
      const { data } = payload
      return (
        state
          /* .updateIn(['items'], items => {
          return items.concat(fromJS(data.items))
        }) */
          .setIn(['items'], fromJS(data.items))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${OUTCOMESCALE_FETCH}_FAILED`: {
      // const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// PRACTICE SCALE
const INITIAL_PRACTICESCALE_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const practicecalereducer = (
  state = INITIAL_PRACTICESCALE_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${PRACTICESCALE_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${PRACTICESCALE_FETCH}_SUCCESS`: {
      const { data } = payload
      return (
        state
          /* .updateIn(['items'], items => {
          return items.concat(fromJS(data.items))
        }) */
          .setIn(['items'], fromJS(data.items))
          .setIn(['isFetched'], true)
          .setIn(['isLoading'], false)
      )
    }
    case `${PRACTICESCALE_FETCH}_FAILED`: {
      // const { data } = payload
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// FETCH QUESTION LIST
const INITIAL_GETQUESTIONLIST_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const questionlistreducer = (
  state = INITIAL_GETQUESTIONLIST_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${GETQUESTIONLIST_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${GETQUESTIONLIST_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${GETQUESTIONLIST_FETCH}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case REST_QUESTION_LIST: {
      return state.setIn(['isFetched'], false).setIn(['items'], [])
    }
    default:
      return state
  }
}

// UPDATE QUESTION LIST
const INITIAL_UPDATEQUESTIONLIST_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const updatequestionlistereducer = (
  state = INITIAL_UPDATEQUESTIONLIST_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${UPDATEQUESTIONLIST_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${UPDATEQUESTIONLIST_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${UPDATEQUESTIONLIST_CREATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}

// UPDATE QUESTION
const INITIAL_UPDATEQUESTION_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const updatequestionereducer = (
  state = INITIAL_UPDATEQUESTION_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${UPDATEQUESTION_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${UPDATEQUESTION_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log(data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${UPDATEQUESTION_CREATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}

// FETCH INFLUENCER
const INITIAL_GETINFLUENCER_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const influencerreducer = (
  state = INITIAL_GETINFLUENCER_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${INFLUENCERS_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${INFLUENCERS_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log('kausar', data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${INFLUENCERS_FETCH}_FAILED`: {
      // const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}
// UPDATE INFLUENCER
const INITIAL_UPDATEINFLUENCER_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const updateinfluencerreducer = (
  state = INITIAL_UPDATEINFLUENCER_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${UPDATEINFLUENCERS_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${UPDATEINFLUENCERS_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload

      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${UPDATEINFLUENCERS_CREATE}_FAILED`: {
      // const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}

// FETCH VALUE
const INITIAL_GETVALUES_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})
export const valuesreducer = (
  state = INITIAL_GETVALUES_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${VALUES_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${VALUES_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log('kausar', data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${VALUES_FETCH}_FAILED`: {
      // const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// UPDATE VALUES
const INITIAL_UPDATEVALUES_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const updatevaluesreducer = (
  state = INITIAL_UPDATEVALUES_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${UPDATEVALUES_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${UPDATEVALUES_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload

      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${UPDATEVALUES_CREATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}

// FETCH INCLUSION
const INITIAL_GETINCLUSION_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  workitems: { type: '' },
  items: [],
})
export const inclusionreducer = (
  state = INITIAL_GETINCLUSION_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${INCLUSION_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${INCLUSION_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }

    case `${INCLUSION_FETCH}_FAILED`: {
      // const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${GET_WORK_EX}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${GET_WORK_EX}_SUCCESS`: {
      const {
        respData: { data },
      } = payload

      return state
        .updateIn(['workitems'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }

    case `${GET_WORK_EX}_FAILED`: {
      // const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

// get work ex

// UPDATE VALUES
const INITIAL_UPDATEINCLUSION_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const updateInclusionsreducer = (
  state = INITIAL_UPDATEINCLUSION_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${UPDATEINCLUSION_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${UPDATEINCLUSION_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload

      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${UPDATEINCLUSION_CREATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    //work
    case `${UPDATE_WORK_EX}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${UPDATE_WORK_EX}_SUCCESS`: {
      const {
        respData: { data },
      } = payload

      return state
        .updateIn(['items'], items => {
          return fromJS(data || { type: '' })
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${UPDATE_WORK_EX}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}

const OHI_TEST_MODULE_INITIAL_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const ohiTestModuleReducer = (
  state = OHI_TEST_MODULE_INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${TEST_MODULE_FETCH}_PENDING`:
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    case `${TEST_MODULE_FETCH}_SUCCESS`:
      const {
        respData: { data },
      } = payload
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSuccessed'], true)
        .updateIn(['items'], items => {
          return fromJS(data)
        })
    case `${TEST_MODULE_FETCH}_FAILED`:
      return state
        .setIn(['isLoading'], false)
        .setIn(['isSuccessed'], false)
        .setIn(['isFetched'], true)
    default:
      return state
  }
}
const INITIAL_UPDATETEST_MODULE_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const ohiUpdateTestModuleReducer = (
  state = INITIAL_UPDATETEST_MODULE_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${TEST_MODULE_UPDATE}_PENDING`:
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    case `${TEST_MODULE_UPDATE}_SUCCESS`:
      const {
        respData: { data },
      } = payload
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSuccessed'], true)
        .updateIn(['items'], items => {
          return fromJS(data)
        })
    case `${TEST_MODULE_UPDATE}_FAILED`:
      return state
        .setIn(['isFetched'], false)
        .setIn(['isLoading'], false)
        .setIn(['isSuccessed'], false)
    default:
      return state
  }
}

const OHI_4_MODULE_INITIAL_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const ohi4ModuleReducer = (
  state = OHI_4_MODULE_INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${OHI_4_MODULE_FETCH}_PENDING`:
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    case `${OHI_4_MODULE_FETCH}_SUCCESS`:
      const {
        respData: { data },
      } = payload
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSuccessed'], true)
        .updateIn(['items'], items => {
          return fromJS(data)
        })
    case `${OHI_4_MODULE_FETCH}_FAILED`:
      return state
        .setIn(['isLoading'], false)
        .setIn(['isSuccessed'], false)
        .setIn(['isFetched'], true)
    default:
      return state
  }
}

const UPDATE_OHI_4_MODULE_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  isSucessed: false,
  items: [],
})
export const updateohi4Modulereducer = (
  state = UPDATE_OHI_4_MODULE_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${OHI_4_MODULE_UPDATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${OHI_4_MODULE_UPDATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload

      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isSucessed'], true)
        .setIn(['isLoading'], false)
    }
    case `${OHI_4_MODULE_UPDATE}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
        .setIn(['isSucessed'], false)
    }
    default:
      return state
  }
}

export default reducer
