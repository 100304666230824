import React from 'react'

import loader from './loader.svg'
import { Box } from '@material-ui/core'
import { Translate } from '@material-ui/icons'

export default function Loader({ background, position }) {
  const bg = background ? background : 'white'
  const pos = position ? position : 'fixed'
  const style = {
    position: pos,
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: bg,
    zIndex: 99999,
  }
  const imgstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'Translate(-50%, -50%)',
  }
  return (
    <Box style={style}>
      <img src={loader} style={imgstyle} alt="loading" />
    </Box>
  )
}
