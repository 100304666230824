import axios from 'axios'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}

const api = new APIs('/api')

// CLIENT IFNO FETCH
export const fetchSummaryinfo = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`summary/${data}`),
  })
  return result
}
