import { removeBrs } from '../../utils'

const paraStart = '<li><span>'
const paraEnd = '</span></li>'
const blockingDivStart = '<div>'
const blockingDivEnd = '</div>'
const unOrderedListStart = '<ul>'
const unOrderedListEnd = '</ul>'
const scaleHeaderElement = '<h6>SCALE</h6>'
const headingStart = '<h5>'
const headingEnd = '</h5>'

const transformData = data => {
  for (var i = 0; i < data.questions.length; i++) {
    data.questions[i].questionText = removeBrs(
      data.questions[i].questionText.trim()
    )
    const answers = data.questions[i].answers
    const scaleValues = data.questions[i].scaleValues
    answers.forEach(item => {
      if (item.text) {
        item.text = removeBrs(item.text.trim())
      }
    })
    scaleValues.forEach(item => {
      if (item.text) {
        item.text = removeBrs(item.text.trim())
      }
    })
  }
  let numberOfBlocksElement = data.questions.length * 2
  const transformedQuestionsArray = data.questions.map((question, index) => {
    const { questionHeader, questionText, answers, scaleValues } = question
    numberOfBlocksElement =
      numberOfBlocksElement +
      answers.length +
      (scaleValues.length > 0 ? scaleValues.length + 1 : 0)
    const indexWithStyling = transformComponent(
      '',
      blockingDivStart,
      blockingDivEnd
    )

    const headingForQuestion =
      questionHeader !== null
        ? transformComponent(questionHeader, headingStart, headingEnd)
        : ''

    if (scaleValues.length > 0) {
      const questionWithScale = indexWithStyling.concat(
        questionText
          .concat(
            transformComponent(
              answers
                .map(ans => transformComponent(ans.text, paraStart, paraEnd))
                .join(''),
              unOrderedListStart,
              unOrderedListEnd
            )
          )
          .concat(scaleHeaderElement)
          .concat(
            transformComponent(
              scaleValues
                .map(sc => transformComponent(sc.text, paraStart, paraEnd))
                .join(''),
              unOrderedListStart,
              unOrderedListEnd
            )
          )
      )
      return headingForQuestion.concat(
        transformComponent(questionWithScale, paraStart, paraEnd)
      )
    } else if (answers.length === 0 && scaleValues.length === 0) {
      return headingForQuestion.concat(
        indexWithStyling.concat(
          transformComponent(questionText, paraStart, paraEnd)
        )
      )
    } else if (answers.length > 0) {
      const normalQuestion = indexWithStyling.concat(
        questionText.concat(
          transformComponent(
            answers
              .map(ans => transformComponent(ans.text, paraStart, paraEnd))
              .join(''),
            unOrderedListStart,
            unOrderedListEnd
          )
        )
      )

      return headingForQuestion.concat(
        transformComponent(normalQuestion, paraStart, paraEnd)
      )
    } else {
      return headingForQuestion.concat(
        indexWithStyling.concat(
          transformComponent(questionText, paraStart, paraEnd)
        )
      )
    }
  })
  const updatedData = transformedQuestionsArray.join('')
  return { data: updatedData, numberOfBlocksElement: numberOfBlocksElement }
}

const transformComponent = (component, startTag, endTag) => {
  const positionStart = 0
  if (component && component.length > 0) {
    const componentWithStartingTag = [
      component.slice(0, positionStart),
      startTag,
      component.slice(positionStart),
    ].join('')
    const positionEnd = componentWithStartingTag.length
    const componentWithTags = [
      componentWithStartingTag.slice(0, positionEnd),
      endTag,
      componentWithStartingTag.slice(positionEnd),
    ].join('')
    return componentWithTags
  }
  return startTag.concat(endTag)
}

const setPayloadForQuestions = (originalQuestions, editorState) => {
  var currentPostionInLists = 0
  const sanitisedEditorState = editorState
    .replace('<ul>', '')
    .replace('</ul>', '')
  if (sanitisedEditorState.match(new RegExp('<p>' + '(.*)' + '</p>', 'g'))) {
    var extractedQuestionsParagraphs = sanitisedEditorState
      .match(new RegExp('<p>' + '(.*)' + '</p>', 'g'))
      .map(p => {
        const newPara = p.replace('<p>', '').replace('</p>', '')
        return newPara
      })
  }
  if (sanitisedEditorState.match(new RegExp('<li>' + '(.*)' + '</li>', 'g'))) {
    var extractedAnswersAndScaleValueLists = sanitisedEditorState
      .match(new RegExp('<li>' + '(.*)' + '</li>', 'g'))
      .map(li => {
        const newList = li.replace('<li>', '').replace('</li>', '')
        return newList
      })
  }
  originalQuestions.questions.forEach((question, index) => {
    question.questionText = extractedQuestionsParagraphs[index]
    if (question.answers.length > 0) {
      question.answers.forEach(answer => {
        answer.text = extractedAnswersAndScaleValueLists[currentPostionInLists]
        currentPostionInLists++
      })
    }
    if (question.scaleValues.length > 0) {
      question.scaleValues.forEach(scale => {
        scale.text = extractedAnswersAndScaleValueLists[currentPostionInLists]
        currentPostionInLists++
      })
    }
  })
  return {
    questions: originalQuestions.questions,
  }
}

export { transformData, setPayloadForQuestions }
