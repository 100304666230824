import React, { useEffect, useState, memo } from 'react'
import {
  Box,
  Tabs,
  Tab,
  Typography,
  InputLabel,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  // TextField,
} from '@material-ui/core'
import useStyles from './styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Icon from '@material-ui/core/Icon'
import { useToasts } from 'react-toast-notifications'
import ProgressBarLoader from '../../../../components/ProgressBarLoader'
import PopupModal from '../../../../components/PopupModal'
import StorageInstance from '../../../../common/Storage'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}
const api = new APIs('/api')

function SummaryDetails({
  params,
  summaryinfo,
  onSummaryFetch,
  history,
  loader,
  ohi4ModuleFetch,
  ohi4ModuleData,
  surveyname,
  // statuscode,
  // isTextUpdateEnabled,
}) {
  //const  = props
  //console.log('kausar', process.env)
  const classes = useStyles()
  const [tabvalue, setTabValue] = useState(0)
  const [designInfo, setDesignino] = useState([])
  const [copytxt, setCopytxt] = React.useState('')
  const [values, setValues] = useState({ comment: '' })
  const { addToast } = useToasts()
  const [outcomes, setOutcomes] = useState(false)
  const [practices, setPractices] = useState(false)
  const [deepdiveAdded, setDeepdiveAdded] = useState(false)
  const [deepdiveRemoved, setDeepdiveRemoved] = useState(false)
  const [additionalQuesAdded, setadditionalQuesAdded] = useState(false)
  const [additionalQuesRemoved, setadditionalQuesRemoved] = useState(false)
  const [ohi4Module, setOhi4Module] = useState([])
  const [optional, setOptional] = useState([])
  const ohiVersionId = surveyname.get('items').get('ohiVersionId')
  const ohiVersion3or4 = [2, 3].includes(ohiVersionId)

  function handleChange(event, newValue) {
    setTabValue(newValue)
    //console.log(tabvalue)
  }
  const saveNContinue = () => {
    // if (!isTextUpdateEnabled()) {
    //   addToast('Please save deep dive modules data for continue', {
    //     appearance: 'error',
    //     autoDismiss: true,
    //   })
    // } else {
    StorageInstance.summary = true
    history.push(`/update-survey-text/introduction/${params}`)
    // }
  }

  useEffect(() => {
    if (ohiVersionId && [2, 3].includes(ohiVersionId)) {
      ohi4ModuleFetch({ surveyId: params, questionId: 10 })
    }
  }, [ohiVersionId])

  useEffect(() => {
    if (ohi4ModuleData.get('isFetched')) {
      setOhi4Module(ohi4ModuleData.toJS()?.items?.Values)
    }
  }, [ohi4ModuleData])

  useEffect(() => {
    const summaryInfo = summaryinfo.toJS()
    const outcomesCheck =
      summaryInfo.items &&
      summaryInfo.items.designInfo &&
      summaryInfo.items.designInfo.outcomes
    if (outcomesCheck) {
      outcomesCheck.map((item, key) => {
        if (!item.isSelected) {
          setOutcomes(true)
        }
        // else {
        //   setDeepdiveRemoved(true)
        // }
      })
      return () => setOutcomes(false)
    }
  }, [summaryinfo])

  useEffect(() => {
    const summaryInfo = summaryinfo.toJS()
    const practicesCheck =
      summaryInfo.items &&
      summaryInfo.items.designInfo &&
      summaryInfo.items.designInfo.practices
    if (practicesCheck) {
      practicesCheck.map((item, key) => {
        if (!item.isSelected) {
          setPractices(true)
        }
      })
      return () => setPractices(false)
    }
  }, [summaryinfo])

  useEffect(() => {
    const summaryInfo = summaryinfo.toJS()
    let numberOfDeepDivesSelected = 0
    const deepDiveCheck =
      summaryInfo.items &&
      summaryInfo.items.designInfo &&
      summaryInfo.items.designInfo.deepDive

    if (deepDiveCheck) {
      deepDiveCheck.map(item => {
        if (item.isSelected) {
          numberOfDeepDivesSelected++
          setDeepdiveAdded(true)
        }
      })
      if (numberOfDeepDivesSelected !== deepDiveCheck.length) {
        setDeepdiveRemoved(true)
      }
    }
  }, [summaryinfo])

  useEffect(() => {
    const summaryInfo = summaryinfo.toJS()
    let numberOfAddQuesSelected = 0
    const additionalQuesCheck =
      summaryInfo.items &&
      summaryInfo.items.designInfo &&
      summaryInfo.items.designInfo.question
    if (additionalQuesCheck) {
      additionalQuesCheck.map(item => {
        if (item.isSelected) {
          numberOfAddQuesSelected++
          setadditionalQuesAdded(true)
        }
      })
      if (numberOfAddQuesSelected !== additionalQuesCheck.length) {
        setadditionalQuesRemoved(true)
      }
    }
  }, [summaryinfo])

  useEffect(() => {
    onSummaryFetch(params)
  }, [])

  useEffect(() => {
    if (
      summaryinfo &&
      summaryinfo.get('items') &&
      summaryinfo.get('items').get('HelpdeskComment') !== undefined
    ) {
      setValues({ comment: summaryinfo.get('items').get('HelpdeskComment') })
    }
  }, [summaryinfo])

  useEffect(() => {
    setDesignino(summaryinfo.toJS().designInfo)
  }, [summaryinfo.get('items')])

  useEffect(() => {
    const data = summaryinfo.toJS()?.items?.designInfo?.optionalQuestion
    if (data) {
      let notIncluded = []
      const obj = []
      data.forEach(({ groupDetails, name: gdname }, i) => {
        notIncluded = []
        const gd = groupDetails.map(({ data: arr, name }) => {
          const count = arr?.filter(({ isSelected }) => isSelected).length
          if (count) {
            return name
          } else {
            notIncluded.push(name)
          }
        })
        obj.push({
          name: gdname,
          questions: gd.filter(item => item),
          questionsNotIcluded: notIncluded,
        })
      })
      setOptional(obj)
    }
  }, [summaryinfo])

  // const handlecomment = name => event => {
  //   setValues({ ...values, [name]: event.target.value })
  // }

  if (loader.get('count') !== 0 && loader.get('count') >= 0) {
    return (
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        className={classes.root}
      >
        <Box style={{ position: 'relative', minHeight: 250 }}>
          <PopupModal>
            <ProgressBarLoader />
          </PopupModal>
        </Box>
      </Box>
    )
  }

  return (
    <Box display="flex" flex="1" className={classes.root}>
      <Box className={classes.rootResize}>
        <Box className={classes.surveyinfo}>
          <Box className={classes.tabwrapper}>
            <Tabs
              value={tabvalue}
              onChange={handleChange}
              aria-label="simple tabs example"
              style={{ marginBottom: -3 }}
              classes={{
                indicator: classes.indicator,
                root: classes.tbroot,
              }}
            >
              <Tab
                label="Client Info."
                classes={{
                  root: classes.tbbutton,
                  selected: classes.tbselected,
                }}
              />
              <Tab
                label="Survey Info."
                classes={{
                  root: classes.tbbutton,
                  selected: classes.tbselected,
                }}
              />
            </Tabs>
          </Box>
          {summaryinfo.get('items').size ? renderclientinfo() : 'Loading'}
          {summaryinfo.get('items').size ? rendersurveyinfo() : null}
        </Box>
        <Box className={classes.designSurvey}>
          <Box component="h3" className={classes.designSurveyTitle}>
            Survey Design
          </Box>
          {/* outcomes */}
          <ExpansionPanel square>
            <ExpansionPanelSummary
              classes={{
                expanded: classes.cstexpanded,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="outcomesac"
              id="outcomesac"
            >
              <Typography className={classes.heading}>Outcomes</Typography>
            </ExpansionPanelSummary>

            {summaryinfo &&
            summaryinfo.get('items') &&
            summaryinfo.get('items').get('designInfo') &&
            summaryinfo
              .get('items')
              .get('designInfo')
              .get('surveyType') &&
            summaryinfo
              .get('items')
              .get('designInfo')
              .get('surveyType') === 5 ? (
              <ExpansionPanelDetails className={classes.fdcolumn}>
                <Box display="flex" flexDirection="row">
                  <Box className={classes.marginRight}>
                    <InputLabel className={classes.summaryLabel}>
                      {outcomes ? 'Included' : 'All options selected'}
                    </InputLabel>
                    <Box>
                      <ul className={classes.addedList}>
                        {summaryinfo.get('items').get('designInfo') &&
                          summaryinfo
                            .get('items')
                            .get('designInfo')
                            .get('outcomes') &&
                          summaryinfo
                            .get('items')
                            .get('designInfo')
                            .get('outcomes')
                            .map((item, index) =>
                              item.get('isSelected') && outcomes ? (
                                <li key={item.get('id')}>{item.get('name')}</li>
                              ) : null
                            )}
                      </ul>
                    </Box>
                  </Box>
                  <Box>
                    <InputLabel className={classes.summaryLabel}>
                      {outcomes ? 'Not Included' : ''}
                    </InputLabel>
                    <Box>
                      <ul className={classes.removed}>
                        {summaryinfo.get('items').get('designInfo') &&
                          summaryinfo
                            .get('items')
                            .get('designInfo')
                            .get('outcomes') &&
                          summaryinfo
                            .get('items')
                            .get('designInfo')
                            .get('outcomes')
                            .map((item, index) =>
                              !item.get('isSelected') ? (
                                <li key={item.get('id')}>{item.get('name')}</li>
                              ) : null
                            )}
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </ExpansionPanelDetails>
            ) : null}
            {summaryinfo &&
            summaryinfo.get('items') &&
            summaryinfo.get('items').get('designInfo') &&
            summaryinfo
              .get('items')
              .get('designInfo')
              .get('surveyType') &&
            summaryinfo
              .get('items')
              .get('designInfo')
              .get('surveyType') !== 5 ? (
              <ExpansionPanelDetails className={classes.fdcolumn}>
                <InputLabel className={classes.summaryLabel}>
                  {summaryinfo
                    .get('items')
                    .get('designInfo')
                    .get('surveyType') === 4
                    ? 'All options selected'
                    : null}
                  {summaryinfo
                    .get('items')
                    .get('designInfo')
                    .get('surveyType') === 6
                    ? 'Not Applicable'
                    : null}
                </InputLabel>
              </ExpansionPanelDetails>
            ) : null}
          </ExpansionPanel>
          {/* practices */}
          <ExpansionPanel square>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="practicesac"
              id="practicesac"
            >
              <Typography className={classes.heading}>Practices</Typography>
            </ExpansionPanelSummary>
            {summaryinfo &&
            summaryinfo.get('items') &&
            summaryinfo.get('items').get('designInfo') &&
            summaryinfo
              .get('items')
              .get('designInfo')
              .get('surveyType') &&
            summaryinfo
              .get('items')
              .get('designInfo')
              .get('surveyType') === 5 ? (
              <ExpansionPanelDetails className={classes.fdcolumn}>
                <Box display="flex" flexDirection="row">
                  <Box className={classes.marginRight}>
                    <InputLabel className={classes.summaryLabel}>
                      {practices ? 'Included' : 'All options selected'}
                    </InputLabel>
                    <Box>
                      <ul className={classes.addedList}>
                        {summaryinfo.get('items').get('designInfo') &&
                          summaryinfo
                            .get('items')
                            .get('designInfo')
                            .get('practices') &&
                          summaryinfo
                            .get('items')
                            .get('designInfo')
                            .get('practices')
                            .map((item, index) =>
                              item.get('isSelected') && practices ? (
                                <li key={item.get('id')}>{item.get('name')}</li>
                              ) : null
                            )}
                      </ul>
                    </Box>
                  </Box>
                  <Box>
                    <InputLabel className={classes.summaryLabel}>
                      {practices ? 'Not Included' : ''}
                    </InputLabel>
                    <Box>
                      <ul className={classes.removed}>
                        {summaryinfo.get('items').get('designInfo') &&
                          summaryinfo
                            .get('items')
                            .get('designInfo')
                            .get('practices') &&
                          summaryinfo
                            .get('items')
                            .get('designInfo')
                            .get('practices')
                            .map((item, index) =>
                              !item.get('isSelected') ? (
                                <li key={item.get('id')}>{item.get('name')}</li>
                              ) : null
                            )}
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </ExpansionPanelDetails>
            ) : null}
            {summaryinfo &&
            summaryinfo.get('items') &&
            summaryinfo.get('items').get('designInfo') &&
            summaryinfo
              .get('items')
              .get('designInfo')
              .get('surveyType') &&
            summaryinfo
              .get('items')
              .get('designInfo')
              .get('surveyType') !== 5 ? (
              <ExpansionPanelDetails className={classes.fdcolumn}>
                <InputLabel className={classes.summaryLabel}>
                  {summaryinfo
                    .get('items')
                    .get('designInfo')
                    .get('surveyType') === 4
                    ? 'All options selected'
                    : null}
                  {summaryinfo
                    .get('items')
                    .get('designInfo')
                    .get('surveyType') === 6
                    ? 'Not Applicable'
                    : null}
                </InputLabel>
              </ExpansionPanelDetails>
            ) : null}
          </ExpansionPanel>
          {/* ohi 4.0 sections */}
          {ohiVersion3or4 ? (
            <ExpansionPanel square>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="practicesac"
                id="practicesac"
              >
                <Typography className={classes.heading}>
                  OHI 4.0 Sections
                </Typography>
              </ExpansionPanelSummary>
              {ohi4Module.length ? (
                <ExpansionPanelDetails className={classes.fdcolumn}>
                  <Box display="flex" flexDirection="row">
                    <Box className={classes.marginRight}>
                      <InputLabel className={classes.summaryLabel}>
                        {ohi4Module.some(({ isSelected }) => isSelected)
                          ? 'Included'
                          : 'All options selected'}
                      </InputLabel>
                      <Box>
                        <ul className={classes.addedList}>
                          {ohi4Module.length &&
                            ohi4Module.map(({ name, isSelected }, index) =>
                              isSelected ? <li key={index}>{name}</li> : null
                            )}
                        </ul>
                      </Box>
                    </Box>
                    <Box>
                      <InputLabel className={classes.summaryLabel}>
                        {ohi4Module.some(({ isSelected }) => !isSelected)
                          ? 'Not Included'
                          : ''}
                      </InputLabel>
                      <Box>
                        <ul className={classes.removed}>
                          {ohi4Module.length &&
                            ohi4Module.map(({ name, isSelected }, index) =>
                              !isSelected ? <li key={index}>{name}</li> : null
                            )}
                        </ul>
                      </Box>
                    </Box>
                  </Box>
                </ExpansionPanelDetails>
              ) : null}
            </ExpansionPanel>
          ) : null}
          {/* deep dives */}
          <ExpansionPanel square>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="deepdiveac"
              id="deepdiveac"
            >
              <Typography className={classes.heading}>
                Deep Dive Modules
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fdcolumn}>
              <InputLabel className={classes.summaryLabel}>
                {deepdiveAdded ? 'Added' : 'Added - None'}
              </InputLabel>
              <Box>
                <ul className={classes.addedList}>
                  {summaryinfo
                    .toJS()
                    ?.items?.designInfo?.deepDive?.filter(item => {
                      if (ohiVersion3or4 && item.id !== 9 && item.id !== 10) {
                        return item
                      } else if (ohiVersionId === 1 && item.id !== 10) {
                        return item
                      }
                    })
                    .map((item, index) =>
                      item.isSelected ? (
                        <li key={item.id} className={classes.liBullet}>
                          {item.name}
                          {item.name === 'Influencer' ? (
                            <ul>
                              <li>
                                Influencer Type :
                                <strong>{item.childObj.type}</strong>
                              </li>
                              <li>
                                Show opt-out option :{' '}
                                <strong>{item.childObj.isOptout}</strong>
                              </li>
                              <li>
                                Is Influencer mandatory :{' '}
                                <strong>{item.childObj.isRequired}</strong>
                              </li>
                              <li>
                                Number to Nominate (Min) :{' '}
                                <strong>{item.childObj.nominateMin}</strong>
                              </li>
                              <li>
                                Number to Nominate (Max) :{' '}
                                <strong>{item.childObj.nominateMax}</strong>
                              </li>
                              {item.childObj.additionalQuestionText !== '' ? (
                                <li>
                                  Another Influencer question :{' '}
                                  <strong>
                                    {item.childObj.additionalQuestionText}
                                  </strong>
                                </li>
                              ) : null}
                            </ul>
                          ) : null}
                          {item.name === 'Inclusion' ? (
                            <ul>
                              <Box>
                                <li>
                                  Inclusion Module Type:{' '}
                                  <strong>{item.childObj.type}</strong>
                                </li>
                              </Box>
                            </ul>
                          ) : null}
                        </li>
                      ) : null
                    )}
                </ul>
              </Box>
              <InputLabel className={classes.summaryLabel}>
                {deepdiveRemoved ? 'Not Included' : null}
              </InputLabel>
              <Box>
                <ul className={classes.removed}>
                  {summaryinfo
                    .toJS()
                    ?.items?.designInfo?.deepDive?.filter(item => {
                      if (ohiVersion3or4 && item.id !== 9 && item.id !== 10) {
                        return item
                      } else if (ohiVersionId === 1 && item.id !== 10) {
                        return item
                      }
                      // return item;
                    })
                    .map((item, index) =>
                      !item.isSelected ? (
                        <li key={item.id}>{item.name}</li>
                      ) : null
                    )}
                </ul>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {/* optional questions */}
          {ohiVersion3or4 ? (
            <ExpansionPanel square>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="optionalQues"
                id="optionalQues"
              >
                <Typography className={classes.heading}>
                  Optional Questions
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.fdcolumn}>
                <InputLabel className={classes.summaryLabel}>
                  {optional
                    .filter(({ questions }) => {
                      if (questions.length) {
                        return true
                      }
                      return false
                    })
                    .filter(item => item).length
                    ? 'Added'
                    : 'Added - None'}
                </InputLabel>
                <Box>
                  <ul className={classes.addedList}>
                    {optional.length &&
                      optional.map(({ name, questions }, index) => {
                        if (questions.length) {
                          return (
                            <li key={index}>
                              {name}: {questions.join(', ')}
                            </li>
                          )
                        }
                      })}
                  </ul>
                </Box>
                <InputLabel className={classes.summaryLabel}>
                  {optional.filter(
                    ({ questionsNotIcluded }) => questionsNotIcluded.length
                  ).length
                    ? 'Not Included'
                    : null}
                </InputLabel>
                <Box>
                  <ul className={classes.removed}>
                    {optional.length &&
                      optional.map(({ name, questionsNotIcluded }, index) => {
                        if (questionsNotIcluded.length) {
                          return (
                            <li key={index} className={classes.liBullet}>
                              {name}
                              <ul
                                style={{
                                  listStyle: 'none',
                                  color: '#999999',
                                  paddingLeft: '7px',
                                }}
                              >
                                {questionsNotIcluded.map(item => (
                                  <li key={item}>{item}</li>
                                ))}
                              </ul>
                            </li>
                          )
                        }
                      })}
                  </ul>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
          {/* additional */}
          <ExpansionPanel square>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="questionsac"
              id="questionsac"
              className={classes.acordContent}
            >
              <Typography className={classes.heading}>
                Additional Questions
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.fdcolumn}>
              <InputLabel className={classes.summaryLabel}>
                {additionalQuesAdded ? 'Added' : 'Added - None'}
              </InputLabel>
              <Box>
                <ul className={classes.addedList}>
                  {summaryinfo.get('items').get('designInfo') &&
                    summaryinfo
                      .get('items')
                      .get('designInfo')
                      .get('question') &&
                    summaryinfo
                      .get('items')
                      .get('designInfo')
                      .get('question')
                      .map((item, index) =>
                        item.get('isSelected') ? (
                          <li key={item.get('id')}>
                            {item.get('questionText') &&
                              item.get('questionText').map((qtext, qindex) => (
                                <Box key={qindex}>
                                  {item.get('questionText').size > 1
                                    ? `${qindex + 1}.`
                                    : null}{' '}
                                  {qtext}
                                </Box>
                              ))}
                          </li>
                        ) : null
                      )}
                </ul>
              </Box>
              <InputLabel className={classes.summaryLabel}>
                {additionalQuesRemoved ? 'Not Included' : null}
              </InputLabel>
              <Box>
                <ul className={classes.removed}>
                  {summaryinfo.get('items').get('designInfo') &&
                    summaryinfo
                      .get('items')
                      .get('designInfo')
                      .get('question') &&
                    summaryinfo
                      .get('items')
                      .get('designInfo')
                      .get('question')
                      .map((item, index) =>
                        !item.get('isSelected') ? (
                          <li key={item.get('id')}>
                            {item.get('questionText') &&
                              item
                                .get('questionText')
                                .map((qtext, qindex) => <Box>{qtext}</Box>)}
                          </li>
                        ) : null
                      )}
                </ul>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {/* standard */}
          {ohiVersion3or4 ? (
            <ExpansionPanel square>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="questionsac"
                id="questionsac"
                className={classes.acordContent}
              >
                <Typography className={classes.heading}>
                  Standard Demographics
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.fdcolumn}>
                <InputLabel className={classes.summaryLabel}>
                  {summaryinfo
                    .toJS()
                    ?.items?.designInfo?.standardDemographics?.some(
                      ({ isSelected }) => isSelected
                    )
                    ? 'Added'
                    : 'Added - None'}
                </InputLabel>
                <Box>
                  <ul className={classes.addedList}>
                    {summaryinfo
                      .toJS()
                      ?.items?.designInfo?.standardDemographics?.map(
                        ({ questionText, isSelected, id }, index) =>
                          isSelected ? (
                            <li key={`${id}-${index}`}>
                              {questionText.length && (
                                <Box key={index}>{questionText[0]}</Box>
                              )}
                            </li>
                          ) : null
                      )}
                  </ul>
                </Box>
                <InputLabel className={classes.summaryLabel}>
                  {summaryinfo
                    .toJS()
                    ?.items?.designInfo?.standardDemographics?.some(
                      ({ isSelected }) => !isSelected
                    )
                    ? 'Not Included'
                    : null}
                </InputLabel>
                <Box>
                  <ul className={classes.removed}>
                    {summaryinfo
                      ?.toJS()
                      ?.items?.designInfo?.standardDemographics?.map(
                        ({ questionText, isSelected, id }, index) =>
                          !isSelected ? (
                            <li key={`${id}-${index}`}>
                              {questionText.length && (
                                <Box key={index}>{questionText[0]}</Box>
                              )}
                            </li>
                          ) : null
                      )}
                  </ul>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
        </Box>
      </Box>
      {summaryinfo.get('items').size ? renderFormBottom() : null}
    </Box>
  )

  function renderFormBottom() {
    function goToClientinfo() {
      history.push(`../design-survey/${params}`)
    }
    const copytext = () => {
      var copyText = document.getElementById('surveyurl')
      copyText.select()
      document.execCommand('copy')
      setCopytxt('Link copied to clipboard')
    }
    return (
      <Box className={classes.surveylinkctr}>
        {/*<Box className={classes.commentWrap}>*/}
        {/*  <TextField*/}
        {/*    id="filled-full-width"*/}
        {/*    label="Comment"*/}
        {/*    style={{ margin: 8 }}*/}
        {/*    placeholder="Enter your comment for OHI helpdesk here"*/}
        {/*    fullWidth*/}
        {/*    margin="normal"*/}
        {/*    value={values.comment}*/}
        {/*    onChange={handlecomment('comment')}*/}
        {/*    InputLabelProps={{*/}
        {/*      shrink: true,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Box>*/}
        <Box display="flex" className={classes.formBottom}>
          <Box
            display="flex"
            className={classes.formBottom}
            style={{ borderTop: 'none', padding: '0px 20px' }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              style={{ flex: '1.5 1 0%' }}
            >
              <Box className={classes.surveylinkLabel}>Survey Link</Box>
              <div className={classes.surveyLink}>
                <Box
                  component="button"
                  className={classes.abs}
                  onClick={copytext}
                >
                  Copy Link
                </Box>
                <input
                  id="surveyurl"
                  type="text"
                  readOnly
                  value={
                    summaryinfo
                      .get('items')
                      .get('surveyInfo')
                      .get('surveyLink') || ''
                  }
                />

                {copytxt !== '' ? (
                  <Box className={classes.copytextshow}>
                    <Icon
                      style={{
                        fontSize: 19,
                        fontWeight: 'bold',
                        marginRight: 4,
                      }}
                    >
                      done
                    </Icon>
                    {copytxt}
                  </Box>
                ) : null}
                <Box className={classes.surveylinkinfo}>
                  This link is the basic OHI survey link, no designs choices
                  have been implemented at this stage
                </Box>
              </div>
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonRound}
                onClick={goToClientinfo}
              >
                Previous
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.buttonRound}
                onClick={() => saveNContinue()}
              >
                Save & Continue
              </Button>
            </Box>
          </Box>
        </Box>
        <Box justifyContent="flex-start" ml={7} mb={3} mt={2}>
          <Box
            style={{
              textAlign: 'left',
              color: '#999999',
              // marginTop: -25,
              marginLeft: 0,
              fontSize: '12px',
            }}
          >
            *Kindly adjust webpage Zoom if buttons are overlapping. Currently
            webpage is not optimized for smaller screens.
          </Box>
        </Box>
      </Box>
    )
  }
  function rendersurveyinfo() {
    return (
      <TabPanel value={tabvalue} index={1} className={classes.tabpanelctr}>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Is it a Resurvey?
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('isReSurvey') == true
              ? 'Yes'
              : 'No'}
          </Box>
        </Box>
        {summaryinfo
          .get('items')
          .get('surveyInfo')
          .get('isReSurvey') == true ? (
          <Box className={classes.itemValue}>
            <InputLabel className={classes.summaryLabel}>
              OHI Id for previous survey
            </InputLabel>
            <Box>
              {summaryinfo
                .get('items')
                .get('surveyInfo')
                .get('reSurveyId') == '0'
                ? '-'
                : summaryinfo
                    .get('items')
                    .get('surveyInfo')
                    .get('reSurveyId')}
            </Box>
          </Box>
        ) : null}
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Client Name for Survey
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('clientDisplayName')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>Languages</InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('languages')
              .map((item, index) =>
                summaryinfo
                  .get('items')
                  .get('surveyInfo')
                  .get('languages').size -
                  1 !==
                index
                  ? item + ', '
                  : item
              )}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Survey Administration
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('surveyAdmin')
              .map((item, index) =>
                summaryinfo
                  .get('items')
                  .get('surveyInfo')
                  .get('surveyAdmin').size -
                  1 !==
                index
                  ? item + ', '
                  : item
              )}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>Analyst</InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('analyst')
              .map((item, index) =>
                summaryinfo
                  .get('items')
                  .get('surveyInfo')
                  .get('analyst').size -
                  1 !==
                index
                  ? item + ', '
                  : item
              )}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Expert’s Name
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('expertName')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Expert’s Email ID
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('expertEmail')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Tentative Launch Date
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('startDate')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Tentative End Date
          </InputLabel>
          <Box>
            {' '}
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('endDate')}
          </Box>
        </Box>

        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>Survey Type</InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('surveyType')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Survey Population
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('population')}
          </Box>
        </Box>
        {summaryinfo
          .get('items')
          .get('surveyInfo')
          .get('subPopulation') ? (
          <Box className={classes.itemValue}>
            <InputLabel className={classes.summaryLabel}>Select One</InputLabel>
            <Box>
              {summaryinfo
                .get('items')
                .get('surveyInfo')
                .get('subPopulation')}
            </Box>
          </Box>
        ) : null}

        {summaryinfo
          .get('items')
          .get('surveyInfo')
          .get('subPopulationSpecify') ? (
          <Box className={classes.itemValue}>
            <InputLabel className={classes.summaryLabel}>Specify</InputLabel>
            <Box>
              {summaryinfo
                .get('items')
                .get('surveyInfo')
                .get('subPopulationSpecify')}
            </Box>
          </Box>
        ) : null}

        {summaryinfo
          .get('items')
          .get('surveyInfo')
          .get('sampleValue') > 0 ? (
          <Box className={classes.itemValue}>
            <InputLabel className={classes.summaryLabel}>
              What % was the sample?
            </InputLabel>
            <Box>
              {summaryinfo
                .get('items')
                .get('surveyInfo')
                .get('sampleValue')}
            </Box>
          </Box>
        ) : null}

        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Is this survey completed in one or multiple countries?
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('surveyCompletedCountries')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Main Country of Survey
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('countryName')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Region of Survey
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('region')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>Sub region 1</InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('subRegion1')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>Sub region 2</InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('surveyInfo')
              .get('subRegion2')}
          </Box>
        </Box>
      </TabPanel>
    )
  }
  function renderclientinfo() {
    return (
      <TabPanel value={tabvalue} index={0} className={classes.tabpanelctr}>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Is this a test survey
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('clientInfo')
              .get('isTestSurvey')
              ? 'Yes'
              : 'No'}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Opportunity Name
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('clientInfo')
              .get('opportunityName')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Opportunity Client Name
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('clientInfo')
              .get('opportunityClientName') == ''
              ? '-'
              : summaryinfo
                  .get('items')
                  .get('clientInfo')
                  .get('opportunityClientName')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>Client</InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('clientInfo')
              .get('clientName')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>Region</InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('clientInfo')
              .get('RegionName') == ''
              ? '-'
              : summaryinfo
                  .get('items')
                  .get('clientInfo')
                  .get('RegionName')}
          </Box>
        </Box>
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Mckinsey Sector Level 1
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('clientInfo')
              .get('McKinseySectorLevel1') == ''
              ? '-'
              : summaryinfo
                  .get('items')
                  .get('clientInfo')
                  .get('McKinseySectorLevel1')}
          </Box>
        </Box>
        {/*
        <Box className={classes.itemValue}>
          <InputLabel className={classes.summaryLabel}>
            Mckinsey Sector Level 2
          </InputLabel>
          <Box>
            {summaryinfo
              .get('items')
              .get('clientInfo')
              .get('McKinseySectorLevel2') == ''
              ? '-'
              : summaryinfo
                  .get('items')
                  .get('clientInfo')
                  .get('McKinseySectorLevel2')}
          </Box>
        </Box> */}
      </TabPanel>
    )
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    )
  }
}
export default memo(SummaryDetails)
