import {
  INTRODUCTION_FETCH,
  FETCH_SIDE_PANEL_DATA,
  FETCH_SIDE_PANEL_DATA_SUCCESS,
  FETCH_SIDE_PANEL_DATA_ERROR,
  FETCH_INTRO_DATA_LANG,
  FETCH_INTRO_DATA_LANG_SUCCESS,
  FETCH_INTRO_DATA_LANG_ERROR,
  SET_SELECTED_LANG,
  SAVE_SURVEY_INTRODUCTION,
  SAVE_SURVEY_INTRODUCTION_SUCCESS,
  SAVE_SURVEY_INTRODUCTION_ERROR,
  HAS_EDITOR_STATE_CHANGED,
  FETCH_SUMMARY_OF_EDITS,
  IMPORT_FILE,
  EXPORT_FILE,
  REPLACE_EVERYWHERE,
  IMPORT_FILE_FAILURE,
  TOGGLE_IMPORT_FILE_FAILURE_CONTAINER,
  SEND_EMAIL,
} from './constants'

//GET INTRODUCTION PARAGRAPHS
export const introductionFetch = surveyId => {
  return {
    type: `${INTRODUCTION_FETCH}_PENDING`,
    surveyId,
  }
}

export const introductionFetchSuccess = data => ({
  type: `${INTRODUCTION_FETCH}_SUCCESS`,
  data,
})

export const introductionFetchFailed = () => ({
  type: `${INTRODUCTION_FETCH}_FAILED`,
})

export function fetchSidePanelData(surveyId) {
  return {
    type: FETCH_SIDE_PANEL_DATA,
    surveyId,
  }
}

export function sidePanelDataSuccess(data) {
  return {
    type: FETCH_SIDE_PANEL_DATA_SUCCESS,
    data,
  }
}

export function sidePanelDataError(error) {
  return {
    type: FETCH_SIDE_PANEL_DATA_ERROR,
    error,
  }
}

export function fetchIntroDataForLang({ languageId, surveyId }) {
  return {
    type: FETCH_INTRO_DATA_LANG,
    languageId,
    surveyId,
  }
}
export function fetchIntroDataForLangSuccess({ data, languageId }) {
  return {
    type: FETCH_INTRO_DATA_LANG_SUCCESS,
    data,
    languageId,
  }
}
export function fetchIntroDataForLangError(error) {
  return {
    type: FETCH_INTRO_DATA_LANG_ERROR,
    error,
  }
}

export function setSelectedLang(languageId) {
  return {
    type: SET_SELECTED_LANG,
    languageId,
  }
}

export function updateSurvey(
  dataFromEditorState,
  surveyId,
  languageId,
  isSync,
  isMultiLanguage,
  outcomeId
) {
  return {
    type: SAVE_SURVEY_INTRODUCTION,
    surveyId,
    languageId,
    dataFromEditorState,
    isSync,
    isMultiLanguage,
    outcomeId,
  }
}

export function saveSurveyIntroductionSuccess(response) {
  return {
    type: SAVE_SURVEY_INTRODUCTION_SUCCESS,
    response,
  }
}

export function saveSurveyIntroductionFailure(error) {
  return {
    type: SAVE_SURVEY_INTRODUCTION_ERROR,
    error,
  }
}

export function setHasEditorStateChanged(hasEditorStateChanged) {
  return {
    type: HAS_EDITOR_STATE_CHANGED,
    hasEditorStateChanged,
  }
}

export function importFile(surveyId, file, pathName, toggleState, object) {
  return {
    type: IMPORT_FILE,
    file,
    surveyId,
    pathName,
    toggleState,
    object,
  }
}

export function exportFile(payload, surveyId) {
  return {
    type: EXPORT_FILE,
    payload,
    surveyId,
  }
}

export const fetchSummaryOfEdits = (isSync, languageId) => {
  return {
    type: FETCH_SUMMARY_OF_EDITS,
    isSync,
    languageId,
  }
}

export function replaceEverywhere({
  ignoreCase,
  selectedLanguageId,
  replaceText,
  findText,
  toggleState,
}) {
  return {
    type: REPLACE_EVERYWHERE,
    isCaseSensitive: !ignoreCase,
    replaceFrom: findText,
    replaceTo: replaceText,
    langId: selectedLanguageId,
    toggleState,
  }
}

export function importFileFailure(data) {
  return {
    type: IMPORT_FILE_FAILURE,
    data,
  }
}

export function toggleImportFileFailureContainer() {
  return {
    type: TOGGLE_IMPORT_FILE_FAILURE_CONTAINER,
  }
}

export const sendEmail = surveyId => ({
  type: `${SEND_EMAIL}_PENDING`,
  surveyId,
})

export const successSendEmail = () => ({
  type: `${SEND_EMAIL}_SUCCESS`,
})

export const failedSendEmail = () => ({
  type: `${SEND_EMAIL}_FAILED`,
})
