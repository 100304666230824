import {
  CLIENTS_FETCH,
  CLIENTS_SURVEY_FETCH,
  CLIENTS_CREATE,
  CLIENTINFO_FETCH,
  SAVECLIENTIFO_CREATE,
  SURVEYNAME_FETCH,
  SURVEYNAMEUPDATE_CREATE,
  GETOPPORTUNITY_FETCH,
} from './constants'

//GET OPPORTUNITY
export const opportunityFetch = payload => ({
  type: `${GETOPPORTUNITY_FETCH}_PENDING`,
  payload,
})

export const opportunityFetchSuccess = payload => ({
  type: `${GETOPPORTUNITY_FETCH}_SUCCESS`,
  payload,
})

export const opportunityFetchFailed = payload => ({
  type: `${GETOPPORTUNITY_FETCH}_FAILED`,
  payload,
})

// SURVEY NAME UPDATE
export const surveynameUpdate = (payload, data) => ({
  type: `${SURVEYNAMEUPDATE_CREATE}_PENDING`,
  payload,
  data,
})

export const surveynameUpdateSuccess = (payload, data) => ({
  type: `${SURVEYNAMEUPDATE_CREATE}_SUCCESS`,
  payload,
  data,
})

export const surveynameUpdateFailed = (payload, data) => ({
  type: `${SURVEYNAMEUPDATE_CREATE}_FAILED`,
  payload,
  data,
})

//GET SURVEY NAME
export const surveynameFetch = payload => ({
  type: `${SURVEYNAME_FETCH}_PENDING`,
  payload,
})

export const surveynameFetchSuccess = payload => ({
  type: `${SURVEYNAME_FETCH}_SUCCESS`,
  payload,
})

export const surveynameFetchFailed = payload => ({
  type: `${SURVEYNAME_FETCH}_FAILED`,
})

// SAVE CLIENT INFO
export const clientInfoSave = payload => ({
  type: `${SAVECLIENTIFO_CREATE}_PENDING`,
  payload,
})

export const clientInfoSaveSuccess = payload => ({
  type: `${SAVECLIENTIFO_CREATE}_SUCCESS`,
  payload,
})

export const clientInfoSaveFailed = payload => ({
  type: `${SAVECLIENTIFO_CREATE}_FAILED`,
  payload,
})

// client info
export const clientInfoFetch = payload => ({
  type: `${CLIENTINFO_FETCH}_PENDING`,
  payload,
})

export const clientInfoFetchSuccess = payload => ({
  type: `${CLIENTINFO_FETCH}_SUCCESS`,
  payload,
})

export const clientInfoFetchFailed = payload => ({
  type: `${CLIENTINFO_FETCH}_FAILED`,
  payload,
})
// CLIENTS_STATUS_FETCH

export const clientsFetch = payload => ({
  type: `${CLIENTS_FETCH}_PENDING`,
  payload,
})

export const clientsFetchSuccess = payload => ({
  type: `${CLIENTS_FETCH}_SUCCESS`,
  payload,
})

export const clientsFetchFailed = payload => ({
  type: `${CLIENTS_FETCH}_FAILED`,
  payload,
})

export const clientsSurveyFetch = payload => ({
  type: `${CLIENTS_SURVEY_FETCH}_PENDING`,
  payload,
})

export const clientsSurveyFetchSuccess = payload => ({
  type: `${CLIENTS_SURVEY_FETCH}_SUCCESS`,
  payload,
})

export const clientsSurveyFetchFailed = payload => ({
  type: `${CLIENTS_SURVEY_FETCH}_FAILED`,
  payload,
})

export const clientsCreate = payload => ({
  type: `${CLIENTS_CREATE}_PENDING`,
  payload,
})

export const clientsCreateSuccess = payload => ({
  type: `${CLIENTS_CREATE}_SUCCESS`,
  payload,
})

export const clientsCreateFailed = payload => ({
  type: `${CLIENTS_CREATE}_FAILED`,
  payload,
})
