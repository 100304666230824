import moment from 'moment'
export default function validate(values) {
  let errors = {}
  // ohiId
  if (values.isReSurvey === true && !values.ohiId) {
    errors.ohiId = 'OHI ID is required'
  }
  // clientName

  if (!values.clientName) {
    errors.clientName = 'This field is required'
    /* } else if (!/^[a-zA-Z0-9- ]*$/.test(values.clientName)) {
    errors.clientName = 'Client name must contain only letters and numbers.' */
  } else if (values.clientName.length > 60) {
    errors.clientName = 'Max character limit: 60'
  }
  // Languages
  if (values.languages.length < 1) {
    errors.languages = 'This field is required.'
  }

  // surveyAdmin
  if (values.surveyAdmin.length < 1) {
    errors.surveyAdmin = 'This field is required.'
  }
  // Analysts
  if (values.analyst.length < 1) {
    errors.analyst = 'This field is required.'
  }
  // Expert Name
  /*  if (!values.expertName) {
    errors.expertName = 'This field is required.'
  } */

  const startdate = moment(values.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
  const enddate = moment(values.endDate, 'DD/MM/YYYY').format('MM/DD/YYYY')

  // startDate
  if (!values.startDate) {
    errors.startDate = 'This field is required.'
  }
  if (!values.endDate) {
    errors.endDate = 'This field is required.'
  } else if (
    new Date(startdate).getTime() >= new Date(enddate).getTime() &&
    values.pId === ''
  ) {
    errors.endDate = 'End date must be greater than launch date..'
  }
  // Survey Type
  if (values.surveyType < 4) {
    errors.surveyType = 'This field is required.'
  }
  // Population
  if (!values.population) {
    errors.population = 'This field is required.'
  }
  if (
    !values.subPopulation &&
    (values.population === 2 || values.population === 4)
  ) {
    errors.subPopulation = 'This field is required.'
  }
  // isMultipleCountries
  if (values.isMultipleCountries < 1) {
    errors.isMultipleCountries = 'This field is required.'
  }
  // MainCountry
  if (!values.countryId) {
    errors.countryId = 'This field is required.'
  }
  // Region of Survey
  if (!values.regionId) {
    errors.regionId = 'This field is required.'
  }
  // Surb 1 Region of Survey
  if (!values.subRegion1Id) {
    errors.subRegion1Id = 'This field is required.'
  }
  // Surb 2 Region of Survey
  if (!values.subRegion2Id) {
    errors.subRegion2Id = 'This field is required.'
  }
  // Sample Value
  if (values.sampleValue > 100) {
    errors.subRegion1Id = 'Sample % must less than or equal to 100.'
  }
  if (
    values.sampleValue <= 0 &&
    (values.population === 3 || values.population === 4)
  ) {
    errors.sampleValue = 'Sample % must greater than 0.'
  }
  if (
    !values.sampleValue &&
    (values.population === 3 || values.population === 4)
  ) {
    errors.sampleValue = 'This field is required.'
  }
  //Email
  /* if (!values.expertEmail) {
    errors.expertEmail = 'Email address is required'
  } else if (!/\S+@\S+\.\S+/.test(values.expertEmail)) {
    errors.expertEmail = 'Email address is invalid'
  } */

  return errors
}
