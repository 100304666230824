import { makeStyles } from '@material-ui/core/styles'

const tabstyles = makeStyles(theme => {
  return {
    root: {
      textAlign: 'left',
      fontSize: 12,
      color: '#333333',
      position: 'relative',
      flexDirection: 'column',
      marginTop: '1.5vh',
    },
    boxRoot: {
      padding: '15px 0',
    },
    questionTextBox: {
      fontSize: 15,
      fontWeight: 400,
    },
    indicator: {
      border: '2px solid #2251FF',
    },
    TabRoot: {
      borderBottom: '1px solid #B3B3B3',
    },
    tbselected: {
      color: '#2251FF',
      textAlign: 'left',
    },
    checked: {
      color: '#021c4b !important',
    },
    accordionDetail: {
      flexDirection: 'column',
      padding: '8px 16px 0',
    },
    accordionSummaryRoot: {
      padding: '0',
      paddingLeft: '4px',
      borderBottom: '1px solid #B3B3B3',
      ' .expandIconWrapper': {
        display: 'none',
      },
    },
    accordionlabel: {
      color: '#4D4D4D',
      fontSize: '15px',
    },
    accordionRoot: {
      background: 'transparent',
      boxShadow: 'none',
    },
    tbbutton: {
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '30px',
      maxWidth: 'fit-content',
      textTransform: 'capitalize',
    },
    tooltipicongray: {
      marginRight: '10px',
      verticalAlign: 'bottom',
      width: '11px',
    },
    tooltipicon: {
      width: '11px',
      marginLeft: '10px',
    },
    checkboxOptionName: {
      marginTop: '2vh',
    },
    bulletIcon: {
      fontSize: 12,
      color: '#021c4b',
    },
    orgExp: {
      margin: 20,
      fontSize: '1rem',
      fontFamily: 'Roboto Helvetica Arial sans-serif',
      fontWeight: '400',
      lineHeight: '1.5rem',
      leteerSpacing: '0.00938em !important',
    },
    tabCheckWrapper: {
      display: 'flex',
    },
    tabCheckCount: {
      transform: 'translate(0,-2px)',
      borderRadius: '50%',
      background: '#000',
      color: '#fff',
      marginLeft: '10px',
      width: '24px',
      height: '24px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      fontSize: '0.8rem',
    },
    tabCheckCountEmpty: {
      marginLeft: '10px',
      width: '24px',
      height: '24px',
    },
  }
})

export default tabstyles
