import { removeBrs } from '../../utils'
const paraStart = '<p>'
const paraEnd = '</p>'
const instructionsHeading = '<h2>Instructions</h2>'
const outcomeInstructionHeading = '<h2>Instructions for Outcome</h2>'
const practiceInstructionHeading = '<h2>Instructions for Practice</h2>'
const outcomeHeading = '<h2>Outcome</h2>'
const practiceHeading = '<h2>Practice</h2>'
const startTagForList = '<li><span>'
const endTagForList = '</span></li>'
const olStartTag = '<ol>'
const olEndTag = '</ol>'

const transformData = data => {
  data.instruction = data.instruction ? removeBrs(data.instruction) : ''
  data.practiceInstruction = data.practiceInstruction
    ? removeBrs(data.practiceInstruction)
    : ''
  for (var i = 0; i < data.outcomes.length; i++) {
    data.outcomes[i].text = removeBrs(data.outcomes[i].text)
  }
  for (var j = 0; j < data.practices.length; j++) {
    data.practices[j].text = removeBrs(data.practices[j].text)
  }
  const transformedInstructionSection = instructionsHeading.concat(
    transformComponent(data.instruction, paraStart, paraEnd)
  )

  const transformed_Outcome_Section = get_transformed_Outcome_Section(data)

  const transformed_Practice_Section = get_transformed_Practice_Section(data)
  const numberOfBlocksElement =
    2 +
    (data.practices.length > 0 ? data.practices.length + 3 : 0) +
    (data.outcomes.length ? data.outcomes.length + 3 : 0)
  const updatedData =
    transformedInstructionSection +
    transformed_Outcome_Section +
    transformed_Practice_Section
  return { data: updatedData, numberOfBlocksElement: numberOfBlocksElement }
}

const transformComponent = (component, startTag, endTag) => {
  const positionStart = 0
  const componentWithStartingTag = [
    component.slice(0, positionStart),
    startTag,
    component.slice(positionStart),
  ].join('')
  const positionEnd = componentWithStartingTag.length
  const componentWithTags = [
    componentWithStartingTag.slice(0, positionEnd),
    endTag,
    componentWithStartingTag.slice(positionEnd),
  ].join('')
  return componentWithTags
}

const transformOrderedList = (arrayData, startTag, endTag) => {
  const listWithTag = arrayData.map(obj => {
    const positionStart = 0
    const componentWithStartingTag = [
      obj.text.slice(0, positionStart),
      startTag,
      obj.text.slice(positionStart),
    ].join('')
    const positionEnd = componentWithStartingTag.length
    const componentWithTags = [
      componentWithStartingTag.slice(0, positionEnd),
      endTag,
      componentWithStartingTag.slice(positionEnd),
    ].join('')
    return componentWithTags
  })
  return listWithTag.join('')
}

const get_transformed_Outcome_Section = data => {
  if (data.outcomes.length !== 0) {
    const transformed_Outcomes_Instruction = outcomeInstructionHeading.concat(
      transformComponent(data.outcomeInstruction, paraStart, paraEnd)
    )

    const transformed_Outcomes_Li_Elements = transformOrderedList(
      data.outcomes,
      startTagForList,
      endTagForList
    )
    const transformed_Outcomes_Ordered_List = outcomeHeading.concat(
      transformComponent(transformed_Outcomes_Li_Elements, olStartTag, olEndTag)
    )

    return transformed_Outcomes_Instruction + transformed_Outcomes_Ordered_List
  }
  return ''
}

const get_transformed_Practice_Section = data => {
  if (data.practices.length !== 0) {
    const transformed_Practices_Instruction = practiceInstructionHeading.concat(
      transformComponent(data.practiceInstruction, paraStart, paraEnd)
    )

    const transformed_Practices_Li_Elements = transformOrderedList(
      data.practices,
      startTagForList,
      endTagForList
    )
    const transformed_Practices_Ordered_List = practiceHeading.concat(
      transformComponent(
        transformed_Practices_Li_Elements,
        olStartTag,
        olEndTag
      )
    )

    return (
      transformed_Practices_Instruction + transformed_Practices_Ordered_List
    )
  }
  return ''
}

const setPayloadForOHITexts = (originalOHITexts, editorStateForOhi) => {
  let indexForPractice = 0
  const sanitisedEditorState = editorStateForOhi
    .replace('<h2>Instructions</h2>', '')
    .replace('<h2>Instructions for Outcome</h2>', '')
    .replace('<h2>Outcome</h2>', '')
    .replace('<h2>Instructions for Practice</h2>', '')
    .replace('<h2>Practice</h2>', '')
  const extractedInstructionsParagraphs = sanitisedEditorState
    .match(new RegExp('<p>' + '(.*)' + '</p>', 'g'))
    .map(p => {
      const newPara = p.replace('<p>', '').replace('</p>', '')
      return newPara
    })
  const extractedOutcomesAndPracticesLists = sanitisedEditorState
    .match(new RegExp('<li>' + '(.*)' + '</li>', 'g'))
    .map(li => {
      const newList = li.replace('<li>', '').replace('</li>', '')
      return newList
    })
  let newOriginalOHITexts = originalOHITexts
  newOriginalOHITexts.instruction = extractedInstructionsParagraphs[0]
  newOriginalOHITexts.outcomeInstruction = originalOHITexts.outcomes.length
    ? extractedInstructionsParagraphs[1]
    : ''
  newOriginalOHITexts.practiceInstruction =
    originalOHITexts.outcomes.length && originalOHITexts.practices.length
      ? extractedInstructionsParagraphs[2]
      : originalOHITexts.practices.length
      ? extractedInstructionsParagraphs[1]
      : ''
  extractedOutcomesAndPracticesLists.forEach((list, index) => {
    if (index < newOriginalOHITexts.outcomes.length) {
      newOriginalOHITexts.outcomes[index].text = list
    } else {
      newOriginalOHITexts.practices[indexForPractice].text = list
      indexForPractice++
    }
  })
  return {
    instruction: newOriginalOHITexts.instruction,
    outcomeInstruction: newOriginalOHITexts.outcomeInstruction,
    outcomes: newOriginalOHITexts.outcomes,
    practiceInstruction: newOriginalOHITexts.practiceInstruction,
    practices: newOriginalOHITexts.practices,
  }
}

export { transformData, setPayloadForOHITexts }
