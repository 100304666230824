import React from 'react'
import { Grid } from '@material-ui/core'

import SwitchToggle from '../SwitchToggle'

function SwitchToggleSet({
  isChecked,
  onChange,
  name,
  disabled,
  text: { txtOn, txtOff, ariaLabel },
}) {
  return (
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>{txtOff}</Grid>
      <Grid item>
        <SwitchToggle
          checked={isChecked}
          onChange={onChange(name)}
          disabled={disabled}
          value={name}
          inputProps={{ 'aria-label': ariaLabel }}
        />
      </Grid>
      <Grid item>{txtOn}</Grid>
    </Grid>
  )
}

export default SwitchToggleSet
