export const FETCH_OHI_OUTCOME_DATA = 'FETCH_OHI_OUTCOME_DATA'
export const FETCH_OHI_OUTCOME_DATA_SUCCESS = 'FETCH_OHI_OUTCOME_DATA_SUCCESS'
export const FETCH_OHI_OUTCOME_DATA_ERROR = 'FETCH_OHI_OUTCOME_DATA_ERROR'
export const FETCH_OHI_OUTCOMES_DATA_LANG_SUCCESS =
  'FETCH_OHI_OUTCOMES_DATA_LANG_SUCCESS'
export const FETCH_OHI_OUTCOMES_DATA_LANG_ERROR =
  'FETCH_OHI_OUTCOMES_DATA_LANG_ERROR'
export const FETCH_OHI_OUTCOMES_DATA_LANG = 'FETCH_OHI_OUTCOMES_DATA_LANG'
export const SET_SELECTED_LANG = 'SET_SELECTED_LANG'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const REPLACE_EVERYWHERE = 'REPLACE_EVERYWHERE_OHI'
