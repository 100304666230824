import {
  SAVE_SUMMARY_OF_EDITS_FOR_DIFFERENT_LANG,
  TOGGLE_SUMMARY_OF_EDITS,
  SAVE_SUMMARY_OF_EDITS,
} from '../common/constants'

const INITIAL_STATE = {
  summaryOfEditsIsOpened: false,
  summary: null,
  availableLanguages: [],
  baseLanguageKey: '',
  isSync: false,
}

const formatDate = summary => {
  return summary.map(summaryElement => {
    let { date } = summaryElement
    if (date.length > 0) {
      const split = date.split('/')
      date = new Date(
        split[1]
          .concat('/')
          .concat(split[0])
          .concat('/')
          .concat(split[2])
      ).toDateString()
      summaryElement.date = date
    }
    return summaryElement
  })
}

const summaryOfEdits = (state = INITIAL_STATE, { type, summary, isSync }) => {
  switch (type) {
    case TOGGLE_SUMMARY_OF_EDITS:
      return {
        ...state,
        summaryOfEditsIsOpened: !state.summaryOfEditsIsOpened,
      }
    case SAVE_SUMMARY_OF_EDITS: {
      return {
        ...state,
        summary: formatDate(summary.summary),
        availableLanguages: summary.availableLanguages,
        baseLanguageKey: summary.baseLanguageKey,
        baseLanguageValue: summary.baseLanguageValue,
        isSync: isSync,
      }
    }
    case SAVE_SUMMARY_OF_EDITS_FOR_DIFFERENT_LANG: {
      return {
        ...state,
        summary: formatDate(summary.summary),
        isSync: isSync,
      }
    }
    default:
      return state
  }
}

export default summaryOfEdits
