import { makeStyles, createStyles } from '@material-ui/core/styles'
import { maxWidth } from '@material-ui/system'

const styles = makeStyles(theme => {
  return {
    root: {
      padding: '48px 146px 110px 69px',
      fontSize: 12,
      color: '#333333',
      paddingBottom: '110px',
      position: 'relative',
      textAlign: 'left',
      width: '100%',
    },
    myhelpText: {
      color: '#ff0000',
      width: '100%',
    },
    tooltipicon: {
      width: '11px',
      marginLeft: '10px',
    },
    csttooltip: {
      /* backgroundColor:'#333' */
    },
    sectionHeading: {
      fontSize: 20,
      fontWeight: 900,
      marginBottom: '38px',
    },
    myhelptextLanguage: {
      color: '#999',
      position: 'absolute',
      right: 0,
      top: 0,
      marginTop: 0,
    },
    inputSet: {
      marginBottom: theme.spacing(5),
    },
    inputLabel: {
      color: '#999999',
      marginBottom: theme.spacing(1),
    },
    fullWidth: {
      width: '100%',
    },
    mb40: {
      marginBottom: 40,
    },
    formBottom: {
      borderTop: '1px solid #dddddd',
      padding: theme.spacing(4, 5),
      position: 'absolute',
      width: 'calc(100% - 96px)',
      bottom: 0,
      left: '48px',
    },
    buttonRound: {
      borderRadius: '25px',
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: 14,
    },
    icon: {
      fontSize: '18px',
      marginRight: '5px',
    },
    divider: {
      height: theme.spacing(2),
    },

    qtypelable: {
      fontSize: 14,
    },

    checked: {
      '&, & + $label': {
        color: '#333333',
        transition: 'all 0.4s ease-in-out',
      },
    },
    label: {
      fontSize: 16,
      fontWeight: 700,
      color: '#999999',
      transition: 'all 0.4s ease-in-out',
      marginRight: 50,
    },
    addquestionbtn: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: 12,
      fontWeight: 700,
      color: '#4b93fd',
      width: 'auto',
      position: 'absolute',
      left: 25,
      bottom: 15,
      textTransform: 'uppercase',
      padding: 0,
    },
    questiontxtmatrix: {
      marginLeft: 25,
      marginBottom: 40,
      '& input': {
        fontSize: '18px',
        fontWeight: 700,
      },
    },
    matrixquestNumber: {
      position: 'absolute',
      left: '-25px',
      fontSize: '18px',
      fontWeight: 700,
      display: 'inline',
      bottom: 4,
    },
    input: {
      fontSize: '18px',
      fontWeight: 700,
    },
    customWidth: {
      padding: '0 !important',
      maxWidth: 500,
    },
    // shamatooltip:{
    //   width:'5000px',
    //   height:'250px'
    // },
    noMaxWidth: {
      maxWidth: 'none',
    },
    maxWidth: {
      maxWidth: 500,
    },
    marginTop: {
      fontSize: 14,
      marginTop: '5px',
    },
    // fullwidth:{

    // }
  }
})

export default styles
