import { makeStyles } from '@material-ui/core/styles'
import introImg from './assets/login-image@3x.png'
const useStyles = makeStyles(theme => {
  return {
    LoginCtrl: {
      display: 'flex',
      flexDirection: 'row',
    },
    loginSection: {
      display: 'flex',
      width: '50%',
      height: '100vh',
      alignItems: 'center',
    },
    loginbtnCenter: {
      flex: '1',
      width: 300,
    },
    loginMeta: {
      padding: '30px 0',
      margin: 0,
      fontSize: 18,
      width: '60%',
      left: '20%',
      position: 'relative',
    },
    loginMetaPdf: {
      marginRight: 8,
    },
    sectionHeading: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 40,
    },
    introSection: {
      display: 'flex',
      flex: '1 1 0%',
      backgroundImage: `url(${introImg})`,
      backgroundPosition: 'right top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 86vh',
    },
    introimg: {
      maxHeight: '100vh',
      opacity: 0,
    },
  }
})

export default useStyles
