import { RESET_DATA, UPLOAD_BACKCODING } from '../common/constants'
const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: false,
  isUploaded: false,
}

export const UploadBackcodingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${UPLOAD_BACKCODING}_PENDING`:
      return { ...state, isLoading: true }
    case `${UPLOAD_BACKCODING}_SUCCESS`:
      return { ...state, isLoading: false, data: action.data, isUploaded: true }
    case `${UPLOAD_BACKCODING}_FAILED`:
      return { ...state, isLoading: false, error: true }
    case `${RESET_DATA}`:
      return {
        ...state,
        isLoading: false,
        data: {},
        isUploaded: false,
        error: false,
      }
    default:
      return state
  }
}
