import AddOnsIcon from '../../assets/AddOnsIcon.svg'
import AdditionalQuestionsIcon from '../../assets/AdditionalQuestionsIcon.svg'
import IntroductionIcon from '../../assets/IntroductionIcon.svg'
import OHIIcon from '../../assets/OHIIcon.svg'
import SummaryIcon from '../../assets/SummaryIcon.png'
import ClientInfoIcon from '../../assets/ClientInfoIcon.png'
import DesignSurveyIcon from '../../assets/DesignSurveyIcon.png'
import SurveyInfoIcon from '../../assets/SurveyInfoIcon.png'

export const defineLinksData = [
  {
    label: 'Client Information',
    route: '/define/client-info',
    icon: ClientInfoIcon,
  },
  {
    label: 'Survey Information',
    route: '/define/survey-info',
    icon: SurveyInfoIcon,
  },
  {
    label: 'Design Survey',
    route: '/define/design-survey',
    icon: DesignSurveyIcon,
  },
  {
    label: 'Summary',
    route: '/define/summary',
    icon: SummaryIcon,
  },
]

export const textUpdateLinksData = [
  {
    label: 'Introduction',
    route: '/update-survey-text/introduction',
    icon: IntroductionIcon,
  },
  {
    label: 'OHI',
    route: '/update-survey-text/ohi',
    icon: OHIIcon,
  },
  {
    label: 'Deep Dive',
    route: '/update-survey-text/add-ons',
    icon: AddOnsIcon,
  },
  {
    label: 'Additional Questions',
    route: '/update-survey-text/additional-ques',
    icon: AdditionalQuestionsIcon,
  },
]

export const Base_Route = '/update-survey-text'
export const UpdateSurveyText = 'update-survey-text'
export const IconObj = {
  AddOnsIcon: AddOnsIcon,
  AdditionalQuestionsIcon: AdditionalQuestionsIcon,
  OptionalQuestionsIcon: AdditionalQuestionsIcon,
  IntroductionIcon: IntroductionIcon,
  InstructionsIcon: IntroductionIcon,
  OHI4_0_SectionsIcon: OHIIcon,
  OHIIcon: OHIIcon,
  StandardDemographicsIcon: OHIIcon,
}
export const OUTCOMEID = '?outcomeId'
