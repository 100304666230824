import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const parseQueryString = string => {
  return string
    .slice(1)
    .split('&')
    .map(queryParam => {
      let queryArray = queryParam.split('=')
      return { key: queryArray[0], value: queryArray[1] }
    })
    .reduce((query, queryArray) => {
      query[queryArray.key] = queryArray.value
      return query
    }, {})
}

const removeBrs = string => {
  const char1 = string.slice(string.length - 4, string.length)
  const char2 = string.slice(string.length - 5, string.length)
  if (char1 === '<br>') {
    string = string.slice(0, string.length - 4)
  }
  if (char2 === '<br/>') {
    string = string.slice(0, string.length - 5)
  }
  if (char1 !== '<br>' && char2 !== '<br/>') {
    return string
  }
  string = removeBrs(string)
  return string
}

const validateLaunchDate = (date = '') => {
  const td = new Date()
  const dd = td.getDate()
  const md = td.getMonth() + 1
  const yy = td.getFullYear()
  let currentDate = md + '/' + dd + '/' + yy
  currentDate = new Date(currentDate)
  const date1 = date.split('/')
  const launchDate = new Date(date1[1] + '/' + date1[0] + '/' + date1[2])
  let endDate = '',
    noOfDaysToAdd = 4,
    count = 0
  while (count < noOfDaysToAdd) {
    endDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    if (endDate.getDay() != 0 && endDate.getDay() != 6) {
      count++
    }
  }
  return launchDate.getTime() >= endDate.getTime()
}

const parseJwt = (token = '') => {
  if (!token) return {}
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const exportSheet = (arrayData, fileName, heading) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const data = XLSX.utils.json_to_sheet(arrayData)
  // json data
  const sheetInformation = { Sheets: { data: data }, SheetNames: ['data'] }
  // column name
  XLSX.utils.sheet_add_aoa(data, heading, { origin: 'A1' })
  //write data in sheet
  const excelBuffer = XLSX.write(sheetInformation, {
    bookType: 'xlsx',
    type: 'array',
  })
  const fileData = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(fileData, fileName + fileExtension)
}

export { parseQueryString, removeBrs, validateLaunchDate, parseJwt }
