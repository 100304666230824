import moment from 'moment'

export const TABLE_HEADERS = [
  {
    name: 'Survey ID',
    key: 'ohiId',
  },
  {
    name: 'Opportunity name',
    key: 'opportunityname',
  },
  {
    name: 'Opportunity client name',
    key: 'Opportunityclientname',
  },
  {
    name: 'Survey Creation Portal',
    key: 'isSelfServe',
  },
  // {
  //   name: 'OHI self-served',
  //   key: 'ohiSelfServe',
  // },
  {
    name: '#&nbsp;Respondents invited',
    key: 'Invited_Respondents',
  },
  {
    name: '#&nbsp;Completed surveys',
    key: 'TotalComplete',
  },
  {
    name: 'Response Rate (%)',
    key: 'Response_Rate',
  },
  {
    name: 'Is this resurvey',
    key: 'isResurvey',
  },
  {
    name: 'Client Region',
    key: 'Region',
  },
  {
    name: 'Client Region L1',
    key: 'SubRegion1Id',
  },
  {
    name: 'Client Region L2',
    key: 'SubRegion2Id',
  },
  {
    name: 'Base language',
    key: 'BaseLanguage',
  },
  {
    name: 'Languages',
    key: 'Languages',
  },
  {
    name: 'Survey Population',
    key: 'Population',
  },
  {
    name: 'Sub-population surveyed',
    key: 'SubPopulation',
  },
  {
    name: 'Is this a sample population?',
    key: 'IsSample',
  },
  {
    name: 'McKinsey Sector L1',
    key: 'MckinseySectorLevel1',
  },
  {
    name: 'McKinsey Sector L2',
    key: 'MckinseySectorLevel2',
  },
  {
    name: 'Launch date',
    key: 'launchDate',
  },
  {
    name: 'Closing date',
    key: 'closeDate',
  },
  // {
  //   name: 'Closing date',
  //   key: 'Interview_EndDateTime',
  // },
  {
    name: 'Is survey live?',
    key: 'isLive',
  },
  {
    name: 'Closing protocol completed?',
    key: 'closingProtocol',
  },
  {
    name: 'Client name',
    key: 'ClientName',
  },
  {
    name: 'Legal checks completed?',
    key: 'legalCheck',
  },
  {
    name: 'Is benchmarking allowed?',
    key: 'BenchmarkingAllowed',
  },
  {
    name: 'Data protocols?',
    key: 'OsDataProtocol',
  },
  {
    name: 'Geographic restriction?',
    key: 'GeoRestriction',
  },
  {
    name: 'Data restrictions?',
    key: 'DataRestriction',
  },
  {
    name: 'Mask client name in GSDB?',
    key: 'MaskClientNameGSDB',
  },
  {
    name: 'OrgScience allowed?',
    key: 'OrgSciAllowed',
  },
  {
    name: 'Analyst name',
    key: 'Analystname',
  },
  {
    name: 'Expert name',
    key: 'ExpertName',
  },
  {
    name: 'Main survey country',
    key: 'MainCountrySurveyCompleted',
  },
  {
    name: 'Sample %',
    key: 'SampleValue',
  },
  {
    name: 'Engagement Module',
    key: 'MOD_Engagement',
  },
  {
    name: 'Inclusion Module',
    key: 'MOD_Inclusion',
  },
  {
    name: 'Roadblocks Module',
    key: 'MOD_Roadblocks',
  },
  {
    name: 'Values Module',
    key: 'MOD_Values',
  },
  {
    key: 'MOD_O2VModule',
    name: 'Org2Value',
  },
  {
    key: 'MOD_Influencer',
    name: 'Influencer',
  },
  {
    key: 'MOD_ExModule',
    name: 'Employee experience',
  },
  {
    key: 'MOD_LTE',
    name: 'LTE',
  },
  {
    key: 'MOD_MA',
    name: 'Maturity Assessment',
  },
  {
    name: 'Last updated',
    key: 'Last_updated_at',
  },
  {
    name: 'OHI Version',
    key: 'OHI_Version',
  },
  {
    key: 'ohiCategory',
    name: 'Product',
  },
  {
    key: 'ohiCategoryQty',
    name: 'Number of products?',
  },
  {
    key: 'ohiSDM',
    name: 'SDM days added?',
  },
  {
    key: 'ohiSDMQty',
    name: '#&nbsp;SDM days',
  },
  {
    key: 'ohiAnalyst',
    name: 'Analyst days added?',
  },
  {
    key: 'ohiAnalystQty',
    name: '#&nbsp;Analyst days',
  },

  {
    key: 'surveyStatus',
    name: 'Survey status',
  },
  {
    key: 'surveyType',
    name: 'Survey type',
  },
  {
    key: 'surveyAdmin',
    name: 'Survey admin',
  },
  {
    key: 'closingProtocolClosedDate',
    name: 'Closing protocol submission date',
  },
  {
    key: 'totalDelivery',
    name: 'Total projected delivery',
  },
  {
    key: 'totalPrice',
    name: 'Total Product Price',
  },
  {
    key: 'opportunityStage',
    name: 'Opportunity stage',
  },
]

export const show_date = [
  'closeDate',
  'launchDate',
  'Last_updated_at',
  'closingProtocolClosedDate',
]

export const show_date_time = [
  // 'Last_updated_at'
]

export const change_type_fields = {
  closeDate: 'date',
  launchDate: 'date',
  Last_updated_at: 'date',
  closingProtocolClosedDate: 'date',
}

export const Date_child_subchild = {
  children: [
    {
      key: 1,
      value: 'Created on',
    },
    {
      key: 2,
      value: 'Live date',
    },
    {
      key: 3,
      value: 'End date',
    },
    {
      key: 4,
      value: 'First Response Date',
    },
  ],
  subChildren: [
    {
      key: 1,
      value: 'Before selected date',
    },
    {
      key: 2,
      value: 'On selected date',
    },
    {
      key: 3,
      value: 'After selected date',
    },
    {
      key: 4,
      value: 'Between selected date',
    },
  ],
}

export const options = [
  {
    id: 0,
    name: '',
  },
  {
    id: 1,
    name: 'Client name visible',
  },
  {
    id: 2,
    name: 'Masked in GSDB',
  },
]

export const NON_STANDARD_SURVEY = [
  {
    id: 0,
    value: '',
  },
  {
    id: 1,
    value: 'No, only standard practices were used',
  },
  {
    id: 2,
    value: 'Removed questions',
  },
  {
    id: 3,
    value: 'Major wording changes to the questions(non-OrgSci approved)',
  },
  {
    id: 4,
    value:
      'Non-representative sample (e.g. all leaders + percentage of frontline workers)',
  },
  {
    id: 5,
    value: 'Weighted responses',
  },
  {
    id: 6,
    value: 'More than one of the above',
  },
  {
    id: 7,
    value: 'Other (please specify)',
  },
]
export const SURVEY_STARTING_YEAR = 2012
export const CURRENT_YEAR = moment().year()
export const SURVEY_ADMIN_TYPE = [
  {
    id: 1,
    name: 'Back Coded',
  },
  {
    id: 2,
    name: 'Generic',
  },
  {
    id: 3,
    name: 'Paper',
  },
]
