import { fromJS } from 'immutable'

import {
  SURVEYS_FETCH,
  SURVEYS_STATUS_FETCH,
  NEWSURVEY_CREATE,
  GET_PENDING_SURVEYS,
  POST_PENDING_SURVEYS,
  RESET_PENDING_SURVEYS,
} from './constants'

const INITIAL_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  list: {
    totalCount: 0,
    items: [],
  },
})

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case `${SURVEYS_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${SURVEYS_FETCH}_SUCCESS`: {
      const {
        reqData,
        respData: { data },
      } = payload
      return state
        .updateIn(['list'], list => {
          return list
            .set('totalCount', fromJS(data.totalCount))
            .updateIn(['items'], items => {
              if (reqData.pageNumber > 1) {
                return items.concat(fromJS(data.items))
              } else {
                return fromJS(data.items)
              }
            })
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${SURVEYS_FETCH}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

const INITIAL_STATUS_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
})

export const statusReducer = (
  state = INITIAL_STATUS_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${SURVEYS_STATUS_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${SURVEYS_STATUS_FETCH}_SUCCESS`: {
      const { data } = payload
      return state
        .updateIn(['items'], items => {
          return fromJS(data.items)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${SURVEYS_STATUS_FETCH}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}
const INITIAL_CREATESURVEY_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: [],
  pendingSurveysData: [],
  isPendingSurveysDataFetched: false,
  isPostFetched: false,
})
export const surveycreatereducer = (
  state = INITIAL_CREATESURVEY_STATE,
  { type, payload }
) => {
  switch (type) {
    case `${NEWSURVEY_CREATE}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${NEWSURVEY_CREATE}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${NEWSURVEY_CREATE}_FAILED`: {
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    case `${GET_PENDING_SURVEYS}_PENDING`: {
      return state.setIn(['isPendingSurveysDataFetched'], false)
    }
    case `${GET_PENDING_SURVEYS}_SUCCESS`: {
      return state
        .updateIn(['pendingSurveysData'], () => fromJS(payload))
        .setIn(['isPendingSurveysDataFetched'], true)
    }
    case `${GET_PENDING_SURVEYS}_FAILED`: {
      return state.setIn(['isPendingSurveysDataFetched'], false)
    }
    case `${POST_PENDING_SURVEYS}_PENDING`: {
      return state
        .setIn(['isPostFetched'], false)
        .updateIn(['pendingSurveysData'], () => fromJS([]))
        .setIn(['isPendingSurveysDataFetched'], false)
    }
    case `${POST_PENDING_SURVEYS}_SUCCESS`: {
      return state
        .updateIn(['pendingSurveysData'], () => fromJS(payload))
        .setIn(['isPostFetched'], true)
        .setIn(['isPendingSurveysDataFetched'], true)
    }
    case `${POST_PENDING_SURVEYS}_FAILED`: {
      return state.setIn(['isPostFetched'], false)
    }
    case RESET_PENDING_SURVEYS: {
      return state
        .updateIn(['pendingSurveysData'], () => fromJS([]))
        .setIn(['isPendingSurveysDataFetched'], false)
        .setIn(['isPostFetched'], false)
    }
    default:
      return state
  }
}

export default reducer
