import React from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import PopupModal from '../../components/PopupModal'
import { revertToLastUpdate, revertToLastError } from './actions'
import { statement } from '@babel/template'

const RevertToLastUpdate = ({
  isVisble,
  surveyId,
  revertToLastUpdate,
  setIsVisible,
  isError,
  revertToLastError,
}) => {
  const handleClick = e => {
    const id = e.target.id
    if (id === 'no') {
      setIsVisible(false)
      revertToLastError(false)
    } else {
      revertToLastUpdate(surveyId)
    }
  }
  if (isVisble) {
    return (
      <PopupModal>
        <Box>
          {isError && (
            <span className="error">
              There is some Error Please try after some time
            </span>
          )}
          <h4 className="modal-title" style={{ marginBottom: '4.2vh' }}>
            This action would roll back the changes for all the languages to
            match the Survey text. Are you sure you want to continue?
          </h4>
          <Box display="flex" flex="1" justifyContent="center">
            <button
              className="button-round no-button"
              onClick={e => handleClick(e)}
              id="no"
            >
              No
            </button>
            <Box style={{ position: 'relative' }}>
              <button
                className="button-round yes-button"
                onClick={e => handleClick(e)}
                id="yes"
              >
                Yes
              </button>
            </Box>
          </Box>
        </Box>
      </PopupModal>
    )
  }
  return null
}

const mapStateToProps = state => {
  return {
    isError: state.get('revertToLastUpdate').error,
  }
}

const mapDispatchToProps = {
  revertToLastUpdate,
  revertToLastError,
}

export default connect(mapStateToProps, mapDispatchToProps)(RevertToLastUpdate)
