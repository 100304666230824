import {
  ADDITIONAL_QUESTIONS_FETCH,
  FETCH_ADDITONAL_QUESTIONS_DATA_LANG,
  FETCH_ADDITONAL_QUESTIONS_DATA_LANG_SUCCESS,
  FETCH_ADDITONAL_QUESTIONS_DATA_LANG_ERROR,
  UPDATE_SURVEY_ADDITIONAL_QUESTIONS,
  REPLACE_EVERYWHERE,
} from './constants'

//GET INTRODUCTION PARAGRAPHS
export const additionalQuestionsFetch = surveyId => {
  return {
    type: `${ADDITIONAL_QUESTIONS_FETCH}_PENDING`,
    surveyId,
  }
}

export const additionalQuestionsFetchData = data => ({
  type: `${ADDITIONAL_QUESTIONS_FETCH}_SUCCESS`,
  data,
})

export const additionalQuestionsFetchFailed = () => ({
  type: `${ADDITIONAL_QUESTIONS_FETCH}_FAILED`,
})

export const fetchIntroDataForAdditionalQuestions = ({ data, languageId }) => {
  return {
    type: FETCH_ADDITONAL_QUESTIONS_DATA_LANG_SUCCESS,
    data,
    languageId,
  }
}

export function fetchIntroDataForLang({ languageId, surveyId }) {
  return {
    type: FETCH_ADDITONAL_QUESTIONS_DATA_LANG,
    languageId,
    surveyId,
  }
}

export function fetchIntroDataForLangError(error) {
  return {
    type: FETCH_ADDITONAL_QUESTIONS_DATA_LANG_ERROR,
    error,
  }
}

export const updateSurvey = (
  editorState,
  surveyId,
  languageId,
  isSync,
  isMultiLanguage,
  outcomeId
) => {
  return {
    type: UPDATE_SURVEY_ADDITIONAL_QUESTIONS,
    editorState,
    surveyId,
    languageId,
    isSync,
    isMultiLanguage,
    outcomeId,
  }
}

export function replaceEverywhere({
  ignoreCase,
  selectedLanguageId,
  replaceText,
  findText,
  toggleState,
}) {
  return {
    type: REPLACE_EVERYWHERE,
    isCaseSensitive: !ignoreCase,
    replaceFrom: findText,
    replaceTo: replaceText,
    langId: selectedLanguageId,
    toggleState,
  }
}
