const INITIAL_STATE = {
  confirmationPopupIsOpened: false,
  methodToExecute: null,
  methodArgumentToPass: '',
}

const confirmationPop = (
  state = INITIAL_STATE,
  { type, methodToExecute, methodArgumentToPass }
) => {
  switch (type) {
    case 'TOGGLE_CONFIRMATION_POP_UP':
      return {
        ...state,
        confirmationPopupIsOpened: !state.confirmationPopupIsOpened,
        methodToExecute: methodToExecute,
        methodArgumentToPass: methodArgumentToPass,
      }
    default:
      return state
  }
}

export default confirmationPop
