export const DESIGNSURVEY_FETCH = 'DESIGNSURVEY/FETCH'
export const UPDATEDESIGNSURVEY_CREATE = 'UPDATEDESIGNSURVEY/CREATE'
export const SAVEQUESTION_CREATE = 'SAVEQUESTION/CREATE'
export const OUTCOMESCALE_FETCH = 'OUTCOMESCALE/FETCH'
export const PRACTICESCALE_FETCH = 'PRACTICESCALE/FETCH'
export const GETQUESTIONLIST_FETCH = 'GETQUESTIONLIST/FETCH'
export const UPDATEQUESTIONLIST_CREATE = 'UPDATEQUESTIONLIST/CREATE'
export const UPDATEQUESTION_CREATE = 'UPDATEQUESTION/CREATE'
export const INFLUENCERS_FETCH = 'INFLUENCER/FETCH'
export const UPDATEINFLUENCERS_CREATE = 'UPDATEINFLUENCERS/CREATE'
export const VALUES_FETCH = 'VALUES/FETCH'
export const UPDATEVALUES_CREATE = 'UPDATEVALUES/CREATE'
export const INCLUSION_FETCH = 'INCLUSION/FETCH'
export const UPDATEINCLUSION_CREATE = 'UPDATEINCLUSION/CREATE'
export const REST_QUESTION_LIST = 'REST_QUESTION_LIST'
export const UPDATE_WORK_EX = 'UPDATE_WORK_EX'
export const GET_WORK_EX = 'GET_WORK_EX'
export const TEST_MODULE_FETCH = 'TEST_MODULE_FETCH'
export const TEST_MODULE_UPDATE = 'TEST_MODULE_UPDATE'
export const OHI_4_MODULE_FETCH = 'OHI_4_MODULE_FETCH'
export const OHI_4_MODULE_UPDATE = 'OHI_4_MODULE_UPDATE'
