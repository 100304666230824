import { call, put, takeLatest } from 'redux-saga/effects'
import Endpoints from '../Endpoints'
import request from '../utils/request'
import {
  ADMIN_LOCK_UNLOCK_SURVEY,
  UNLOCK_USER_LOCKED_SURVEY,
  GET_GSDB,
  POST_GSDB,
  GET_USER_DETAILS,
  UPDATE_USER_DETAILS,
  DELETE_USER,
  DOWNLOAD_USER_TEMPLATE,
  UPLOAD_USER_TEMPLATE,
  EXPORT_USERS,
  SUCCESS_UPLOAD,
  GET_FILTER,
  FETCH_SURVEYS,
  UPLOAD_BACKCODING,
  DOWNLOAD_MAPPING,
  GENERATE_LOGS,
  TASK_LOGS,
} from './constants'
import {
  getGSDBDetailsSuccess,
  toggleToastOnSuccess,
  getUserDetailsSuccess,
  successUpdateUserDetails,
  failedUpdateUserDetails,
  getUserDetails,
  failedGetUserDetails,
  successUploadUsers,
  failedUploadUsers,
  failedDownloadUserTemplate,
  exportUsersFailed,
  fetchReplaceWordSuccess,
  fetchFilterSuccessAction,
  getSurveyDataSuccessAction,
  uploadBckcodingSuccessAction,
  uploadBckcodingFailedAction,
  downloadMappingFileFailed,
  downloadMappingFileSuccess,
  generateLogsFaileAction,
  generateLogsSuccessAction,
  GenerateTaskLogsSuccessAction,
} from './actions'
import store from '../store'

import { surveynameFetch } from '../pages/ClientInfo/actions'
import { createFilter } from './arrayFunctions'
import moment from 'moment'
import {
  CURRENT_YEAR,
  Date_child_subchild,
  NEXT_YEAR,
  NON_STANDARD_SURVEY,
  options,
  SURVEY_STARTING_YEAR,
} from '../pages/Dashboard/constants'

function* lockUnclockSurveyFn({ surveyId, lockState }) {
  const url = lockState ? Endpoints.lockSurvey : Endpoints.unlockSurvey
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: url.replace(':surveyId', surveyId),
    })
    if (!error) {
      yield put(surveynameFetch(surveyId))
    }
  } catch (e) {
    console.log(e)
  }
}

function* lockUnclockSurvey() {
  yield takeLatest(ADMIN_LOCK_UNLOCK_SURVEY, lockUnclockSurveyFn)
}

function* unlockUserLockedSurveyFn() {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.unlockUserLockedSurvey,
    })
    if (!error) {
    }
  } catch (e) {
    console.log(e)
    removeStorage()
  }
}

const removeStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
  window.location.reload()
}

function* unlockUserLockedSurvey() {
  yield takeLatest(UNLOCK_USER_LOCKED_SURVEY, unlockUserLockedSurveyFn)
}

function* getGSDBDetailsFn({ surveyId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.GSDBApi.replace(':surveyId', surveyId),
    })
    if (!error) {
      return yield put(getGSDBDetailsSuccess(data))
    }
  } catch (e) {
    console.log(e)
  }
}

function* getGSDBDetails() {
  yield takeLatest(GET_GSDB, getGSDBDetailsFn)
}

function* postGSDBDetailsFn({ payload, surveyId }) {
  payload = {
    ...payload,
    agreementInformation: {
      infoVarified: true,
      osDataProtocol: true,
      boxStored: true,
    },
  }
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'PUT',
      url: Endpoints.GSDBApi.replace(':surveyId', surveyId),
      payload: payload,
    })
    if (!error) {
      yield put(toggleToastOnSuccess({ successText: data, openToast: true }))
    }
  } catch (e) {
    console.log(e)
  }
}

function* postGSDBDetails() {
  yield takeLatest(POST_GSDB, postGSDBDetailsFn)
}

function* getUserDetailsFn() {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.userApi,
    })
    if (!error) {
      return yield put(getUserDetailsSuccess(data))
    }
  } catch (e) {
    yield put(failedGetUserDetails(e.response))
  }
}

function* getUserDetail() {
  yield takeLatest(GET_USER_DETAILS, getUserDetailsFn)
}

function* updateUserDetailsFn({ payload }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: payload.userId ? 'PUT' : 'POST',
      url: payload.userId
        ? `${Endpoints.userApi}/${payload.userId}`
        : Endpoints.userApi,
      payload: payload,
    })

    if (!error) {
      yield put(successUpdateUserDetails(data))
      yield put(getUserDetails())
    }
  } catch (e) {
    yield put(failedUpdateUserDetails(e.response))
    yield put(getUserDetails())
  }
}

function* updateUserDetails() {
  yield takeLatest(`${UPDATE_USER_DETAILS}_PENDING`, updateUserDetailsFn)
}

function* deleteUserFn({ userId }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'DELETE',
      url: `${Endpoints.userApi}/${userId}`,
    })

    if (!error) {
      yield put(successUpdateUserDetails(data))
      yield put(getUserDetails())
    }
  } catch (e) {
    yield put(failedUpdateUserDetails(e.response))
    yield put(getUserDetails())
  }
}

function* deleteUserCaller() {
  yield takeLatest(`${DELETE_USER}_PENDING`, deleteUserFn)
}

function* uploadUserTemplateFn({ file }) {
  const formData = new FormData()
  formData.append('file', file)
  try {
    const {
      body: { error },
    } = yield call(request, {
      method: 'POST',
      url: Endpoints.importTemplate,
      payload: formData,
    })
    if (!error) {
      store.dispatch({
        type: SUCCESS_UPLOAD,
        payload: {
          uploadedCode: 200,
          data: 'Users uploaded successfully!',
        },
      })
      yield put(successUploadUsers())
    } else {
      yield put(failedUploadUsers(error))
    }
  } catch (e) {
    yield put(failedUploadUsers(e.response))
  }
}

function* uploadUsers() {
  yield takeLatest(`${UPLOAD_USER_TEMPLATE}_PENDING`, uploadUserTemplateFn)
}

function* downloadUserTemplateFn() {
  try {
    const {
      body: { error },
      response,
      response: { data },
    } = yield call(request, {
      method: 'GET',
      url: Endpoints.exportTemplate,
      responseType: 'arraybuffer',
    })
    if (!error) {
      // var filename = response.headers['x-filename']
      // filename = filename.replace(/\"/g, '')
      var filename = 'TemplateFile_User'
      var blob = new Blob([data], { type: response['headers']['content-type'] })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      yield put(failedDownloadUserTemplate(error))
    }
  } catch (e) {
    yield put(failedDownloadUserTemplate(e.response))
  }
}

function* exportUsers() {
  try {
    try {
      const {
        body: { error },
        response,
        response: { data },
      } = yield call(request, {
        method: 'GET',
        url: Endpoints.exportAllUsers,
      })
      if (!error) {
        var filename = 'Export_All_Users'
        var mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${data.data}`
        var link = document.createElement('a')
        link.href = mediaType
        link.download = filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        yield put(exportUsersFailed(error))
      }
    } catch (e) {
      yield put(exportUsersFailed(e.response))
    }
  } catch (error) {
    yield put(exportUsersFailed(error.response))
  }
}

/**
 * fetch replace word sagas
 */
function* fetchReplaceWord() {
  try {
    const response = yield call(request, {
      method: 'Get',
      url: Endpoints.replaceWordsApi,
    })
    const {
      response: {
        data: {
          data: { items = [] },
        },
      },
    } = response
    const replaceWords = items.length
      ? items.find(({ id = 0 }) => id === 0).replaceKeys
      : []
    yield put(fetchReplaceWordSuccess(replaceWords))
  } catch (error) {
    yield put(fetchReplaceWordSuccess([]))
  }
}

function* fetchFilterSagas() {
  try {
    const response = yield call(request, {
      method: 'Get',
      url: Endpoints.filterUrl,
    })
    const {
      response: {
        data: { data = {} },
      },
    } = response
    const keys = Object.keys(data)
    const values = Object.values(data)
    const getFilter = createFilter(values)
    let obj = {}
    keys.map((item, i) => {
      obj[item] = getFilter[i]
    })

    //get date
    const dateData = {
      key: 'date',
      display: 'Date',
      dateValue: moment()
        .format('YYYY-MM-DD')
        .toString(),
      dateRangeValue: moment()
        .format('YYYY-MM-DD')
        .toString(),
      type: 'date',
      isCheckbox: false,
      ...Date_child_subchild,
    }
    obj[`date`] = { ...dateData }

    //get years
    const yearObj = []
    ;[...Array(CURRENT_YEAR - SURVEY_STARTING_YEAR + 1).fill(0)].map(
      (item, i) => {
        yearObj.push({
          key: i,
          value: SURVEY_STARTING_YEAR + i,
          isSelected: false,
        })
      }
    )
    yearObj[yearObj.length - 1].isSelected = true
    obj[`year`] = {
      display: 'Year',
      key: 'year',
      isCheckbox: false,
      children: yearObj,
    }
    obj[`product`] = {
      display: 'Product',
      key: 'ohiCategory',
      isCheckbox: false,
      children: [],
    }
    obj[`clientRegion`] = {
      display: 'Client Region',
      key: 'clientRegion',
      isCheckbox: false,
      children: [],
    }
    yield put(fetchFilterSuccessAction(obj))
  } catch (error) {
    yield put(fetchFilterSuccessAction([]))
  }
}

function* getSurveyData({ filterParams }) {
  try {
    const payloadData = {
      filters: filterParams,
    }
    const response = yield call(request, {
      method: 'POST',
      url: Endpoints.surveyDataUrl,
      payload: payloadData,
    })
    const {
      response: {
        data: { data },
      },
    } = response

    // changed values
    data.items.map(item => {
      item.OrgSciAllowed =
        NON_STANDARD_SURVEY.find(({ id }) => id === Number(item.OrgSciAllowed))
          .value || ''
      item.MaskClientNameGSDB =
        options.find(({ id }) => id === Number(item.MaskClientNameGSDB)).name ||
        ''
      item.ohiSelfServe = item.isSelfServe
    })

    yield put(getSurveyDataSuccessAction(data))
  } catch (error) {
    yield put(getSurveyDataSuccessAction([]))
  }
}

/**
 * Function to import backcoding file
 * @param {*} param0 pid String
 * @param {*} param1 files FileObject
 * @returns Response Promise
 */
function* importBackcodingFileSagas({ payload }) {
  try {
    const { pid, files } = payload
    const formData = new FormData()
    formData.append('file', files)
    const response = yield call(request, {
      method: 'POST',
      url: Endpoints.importBackcoding.replace(':pid', pid),
      payload: formData,
    })
    const {
      body: { data, error = false },
      response: { status = '' },
    } = response
    if (status === 200) {
      yield put(uploadBckcodingSuccessAction(data))
    } else {
      yield put(uploadBckcodingFailedAction({}))
    }
  } catch (err) {
    console.log(err, err.response, '::: EXCEPTION :::')
    yield put(uploadBckcodingFailedAction({}))
  }
}

function* downloadMappingFileSagas({ payload }) {
  try {
    const { pid, fileName, type } = payload
    const response = yield call(request, {
      method: 'POST',
      url: Endpoints.downloadMappingFileUrl,
      payload: {
        pid,
        fileName,
        type,
      },
    })
    const {
      response: { status = '' },
      body: { error = false, data },
    } = response
    if (status === 200 && !error) {
      var mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64, ${data}`
      var link = document.createElement('a')
      link.href = mediaType
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      yield put(downloadMappingFileSuccess('Downloaded successfully.'))
    } else {
      yield put(
        downloadMappingFileFailed(
          'An error occured! Please try again after somtime'
        )
      )
    }
  } catch (error) {
    downloadMappingFileFailed(
      'Exception occured! Please try again after somtime.'
    )
    console.warn(error, error.response, '::: EXCEPTION :::')
  }
}

function* GenerateQuestionSagas({ payload }) {
  try {
    const { pid, hrFileName, extension, tempGuid } = payload
    const response = yield call(request, {
      method: 'POST',
      url: Endpoints.generateQuestion,
      payload: {
        pid,
        hrFileName,
        extension,
        tempGuid,
      },
    })
    const {
      body: { data },
      response: { status },
    } = response
    if (status === 200) {
      yield put(generateLogsSuccessAction(data.taskId))
    } else {
      yield put(generateLogsFaileAction(data))
    }
  } catch (error) {
    console.error(error, error.response, '::: EXCEPTION :::')
    yield put(generateLogsFaileAction('Exception occured!'))
  }
}

/**
 * Task Logs Api
 * @param {*} param0
 */
function* callTaskApiForLogsSagas({ payload }) {
  try {
    const { taskId = '' } = payload
    const response = yield call(request, {
      method: 'GET',
      url: Endpoints.taskForLogsUrl.replace(':taskid', taskId),
    })
    const {
      body: { data },
    } = response
    yield put(GenerateTaskLogsSuccessAction(data))
  } catch (error) {
    console.log(error, '::: EXCEPTION GENERATING LOGS :::')
  }
}

function* TaskApiForLogsUrl() {
  yield takeLatest(`${TASK_LOGS}_PENDING`, callTaskApiForLogsSagas)
}

function* generatelogs() {
  yield takeLatest(`${GENERATE_LOGS}_PENDING`, GenerateQuestionSagas)
}

function* downloadMapping() {
  yield takeLatest(`${DOWNLOAD_MAPPING}_PENDING`, downloadMappingFileSagas)
}

function* downloadUserTemplate() {
  yield takeLatest(`${DOWNLOAD_USER_TEMPLATE}_PENDING`, downloadUserTemplateFn)
}

function* exportAllUsers() {
  yield takeLatest(`${EXPORT_USERS}_PENDING`, exportUsers)
}

function* fetchAllReplaceWords() {
  yield takeLatest(`FETCH_REPLACE_WORD_PENDING`, fetchReplaceWord)
}

function* fetchAllFilter() {
  yield takeLatest(`${GET_FILTER}_PENDING`, fetchFilterSagas)
}

function* fetchSurveys() {
  yield takeLatest(`${FETCH_SURVEYS}_PENDING`, getSurveyData)
}

function* importBackcodingFile() {
  yield takeLatest(`${UPLOAD_BACKCODING}_PENDING`, importBackcodingFileSagas)
}

export default [
  lockUnclockSurvey,
  unlockUserLockedSurvey,
  getGSDBDetails,
  postGSDBDetails,
  getUserDetail,
  updateUserDetails,
  deleteUserCaller,
  downloadUserTemplate,
  uploadUsers,
  exportAllUsers,
  fetchAllReplaceWords,
  fetchAllFilter,
  fetchSurveys,
  importBackcodingFile,
  downloadMapping,
  generatelogs,
  TaskApiForLogsUrl,
]
