import { fromJS } from 'immutable'
import {
  RESET_ERROR_STATE,
  API_ERROR,
  SUCCESS_UPLOAD,
} from '../common/constants'

const ignoreActions = [
  'DEMO_ACTION_IGNORED',
  'DEMO_ACTION_IGNORED_1',
  'SURVEYS/FETCH_PENDING',
  'SURVEYS/FETCH_SUCCESS',
]

const actionIgnored = actionType => {
  let isIgnored = false
  ignoreActions.forEach(ignoreAction => {
    if (~actionType.indexOf(ignoreAction)) {
      isIgnored = true
    }
  })
  return isIgnored
}

const INITIAL_STATE = fromJS({
  errorstatus: '',
})
const statuscode = (state = INITIAL_STATE, action) => {
  if (action.type === API_ERROR) {
    return state.setIn(['errorstatus'], action)
  } else if (action.type === RESET_ERROR_STATE) {
    return INITIAL_STATE
  } else if (action.type === SUCCESS_UPLOAD) {
    return state.setIn(['errorstatus'], action)
  } else {
    return state
  }
}

export default statuscode
