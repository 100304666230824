import React, { Fragment } from 'react'

import { Box } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import Links from './SurveyLinks'

import { defineLinksData, textUpdateLinksData } from './constants'
import useStyles from './styles'

function SurveySidebar({
  location,
  history,
  params,
  isDisabled,
  isTextUpdateComponent,
  sidePanelData,
  hasEditorStateChanged,
  confirmationPopupToggle,
}) {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex="1"
      className={classes.root}
    >
      <Box
        className={classes.childContainer}
        display="flex"
        flexDirection="column"
        alignItems="center"
        flex={1}
      >
        <SidebarLinks
          classes={classes}
          currentPath={location.pathname}
          onGo={goTo}
          paramID={params.id}
          isDisabled={isDisabled}
          isTextUpdateComponent={isTextUpdateComponent}
          sidePanelData={sidePanelData}
          location={location}
          hasEditorStateChanged={hasEditorStateChanged}
          confirmationPopupToggle={confirmationPopupToggle}
          history={history}
        />
      </Box>
    </Box>
  )

  function goTo(route, openDropDown) {
    history.push(route)
  }
}

function SidebarLinks({
  classes,
  currentPath,
  onGo,
  paramID,
  isDisabled,
  isTextUpdateComponent,
  sidePanelData,
  location,
  hasEditorStateChanged,
  confirmationPopupToggle,
  history,
}) {
  const linksData = isTextUpdateComponent ? sidePanelData : defineLinksData
  return (
    <Fragment>
      {linksData.map((link, index) => (
        <Links
          key={index}
          classes={classes}
          currentPath={currentPath}
          onGo={onGo}
          paramID={paramID}
          isDisabled={isDisabled}
          index={index}
          link={link}
          isTextUpdateComponent={isTextUpdateComponent}
          location={location}
          hasEditorStateChanged={hasEditorStateChanged}
          confirmationPopupToggle={confirmationPopupToggle}
          history={history}
        />
      ))}
    </Fragment>
  )
}

export default withRouter(SurveySidebar)
