import axios from 'axios'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}

const api = new APIs('/api')


export const emailTemplateapi = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`email/email-survey-list/${data.surveyId}`)
  })
  return result
}
export const singleEmailTemplateapi = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`email/email-survey/?surveyId=${data.surveyId}&emailType=${data.emailType}`)
  })
  return result
}

export const lockTemplateapi = async data => {
  // console.log('api', data)
  // const postdata = {"id":data.id,"locked":!data.locked}
  const result = await axios({
    method: 'put',
    url: api.getURL(`email/lock`),
    data,
  })
  return result
}
export const updateTemplateapi = async data => {
  // console.log('api', data)
  const result = await axios({
    method: 'put',
    url: api.getURL(`email/email-survey`),
    data,
  })
  return result
}
export const duplicatetemplateapi = async data => {
  // console.log('api', data)
  const result = await axios({
    method: 'post',
    url: api.getURL(`email/duplicate-email`),
    data,
  })
  return result
}
export const sendPreviewEmailapi = async data => {
  // console.log('api', data)
  const result = await axios({
    method: 'put',
    url: api.getURL(`email/send-preview-email`),
    data,
  })
  return result
}

