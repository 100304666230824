export default function designSurvey(
  values,
  deepdive,
  deepdiveincl,
  workexobj,
  ohiTestModuleData
) {
  let errors = {}

  const influencer = values.deepDive.filter(x => x.id === 3)
  const inclusion = values.deepDive.filter(x => x.id === 6)
  const workex = values.deepDive.filter(x => x.id === 7)
  const oHi4 = values.deepDive.find(x => x.id === 9)
  const ohiModuleLength =
    ohiTestModuleData?.length &&
    ohiTestModuleData.filter(({ isSelected = false }) => isSelected === true)
      .length
  if (!deepdive.isOptout && influencer[0].isSelected) {
    errors.isOptout = 'More information required'
  }
  if (deepdiveincl && !deepdiveincl.type && inclusion[0].isSelected) {
    errors.type = 'More information required'
  }
  if (workexobj && !workexobj.type && workex[0].isSelected) {
    errors.type = 'More information required'
  }

  if (oHi4?.isSelected === true && !ohiModuleLength) {
    errors.type = 'More information required'
  }
  return errors
}
