import { call, put, takeLatest } from 'redux-saga/effects'

import {
  clientsFetchSuccess,
  clientsFetchFailed,
  clientsCreateSuccess,
  clientsCreateFailed,
  clientInfoFetchSuccess,
  clientInfoFetchFailed,
  clientInfoSaveSuccess,
  clientInfoSaveFailed,
  surveynameFetchSuccess,
  surveynameFetchFailed,
  surveynameUpdateSuccess,
  surveynameUpdateFailed,
  opportunityFetchSuccess,
  opportunityFetchFailed,
} from './actions'
import {
  CLIENTS_FETCH,
  CLIENTS_CREATE,
  CLIENTINFO_FETCH,
  SAVECLIENTIFO_CREATE,
  SURVEYNAME_FETCH,
  SURVEYNAMEUPDATE_CREATE,
  GETOPPORTUNITY_FETCH,
} from './constants'
import {
  fetchClients,
  createClient,
  fetchClientinfo,
  saveClientInfo,
  fetchSurveyName,
  updateSurveyName,
  fetchOpportunityOption,
} from './apis'

// GET opportunity
function* fetchOpportunityoptionfn({ payload }) {
  try {
    const response = yield call(fetchOpportunityOption, payload)
    const data = { respData: response.data }

    if (response.status === 200) {
      yield put(opportunityFetchSuccess(data))
    } else {
      yield put(opportunityFetchFailed(response))
    }
  } catch (e) {
    yield put(opportunityFetchFailed(e.response))
  }
}

// GET SURVEY NAME
function* fetchSurveyNamefn({ payload }) {
  try {
    const response = yield call(fetchSurveyName, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(surveynameFetchSuccess(data))
    } else {
      yield put(surveynameFetchFailed(response))
    }
  } catch (e) {
    yield put(surveynameFetchFailed(e.response))
  }
}
// UPDATE SURVEY NAME
function* updateSurveyNameFn({ payload }) {
  try {
    const response = yield call(updateSurveyName, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(surveynameUpdateSuccess(data))
    } else {
      yield put(surveynameUpdateFailed(response))
    }
  } catch (e) {
    yield put(surveynameUpdateFailed(e.response))
  }
}

// SAVE SURVEYINFO
function* saveClientInfoFn({ payload }) {
  // console.log(payload)
  try {
    const response = yield call(saveClientInfo, payload)
    const data = { respData: response }
    if (response.status === 200) {
      yield put(clientInfoSaveSuccess(data))
    } else {
      yield put(clientInfoSaveFailed(response))
    }
  } catch (e) {
    yield put(clientInfoSaveFailed(e.response))
  }
}
//CLIENTINFO FETCH

function* fetchClientInfofn({ payload }) {
  //console.log(payload)
  try {
    const response = yield call(fetchClientinfo, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(clientInfoFetchSuccess(data))
    } else {
      yield put(clientInfoFetchFailed(response))
    }
  } catch (e) {
    yield put(clientInfoFetchFailed(e.response))
  }
}

function* fetchClientsfn({ payload }) {
  try {
    const response = yield call(fetchClients, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(clientsFetchSuccess(data))
    } else {
      yield put(clientsFetchFailed(response))
    }
  } catch (e) {
    yield put(clientsFetchFailed(e.response))
  }
}

function* clientCreateFn({ payload }) {
  //console.log(payload)
  try {
    const response = yield call(createClient, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(clientsCreateSuccess(data))
    } else {
      yield put(clientsCreateFailed(response))
    }
  } catch (e) {
    yield put(clientsCreateFailed(e.response))
  }
}

const sagas = [
  takeLatest(`${CLIENTS_FETCH}_PENDING`, fetchClientsfn),
  takeLatest(`${CLIENTS_CREATE}_PENDING`, clientCreateFn),
  takeLatest(`${CLIENTINFO_FETCH}_PENDING`, fetchClientInfofn),
  takeLatest(`${SAVECLIENTIFO_CREATE}_PENDING`, saveClientInfoFn),
  takeLatest(`${SURVEYNAME_FETCH}_PENDING`, fetchSurveyNamefn),
  takeLatest(`${SURVEYNAMEUPDATE_CREATE}_PENDING`, updateSurveyNameFn),
  takeLatest(`${GETOPPORTUNITY_FETCH}_PENDING`, fetchOpportunityoptionfn),

  //takeLatest(`${CLIENTS_SURVEY_FETCH}_PENDING`, fetchClientsSurveyfn),
]
export default sagas
