import React, { Suspense } from 'react'
import PopupModal from './components/PopupModal'
import ProgressBarLoader from './components/ProgressBarLoader'
import { Route } from 'react-router-dom'

const FallbackRoute = ({ component: Component, path }) => (
  <Suspense
    fallback={
      <PopupModal>
        <ProgressBarLoader />
      </PopupModal>
    }
  >
    <Route exact path={path} component={Component} />
  </Suspense>
)

export default FallbackRoute
