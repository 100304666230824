import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import pathOr from 'lodash/fp/pathOr'
import { OUTCOMEID } from '../SurveySidebar/constants'
function UnOrderList({
  data,
  classes,
  isVisible,
  index = 0,
  route,
  hasEditorStateChanged,
  confirmationPopupToggle,
  history,
}) {
  const liClasses = pathOr('', 'liCls', classes)
  const ulClasses = pathOr('', 'ulCls', classes)
  const linkClass = pathOr('', 'aCls', classes)
  const selectedLi = pathOr('', 'slectedLi', classes)
  const selectedLink = pathOr('', 'selectedLink', classes)

  function onGo(route) {
    history.push(route)
  }

  function confirmationPopupToggleMethod(onGo, updatedRoute) {
    confirmationPopupToggle(onGo, updatedRoute)
  }

  if (isVisible) {
    const presentPath = window.location.search
    const orderData = data.map(menuItem => {
      const selectedClass = presentPath.includes(`${OUTCOMEID}=${menuItem.id}`)
        ? selectedLi
        : ''
      const selectedLinkClass = presentPath.includes(
        `${OUTCOMEID}=${menuItem.id}`
      )
        ? selectedLink
        : ''
      if (hasEditorStateChanged) {
        return (
          <li
            className={clsx(liClasses, selectedClass)}
            key={`ul${menuItem.id}`}
          >
            <span
              onClick={() =>
                confirmationPopupToggleMethod(
                  onGo,
                  `${route}${OUTCOMEID}=${menuItem.id}`
                )
              }
              className={clsx(linkClass, selectedLinkClass)}
              to={`${route}${OUTCOMEID}=${menuItem.id}`}
            >
              {menuItem.menuName}
            </span>
          </li>
        )
      }
      return (
        <li className={clsx(liClasses, selectedClass)} key={`ul${menuItem.id}`}>
          <Link
            className={clsx(linkClass, selectedLinkClass)}
            to={`${route}${OUTCOMEID}=${menuItem.id}`}
          >
            {menuItem.menuName}
          </Link>
        </li>
      )
    })
    return <ul className={ulClasses}>{orderData}</ul>
  }
  return null
}

UnOrderList.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object,
  isVisible: PropTypes.bool,
  index: PropTypes.number,
  route: PropTypes.string.isRequired,
}

UnOrderList.defaultProps = {
  data: [],
  route: '/update-survey-text/OHI/-1/',
}

export default UnOrderList
