import {
  SURVEYS_FETCH,
  SURVEYS_STATUS_FETCH,
  NEWSURVEY_CREATE,
  GET_PENDING_SURVEYS,
  POST_PENDING_SURVEYS,
  RESET_PENDING_SURVEYS,
} from './constants'

// SURVEYS_FETCH
export const surveysFetch = payload => ({
  type: `${SURVEYS_FETCH}_PENDING`,
  payload,
})

export const surveysFetchSuccess = payload => ({
  type: `${SURVEYS_FETCH}_SUCCESS`,
  payload,
})

export const surveysFetchFailed = payload => ({
  type: `${SURVEYS_FETCH}_FAILED`,
  payload,
})

// SURVEYS_STATUS_FETCH
export const surveysStatusFetch = payload => ({
  type: `${SURVEYS_STATUS_FETCH}_PENDING`,
  payload,
})

export const surveysStatusFetchSuccess = payload => ({
  type: `${SURVEYS_STATUS_FETCH}_SUCCESS`,
  payload,
})

export const surveysStatusFetchFailed = payload => ({
  type: `${SURVEYS_STATUS_FETCH}_FAILED`,
  payload,
})

// SURVEY_CREATE
export const surveyCreate = payload => ({
  type: `${NEWSURVEY_CREATE}_PENDING`,
  payload,
})

export const surveyCreateSuccess = payload => ({
  type: `${NEWSURVEY_CREATE}_SUCCESS`,
  payload,
})

export const surveyCreateFailed = payload => ({
  type: `${NEWSURVEY_CREATE}_FAILED`,
  payload,
})

export const getPendingSurveys = () => ({
  type: `${GET_PENDING_SURVEYS}_PENDING`,
})

export const getPendingSurveysSuccess = payload => ({
  type: `${GET_PENDING_SURVEYS}_SUCCESS`,
  payload,
})

export const getPendingSurveysFailed = payload => ({
  type: `${GET_PENDING_SURVEYS}_FAILED`,
  payload,
})

export const postPendingSurveys = payload => ({
  type: `${POST_PENDING_SURVEYS}_PENDING`,
  payload,
})

export const postPendingSurveysSuccess = payload => ({
  type: `${POST_PENDING_SURVEYS}_SUCCESS`,
  payload,
})

export const postPendingSurveysFailed = payload => ({
  type: `${POST_PENDING_SURVEYS}_FAILED`,
  payload,
})

export const resetPendingSurveys = () => ({
  type: RESET_PENDING_SURVEYS,
})
