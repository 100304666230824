import React, { useEffect, useState, memo } from 'react'
import useStyles from './styles'
import { Box, Button, Tooltip, Typography } from '@material-ui/core'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import editIcon from '../../assets/Edit-Icon.svg'
import reorderIcon from '../../assets/Reorder_Value.svg'
import deleteIcon from '../../assets/Cross2x.png'
import tooltipblue from '../../../ClientInfo/assets/tooltip-icon_blue.png'

//component

function QuestionOrdering({
  params,
  getEditQuestion,
  questionlist,
  onGetquestionlist,
  onUpdatelist,
  resetQuestionList,
}) {
  const classes = useStyles()
  const [questList, setquestList] = useState({
    surveyQuestions: [],
    surveyQuestions_Inclusion: [],
  })
  const [useFormord, setUseformord] = useState({
    surveyId: params,
    surveyQuestions: [],
    surveyQuestions_Inclusion: [],
  })
  useEffect(() => {
    !questionlist.get('isFetched') && onGetquestionlist(params)
  }, [])

  useEffect(() => {
    setquestList({
      surveyQuestions: questionlist.toJS().items.surveyQuestions,
      surveyQuestions_Inclusion: questionlist.toJS().items
        .surveyQuestions_Inclusion,
    })
    return () => {
      resetQuestionList()
    }
  }, [questionlist])

  useEffect(() => {
    setUseformord({
      ...useFormord,
      surveyQuestions: questList.surveyQuestions,
      surveyQuestions_Inclusion: questList.surveyQuestions_Inclusion,
    })
  }, [questList])

  function handleInputChange(index, listName) {
    return function(event) {
      const newpageNumber = { ...questList[listName][index] }
      newpageNumber.pageNumber = event.target.value
      setquestList({
        ...questList,
        [listName]: [
          ...questList[listName].slice(0, index),
          newpageNumber,
          ...questList[listName].slice(index + 1),
        ],
      })

      setTimeout(function() {
        document
          .querySelectorAll(`[data-input-type=${listName}]`)
          [index].focus()
      }, 100)
    }
  }

  const DragHandle = sortableHandle(() => (
    <Box>
      <Box component="img" src={reorderIcon}></Box>
    </Box>
  ))
  const SortableItem = SortableElement(({ listindex, value, subSection }) => (
    <Box display="flex" className={classes.questionListupdate}>
      <div
        className={
          subSection === 'surveyQuestions_Inclusion' ? classes.hideReorder : ''
        }
      >
        <DragHandle></DragHandle>
      </div>
      <Box className={classes.questionText}>
        <Box className={classes.questionTitle}>
          {value.questionText.map((item, qindex) =>
            value.questionText.length > 1 ? (
              <Box key={qindex}>
                0{qindex + 1}. {item}
              </Box>
            ) : (
              <Box key={qindex}>{item}</Box>
            )
          )}
        </Box>
        <Box component="em" className={classes.questionListType}>
          {value.questionType == 1 ? 'Open Ended' : null}
          {value.questionType == 2 ? 'Single Select' : null}
          {value.questionType == 3 ? 'Matrix/Likert Scale' : null}
          {value.questionType == 4 ? 'Instructions' : null}
          {value.questionType == 4 && value.qid === 'i67'
            ? ' - Legal Text'
            : null}
          {value.typeSuffix}
        </Box>
        <Box
          component="button"
          className={classes.qEdit}
          onClick={() => getEditQuestion(value)}
          style={{ right: 5 }}
        >
          <Box component="img" src={editIcon}></Box>
        </Box>
      </Box>
      <div>
        <input
          type="number"
          data-input-index={classes.orderingTextbox}
          className={classes.orderingTextbox}
          value={value.pageNumber || '0'}
          data-input-type={subSection}
          style={{
            opacity: subSection === 'surveyQuestions_Inclusion' ? '0.2' : '',
          }}
          disabled={subSection === 'surveyQuestions_Inclusion'}
          onChange={handleInputChange(listindex, subSection)}
        />
      </div>
    </Box>
  ))

  function saveQListForm() {
    onUpdatelist(useFormord)
  }

  const onSortEndSurveyQuestion = ({ oldIndex, newIndex }) => {
    setquestList({
      ...questList,
      surveyQuestions: arrayMove(questList.surveyQuestions, oldIndex, newIndex),
    })
  }

  const onSortEndSurveyQuestionInclusion = ({ oldIndex, newIndex }) => {
    setquestList({
      ...questList,
      surveyQuestions_Inclusion: arrayMove(
        questList.surveyQuestions_Inclusion,
        oldIndex,
        newIndex
      ),
    })
  }

  const SortableList = SortableContainer(({ items, subSection }) => {
    return (
      <Box>
        <Box className={classes.pageno}>Page No.</Box>
        {items.map((value, index) =>
          !value.isDeleted ? (
            <SortableItem
              key={`item-${index}`}
              index={index}
              listindex={index}
              value={value}
              subSection={subSection}
            />
          ) : null
        )}
      </Box>
    )
  })
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      <Typography
        variant="h1"
        component="h2"
        className={classes.sectionHeading}
      >
        Additional Questions
        <Box
          component="p"
          style={{
            fontSize: 12,
            paddingTop: 10,
            fontWeight: 400,
            marginBottom: 0,
          }}
        >
          Please order your additional questions below. The page number on the
          right indicates what page number, within the additional question
          section, the question will appear on.
        </Box>
      </Typography>

      {questList.surveyQuestions_Inclusion &&
        questList.surveyQuestions_Inclusion.length > 0 && (
          <Typography
            variant="h1"
            component="h2"
            style={{ marginTop: '10px' }}
            className={classes.subSectionHeading}
          >
            Inclusion Questions
            <Tooltip
              interactive={true}
              placement="right"
              title={
                <p style={{ fontSize: 12, marginBottom: 0 }}>
                  <em>
                    The below questions for the Inclusion module cannot be
                    re-ordered. The text can be edited, but the order is
                    intentional. Once you submit your survey, the OHI Helpdesk
                    can provide additional help, if needed.
                  </em>
                </p>
              }
            >
              <img src={tooltipblue} alt="tooltip" className="imgHeight" />
            </Tooltip>
          </Typography>
        )}
      {questList.surveyQuestions_Inclusion &&
        questList.surveyQuestions_Inclusion.length > 0 && (
          <SortableList
            items={questList.surveyQuestions_Inclusion}
            // onSortEnd={onSortEndSurveyQuestionInclusion}
            useDragHandle={true}
            subSection="surveyQuestions_Inclusion"
          />
        )}

      {questList.surveyQuestions && questList.surveyQuestions.length > 0 && (
        <Typography
          variant="h1"
          component="h2"
          style={{ marginTop: '10px' }}
          className={classes.subSectionHeading}
        >
          General Questions
        </Typography>
      )}
      {questList.surveyQuestions && questList.surveyQuestions.length > 0 && (
        <SortableList
          items={questList.surveyQuestions}
          onSortEnd={onSortEndSurveyQuestion}
          useDragHandle={true}
          subSection="surveyQuestions"
        />
      )}

      <Box>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonRound}
          onClick={saveQListForm}
          style={{ marginLeft: 0, width: 120 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

export default memo(QuestionOrdering)
