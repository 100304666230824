import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {},
    input: {
      fontSize: 12,
    },
  }
})

export default styles
