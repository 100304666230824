import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function DateIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 16">
      <g id="V9" fill="none" fillRule="evenodd">
        <g id="20_Test-Emails-Copy-5" transform="translate(-1310 -586)">
          <g id="Group-12" transform="translate(874 555)">
            <g id="Calendar" transform="translate(436 32)">
              <rect
                id="Rectangle"
                stroke="#333"
                fill="#FFF"
                x="0.5"
                y="1.5"
                width="13"
                height="13"
                rx="3"
              />
              <path
                d="M1,4.5 L12.5,4.5"
                id="Line-3"
                stroke="#333"
                strokeLinecap="square"
              />
              <path
                d="M2.375,1.5 L4.625,1.5"
                id="Line-3-Copy"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="rotate(90 3.5 1.5)"
              />
              <path
                d="M9.375,1.5 L11.625,1.5"
                id="Line-3-Copy-2"
                stroke="#333"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="rotate(90 10.5 1.5)"
              />
              <rect
                id="Rectangle"
                fill="#333"
                x="3"
                y="7"
                width="2"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-13"
                fill="#333"
                x="3"
                y="10"
                width="2"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-11"
                fill="#333"
                x="6"
                y="7"
                width="2"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-14"
                fill="#333"
                x="6"
                y="10"
                width="2"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-12"
                fill="#333"
                x="9"
                y="7"
                width="2"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-15"
                fill="#333"
                x="9"
                y="10"
                width="2"
                height="2"
                rx="1"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
