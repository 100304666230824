import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    formBottom: {
      borderTop: '1px solid #dddddd',
      padding: '2.3vh 2.4vw',
      position: 'relative',
      width: '71vw',
      bottom: 0,
    },
    buttonRound: {
      borderRadius: '25px',
      marginLeft: '1.3vw',
      fontWeight: '400',
      fontSize: '1.8vh',
    },
    buttonRoundDisabled: {
      borderRadius: '25px',
      marginLeft: '1.3vw',
      fontWeight: '400',
      fontSize: '1.8vh',
      pointerEvents: 'none',
      opacity: 0.4,
    },
    summaryLinkLabel: {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#4b93fd',
      cursor: 'pointer',
    },
    inputSet: {
      marginTop: '4vh',
      marginBottom: '3vh',
      width: 'fit-content',
    },
    toggleText: {
      fontSize: '10px',
    },
    displayNone: {
      display: 'none',
    },
    displayBlock: {
      display: 'block',
    },
    commentWrap: {
      width: '72vw',
      padding: '1.5vh 2.5vw',
      borderTop: 'solid 1px #ddd',
      backgroundColor: '#fafafa',
    },
  }
})

export default styles
