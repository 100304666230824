import { call, put, takeLatest } from 'redux-saga/effects'

import {
  surveysFetchSuccess,
  surveysFetchFailed,
  surveysStatusFetchSuccess,
  surveysStatusFetchFailed,
  surveyCreateSuccess,
  surveyCreateFailed,
  getPendingSurveysSuccess,
  getPendingSurveysFailed,
  postPendingSurveysSuccess,
  postPendingSurveysFailed,
} from './actions'
import {
  SURVEYS_FETCH,
  SURVEYS_STATUS_FETCH,
  NEWSURVEY_CREATE,
  GET_PENDING_SURVEYS,
  POST_PENDING_SURVEYS,
} from './constants'
import { fetchAll, fetchStatus, createSurvey } from './apis'
import request from '../../utils/request'

function* fetchSurveys({ payload }) {
  try {
    const response = yield call(fetchAll, payload)
    const data = {
      reqData: {
        pageNumber: payload.pageNumber,
      },
      respData: response.data,
    }
    if (response.status === 200) {
      yield put(surveysFetchSuccess(data))
    } else {
      yield put(surveysFetchFailed(response))
    }
  } catch (e) {
    yield put(surveysFetchFailed(e.response))
  }
}

function* fetchSurveysStatus() {
  try {
    const response = yield call(fetchStatus)
    if (response.status === 200) {
      yield put(surveysStatusFetchSuccess(response.data))
    } else {
      yield put(surveysStatusFetchFailed(response))
    }
  } catch (e) {
    yield put(surveysStatusFetchFailed(e.response))
  }
}

// CREATE SURVEY
function* createSurveyFn({ payload }) {
  try {
    const response = yield call(createSurvey, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(surveyCreateSuccess(data))
    } else {
      yield put(surveyCreateFailed(response))
    }
  } catch (e) {
    yield put(surveyCreateFailed(e.response))
  }
}

function* getPendingSurveysFn() {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'GET',
      url: '/api/surveys/pending',
    })
    if (!error) {
      yield put(getPendingSurveysSuccess(data))
      if (data.length) {
      }
    } else {
      yield put(getPendingSurveysFailed(error))
    }
  } catch (e) {
    yield put(getPendingSurveysFailed(e.response))
  }
}

function* postPendingSurveysFn({ payload }) {
  try {
    const {
      body: { data, error },
    } = yield call(request, {
      method: 'PUT',
      url: '/api/surveys/pending',
      payload,
    })
    if (!error) {
      yield put(postPendingSurveysSuccess(data))
    } else {
      yield put(postPendingSurveysFailed(error))
    }
  } catch (e) {
    yield put(postPendingSurveysFailed(e.response))
  }
}

const sagas = [
  takeLatest(`${SURVEYS_FETCH}_PENDING`, fetchSurveys),
  takeLatest(`${SURVEYS_STATUS_FETCH}_PENDING`, fetchSurveysStatus),
  takeLatest(`${NEWSURVEY_CREATE}_PENDING`, createSurveyFn),
  takeLatest(`${GET_PENDING_SURVEYS}_PENDING`, getPendingSurveysFn),
  takeLatest(`${POST_PENDING_SURVEYS}_PENDING`, postPendingSurveysFn),
]
export default sagas
