import React from 'react'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { Input, IconButton, Box } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'

import useStyles from './styles'
import { DateIcon } from '../IconComponents'

function CustomDateRangePicker({
  onApply,
  onClear,
  values: { startDate, endDate },
}) {
  const classes = useStyles()
  const inputValue = endDate ? `${startDate} - ${endDate}` : ''
  return (
    <Box display="flex" className={classes.root}>
      <DateRangePicker
        containerClass={classes.root}
        containerStyles={{
          display: 'flex',
          flex: '1',
        }}
        startDate={startDate ? moment(startDate) : moment()}
        endDate={endDate ? moment(endDate) : moment()}
        showDropdowns
        showCustomRangeLabel
        ranges={{
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, 'days'),
            moment().subtract(1, 'days'),
          ],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [
            moment()
              .subtract(1, 'month')
              .startOf('month'),
            moment()
              .subtract(1, 'month')
              .endOf('month'),
          ],
        }}
        onApply={onApply}
      >
        <Input
          placeholder="Search"
          disableUnderline
          value={inputValue}
          classes={{ root: classes.input }}
          fullWidth
        />
        <IconButton aria-label="Date Range" size="small">
          <DateIcon fontSize="inherit" />
        </IconButton>
      </DateRangePicker>
      {inputValue ? (
        <IconButton aria-label="Clear" size="small" onClick={handleClear}>
          <Close fontSize="inherit" />
          {/* <CrossIcon fontSize="inherit" /> */}
        </IconButton>
      ) : null}
    </Box>
  )

  function handleClear() {
    onClear()
  }
}

export default CustomDateRangePicker
