import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import { fromJS } from 'immutable'
import { Loader } from '../../components'
import {
  clientsFetch,
  clientsCreate,
  clientInfoFetch,
  clientInfoSave,
  surveynameFetch,
  surveynameUpdate,
  opportunityFetch,
} from './actions'

import { surveysStatusFetch } from '../ManageSurveys/actions'
import { SurveyHeader, SurveyProgress, SurveySidebar } from '../../components'
import useStyles from './styles'
import { ClientForm } from './Components'
import { survey_Status_For_Disabling_Post_Req } from '../../utils/constants'
import { isTextUpdateEnabled } from '../../common/arrayFunctions'

function ClientInfo({
  clients,
  clientsFetch,
  match,
  clientInfoFetch,
  clientinfo,
  clientsCreate,
  history,
  clientInfoSave,
  clientinfoStatus,
  surveyname,
  surveynameFetch,
  surveynameUpdate,
  surveynameupdateStatus,
  createclientstaus,
  loader,
  surveysStatusFetch,
  surveyStatus,
  statuscode,
  opportunityDetails,
  opportunityFetch,
  ohiTestModuleData,
  ohiUpdateDataStatus,
  designsurveyget,
  designupdateStatus,
}) {
  const classes = useStyles()
  const surveyStatusReturned = surveyname.get('items').get('status')
  const isSurveyUserLocked = surveyname.get('items').get('isUserLock')
  const isSurveyAdminLocked = surveyname.get('items').get('isAdminLock')
  const surveyType = surveyname.get('items').get('surveyType')
  const sendHelpDeskEmailInitial = surveyname
    .get('items')
    .get('sendHelpDeskEmailInitial')
  const isButtonToBeDisabled =
    survey_Status_For_Disabling_Post_Req.indexOf(surveyStatusReturned) !== -1 ||
    isSurveyAdminLocked ||
    isSurveyUserLocked

  document.title = 'OHI Portal - Client Information'
  const [isDisabled, setIsdisable] = useState()
  //console.log(surveyStatus)
  function getpidfn(pid) {
    //console.log(pid)
    if (pid === '') {
      setIsdisable(true)
    } else {
      setIsdisable(false)
    }
  }

  function textEnabled() {
    const flag = isTextUpdateEnabled(
      sendHelpDeskEmailInitial,
      designupdateStatus,
      surveyType,
      designsurveyget,
      ohiTestModuleData
    )
    return flag
  }

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <SurveyHeader
        history={history}
        statuscode={statuscode}
        surveyId={match.params.id}
      />
      <SurveyProgress
        onSurveynameFetch={surveynameFetch}
        onSurveynameUpdate={surveynameUpdate}
        surveyname={surveyname}
        params={match.params.id}
        surveynameupdateStatus={surveynameupdateStatus}
        statusList={surveyStatus}
        surveysStatusFetch={surveysStatusFetch}
        ongetpidfn={getpidfn}
        isButtonToBeDisabled={isButtonToBeDisabled}
        textUpdate={textEnabled}
      />
      <Box display="flex" flex={1} className={classes.main}>
        <Box display="flex" flex={1} className={classes.sidebar}>
          <SurveySidebar
            params={match.params}
            surveyname={surveyname}
            isDisabled={isDisabled}
          />
        </Box>
        <Box
          display="flex"
          flex={5}
          className={`${classes.formWrapper} scrollbar-class`}
        >
          <ClientForm
            clients={clients}
            onClientsFetch={clientsFetch}
            params={match.params.id}
            onClientCreate={clientsCreate}
            onClientInfoFetch={clientInfoFetch}
            clientinfo={clientinfo}
            history={history}
            onClientSave={clientInfoSave}
            saveclientStatus={clientinfoStatus}
            createclientstaus={createclientstaus}
            loader={loader}
            opportunityDetails={opportunityDetails}
            opportunityFetch={opportunityFetch}
            statuscode={statuscode}
            isButtonToBeDisabled={isButtonToBeDisabled}
          />
        </Box>
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  clients: state.get('clients'),
  clientinfo: state.get('clientinfo'),
  clientinfoStatus: state.get('clientinfoStatus'),
  surveyname: state.get('surveyname'),
  surveynameupdateStatus: state.get('surveynameupdateStatus'),
  createclientstaus: state.get('createclientstaus'),
  loader: state.get('loader'),
  surveyStatus: state.get('surveyStatus'),
  statuscode: state.get('statuscode'),
  opportunityDetails: state.get('opportunityDetails'),
  ohiTestModuleData: state.get('ohiTestModuleReducer'),
  ohiUpdateDataStatus: state.get('ohiUpdateStatus'),
  designsurveyget: state.get('designsurveyget'),
  designupdateStatus: state.get('designupdateStatus'),
})

const mapDispatchToProps = {
  clientsFetch,
  clientsCreate,
  clientInfoFetch,
  clientInfoSave,
  surveynameFetch,
  surveynameUpdate,
  surveysStatusFetch,
  opportunityFetch,
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo)
