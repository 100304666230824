import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import {
  languageFetch,
  analystFetch,
  countriesFetch,
  regionsFetch,
  populationsFetch,
  surveyTypesFetch,
  getResurveyFetch,
  getSurveyinfoFetch,
  surveyInfoSave,
  OhiVersionFetch,
} from './actions'
import { surveynameFetch, surveynameUpdate } from '../ClientInfo/actions'
import { surveysStatusFetch } from '../ManageSurveys/actions'
import {
  SurveyHeader,
  SurveyProgress,
  SurveySidebar,
  Loader,
} from '../../components'

import useStyles from './styles'
import { SurveyForm, ModalView } from './components'
import { survey_Status_For_Disabling_Post_Req } from '../../utils/constants'
import { isTextUpdateEnabled } from '../../common/arrayFunctions'
import StorageInstance from '../../common/Storage'

function SurveyInfo({
  languages,
  languageFetch,
  analysts,
  analystFetch,
  countries,
  countriesFetch,
  regions,
  regionsFetch,
  populations,
  populationsFetch,
  surveytypes,
  surveyTypesFetch,
  resurvey,
  getResurveyFetch,
  surveyinfo,
  getSurveyinfoFetch,
  match,
  surveyInfoSave,
  savesurveyStatus,
  loader,
  history,
  surveyname,
  surveynameFetch,
  surveynameUpdate,
  surveynameupdateStatus,
  surveysStatusFetch,
  surveyStatus,
  statuscode,
  ohiTestModuleData,
  ohiUpdateDataStatus,
  designsurveyget,
  designupdateStatus,
  OhiVersionFetch,
  OhiVersionList,
}) {
  const classes = useStyles()
  document.title = 'OHI Portal - Survey Information'
  const surveyStatusReturned = surveyname.get('items').get('status')
  const isSurveyUserLocked = surveyname.get('items').get('isUserLock')
  const isSurveyAdminLocked = surveyname.get('items').get('isAdminLock')
  const surveyType = surveyname.get('items').get('surveyType')
  const ohiVersionId = surveyname.get('items').get('ohiVersionId')
  const sendHelpDeskEmailInitial = surveyname
    .get('items')
    .get('sendHelpDeskEmailInitial')
  const isButtonToBeDisabled =
    survey_Status_For_Disabling_Post_Req.indexOf(surveyStatusReturned) !== -1 ||
    isSurveyAdminLocked ||
    isSurveyUserLocked

  const [isDisabled, setIsdisable] = useState()
  //console.log(surveyStatus)
  function getpidfn(pid) {
    //console.log(pid)
    if (pid === '') {
      setIsdisable(true)
    } else {
      setIsdisable(false)
    }
  }
  function gotoNextpage() {
    StorageInstance.surveyInfo = true
    history.push(`../design-survey/${match.params.id}`)
  }

  function textEnabled() {
    const flag = isTextUpdateEnabled(
      sendHelpDeskEmailInitial,
      designupdateStatus,
      surveyType,
      designsurveyget,
      ohiTestModuleData
    )
    return flag
  }

  //console.log(store.getState().toJS().analysts)
  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      {surveyinfo && surveyinfo.get('items') ? (
        <ModalView
          savesurveyStatus={savesurveyStatus}
          gotoNextpage={gotoNextpage}
        ></ModalView>
      ) : null}

      <SurveyHeader
        history={history}
        statuscode={statuscode}
        surveyId={match.params.id}
      />
      <SurveyProgress
        onSurveynameFetch={surveynameFetch}
        onSurveynameUpdate={surveynameUpdate}
        surveyname={surveyname}
        params={match.params.id}
        surveynameupdateStatus={surveynameupdateStatus}
        statusList={surveyStatus}
        surveysStatusFetch={surveysStatusFetch}
        ongetpidfn={getpidfn}
        isButtonToBeDisabled={isButtonToBeDisabled}
        textUpdate={textEnabled}
      />
      <Box display="flex" flex={1} className={classes.main}>
        <Box display="flex" flex={1} className={classes.sidebar}>
          <SurveySidebar params={match.params} isDisabled={isDisabled} />
        </Box>
        <Box
          display="flex"
          flex={5}
          className={classes.formWrapper}
          className="scrollbar-class"
        >
          <SurveyForm
            loader={loader}
            languages={languages}
            onFetch={languageFetch}
            analysts={analysts}
            onAnalystsFetch={analystFetch}
            countries={countries}
            onCountriesFetch={countriesFetch}
            regions={regions}
            onRegionsFetch={regionsFetch}
            populations={populations}
            onPopulationsFetch={populationsFetch}
            surveytypes={surveytypes}
            onSurveytypeFetch={surveyTypesFetch}
            resurvey={resurvey}
            onResurveyFetch={getResurveyFetch}
            surveyinfo={surveyinfo}
            onSurveyinfoFetch={getSurveyinfoFetch}
            params={match.params.id}
            onSurveySave={surveyInfoSave}
            savesurveyStatus={savesurveyStatus}
            history={history}
            statuscode={statuscode}
            isButtonToBeDisabled={isButtonToBeDisabled}
            OhiVersionList={OhiVersionList}
            OhiVersionFetch={OhiVersionFetch}
            defaultohiVersionId={ohiVersionId}
          />
        </Box>
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  languages: state.get('languages'),
  analysts: state.get('analysts'),
  countries: state.get('countries'),
  regions: state.get('regions'),
  populations: state.get('populations'),
  surveytypes: state.get('surveytypes'),
  resurvey: state.get('resurvey'),
  surveyinfo: state.get('surveyinfo'),
  savesurveyStatus: state.get('savesurveyStatus'),
  surveyname: state.get('surveyname'),
  surveynameupdateStatus: state.get('surveynameupdateStatus'),
  loader: state.get('loader'),
  surveyStatus: state.get('surveyStatus'),
  statuscode: state.get('statuscode'),
  ohiTestModuleData: state.get('ohiTestModuleReducer'),
  ohiUpdateDataStatus: state.get('ohiUpdateStatus'),
  designsurveyget: state.get('designsurveyget'),
  designupdateStatus: state.get('designupdateStatus'),
  OhiVersionList: state.get('OhiVersionList'),
})

const mapDispatchToProps = {
  languageFetch,
  analystFetch,
  countriesFetch,
  regionsFetch,
  populationsFetch,
  surveyTypesFetch,
  getResurveyFetch,
  getSurveyinfoFetch,
  surveyInfoSave,
  surveynameFetch,
  surveynameUpdate,
  surveysStatusFetch,
  OhiVersionFetch,
}
export default connect(mapStateToProps, mapDispatchToProps)(SurveyInfo)
