import React, { Fragment, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Slide,
  Select,
  MenuItem,
  FormControl,
  Box,
  List,
  ListItem,
  Button,
  TableContainer,
} from '@material-ui/core'
import moment from 'moment'

import InfiniteScroll from 'react-infinite-scroller'
import ReactTooltip from 'react-tooltip'

import useStyles from './styles'

import SearchInput from '../SearchInput'
import { CustomDateRangePicker, Loader } from '../../../../components'
import { SelectIcon } from '../../../../components/IconComponents'
import { hardRefresh } from '../../apis'

function Surveys({
  surveys,
  onFetch,
  onStatusFetch,
  statusList,
  history,
  userEmail,
  isPostFetched,
}) {
  const classes = useStyles()
  const dateFormat = 'YYYY-MM-DD'
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    surveyId: '',
    clientName: '',
    surveyName: '',
    status: '',
    analysts: '',
    openStartDate: null,
    openEndDate: null,
    closedStartDate: null,
    closedEndDate: null,
    sdRollOffOpenStartDate: null,
    sdRollOffOpenEndDate: null,
    isForAnalyticsBechmark: '',
    pageNumber: 1,
    orderByColumn: '',
    orderType: '',
  })

  useEffect(() => {
    if (isPostFetched) {
      onFetch(values)
    }
  }, [isPostFetched])

  useEffect(() => {
    onFetch(values)
  }, [values])
  useEffect(() => {
    if (userEmail !== '') {
      setValues({
        ...values,
        analysts: userEmail,
        pageNumber: 1,
      })
    } else {
      setValues({
        ...values,
        analysts: '',
        pageNumber: 1,
      })
    }
  }, [userEmail])
  //console.log(surveys.toJS())
  useEffect(() => {
    !statusList.get(' isFetched') && onStatusFetch()
  }, [])

  function goToClientInfo(id) {
    return function() {
      history.push(`/define/client-info/${id}`)
    }
  }

  const handleHardRefresh = async id => {
    setLoading(true)
    await hardRefresh(id)
    setLoading(false)
  }

  return (
    <Fragment>
      <InfiniteScroll
        pageStart={values.pageNumber}
        loadMore={loadFunc}
        hasMore={
          surveys.get('isFetched') &&
          surveys.getIn(['list', 'items']).size <
            surveys.getIn(['list', 'totalCount'])
        }
        loader={<Box key={1}>Loading ...</Box>}
      >
        {loading ? <Loader /> : null}
        <TableContainer>
          <Table className={classes.surveyTable} size="medium" key={0}>
            {renderTableHead()}
            {renderTableBody()}
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </Fragment>
  )

  function renderTableHead() {
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell
            className={getSortHeaderClass('ohiId')}
            align="left"
            style={{ width: '250px' }}
            onClick={handleSort('ohiId')}
          >
            Survey ID
          </TableCell>
          <TableCell
            className={getSortHeaderClass('clientName')}
            align="left"
            onClick={handleSort('clientName')}
          >
            Client Name
          </TableCell>
          <TableCell
            className={getSortHeaderClass('surveyName')}
            align="left"
            onClick={handleSort('surveyName')}
          >
            Survey Name
          </TableCell>
          <TableCell
            className={getSortHeaderClass('status')}
            align="left"
            onClick={handleSort('status')}
          >
            Status
          </TableCell>
          <TableCell
            className={getSortHeaderClass('analysts')}
            align="left"
            onClick={handleSort('analysts')}
          >
            Owners
          </TableCell>
          <TableCell
            className={getSortHeaderClass('startDate')}
            align="left"
            onClick={handleSort('startDate')}
          >
            Launch Date
          </TableCell>
          <TableCell
            className={getSortHeaderClass('startDate')}
            align="left"
            onClick={handleSort('startDate')}
          >
            Closing Date
          </TableCell>
          <TableCell
            className={getSortHeaderClass('SDRollOffDate')}
            align="left"
            onClick={handleSort('SDRollOffDate')}
          >
            SD RollOff Date
          </TableCell>
          <TableCell
            className={getSortHeaderClass('isForAnalyticsBechmark')}
            align="left"
            onClick={handleSort('isForAnalyticsBechmark')}
          >
            GSDB Verified
          </TableCell>

          {localStorage.getItem('userInfo') !== null &&
            JSON.parse(localStorage.getItem('userInfo')).roleId === 1 && (
              <TableCell
                className={getSortHeaderClass('isForAnalyticsBechmark')}
                align="left"
              >
                Hard Refresh
              </TableCell>
            )}
        </TableRow>
      </TableHead>
    )
  }

  function renderSearchRow() {
    return (
      <TableRow>
        <TableCell
          align="left"
          component="th"
          scope="row"
          size="small"
          className={classes.tableBodyCell}
        >
          <SearchInput name="surveyId" onSearch={handleChange} />
        </TableCell>
        <TableCell align="left" size="small" className={classes.tableBodyCell}>
          <SearchInput name="clientName" onSearch={handleChange} />
        </TableCell>
        <TableCell size="small" className={classes.tableBodyCell}>
          <SearchInput name="surveyName" onSearch={handleChange} />
        </TableCell>
        <TableCell size="small" className={classes.tableBodyCell}>
          <FormControl>
            <Select
              value={values.status}
              onChange={handleChange}
              displayEmpty
              name="status"
              IconComponent={SelectIcon}
              autoWidth
              disableUnderline
              className={classes.cstSelect}
            >
              <MenuItem value="">No Selection</MenuItem>
              {statusList.get('items').size
                ? statusList.get('items').map(status => (
                    <MenuItem
                      key={status.get('key')}
                      value={status.get('value')}
                    >
                      {status.get('value')}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell size="small" className={classes.tableBodyCell}>
          <SearchInput
            name="analysts"
            onSearch={handleChange}
            readOnly={userEmail !== ''}
          />
        </TableCell>
        <TableCell size="small" className={classes.tableBodyCell}>
          <CustomDateRangePicker
            onApply={handleRangeApply}
            onClear={handleRangeClear}
            values={{
              startDate: values.openStartDate,
              endDate: values.openEndDate,
            }}
          />
        </TableCell>
        <TableCell size="small" className={classes.tableBodyCell}>
          <CustomDateRangePicker
            onApply={handleCloseRangeApply}
            onClear={handleCloseRangeClear}
            values={{
              startDate: values.closedStartDate,
              endDate: values.closedEndDate,
            }}
          />
        </TableCell>
        <TableCell size="small" className={classes.tableBodyCell}>
          <CustomDateRangePicker
            onApply={handleRangeApplySdRollOff}
            onClear={handleRangeClearSdRollOff}
            values={{
              startDate: values.sdRollOffOpenStartDate,
              endDate: values.sdRollOffOpenEndDate,
            }}
          />
        </TableCell>
        <TableCell size="small" className={classes.tableBodyCell}>
          <FormControl>
            <Select
              value={values.isForAnalyticsBechmark}
              onChange={handleChange}
              displayEmpty
              name="isForAnalyticsBechmark"
              disableUnderline
              autoWidth
              IconComponent={SelectIcon}
              className={classes.cstSelect}
            >
              <MenuItem value="">No Selection</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    )
  }

  function handleRangeApply(event, picker) {
    setValues({
      ...values,
      pageNumber: 1,
      openStartDate: moment(picker.startDate).format(dateFormat),
      openEndDate: moment(picker.endDate).format(dateFormat),
    })
  }
  function handleRangeClear() {
    setValues({ ...values, openStartDate: null, openEndDate: null })
  }

  function handleCloseRangeApply(event, picker) {
    setValues({
      ...values,
      pageNumber: 1,
      closedStartDate: moment(picker.startDate).format(dateFormat),
      closedEndDate: moment(picker.endDate).format(dateFormat),
    })
  }
  function handleCloseRangeClear() {
    setValues({ ...values, closedStartDate: null, closedEndDate: null })
  }

  function handleRangeApplySdRollOff(event, picker) {
    setValues({
      ...values,
      pageNumber: 1,
      sdRollOffOpenStartDate: moment(picker.startDate).format(dateFormat),
      sdRollOffOpenEndDate: moment(picker.endDate).format(dateFormat),
    })
  }

  function handleRangeClearSdRollOff() {
    setValues({
      ...values,
      sdRollOffOpenStartDate: null,
      sdRollOffOpenEndDate: null,
    })
  }

  function renderSurveyRows() {
    return surveys.getIn(['list', 'items']).size
      ? surveys.getIn(['list', 'items']).map((row, index) => (
          <Slide
            direction="right"
            in={true}
            timeout={300}
            mountOnEnter
            unmountOnExit
            key={index}
          >
            <TableRow>
              <TableCell
                align="left"
                component="th"
                scope="row"
                className={classes.tableBodyCell1}
                onClick={goToClientInfo(row.get('id'))}
              >
                {`${row.get('ohiId')} `}
                <Box>{row.get('pId') ? `(${row.get('pId')})` : null}</Box>
              </TableCell>
              <TableCell align="left" className={classes.tableBodyCell}>
                {row.get('clientName')}
              </TableCell>
              <TableCell align="left" className={classes.tableBodyCell}>
                {row.get('surveyName')}
              </TableCell>
              <TableCell align="left" className={classes.tableBodyCell}>
                {row.get('status')}
              </TableCell>
              <TableCell align="left" className={classes.tableBodyCell}>
                {row.get('analysts').size ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>{row.getIn(['analysts', '0'])}</Box>
                    {row.get('analysts').size > 1 ? (
                      <Fragment>
                        <Box
                          className={classes.viewAll}
                          data-tip
                          data-for={`analyst-row-${index}`}
                        >
                          View All
                        </Box>
                        <ReactTooltip
                          id={`analyst-row-${index}`}
                          className="customeTheme"
                        >
                          <List className={classes.listAnalysts}>
                            {row.get('analysts').map((analyst, index) => (
                              <ListItem
                                key={index}
                                className={classes.listItemAnalysts}
                              >
                                <span className={classes.analystsText}>
                                  {analyst}
                                </span>
                              </ListItem>
                            ))}
                          </List>
                        </ReactTooltip>
                      </Fragment>
                    ) : null}
                  </Box>
                ) : (
                  ''
                )}
              </TableCell>
              <TableCell align="left" className={classes.tableBodyCell}>
                {row.get('liveDate')}
              </TableCell>
              <TableCell align="left" className={classes.tableBodyCell}>
                {row.get('closedDate')}
              </TableCell>
              <TableCell align="left" className={classes.tableBodyCell}>
                {row.get('SDRollOffDate')}
              </TableCell>
              <TableCell align="left" className={classes.tableBodyCell}>
                {row.get('isForAnalyticsBechmark') ? 'Yes' : 'No'}
              </TableCell>
              {localStorage.getItem('userInfo') !== null &&
                JSON.parse(localStorage.getItem('userInfo')).roleId === 1 && (
                  <TableCell align="left" className={classes.tableBodyCell}>
                    <Button
                      className={classes.buttonRound}
                      color="primary"
                      variant="contained"
                      onClick={() => handleHardRefresh(row.get('id'))}
                    >
                      Refresh
                    </Button>
                  </TableCell>
                )}
            </TableRow>
          </Slide>
        ))
      : null
  }

  function renderTableBody() {
    return (
      <TableBody>
        {renderSearchRow()}
        {renderSurveyRows()}
      </TableBody>
    )
  }

  function loadFunc() {
    const newValues = { ...values, pageNumber: values.pageNumber + 1 }
    setValues(newValues)
  }

  function handleChange(event) {
    const { name, value } = event.target
    const newValues = { ...values, [name]: value, pageNumber: 1 }
    setValues(newValues)
  }

  function handleSort(orderByColumn) {
    return function() {
      // console.log('orderByColumn', orderByColumn)
      setValues({
        ...values,
        orderByColumn,
        orderType: values.orderType === 'ASC' ? 'DESC' : 'ASC',
        pageNumber: 1,
      })
    }
  }

  function getSortHeaderClass(orderByColumn) {
    return `${classes.tableHeadCell} ${classes.sortable} ${
      values.orderType && orderByColumn === values.orderByColumn
        ? classes[values.orderType === 'ASC' ? 'sortASC' : 'sortDESC']
        : ''
    }`
  }
}

export default Surveys
