import DesignSurvey from './DesignSurvey'
import reducer, {
  designupdatereducer,
  savequestionreducer,
  outcomescalereducer,
  practicecalereducer,
  questionlistreducer,
  updatequestionlistereducer,
  updatequestionereducer,
  influencerreducer,
  updateinfluencerreducer,
  valuesreducer,
  updatevaluesreducer,
  inclusionreducer,
  updateInclusionsreducer,
  ohiTestModuleReducer,
  ohiUpdateTestModuleReducer,
  ohi4ModuleReducer,
  updateohi4Modulereducer,
} from './reducer'

import sagas from './sagas'

export {
  reducer,
  designupdatereducer,
  savequestionreducer,
  outcomescalereducer,
  practicecalereducer,
  questionlistreducer,
  updatequestionlistereducer,
  updatequestionereducer,
  influencerreducer,
  updateinfluencerreducer,
  valuesreducer,
  updatevaluesreducer,
  inclusionreducer,
  updateInclusionsreducer,
  ohiTestModuleReducer,
  ohiUpdateTestModuleReducer,
  ohi4ModuleReducer,
  updateohi4Modulereducer,
  sagas,
}
export default DesignSurvey
