import {
  DESIGNSURVEY_FETCH,
  UPDATEDESIGNSURVEY_CREATE,
  SAVEQUESTION_CREATE,
  OUTCOMESCALE_FETCH,
  PRACTICESCALE_FETCH,
  GETQUESTIONLIST_FETCH,
  UPDATEQUESTIONLIST_CREATE,
  UPDATEQUESTION_CREATE,
  INFLUENCERS_FETCH,
  UPDATEINFLUENCERS_CREATE,
  VALUES_FETCH,
  UPDATEVALUES_CREATE,
  INCLUSION_FETCH,
  UPDATEINCLUSION_CREATE,
  REST_QUESTION_LIST,
  UPDATE_WORK_EX,
  GET_WORK_EX,
  TEST_MODULE_FETCH,
  TEST_MODULE_UPDATE,
  OHI_4_MODULE_FETCH,
  OHI_4_MODULE_UPDATE,
} from './constants'

// GET DESIGN SURVEY
export const fetchDesignSurvey = payload => ({
  type: `${DESIGNSURVEY_FETCH}_PENDING`,
  payload,
})

export const fetchDesignSurveySuccess = payload => ({
  type: `${DESIGNSURVEY_FETCH}_SUCCESS`,
  payload,
})

export const fetchDesignSurveyFailed = payload => ({
  type: `${DESIGNSURVEY_FETCH}_FAILED`,
  payload,
})

// UPDATE DESIGN SURVEY
export const updateDesignSurvey = (payload, moveToNxtPage) => ({
  type: `${UPDATEDESIGNSURVEY_CREATE}_PENDING`,
  payload,
  moveToNxtPage,
})

export const updateDesignSurveySuccess = payload => ({
  type: `${UPDATEDESIGNSURVEY_CREATE}_SUCCESS`,
  payload,
})

export const updateDesignSurveyFailed = payload => ({
  type: `${UPDATEDESIGNSURVEY_CREATE}_FAILED`,
  payload,
})

// SAVE QUESTION SURVEY
export const saveQuestions = payload => ({
  type: `${SAVEQUESTION_CREATE}_PENDING`,
  payload,
})

export const saveQuestionsSuccess = payload => ({
  type: `${SAVEQUESTION_CREATE}_SUCCESS`,
  payload,
})

export const saveQuestionsFailed = payload => ({
  type: `${SAVEQUESTION_CREATE}_FAILED`,
  payload,
})

// get outcome scale

export const getOutcomeFetch = payload => ({
  type: `${OUTCOMESCALE_FETCH}_PENDING`,
  payload,
})

export const getOutcomeFetchSuccess = payload => ({
  type: `${OUTCOMESCALE_FETCH}_SUCCESS`,
  payload,
})

export const getOutcomeFetchFailed = payload => ({
  type: `${OUTCOMESCALE_FETCH}_FAILED`,
  payload,
})

// get practice scale
export const getPracticeFetch = payload => ({
  type: `${PRACTICESCALE_FETCH}_PENDING`,
  payload,
})

export const getPracticeFetchSuccess = payload => ({
  type: `${PRACTICESCALE_FETCH}_SUCCESS`,
  payload,
})

export const getPracticeFetchFailed = payload => ({
  type: `${PRACTICESCALE_FETCH}_FAILED`,
  payload,
})

// get question list
export const getQuestionListFetch = payload => ({
  type: `${GETQUESTIONLIST_FETCH}_PENDING`,
  payload,
})

export const getQuestionListSuccess = payload => ({
  type: `${GETQUESTIONLIST_FETCH}_SUCCESS`,
  payload,
})

export const getQuestionListFailed = payload => ({
  type: `${GETQUESTIONLIST_FETCH}_FAILED`,
  payload,
})

export const resetQuestionList = () => ({
  type: REST_QUESTION_LIST,
})

// update question list
export const updateQuestionListFetch = payload => ({
  type: `${UPDATEQUESTIONLIST_CREATE}_PENDING`,
  payload,
})

export const updateQuestionListSuccess = payload => ({
  type: `${UPDATEQUESTIONLIST_CREATE}_SUCCESS`,
  payload,
})

export const updateQuestionListFailed = payload => ({
  type: `${UPDATEQUESTIONLIST_CREATE}_FAILED`,
  payload,
})

// update question
export const updateQuestionFetch = payload => ({
  type: `${UPDATEQUESTION_CREATE}_PENDING`,
  payload,
})

export const updateQuestionSuccess = payload => ({
  type: `${UPDATEQUESTION_CREATE}_SUCCESS`,
  payload,
})

export const updateQuestionFailed = payload => ({
  type: `${UPDATEQUESTION_CREATE}_FAILED`,
  payload,
})

// GET INFLUENCER
export const influencerFetch = payload => ({
  type: `${INFLUENCERS_FETCH}_PENDING`,
  payload,
})

export const influencerFetchSuccess = payload => ({
  type: `${INFLUENCERS_FETCH}_SUCCESS`,
  payload,
})
export const influencerFetchFailed = payload => ({
  type: `${INFLUENCERS_FETCH}_FAILED`,
  payload,
})

// update INFLUENCER
export const updateInfluencer = payload => ({
  type: `${UPDATEINFLUENCERS_CREATE}_PENDING`,
  payload,
})

export const updateInfluencerSuccess = payload => ({
  type: `${UPDATEINFLUENCERS_CREATE}_SUCCESS`,
  payload,
})

export const updateInfluencerFailed = payload => ({
  type: `${UPDATEINFLUENCERS_CREATE}_FAILED`,
  payload,
})

// GET VALUES
export const valuesFetch = payload => ({
  type: `${VALUES_FETCH}_PENDING`,
  payload,
})

export const valuesFetchSuccess = payload => ({
  type: `${VALUES_FETCH}_SUCCESS`,
  payload,
})
export const valuesFetchFailed = payload => ({
  type: `${VALUES_FETCH}_FAILED`,
  payload,
})

// update Values
export const updateValue = payload => ({
  type: `${UPDATEVALUES_CREATE}_PENDING`,
  payload,
})

export const updateValueSuccess = payload => ({
  type: `${UPDATEVALUES_CREATE}_SUCCESS`,
  payload,
})

export const updateValueFailed = payload => ({
  type: `${UPDATEVALUES_CREATE}_FAILED`,
  payload,
})

// GET inclusion
export const inclusionFetch = payload => ({
  type: `${INCLUSION_FETCH}_PENDING`,
  payload,
})

export const inclusionFetchSuccess = payload => ({
  type: `${INCLUSION_FETCH}_SUCCESS`,
  payload,
})
export const inclusionFetchFailed = payload => ({
  type: `${INCLUSION_FETCH}_FAILED`,
  payload,
})

// update Values
export const updateInclusion = payload => ({
  type: `${UPDATEINCLUSION_CREATE}_PENDING`,
  payload,
})

export const updateInclusionSuccess = payload => ({
  type: `${UPDATEINCLUSION_CREATE}_SUCCESS`,
  payload,
})

export const updateInclusionFailed = payload => ({
  type: `${UPDATEINCLUSION_CREATE}_FAILED`,
  payload,
})
// update Values
export const updateWorkEx = payload => ({
  type: `${UPDATE_WORK_EX}_PENDING`,
  payload,
})

export const updateWorkExSuccess = payload => ({
  type: `${UPDATE_WORK_EX}_SUCCESS`,
  payload,
})

export const updateWorkExFailed = payload => ({
  type: `${UPDATE_WORK_EX}_FAILED`,
  payload,
})

// GET workex
export const workexFetch = payload => ({
  type: `${GET_WORK_EX}_PENDING`,
  payload,
})

export const workexFetchSuccess = payload => ({
  type: `${GET_WORK_EX}_SUCCESS`,
  payload,
})
export const workexFetchFailed = payload => ({
  type: `${GET_WORK_EX}_FAILED`,
  payload,
})

// GET INFLUENCER
export const ohiTestModuleFetch = payload => ({
  type: `${TEST_MODULE_FETCH}_PENDING`,
  payload,
})

export const ohiTestModuleFetchSuccess = payload => ({
  type: `${TEST_MODULE_FETCH}_SUCCESS`,
  payload,
})

export const ohiTestModuleFetchFailed = payload => ({
  type: `${TEST_MODULE_FETCH}_FAILED`,
  payload,
})

export const updateTestModule = payload => ({
  type: `${TEST_MODULE_UPDATE}_PENDING`,
  payload,
})

export const ohiTestModuleUpdateSuccess = payload => ({
  type: `${TEST_MODULE_UPDATE}_SUCCESS`,
  payload,
})

export const ohiTestModuleUpdateFailed = payload => ({
  type: `${TEST_MODULE_UPDATE}_FAILED`,
  payload,
})

export const TextUpdateAllowed = () => ({
  type: `TEXT_UPDATE_ACTION`,
})

// GET OHI 4 Module
export const ohi4ModuleFetch = payload => ({
  type: `${OHI_4_MODULE_FETCH}_PENDING`,
  payload,
})

export const ohi4ModuleFetchSuccess = payload => ({
  type: `${OHI_4_MODULE_FETCH}_SUCCESS`,
  payload,
})
export const ohi4ModuleFetchFailed = payload => ({
  type: `${OHI_4_MODULE_FETCH}_FAILED`,
  payload,
})

// update OHI 4 Module Values
export const updateohi4Module = payload => ({
  type: `${OHI_4_MODULE_UPDATE}_PENDING`,
  payload,
})

export const updateohi4ModulenSuccess = payload => ({
  type: `${OHI_4_MODULE_UPDATE}_SUCCESS`,
  payload,
})

export const updateohi4ModuleFailed = payload => ({
  type: `${OHI_4_MODULE_UPDATE}_FAILED`,
  payload,
})
