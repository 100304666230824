export default function validateNewClient(values, allclients) {
  let errors = {}
  //console.log(allclients)
  let matchnamenew = true
  if (values.newClientName !== '' && allclients !== undefined) {
    if (allclients.some(client => client.value === values.newClientName)) {
      matchnamenew = true
    } else {
      matchnamenew = false
    }
  }
  if (!values.newClientName) {
    errors.newClientName = 'This field is required'
  } else if (matchnamenew) {
    errors.newClientName = 'Client name already exist'
  }
  return errors
}
