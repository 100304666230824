import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import pathOr from 'lodash/fp/pathOr'
import { surveysStatusFetch } from '../ManageSurveys/actions'
import { surveynameFetch, surveynameUpdate } from '../ClientInfo/actions'
import {
  SurveyHeader,
  SurveyProgress,
  SurveySidebar,
  RightPanelTextEditor,
} from '../../components'
import {
  introductionFetch,
  fetchSidePanelData,
  fetchIntroDataForLang,
  setSelectedLang,
  updateSurvey,
  setHasEditorStateChanged,
  fetchSummaryOfEdits,
  importFile,
  exportFile,
  replaceEverywhere,
  toggleImportFileFailureContainer,
  sendEmail,
} from './actions'
import {
  confirmationPopupToggle,
  toggleToastOnSuccess,
} from '../../common/actions'
import useStyles from './styles'
import { connect } from 'react-redux'
import IntroductionBottom from './components/IntroductionBottom'
import { remove_linebreaks } from './utils'
import { removeBrs } from '../../utils'
import PopupModal from '../../components/PopupModal'
import SummaryOfEdits from '../../components/SummaryOfEdits'
import ConfirmationPopup from '../../components/ConfirmationPopup'
import { survey_Status_For_Disabling_Post_Req } from '../../utils/constants'
import { useToasts } from 'react-toast-notifications'
import { isTextUpdateEnabled } from '../../common/arrayFunctions'
import { cloneDeep } from 'lodash'

function SurveyIntoduction({
  match,
  history,
  statuscode,
  surveynameFetch,
  surveynameUpdate,
  surveyname,
  surveynameupdateStatus,
  surveyStatus,
  surveysStatusFetch,
  introductionFetch,
  introductionTexts,
  getSidePanelData,
  sidePanelData,
  isMultiLanguage,
  availableLangauge,
  multipleLanguageData,
  fetchIntroDataForLang,
  selectedLanguageId,
  setSelectedLang,
  baseLanguageName,
  updateSurvey,
  setHasEditorStateChanged,
  hasEditorStateChanged,
  summaryOfEditsIsOpened,
  fetchSummaryOfEdits,
  confirmationPopupToggle,
  confirmationPopupIsOpened,
  importFile,
  baseLanguageId,
  exportFile,
  replaceEverywhere,
  importFileFailureData,
  toggleImportFileFailureContainer,
  importFailureContainerIsOpened,
  successText,
  openToast,
  toggleToastOnSuccess,
  introDuctionClassNames,
  sendEmail,
  designsurveyget,
  ohiTestModuleData,
  designupdateStatus,
}) {
  document.title = 'OHI Portal - Text Update'
  const { addToast } = useToasts()
  const surveyStatusReturned = surveyname.get('items').get('status')
  const introText = surveyname.get('items').get('IntroText')
  const isSurveyUserLocked = surveyname.get('items').get('isUserLock')
  const isSurveyAdminLocked = surveyname.get('items').get('isAdminLock')
  const isButtonToBeDisabled =
    survey_Status_For_Disabling_Post_Req.indexOf(surveyStatusReturned) !== -1 ||
    isSurveyAdminLocked ||
    isSurveyUserLocked
  const viewSurveyURL = surveyname.get('items').get('surveyUrl')
  const surveyType = surveyname.get('items').get('surveyType')
  const sendHelpDeskEmailInitial = surveyname
    .get('items')
    .get('sendHelpDeskEmailInitial')

  const classes = useStyles()
  const surveyId = pathOr('', 'params.id', match)
  const [isDisabled, setIsdisable] = useState()
  const [sideMenuCalled, setSideMenuCalled] = useState(false)
  const [editorState, seEditorState] = useState([])
  const [
    hasUserSelcetdMultiLangOption,
    setHasUserSelcetdMultiLangOption,
  ] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [popupText, setPopupText] = useState('')
  const [popupAppearance, setPopupAppearance] = useState('success')

  function getpidfn(pid) {
    if (pid === '') {
      setIsdisable(true)
    } else {
      setIsdisable(false)
    }
  }

  useEffect(() => {
    setHasEditorStateChanged(false)
    introductionFetch(surveyId)
    sendEmail(surveyId)
    if (!sideMenuCalled) {
      setSideMenuCalled(true)
      getSidePanelData(surveyId)
    }
  }, [])

  useEffect(() => {
    if (openToast || showPopup) {
      const text = successText !== '' ? successText : popupText
      addToast(text, {
        appearance: popupAppearance,
        autoDismiss: true,
      })
      toggleToastOnSuccess({ successText: '', openToast: false })
      setShowPopup(false)
      setPopupText('')
      setPopupAppearance('success')
    }
  }, [openToast, showPopup])

  useEffect(() => {
    if (!hasUserSelcetdMultiLangOption) {
      introductionFetch(surveyId)
    }
  }, [hasUserSelcetdMultiLangOption])

  const toggleShowPopup = (text, appearance) => {
    setShowPopup(true)
    setPopupText(text)
    setPopupAppearance(appearance)
  }

  const introductionData = () => {
    let textData = ''
    let ids = []
    if (
      introductionTexts &&
      introductionTexts.surveyIntroductions &&
      introductionTexts.surveyIntroductions.length > 0
    ) {
      if (introductionTexts.surveyIntroductions.length > 1) {
        introductionTexts.surveyIntroductions.map((item, indx) => {
          ids.push(item.id)
          let trimmedString = remove_linebreaks(item.text).trim()
          trimmedString = removeBrs(trimmedString)
          textData =
            textData + `<div className=p${indx}>` + trimmedString + '</div>'
        })
      } else {
        ids.push(introductionTexts.surveyIntroductions[0].id)
        let trimmedString = remove_linebreaks(
          introductionTexts.surveyIntroductions[0].text
        ).trim()
        trimmedString = removeBrs(trimmedString)
        textData =
          textData +
          `<div className=p${0}>` +
          trimmedString
            .replaceAll('<ul>', '')
            .replaceAll('<li>', '-')
            .replaceAll('</ul>', '')
            .replaceAll('</li>', '<br/>') +
          '</div>'
      }
    }
    return { textData, ids }
  }

  const multiLang = () => {
    if (multipleLanguageData?.length) {
      const data = cloneDeep(multipleLanguageData)
      multipleLanguageData.forEach(({ value: { surveyIntroductions } }, i) => {
        surveyIntroductions.forEach((item, index) => {
          data[i].value.surveyIntroductions[index].text = item?.text
            ?.replaceAll('<ul>', '')
            .replaceAll('<li>', '-')
            .replaceAll('</ul>', '')
            .replaceAll('</li>', '<br/>')
        })
      })
      return data
    }
    return []
  }

  const setEditorState = data => {
    seEditorState(data)
  }

  function textEnabled() {
    const flag = isTextUpdateEnabled(
      sendHelpDeskEmailInitial,
      designupdateStatus,
      surveyType,
      designsurveyget,
      ohiTestModuleData
    )
    return flag
  }

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      {summaryOfEditsIsOpened && (
        <PopupModal fixedHeight modalHeight="90vh" modalWidth="77vw">
          <SummaryOfEdits
            updateSurvey={updateSurvey}
            selectedLanguageId={selectedLanguageId}
            editorState={editorState}
          />
        </PopupModal>
      )}
      {confirmationPopupIsOpened && (
        <PopupModal modalWidth="30vw">
          <ConfirmationPopup
            updateSurvey={updateSurvey}
            editorState={editorState}
            surveyId={surveyId}
            selectedLanguageId={selectedLanguageId}
            hasUserSelcetdMultiLangOption={hasUserSelcetdMultiLangOption}
          />
        </PopupModal>
      )}
      <SurveyHeader
        history={history}
        statuscode={statuscode}
        surveyId={match.params.id}
      />
      <SurveyProgress
        onSurveynameFetch={surveynameFetch}
        onSurveynameUpdate={surveynameUpdate}
        surveyname={surveyname}
        params={match.params.id}
        surveynameupdateStatus={surveynameupdateStatus}
        statusList={surveyStatus}
        surveysStatusFetch={surveysStatusFetch}
        ongetpidfn={getpidfn}
        history={history}
        isDisabled={isDisabled}
        hasEditorStateChanged={hasEditorStateChanged}
        confirmationPopupToggle={confirmationPopupToggle}
        isButtonToBeDisabled={isButtonToBeDisabled}
        textUpdate={textEnabled}
      />
      <Box display="flex" flex={1} className={classes.main}>
        <Box
          display="flex"
          flex={1}
          className={`${classes.sidebar} surveySideBar`}
        >
          <SurveySidebar
            params={match.params}
            isDisabled={isDisabled}
            isTextUpdateComponent={true}
            sidePanelData={sidePanelData}
            hasEditorStateChanged={hasEditorStateChanged}
            confirmationPopupToggle={confirmationPopupToggle}
          />
        </Box>
        <Box
          display="flex"
          flex={5}
          flexDirection="column"
          className={`${classes.formWrapper} scrollbar-class`}
          id="editableText"
        >
          <RightPanelTextEditor
            introductionFetch={introductionFetch}
            richTextContent={introductionData().textData}
            customClassName={'survey-introduction-editor'}
            isMultiLanguage={isMultiLanguage}
            availableLangauge={availableLangauge}
            multipleLanguageData={
              history.location.pathname.includes('instructions')
                ? multiLang()
                : multipleLanguageData
            }
            fetchIntroDataForLang={fetchIntroDataForLang}
            surveyId={surveyId}
            selectedLanguageId={selectedLanguageId}
            setSelectedLang={setSelectedLang}
            ids={introductionData().ids}
            baseLanguageName={baseLanguageName}
            updateSurvey={updateSurvey}
            setEditorState={setEditorState}
            setHasEditorStateChanged={setHasEditorStateChanged}
            hasEditorStateChanged={hasEditorStateChanged}
            confirmationPopupToggle={confirmationPopupToggle}
            setHasUserSelcetdMultiLangOption={setHasUserSelcetdMultiLangOption}
            fetchSummaryOfEdits={fetchSummaryOfEdits}
            importFile={importFile}
            baseLanguageId={baseLanguageId}
            exportFile={exportFile}
            isButtonToBeDisabled={isButtonToBeDisabled}
            viewSurveyURL={viewSurveyURL}
            replaceEverywhere={replaceEverywhere}
            importFileFailureData={importFileFailureData}
            toggleImportFileFailureContainer={toggleImportFileFailureContainer}
            importFailureContainerIsOpened={importFailureContainerIsOpened}
            hasUserSelcetdMultiLangOption={hasUserSelcetdMultiLangOption}
            editorState={editorState}
            showPopup={toggleShowPopup}
            match={match}
            introDuctionClassNames={introDuctionClassNames}
            introText={introText}
          />
          <IntroductionBottom
            history={history}
            match={match}
            editorState={editorState}
            surveyId={surveyId}
            selectedLanguageId={selectedLanguageId}
            updateSurvey={updateSurvey}
            hasEditorStateChanged={hasEditorStateChanged}
            fetchSummaryOfEdits={fetchSummaryOfEdits}
            confirmationPopupToggle={confirmationPopupToggle}
            hasUserSelcetdMultiLangOption={hasUserSelcetdMultiLangOption}
            sidePanelData={sidePanelData}
            isButtonToBeDisabled={isButtonToBeDisabled}
            setHasEditorStateChanged={setHasEditorStateChanged}
          />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => {
  return {
    surveyname: state.get('surveyname'),
    surveynameupdateStatus: state.get('surveynameupdateStatus'),
    surveyStatus: state.get('surveyStatus'),
    introductionTexts: state.get('sureveyIntroductionFetch').introductionTexts,
    sidePanelData: state.get('sureveyIntroductionFetch').sidePanelData,
    isMultiLanguage: state.get('sureveyIntroductionFetch').introductionTexts
      .isMultipleLanguage,
    availableLangauge: state.get('sureveyIntroductionFetch').introductionTexts
      .availableLanguages,
    multipleLanguageData: state.get('sureveyIntroductionFetch')
      .multipleLanguageData,
    selectedLanguageId: state.get('sureveyIntroductionFetch').languageId,
    baseLanguageName: state.get('sureveyIntroductionFetch').introductionTexts
      .languageName,
    hasEditorStateChanged: state.get('sureveyIntroductionFetch')
      .hasEditorStateChanged,
    summaryOfEditsIsOpened: state.get('summaryOfEdits')[
      'summaryOfEditsIsOpened'
    ],
    confirmationPopupIsOpened: state.get('confirmationPop')[
      'confirmationPopupIsOpened'
    ],
    baseLanguageId: state.get('sureveyIntroductionFetch').introductionTexts
      .languageId,
    statuscode: state.get('statuscode'),
    importFileFailureData: state.get('sureveyIntroductionFetch')
      .importFileFailureData,
    importFailureContainerIsOpened: state.get('sureveyIntroductionFetch')
      .importFailureContainerIsOpened,
    successText: state.get('successToast')['successText'],
    openToast: state.get('successToast')['openToast'],
    introDuctionClassNames: state.get('sureveyIntroductionFetch')
      .introDuctionClassNames,
    designsurveyget: state.get('designsurveyget'),
    ohiTestModuleData: state.get('ohiTestModuleReducer'),
    designupdateStatus: state.get('designupdateStatus'),
  }
}

const mapDispatchToProps = {
  surveynameFetch,
  surveynameUpdate,
  surveysStatusFetch,
  introductionFetch,
  getSidePanelData: fetchSidePanelData,
  fetchIntroDataForLang,
  setSelectedLang,
  updateSurvey,
  setHasEditorStateChanged,
  fetchSummaryOfEdits,
  confirmationPopupToggle,
  importFile,
  exportFile,
  replaceEverywhere,
  toggleImportFileFailureContainer,
  toggleToastOnSuccess,
  sendEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyIntoduction)
