import React, { useEffect, useState } from 'react'
import PopupModal from '../../../../components/PopupModal'
import closeModalIcon from '../../../../pages/ClientInfo/assets/Cancel_Icon.png'
import './index.scss'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { ModifiedSelect } from '../../../../components'
import { Button, FormControl } from '@material-ui/core'

const PendingSurveys = ({
  history,
  data,
  analysts,
  postPendingSurveys,
  setOpenPendingSurveyPopup,
}) => {
  const [pendingSurveys, setPendingSurveysData] = useState([])
  useEffect(() => {
    setPendingSurveysData(data)
  }, [data])

  const openClosingProtocol = surveyId => {
    history.push(`/survey/GSDB/${surveyId.substr(-4, 4)}`)
  }

  const handleChange = (value, name, optionValue, isMulti, surveyId) => {
    const data = pendingSurveys.map(survey => {
      if (survey.surveyId === surveyId) {
        return { ...survey, analyst: value ? value.map(item => item.key) : [] }
      }
      return survey
    })
    setPendingSurveysData(data)
  }

  const handleSave = surveyId => {
    postPendingSurveys(
      pendingSurveys.filter(item => item.surveyId === surveyId)
    )
  }
  return (
    <PopupModal modalWidth="33.5vw" modalHeight="86vh">
      <div className="pending-survey-wrapper">
        <img
          src={closeModalIcon}
          onClick={() => setOpenPendingSurveyPopup(false)}
          alt=""
        />
        <span>WARNING</span>
        <span>
          You must complete the closing protocols for the below past due
          survey(s) before starting a new one
        </span>
        <div>
          {pendingSurveys.map(item => (
            <div className="survey-box">
              <div>
                <div>
                  <span>Survey ID</span>
                  <span>Client name</span>
                  <span>Survey name</span>
                </div>
                <div>
                  <span onClick={() => openClosingProtocol(item.surveyId)}>
                    <span>{item.surveyId}</span>
                    <span>&nbsp;({item.pid})</span>
                  </span>
                  <span>{item.clientName}</span>
                  <span>{item.surveyName}</span>
                </div>
                <div>
                  <ArrowForwardIosIcon
                    onClick={() => openClosingProtocol(item.surveyId)}
                    fontSize="small"
                  />
                </div>
              </div>
              <div>
                <span>
                  If these closing protocols need to be completed by another
                  coach, please re-assign this survey:
                </span>
                <FormControl fullWidth>
                  <ModifiedSelect
                    options={analysts.toJS().items}
                    inputId="analyst-select"
                    placeholder="Select Owner..."
                    optionValue="key"
                    optionLabel="value"
                    name="analyst"
                    selectevalue={item.analyst || ''}
                    handleChangefn={handleChange}
                    isMulti
                    surveyId={item.surveyId}
                  />
                </FormControl>
                <Button
                  variant="outlined"
                  color="primary"
                  className={`round_button ${
                    item.analyst.length ? '' : 'disabled'
                  }`}
                  onClick={() => handleSave(item.surveyId)}
                >
                  SAVE
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PopupModal>
  )
}

export default PendingSurveys
