import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(6),
      fontSize: 16,
      color: '#333333',
      position: 'relative',
      '&.design-survey-box-wrapper:nth-child(odd)': {
        backgroundColor: '#fff',
      },
      '&.design-survey-box-wrapper:nth-child(even)': {
        backgroundColor: '#fafafa',
      },
    },
    main: {},
    sectionTitle: {
      fontSize: 20,
      fontWeight: 700,
      color: '#333333',
      textAlign: 'left',
    },
    sectionTitleP: {
      fontSize: 20,
      fontWeight: 700,
      color: '#333333',
      textAlign: 'left',
      marginBottom: 15,
      position: 'relative',
    },
    checkboxGrid: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      '& label': {
        marginBottom: '0 !important',
      },
    },
    selectAll: {
      borderRadius: '12.5px',
      marginLeft: 10,
      fontSize: 12,
      padding: '1px 12px',
      fontWeight: 700,
      color: '#4b93fd',
      borderColor: '#4b93fd',
      '&:focus': {
        outline: 'none',
      },
    },
    showmorebtn: {
      fontSize: 14,
      color: '#4b93fd',
      textAlign: 'left',
      textTransform: 'uppercase',
      fontWeight: 700,
      cursor: 'pointer',
    },
    checkboxContainer: {
      padding: '20px 0',
    },
    checkboxIconUnchecked: {
      fontSize: 25,
      color: '#999999',
    },
    checkboxIcon: {
      fontSize: 25,
      color: '#021c4b',
    },
    checkboxLabel: {
      fontSize: 16,
      color: '#999999',
    },
    outcomeTitle: {
      fontSize: 12,
      color: '#000000',
      backgroundColor: '#f0f0f0',
      display: 'inline-block',
      padding: ' 3px 10px',
      marginBottom: '8px',
    },
    subtitle: {
      fontSize: 12,
      lineHeight: 2.25,
      color: '#393939',
      textAlign: 'left',
      fontStyle: 'italic',
    },
    subtitleAnchorTag: {
      fontSize: 12,
      lineHeight: 2.25,
      color: '#4b93fd',
      textAlign: 'left',
      fontStyle: 'italic',
      marginLeft: '2px',
      fontWeight: 'bold',
    },
    practiceContainer: {
      padding: '10px 0',
      marginBottom: '14px',
      position: 'relative',
      '&:after': {
        content: '""',
        height: 1,
        width: 'calc(100% - 24px)',
        bottom: 0,
        backgroundColor: '#e3e3e3',
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 'auto',
      },
      '& label': {
        marginBottom: 0,
      },
      checkboxGrid: {
        marginBottom: 0,
      },
    },
    headingbtnsection: {
      position: 'absolute',
      right: 0,
      '& img': {
        cursor: 'pointer',
        position: 'absolute',
        zIndex: '99',
        top: '7px',
        left: '7px',
      },
    },
    headingbtnsectionC: {
      position: 'absolute',
      height: 35,
      width: 35,
      lineHeight: '32px',
      textAlign: 'center',
      right: 0,
      borderRadius: '100%',
      cursor: 'pointer',
      '& span': {
        fontSize: 20,
      },
    },
    headingbtnsectionCDisabled: {
      position: 'absolute',
      height: 35,
      width: 35,
      lineHeight: '32px',
      textAlign: 'center',
      right: 0,
      borderRadius: '100%',
      cursor: 'pointer',
      '& span': {
        fontSize: 20,
      },
      pointerEvents: 'none',
      opacity: 0.4,
    },
    buttonProgress: {
      color: '#4b93fd',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: 0,
    },
    searchinput: {
      width: 323,
      height: 35,
      borderRadius: '17.5px',
      boxShadow: '0 2px 4px 0 rgba(211, 211, 211, 0.5)',
      padding: '0 10px',
      border: 'solid 1px #fafafa',
      transition: 'all 0.4s ease-in',
      paddingLeft: '44px',
      fontSize: 16,
      fontWeight: 'normal',
      '&:focus': {
        outline: 'none',
      },
    },
    checked: {
      '&, & + $label': {
        color: '#333333',
        transition: 'all 0.4s ease-in-out',
        marginBottom: 0,
      },
    },
    label: {
      fontSize: 16,
      fontWeight: 400,
      color: '#999999',
      marginBottom: 0,
      transition: 'all 0.4s ease-in-out',
    },
    formBottom: {
      borderTop: '1px solid #dddddd',
      padding: theme.spacing(4, 5),
    },
    buttonRound: {
      borderRadius: '25px',
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: 14,
    },
    buttonRoundDisabled: {
      borderRadius: '25px',
      marginLeft: '20px',
      fontWeight: '400',
      fontSize: 14,
      pointerEvents: 'none',
      opacity: 0.4,
    },
    backbtn: {
      width: 40,
      height: 40,
      backgroundColor: 'transparent',
      border: 'none',
      backgroundPosition: 'center center',
      position: 'absolute',
      right: 48,
      top: 40,
      zIndex: 99,
      '&:focus': {
        outline: 'none',
      },
    },
    questionlbl: {
      position: 'relative',
      '& label': {
        paddingLeft: 42,
        marginBottom: '20px !important',
        '& span:first-child': {
          position: 'absolute',
          left: 7,
          top: 0,
        },
      },
    },
    deepdiveEdit: {
      backgroundColor: 'transparent',
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },

    editDeepdive: {
      position: 'fixed',
      width: '450px',
      right: -450,
      height: 'calc(100% - 145px)',
      top: 145,
      zIndex: 999,
      backgroundColor: '#fff',
      boxShadow: '0 -1px 15px rgba(204, 204, 204, 0.6)',
      transition: 'all 0.4s ease-in-out',
      paddingBottom: 77,
    },
    vluesmodal: {
      width: '50%',
      right: '-50%',
    },
    editDeepdiveActive: {
      right: 0,
    },
    EditInclusionActive: {
      right: 0,
    },
    EditTestModule: {
      right: 0,
    },
    myhelptext: {
      color: '#ff0000',
      display: 'inline-block',
      marginLeft: 10,
    },
    editValuesActive: {
      right: 0,
    },
    nodataOP: {
      float: 'right',
      '& em': {
        fontSize: 12,
        fontWeight: 400,
        color: '#999',
        marginLeft: 5,
      },
    },
    allselected: {
      backgroundColor: '#4b93fd !important',
      border: '1px solid rgba(2, 28, 75, 0.5)',
      borderColor: '#4b93fd !important',
      boxShadow: 'none',
      '& span': {
        color: '#ffffff !important',
      },
    },
    sensitiveDemo: {
      alignItems: 'flex-start !important',
    },
    sensitiveDDataPreview: {
      paddingBottom: 5,
    },
  }
})

export default styles
