import React from 'react'
import { SITE_EMAIL } from '../../utils/constants'

const Unauthenticated = () => (
  <div style={{ padding: '80px' }}>
    <div>You don’t have access to this page.</div>
    <div>
      {'Try accessing '}
      <a href={window.location.origin}>{` ${window.location.origin} `}</a>
      again. Otherwise, please reach out to{' '}
      <a href={`mailto:${SITE_EMAIL}`}>{SITE_EMAIL}</a> to request access. We’re
      sorry for the inconvenience.
    </div>
  </div>
)

export default Unauthenticated
