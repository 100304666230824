import StorageInstance from './Storage'

export const createFilter = arrayFilter => {
  arrayFilter.map((item, index) => {
    arrayFilter[index].isCheckBox = true
    item.children.map((items, i) => {
      arrayFilter[index].children[i].isSelected = false
    })
  })
  return arrayFilter
}

export const isTextUpdateEnabled = (
  sendHelpDeskEmailInitial,
  designupdateStatus,
  surveyType,
  designsurveyget,
  ohiTestModuleData
) => {
  if (
    sendHelpDeskEmailInitial === true ||
    (StorageInstance.summary &&
      StorageInstance.designSurvey &&
      StorageInstance.clientInfo &&
      StorageInstance.surveyInfo)
  ) {
    return true
  }
  // else if (sendHelpDeskEmailInitial === false) {
  //   if (surveyType === 4) {
  //     const ifOhiTrue = designsurveyget
  //       .toJS()
  //       ?.items?.deepDive?.filter(
  //         ({ id = '', isSelected = false }) => id === 9 && isSelected === true
  //       ).length
  //       ? true
  //       : false
  //     const ohiModuleTrue = ohiTestModuleData
  //       .toJS()
  //       ?.items?.Values?.some(({ isSelected }) => isSelected === true)
  //     return ifOhiTrue && ohiModuleTrue
  //   } else {
  //     const ifAnyDeepDiveTrue = designsurveyget
  //       .toJS()
  //       ?.items?.deepDive?.some(({ isSelected }) => isSelected === true)
  //     return ifAnyDeepDiveTrue || false
  //   }
  // }
  else {
    return false
  }
}
