import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    modalTitle: {
      fontSize: '1.9vh',
      marginBottom: '3vh',
    },
    loadingText: {
      fontSize: '1.5vh',
      marginTop: '1.4vh',
      color: '#999999',
      fontStyle: 'italic',
    },
  }
})

export default styles
