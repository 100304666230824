import axios from 'axios'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}

const api = new APIs('/api')

// get opportunity
export const fetchOpportunityOption = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`SFClient`),
    params: { ...data },
  })
  return result
}

// SURVEY NAME FETCH
export const fetchSurveyName = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveys/getSurveyName/${data}`),
  })
  return result
}

// SURVEY NAME UPDATE

export const updateSurveyName = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveys/updateSurveyName/${data.id}`),
    data,
  })
  return result
}

//UPDATE CLIENT INFO
export const saveClientInfo = async data => {
  const result = await axios({
    method: 'put',
    url: api.getURL(`surveys/updateClientInformation`),
    data,
  })
  return result
}

// CLIENT IFNO FETCH
export const fetchClientinfo = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveys/getclientinformation/${data}`),
  })
  return result
}

export const fetchClients = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL('clients'),
    params: { ...data },
  })
  return result
}

/* export const fetchClientsSurvey = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL(`surveys/getclientinformation/${data}`),
  })
  return result
} */

export const createClient = async data => {
  const result = await axios({
    method: 'post',
    url: api.getURL(`clients`),
    data,
  })
  return result
}
