import axios from 'axios'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}

const api = new APIs('/api')

export const fetchAll = async data => {
  const result = await axios({
    method: 'get',
    url: api.getURL('surveys'),
    params: { ...data },
  })

  return result
}

export const fetchStatus = async () => {
  const result = await axios({
    method: 'get',
    url: api.getURL('surveys-status'),
  })
  return result
}

// CREATE NEW SURVEY

export const createSurvey = async () => {
  const result = await axios({
    method: 'post',
    url: api.getURL('surveys'),
  })
  return result
}

// HARD REFRESH
export const hardRefresh = async data => {
  const result = await axios({
    method: 'post',
    url: api.getURL(`response-rate/hard-refresh/${data}`),
  })
  return result
}
