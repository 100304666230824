import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Tabs,
  Tab,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import tabstyles from './styles'
import { cloneDeep } from 'lodash'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded'
import { Intent_Leave } from '../../../../utils/constants'

function TabPanel(props) {
  const classes = tabstyles()
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          classes={{
            root: classes.boxRoot,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Optionalquestions({ data, dataHandler, ohi4Module }) {
  const classes = tabstyles()
  const [value, setValue] = React.useState(0)
  const [activeAccordion, setActiveAccordion] = React.useState('panel-0-header')
  const [options, setOptions] = React.useState({
    optionalques: [],
  })

  React.useEffect(() => {
    if (data.length) {
      setOptions({ optionalques: data })
    }
  }, [data])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setActiveAccordion([])
  }

  const changeOptionalquestionData = (
    subItemindex,
    childIndex,
    itemIndex,
    flag
  ) => {
    let tempOptionalData = cloneDeep(data.optionalQuestion)
    tempOptionalData[itemIndex].groupDetails[subItemindex].data[
      childIndex
    ].isSelected = flag
    dataHandler({
      ...data,
      optionalQuestion: tempOptionalData,
    })
  }

  const handleAccordionChange = value => {
    if (activeAccordion.includes(value)) {
      const data = [...activeAccordion]
      const index = data.indexOf(value)
      if (index > -1) {
        data.splice(index, 1)
      }
      setActiveAccordion([...data])
    } else {
      setActiveAccordion([...activeAccordion, value])
    }
  }

  const getCount = itemIndex => {
    let countDetails = 0
    const getGroupDetails = data.optionalQuestion[itemIndex].groupDetails
    getGroupDetails.forEach(item => {
      const { data } = item
      data.forEach(item => {
        if (item.isSelected === true) {
          countDetails = countDetails + 1
        }
      })
    })
    return countDetails
  }

  const handleQuestions = (e, id) => {
    const clone = cloneDeep(data)
    const {
      target: { checked },
    } = e
    data.optionalQuestion[id].groupDetails.forEach(({ data: cdata }, i) => {
      cdata.forEach((item, ci) => {
        clone.optionalQuestion[id].groupDetails[i].data[ci].isSelected = checked
      })
    })
    dataHandler(clone)
  }

  const handleQuestionsThree = (e, id, sindex) => {
    const {
      target: { checked },
    } = e
    const clone = cloneDeep(data)
    data.optionalQuestion[id].groupDetails[sindex].data.forEach((item, i) => {
      clone.optionalQuestion[id].groupDetails[sindex].data[
        i
      ].isSelected = checked
    })
    dataHandler(clone)
  }

  return (
    <Box
      sx={{ width: '100%' }}
      classes={{
        root: classes.root,
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Otional Questions Tabs"
          classes={{
            indicator: classes.indicator,
            root: classes.TabRoot,
          }}
        >
          {data?.optionalQuestion?.map((chkitem, itemIndex) => {
            return (
              <Tab
                label={
                  <div className={classes.tabCheckWrapper}>
                    {chkitem.name}
                    <div
                      className={
                        getCount(itemIndex)
                          ? classes.tabCheckCount
                          : classes.tabCheckCountEmpty
                      }
                    >
                      {getCount(itemIndex) ? getCount(itemIndex) : null}
                    </div>
                  </div>
                }
                {...a11yProps(itemIndex)}
                classes={{
                  root: classes.tbbutton,
                  selected: classes.tbselected,
                }}
                disabled={chkitem.groupDetails.length ? false : true}
              />
            )
          })}
        </Tabs>
      </Box>
      {data?.optionalQuestion?.map((chkitem, itemIndex) => {
        if (value === 3 && chkitem.name === '4.0 Questions') {
          const checkedFlag = data?.optionalQuestion[
            value
          ]?.groupDetails[0]?.data?.some(({ isSelected }) => isSelected)
          return (
            <TabPanel value={value} index={itemIndex}>
              <Accordion
                classes={{ root: classes.accordionRoot }}
                onChange={() => handleAccordionChange(`panel-0-4.0 Questions`)}
              >
                <AccordionSummary
                  expandIcon={
                    activeAccordion.includes(`panel-0-4.0 Questions`) ? (
                      <RemoveIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                  aria-controls={`panel-0-4.0 Questions-content`}
                  id={`panel-0-4.0 Questions-content`}
                  classes={{ root: classes.accordionSummaryRoot }}
                >
                  <Box
                    display={`flex`}
                    justifyContent={'flex-start'}
                    sx={{ gap: '10px' }}
                    alignItems={`center`}
                  >
                    <Checkbox
                      name="4.0 Questions"
                      id="4.0 Questions"
                      onClick={e => handleQuestions(e, 3)}
                      disabled={false}
                      classes={{
                        checked: classes.checked,
                      }}
                      checked={checkedFlag}
                      icon={
                        <CircleUnchecked
                          className={classes.checkboxIconUnchecked}
                        />
                      }
                      checkedIcon={
                        <CircleCheckedFilled className={classes.checkboxIcon} />
                      }
                      sx={{ height: 0, width: 0 }}
                    />
                    <Typography classes={{ root: classes.accordionlabel }}>
                      OHI 4.0 Practice Questions
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetail }}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto auto',
                      gap: '10px 10px',
                    }}
                  >
                    {data?.optionalQuestion[value].groupDetails?.length
                      ? data?.optionalQuestion[value].groupDetails?.map(
                          ({ name }, i) => {
                            return (
                              <Box
                                display={`flex`}
                                justifyContent={`flex-start`}
                                sx={{ gap: '7px' }}
                                alignItems="center"
                              >
                                <Checkbox
                                  sx={{ height: 0, width: 0 }}
                                  style={{ pointerEvents: 'none' }}
                                  checked={true}
                                  disabled={false}
                                  checkedIcon={
                                    <FiberManualRecordRoundedIcon
                                      style={{
                                        height: '10px',
                                        widht: '10px',
                                        color: 'black',
                                      }}
                                      fontSize="small"
                                    />
                                  }
                                />
                                <Typography
                                  style={{
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                  }}
                                >
                                  {name}
                                </Typography>
                              </Box>
                            )
                          }
                        )
                      : null}
                  </div>
                </AccordionDetails>
              </Accordion>
            </TabPanel>
          )
        }

        if (value === 3 && chkitem.name === '3.2 Questions') {
          return (
            <TabPanel value={value} index={itemIndex}>
              {chkitem.groupDetails.map((subItem, subItemindex) => {
                return (
                  <Accordion
                    classes={{ root: classes.accordionRoot }}
                    onChange={() =>
                      handleAccordionChange(
                        `panel-${subItemindex}-${subItem.name}`
                      )
                    }
                  >
                    <AccordionSummary
                      expandIcon={
                        activeAccordion.includes(
                          `panel-${subItemindex}-${subItem.name}`
                        ) ? (
                          <RemoveIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      aria-controls={`panel-${subItemindex}-${subItem.name}-content`}
                      id={`panel-${subItemindex}-${subItem.name}-header`}
                      classes={{ root: classes.accordionSummaryRoot }}
                    >
                      <Box
                        display={`flex`}
                        justifyContent={'flex-start'}
                        sx={{ gap: '10px' }}
                        alignItems={`center`}
                      >
                        <Checkbox
                          name={subItem.name}
                          id={subItem.name}
                          onClick={e =>
                            handleQuestionsThree(e, 3, subItemindex)
                          }
                          disabled={false}
                          classes={{
                            checked: classes.checked,
                          }}
                          checked={chkitem.groupDetails[
                            subItemindex
                          ]?.data?.some(({ isSelected }) => isSelected)}
                          icon={
                            <CircleUnchecked
                              className={classes.checkboxIconUnchecked}
                            />
                          }
                          checkedIcon={
                            <CircleCheckedFilled
                              className={classes.checkboxIcon}
                            />
                          }
                        />
                        <Typography classes={{ root: classes.accordionlabel }}>
                          {subItem.name}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails
                      classes={{ root: classes.accordionDetail }}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'auto auto',
                          gap: '5px 5px',
                        }}
                        key={`${subItemindex}-${subItem.name}`}
                      >
                        {subItem?.data?.map(child => {
                          return (
                            <Box
                              display={`flex`}
                              justifyContent={`flex-start`}
                              sx={{ gap: '7px' }}
                              alignItems="center"
                            >
                              <Checkbox
                                sx={{ height: 0, width: 0 }}
                                style={{ pointerEvents: 'none' }}
                                checked={true}
                                disabled={false}
                                checkedIcon={
                                  <FiberManualRecordRoundedIcon
                                    style={{
                                      height: '10px',
                                      widht: '10px',
                                      color: 'black',
                                    }}
                                    fontSize="small"
                                  />
                                }
                              />
                              <Typography
                                style={{ fontSize: '14px', lineHeight: '20px' }}
                              >
                                {child.questionText[0]}
                              </Typography>
                            </Box>
                          )
                        })}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </TabPanel>
          )
        }

        return (
          <TabPanel value={value} index={itemIndex}>
            {chkitem.groupDetails.map((subItem, subItemindex) => {
              return (
                <Accordion
                  classes={{ root: classes.accordionRoot }}
                  onChange={() =>
                    handleAccordionChange(
                      `panel-${subItemindex}-${subItem.name}`
                    )
                  }
                >
                  <AccordionSummary
                    expandIcon={
                      activeAccordion.includes(
                        `panel-${subItemindex}-${subItem.name}`
                      ) ? (
                        <RemoveIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    aria-controls={`panel-${subItemindex}-${subItem.name}-content`}
                    id={`panel-${subItemindex}-${subItem.name}-header`}
                    classes={{ root: classes.accordionSummaryRoot }}
                  >
                    <Typography classes={{ root: classes.accordionlabel }}>
                      {subItem.name}
                      <em style={{ paddingLeft: '3px' }}>
                        {subItem.name === 'Intent to Leave - Reasons'
                          ? Intent_Leave
                          : null}
                      </em>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: classes.accordionDetail }}>
                    {subItem?.data?.map((child, childIndex) => {
                      const disabled =
                        value === 0 &&
                        subItem.name === 'Intent to Leave - Reasons' &&
                        ohi4Module?.filter(
                          ({ name, isSelected }) =>
                            name === 'Employee Experience' && !isSelected
                        ).length
                      return (
                        <Grid
                          item
                          xs={12}
                          align="left"
                          className={`${classes.checkboxGrid} ${classes.questionlbl}`}
                          key={child.id}
                        >
                          <FormControlLabel
                            classes={{
                              label: classes.label,
                            }}
                            control={
                              <Checkbox
                                disabled={disabled}
                                classes={{
                                  checked: classes.checked,
                                }}
                                checked={child.isSelected}
                                icon={
                                  <CircleUnchecked
                                    className={classes.checkboxIconUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <CircleCheckedFilled
                                    className={classes.checkboxIcon}
                                  />
                                }
                                onChange={e => {
                                  changeOptionalquestionData(
                                    subItemindex,
                                    childIndex,
                                    itemIndex,
                                    e.target.checked
                                  )
                                }}
                                style={{ fontSize: 20 }}
                                inputProps={{
                                  'aria-label': child.questionText[0],
                                }}
                              />
                            }
                            label={
                              <Box
                                classes={{
                                  root: classes.questionTextBox,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: `${child.questionText[0]}`,
                                }}
                              ></Box>
                            }
                          />
                        </Grid>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </TabPanel>
        )
      })}
    </Box>
  )
}
