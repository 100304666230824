import { makeStyles, fade } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {},
    surveyTable: {
      marginTop: '2.9vh',
      minWidth: '38.7vw',
      width: '72.2vw',
      boxShadow: '0px 2px 4px 3px rgba(235, 235, 235, 0.5)',
    },
    tableHead: {
      width: '100%',
    },
    tableHeadCell: {
      backgroundColor: fade('#4b93fd', 0.1),
      color: '#1e1e1e',
      fontWeight: 700,
      fontSize: '1.6vh',
      padding: '2vh',
      '&:nth-child(1)': {
        width: '7vw',
      },
      '&:nth-child(2)': {
        width: '6vw',
      },
      '&:nth-child(4)': {
        width: '21vw',
      },
      '&:nth-child(3)': {
        width: '21vw',
      },
      '&:nth-child(6)': {
        width: '6vw',
      },
      '&:last-child': {
        width: '13vw',
      },
      borderRightStyle: 'solid',
      borderRightWidth: '2px',
      borderRightColor: fade('#979797', 0.15),
      borderBottom: 'none',
      position: 'relative',
    },
    sortable: {
      cursor: 'pointer',
    },
    tableBodyCell: {
      borderRightStyle: 'solid',
      borderRightWidth: '2px',
      borderRightColor: fade('#979797', 0.15),
      borderBottom: 'none',
      padding: '0.9vh 1.8vw 0.9vh 1.2vw',
      '&:nth-child(1)': {
        width: '7vw',
      },
      '&:nth-child(2)': {
        width: '6vw',
      },
      '&:nth-child(4)': {
        width: '21vw',
      },
      '&:nth-child(3)': {
        width: '21vw',
      },
      '&:nth-child(6)': {
        width: '6vw',
      },
      '&:last-child': {
        width: '13vw',
      },
      fontSize: '1.4vh',
    },
    text: {
      fontSize: '2.1vh',
      fontWeight: 700,
    },
    activeChip: {
      backgroundColor: '#f28165',
      color: '#fff',
      border: '1px solid #f28165',
      cursor: 'pointer',
    },
    chip: {
      marginRight: '0.5vw',
      cursor: 'pointer',
      fontSize: '1.4vh',
    },
    tableRow: {
      height: 100,
      overflow: 'scroll',
      backgroundColor: 'rgba(75, 147, 253, 0.04) !important',
    },
    tableRowHighlighted: {
      height: 100,
      overflow: 'scroll',
      backgroundColor: 'lightgoldenrodyellow !important',
    },
    baselanguageIdentifier: {
      width: 7,
      height: 7,
      marginLeft: 8,
      marginRight: 8,
      borderRadius: '100%',
      backgroundColor: 'blue',
    },
    summaryContainer: {
      overflow: 'scroll',
      height: '100%',
      width: '73.2vw',
    },
    noDataText: {
      textAlign: 'center',
      fontSize: '1.2rem',
      color: '(--gray-dark)',
    },
    fixedHeightClass: {
      height: '10vh',
    },
    arabicTextDir: {
      direction: 'rtl',
    },
    cstSelect: {
      fontSize: '1.4vh !important',
    },
  }
})

export default styles
