export default function validateInfluencer(values) {
  let errors = {}

  if (!values.isOptout) {
    errors.isOptout = 'This field is required'
  }
  if (!values.isRequired) {
    errors.isRequired = 'This field is required'
  }
  if (values.nominateMin > values.nominateMax) {
    errors.numbertonominate = 'Max value should be greater than min'
  }
  if (!values.nominateMax) {
    errors.numbertonominate = 'Max is required'
  }
  if (!values.nominateMin) {
    errors.numbertonominate = 'Min is required'
  }

  return errors
}
