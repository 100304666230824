import { call, put, takeLatest } from 'redux-saga/effects'

import {
  fetchDesignSurveySuccess,
  fetchDesignSurveyFailed,
  updateDesignSurveySuccess,
  updateDesignSurveyFailed,
  saveQuestionsSuccess,
  saveQuestionsFailed,
  getOutcomeFetchSuccess,
  getOutcomeFetchFailed,
  getPracticeFetchSuccess,
  getPracticeFetchFailed,
  getQuestionListSuccess,
  getQuestionListFailed,
  updateQuestionListSuccess,
  updateQuestionListFailed,
  updateQuestionSuccess,
  updateQuestionFailed,
  influencerFetchSuccess,
  influencerFetchFailed,
  updateInfluencerSuccess,
  updateInfluencerFailed,
  valuesFetchSuccess,
  valuesFetchFailed,
  updateValueSuccess,
  updateValueFailed,
  inclusionFetchSuccess,
  inclusionFetchFailed,
  updateInclusionSuccess,
  updateInclusionFailed,
  fetchDesignSurvey as fetchDesignSurveyAction,
  updateWorkExSuccess,
  updateWorkExFailed,
  workexFetchFailed,
  workexFetchSuccess,
  ohiTestModuleFetchFailed,
  ohiTestModuleFetchSuccess,
  ohiTestModuleUpdateSuccess,
  ohiTestModuleUpdateFailed,
  ohi4ModuleFetchSuccess,
  ohi4ModuleFetchFailed,
  updateohi4Module,
  updateohi4ModulenSuccess,
  updateohi4ModuleFailed,
} from './actions'
import {
  DESIGNSURVEY_FETCH,
  UPDATEDESIGNSURVEY_CREATE,
  SAVEQUESTION_CREATE,
  OUTCOMESCALE_FETCH,
  PRACTICESCALE_FETCH,
  GETQUESTIONLIST_FETCH,
  UPDATEQUESTIONLIST_CREATE,
  UPDATEQUESTION_CREATE,
  INFLUENCERS_FETCH,
  UPDATEINFLUENCERS_CREATE,
  VALUES_FETCH,
  UPDATEVALUES_CREATE,
  INCLUSION_FETCH,
  UPDATEINCLUSION_CREATE,
  UPDATE_WORK_EX,
  GET_WORK_EX,
  TEST_MODULE_FETCH,
  TEST_MODULE_UPDATE,
  OHI_4_MODULE_FETCH,
  OHI_4_MODULE_UPDATE,
} from './constants'
import {
  fetchDesignSurvey,
  updateDesignSurvey,
  saveQuestion,
  fetchOutcmeScale,
  fetchPracticeScale,
  fetchQuestionList,
  updateQuestionList,
  updateQuestion,
  fetchInfluencer,
  updateInfluencer,
  updateValues,
  updateInclusion,
  updateWordExapi,
  fetchOhiTestModule,
  updateOhiTestModule,
  fetchOhi4module,
  updateOhi4module,
} from './apis'

// Design Survey Get

function* fetchDesignSurveyfn({ payload }) {
  try {
    const response = yield call(fetchDesignSurvey, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(fetchDesignSurveySuccess(data))
    } else {
      yield put(fetchDesignSurveyFailed(response))
    }
  } catch (e) {
    yield put(fetchDesignSurveyFailed(e.response))
  }
}

function* updateDesignSurveyFn({ payload, moveToNxtPage }) {
  const { surveyId } = payload
  try {
    const response = yield call(updateDesignSurvey, payload)
    const data = { respData: response }
    if (response.status === 200) {
      yield put(updateDesignSurveySuccess(data))
      if (!moveToNxtPage) {
        yield put(fetchDesignSurveyAction({ id: surveyId }))
      }
    } else {
      yield put(updateDesignSurveyFailed(response))
    }
  } catch (e) {
    yield put(updateDesignSurveyFailed(e.response))
  }
}
//SAVE QUESTION
function* saveQuestionFn({ payload }) {
  try {
    const response = yield call(saveQuestion, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(saveQuestionsSuccess(data))
    } else {
      yield put(saveQuestionsFailed(response))
    }
  } catch (e) {
    yield put(saveQuestionsFailed(e.response))
  }
}
//OUTCOME SCALE
function* fetchOutcomeScalefn() {
  try {
    const response = yield call(fetchOutcmeScale)
    if (response.status === 200) {
      yield put(getOutcomeFetchSuccess(response.data))
    } else {
      yield put(getOutcomeFetchFailed(response))
    }
  } catch (e) {
    yield put(getOutcomeFetchFailed(e.response))
  }
}

//PRACTICE SCALE
function* fetchPracticeScalefn() {
  try {
    const response = yield call(fetchPracticeScale)
    if (response.status === 200) {
      yield put(getPracticeFetchSuccess(response.data))
    } else {
      yield put(getPracticeFetchFailed(response))
    }
  } catch (e) {
    yield put(getPracticeFetchFailed(e.response))
  }
}
// GET QUESTION LIST
function* fetchQuestionListfn({ payload }) {
  try {
    const response = yield call(fetchQuestionList, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(getQuestionListSuccess(data))
    } else {
      yield put(getQuestionListFailed(response))
    }
  } catch (e) {
    yield put(getQuestionListFailed(e.response))
  }
}

//UPDATE QUESTION LIST
function* updateQuestionListFn({ payload }) {
  try {
    const response = yield call(updateQuestionList, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(updateQuestionListSuccess(data))
    } else {
      yield put(updateQuestionListFailed(response))
    }
  } catch (e) {
    yield put(updateQuestionListFailed(e.response))
  }
}

//UPDATE QUESTION
function* updateQuestionFn({ payload }) {
  try {
    const response = yield call(updateQuestion, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(updateQuestionSuccess(data))
    } else {
      yield put(updateQuestionFailed(response))
    }
  } catch (e) {
    yield put(updateQuestionFailed(e.response))
  }
}

// FETCH INFLUENCER
function* fetchInfluencerfn({ payload }) {
  try {
    const response = yield call(fetchInfluencer, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(influencerFetchSuccess(data, payload))
    } else {
      yield put(influencerFetchFailed(response))
    }
  } catch (e) {
    yield put(influencerFetchFailed(e.response))
  }
}

// FETCH INFLUENCER
function* fetchOHItestModulefn({ payload }) {
  try {
    const response = yield call(fetchOhiTestModule, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(ohiTestModuleFetchSuccess(data, payload))
    } else {
      yield put(ohiTestModuleFetchFailed(response))
    }
  } catch (e) {
    yield put(ohiTestModuleFetchFailed(e.response))
  }
}

// UPDATE INFLUENCER
function* updateInfluencerFn({ payload }) {
  try {
    const response = yield call(updateInfluencer, payload)
    const data = { respData: response }
    if (response.status === 200) {
      yield put(updateInfluencerSuccess(data))
    } else {
      yield put(updateInfluencerFailed(response))
    }
  } catch (e) {
    yield put(updateInfluencerFailed(e.response))
  }
}

//UPDATE TEST MODULE DATA
function* updateTestModuleFn({ payload }) {
  try {
    const response = yield call(updateOhiTestModule, payload)
    const data = { respData: response }
    if (response.status === 200) {
      yield put(ohiTestModuleUpdateSuccess(data))
    } else {
      yield put(ohiTestModuleUpdateFailed(response))
    }
  } catch (e) {
    yield put(ohiTestModuleUpdateFailed(e.response))
  }
}

// FETCH VALUE
function* fetchValuesfn({ payload }) {
  try {
    const response = yield call(fetchInfluencer, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(valuesFetchSuccess(data))
    } else {
      yield put(valuesFetchFailed(response))
    }
  } catch (e) {
    yield put(valuesFetchFailed(e.response))
  }
}

// UPDATE values
function* updateValuesFn({ payload }) {
  try {
    const response = yield call(updateValues, payload)
    const data = { respData: response }
    if (response.status === 200) {
      yield put(updateValueSuccess(data))
    } else {
      yield put(updateValueFailed(response))
    }
  } catch (e) {
    yield put(updateValueFailed(e.response))
  }
}

// FETCH INCULSION
function* fetchInclusinfn({ payload }) {
  try {
    const response = yield call(fetchInfluencer, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(inclusionFetchSuccess(data))
    } else {
      yield put(inclusionFetchFailed(response))
    }
  } catch (e) {
    yield put(inclusionFetchFailed(e.response))
  }
}
// work
function* fetchworkexdata({ payload }) {
  try {
    const response = yield call(fetchInfluencer, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(workexFetchSuccess(data))
    } else {
      yield put(workexFetchFailed(response))
    }
  } catch (e) {
    yield put(workexFetchFailed(e.response))
  }
}

// UPDATE values
function* updateInclusionFn({ payload }) {
  try {
    const response = yield call(updateInclusion, payload)
    const data = { respData: response }
    if (response.status === 200) {
      yield put(updateInclusionSuccess(data))
    } else {
      yield put(updateInclusionFailed(response))
    }
  } catch (e) {
    yield put(updateInclusionFailed(e.response))
  }
}

// update work ex
function* updateworkexFn({ payload }) {
  try {
    const response = yield call(updateWordExapi, payload)
    const data = { respData: response }
    if (response.status === 200) {
      yield put(updateWorkExSuccess(data))
    } else {
      yield put(updateWorkExFailed(response))
    }
  } catch (e) {
    yield put(updateWorkExFailed(e.response))
  }
}

// OHI 4 Modules
function* fetchOhi4Modulefn({ payload }) {
  try {
    const response = yield call(fetchOhi4module, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(ohi4ModuleFetchSuccess(data))
      const {
        respData: { data: ohi4moduleData = [] },
      } = data
      yield updateOhi4ModuleFn({ payload: ohi4moduleData })
    } else {
      yield put(ohi4ModuleFetchFailed(response))
    }
  } catch (e) {
    yield put(ohi4ModuleFetchFailed(e.response))
  }
}

// UPDATE OHI 4 Modules values
function* updateOhi4ModuleFn({ payload }) {
  try {
    const response = yield call(updateOhi4module, payload)
    const data = { respData: response }
    if (response.status === 200) {
      yield put(updateohi4ModulenSuccess(data))
    } else {
      yield put(updateohi4ModuleFailed(response))
    }
  } catch (e) {
    yield put(updateohi4ModuleFailed(e.response))
  }
}

const sagas = [
  takeLatest(`${DESIGNSURVEY_FETCH}_PENDING`, fetchDesignSurveyfn),
  takeLatest(`${UPDATEDESIGNSURVEY_CREATE}_PENDING`, updateDesignSurveyFn),
  takeLatest(`${SAVEQUESTION_CREATE}_PENDING`, saveQuestionFn),
  takeLatest(`${OUTCOMESCALE_FETCH}_PENDING`, fetchOutcomeScalefn),
  takeLatest(`${PRACTICESCALE_FETCH}_PENDING`, fetchPracticeScalefn),
  takeLatest(`${GETQUESTIONLIST_FETCH}_PENDING`, fetchQuestionListfn),
  takeLatest(`${UPDATEQUESTIONLIST_CREATE}_PENDING`, updateQuestionListFn),
  takeLatest(`${UPDATEQUESTION_CREATE}_PENDING`, updateQuestionFn),
  takeLatest(`${INFLUENCERS_FETCH}_PENDING`, fetchInfluencerfn),
  takeLatest(`${UPDATEINFLUENCERS_CREATE}_PENDING`, updateInfluencerFn),
  takeLatest(`${VALUES_FETCH}_PENDING`, fetchValuesfn),
  takeLatest(`${UPDATEVALUES_CREATE}_PENDING`, updateValuesFn),
  takeLatest(`${INCLUSION_FETCH}_PENDING`, fetchInclusinfn),
  takeLatest(`${UPDATEINCLUSION_CREATE}_PENDING`, updateInclusionFn),
  takeLatest(`${UPDATE_WORK_EX}_PENDING`, updateworkexFn),
  takeLatest(`${GET_WORK_EX}_PENDING`, fetchworkexdata),
  takeLatest(`${TEST_MODULE_FETCH}_PENDING`, fetchOHItestModulefn),
  takeLatest(`${TEST_MODULE_UPDATE}_PENDING`, updateTestModuleFn),
  takeLatest(`${OHI_4_MODULE_FETCH}_PENDING`, fetchOhi4Modulefn),
  takeLatest(`${OHI_4_MODULE_UPDATE}_PENDING`, updateOhi4ModuleFn),
]
export default sagas
