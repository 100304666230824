import { makeStyles, fade } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      backgroundColor: '#021c4b',
      color: fade('#ffffff', 0.7),
      fontSize: '0.75rem',
    },
    listItem: {
      width: '100%',
      alignItems: 'center',
      padding: '2.8vh 1vw',
      cursor: 'pointer',
    },
    listItemActive: {
      backgroundColor: '#013362',
      borderTopLeftRadius: 100,
      borderBottomLeftRadius: 100,
      color: '#f28165',
      fontWeight: 'bold',
    },
    listItemText: {
      marginLeft: '1vw',
      fontSize: '1.9vh',
    },
    lockiconcss: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 15,
      margin: 'auto',
    },
    disablemenu: {
      cursor: 'not-allowed',
    },
    aCls: {
      color: 'var(--white)',
      '&:hover': {
        color: 'var(--white)',
        'text-decoration': 'unset',
      },
    },
    slectedLi: {
      color: 'var(--orange)',
    },
    ulCls: {
      textAlign: 'left',
      marginLeft: '1.4vw',
      paddingLeft: '3.2vw',
    },
    selectedLink: {
      color: 'var(--orange)',
      '&:hover': {
        color: 'var(--orange)',
        'text-decoration': 'unset',
      },
    },
    liCls: {
      margin: '0 0 2.5vh',
      fontSize: '1.7vh',
    },
    ulListContainer: {
      margin: '1.9vh 0 0 0',
      width: '100%',
    },
    tarnsformArrow: {
      transform: 'rotate(180deg)',
    },
    fullWidth: {
      width: '100%',
    },
    alignLeft: {
      'text-align': 'left',
    },
    childContainer: {
      overflow: 'overlay',
      overflowX: 'hidden',
      marginRight: '0.1vw',
      padding: '2vh 0 2vh 0.5vw',
      '&::-webkit-scrollbar': {
        width: '0.4vw',
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: 'rgba(255,255,255,0.35)',
      },
      '&::-webkit-scrollbar-button': {
        display: 'none',
      },
    },
  }
})

export default styles
