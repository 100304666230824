import {
  DEEP_DIVE_SUCCESS,
  DEEP_DIVE,
  DEEP_DIVE_LANG_SUCCESS,
  DEEP_DIVE_LANG_ERORR,
  SET_SELECTED_LANG,
} from './constants'
import { transformData } from '../SurveyAdditionalQuestions/utils'

const INITIAL_STATE = {
  additionalQuestionTexts: '',
  initialStateIntroTexts: '',
  originalApiData: null,
  multipleLanguageData: [],
  multipleLangDataError: false,
  languageId: -1,
  numberOfBlocksElement: 0,
}

const deepDiveReducer = (
  state = INITIAL_STATE,
  { type, data, error, languageId, outcomeId }
) => {
  switch (type) {
    case DEEP_DIVE_SUCCESS: {
      const updatedData = transformData(data)
      return {
        ...state,
        additionalQuestionTexts: updatedData.data,
        originalApiData: data,
        numberOfBlocksElement: updatedData.numberOfBlocksElement,
      }
    }
    case DEEP_DIVE_LANG_SUCCESS: {
      const multLangData = JSON.parse(
        JSON.stringify(state.multipleLanguageData)
      )

      const sameLanguageIdFound = multLangData.find(
        data => data.languageId === languageId && data.outcomeId === outcomeId
      )
      if (sameLanguageIdFound) {
        multLangData.forEach(element => {
          if (
            element.languageId === languageId &&
            element.outcomeId === outcomeId
          ) {
            element.value = data
          }
        })
      } else {
        multLangData.push({
          languageId: languageId,
          value: data,
          outcomeId: outcomeId,
        })
      }

      const newData = JSON.parse(JSON.stringify(multLangData))
      const newState = {
        ...state,
        multipleLanguageData: newData,
        originalApiData: data,
      }
      return Object.assign({}, newState)
    }

    case DEEP_DIVE_LANG_ERORR: {
      return { ...state, multipleLangDataError: true }
    }

    case DEEP_DIVE: {
      return { ...state, multipleLangDataError: false }
    }

    default:
      return state
  }
}
export default deepDiveReducer
