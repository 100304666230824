import React, { useEffect, useState, memo } from 'react'
import useStyles from './styles'
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  Button,
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  createMuiTheme,
  FormHelperText,
  createStyles,
} from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import { ThemeProvider } from '@material-ui/styles'
import additionalQuesions from '../../../../validations/additionalQuesions'

import tooltipblue from '../../assets/tooltip-icon_blue.png'
import matrisTooltip from './assets/matrixScale.png'
import openendTooltip from './assets/openQuestion.png'
import singleQuestionTooltip from './assets/Single.png'
import introductionTooltip from './assets/introduction.png'

import { makeStyles } from '@material-ui/core/styles'
//import { url } from 'inspector'

//component

function AddAdditionalQuestion({
  params,
  onPostQuestion,
  backtoquestionlist,
  outcomescales,
  practicescales,
  editQuestiondata,
}) {
  const classes = useStyles()

  const [isSubscribed, setSubscribe] = useState(false)
  const [listValues, setListValues] = useState([])
  const [initialScale, setInitialScale] = useState([])
  const [scaleValues, setScaleValues] = useState(['dsfd', 'sdfs'])
  const [errors, setErrors] = useState({})

  const [useFormad, setUseformad] = useState({
    surveyId: params,
    questionType: '4',
    instruction: '',
    questionText: [''],
    listType: '0',
    listValues: [],
    scaleType: '0',
    scaleValues: [''],
    answerValues: [''],
  })
  /* useEffect(() => {
    setUseformad({ ...useFormad, listValues: listValues })
  }, [listValues]) */
  //console.log(errors)

  useEffect(() => {
    if (useFormad.scaleType == 1) {
      const outcomeplane = outcomescales.get('items').toJS()
      if (outcomeplane !== '') {
        const outcomescl = outcomeplane.map(item => item.value)
        //console.log('ot', outcomescl)
        setScaleValues(outcomescl)
      }
    } else if (useFormad.scaleType == 2) {
      const practiceplane = practicescales.get('items').toJS()
      if (practiceplane !== '') {
        const practicescl = practiceplane.map(item => item.value)
        //console.log('ot', outcomescl)
        setScaleValues(practicescl)
      }
    } else {
      setScaleValues(initialScale)
    }
  }, [useFormad.scaleType])
  useEffect(() => {
    setUseformad({ ...useFormad, scaleValues: scaleValues })
    if (useFormad.scaleType == 3) {
      setInitialScale(scaleValues)
    }
  }, [scaleValues])

  useEffect(() => {
    if (editQuestiondata !== null) {
      editQuestiondata.surveyId = params
      setUseformad(editQuestiondata)
      setInitialScale(editQuestiondata.scaleValues)
      //console.log('editq', editQuestiondata)
    }
  }, [editQuestiondata])

  function handleInputChange(name, i) {
    return function(event) {
      if (name == 'questionText') {
        let toggledItem = { ...useFormad.questionText[i] }
        toggledItem = event.target.value
        setUseformad({
          ...useFormad,
          questionText: [
            ...useFormad.questionText.slice(0, i),
            toggledItem,
            ...useFormad.questionText.slice(i + 1),
          ],
        })
      } else if (name == 'answerValues') {
        let toggledItem = { ...useFormad.answerValues[i] }
        toggledItem = event.target.value
        setUseformad({
          ...useFormad,
          answerValues: [
            ...useFormad.answerValues.slice(0, i),
            toggledItem,
            ...useFormad.answerValues.slice(i + 1),
          ],
        })
      } else {
        setUseformad({ ...useFormad, [name]: event.target.value })
      }
    }
  }

  function saveAddForm() {
    setErrors(additionalQuesions(useFormad))
    //console.log(useFormad)
    if (Object.keys(additionalQuesions(useFormad)).length === 0) {
      onPostQuestion(useFormad)
      backtoquestionlist()
    }
  }
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      className={classes.root}
    >
      <Typography
        variant="h1"
        component="h2"
        className={classes.sectionHeading}
      >
        Add New Question
      </Typography>
      {renderOpenEnd()}
      {useFormad.questionType === '2' ? renderSingleSelect() : null}
      {useFormad.questionType === '3' ? renderMatrix() : null}

      <Box>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonRound}
          onClick={saveAddForm}
          style={{ marginLeft: 0, width: 120 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  )

  function renderOpenEnd() {
    function addquestionbtn() {
      setUseformad({
        ...useFormad,
        answerValues: [...useFormad.answerValues, ''],
      })
    }
    return (
      <Box display="flex" flexDirection="column">
        <FormLabel component="legend" className={classes.qtypelable}>
          Question Type
        </FormLabel>
        <RadioGroup
          aria-label="position"
          name="position"
          value={useFormad.questionType}
          style={{ marginBottom: 20 }}
          onChange={handleInputChange('questionType')}
          row
        >
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="4"
            disabled={editQuestiondata != null}
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label={
              <Box>
                Instructions
                <Tooltip
                  // className={classes.csttooltip}
                  classes={{ tooltip: classes.maxWidth }}
                  interactive={true}
                  placement="right"
                  title={
                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                      <div>
                        <img src={introductionTooltip} alt="" />
                      </div>
                    </p>
                  }
                >
                  <img
                    src={tooltipblue}
                    alt="tooltip"
                    className={classes.tooltipicon}
                  />
                </Tooltip>
              </Box>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="1"
            disabled={editQuestiondata != null}
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label={
              <Box>
                Open Ended
                <Tooltip
                  // classes={{ tooltip: classes.shamatooltip }}
                  classes={{ tooltip: classes.maxWidth }}
                  // noMaxWidth
                  interactive={true}
                  placement="right"
                  title={
                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                      {/* <Box
                        // component="div"
                        // classes={classes.shamatooltip}
                        style={{height:'250px',width:'100%',backgroundImage:`url(${openendTooltip})`}}
                        classname='img-responsive'
                        // classname={{ tooltip: classes.shamatooltip }}
                      ></Box> */}
                      <div>
                        <img src={openendTooltip} />
                      </div>
                    </p>
                  }
                >
                  <img
                    src={tooltipblue}
                    alt="tooltip"
                    className={classes.tooltipicon}
                  />
                  {/* <img src={openendTooltip } /> */}
                </Tooltip>
              </Box>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="2"
            disabled={editQuestiondata != null}
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label={
              <Box>
                Single Select
                <Tooltip
                  // className={classes.csttooltip}
                  classes={{ tooltip: classes.maxWidth }}
                  interactive={true}
                  placement="right"
                  title={
                    <p style={{ fontSize: 12, margin: -20 }}>
                      {/* <Box
                        component="img"
                        src={singleQuestionTooltip}
                        style={{ width: 500 }}
                      ></Box> */}
                      <div>
                        <img src={singleQuestionTooltip} />
                      </div>
                    </p>
                  }
                >
                  <img
                    src={tooltipblue}
                    alt="tooltip"
                    className={classes.tooltipicon}
                  />
                </Tooltip>
              </Box>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="3"
            disabled={editQuestiondata != null}
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label={
              <Box>
                Matrix/Likert Scale
                <Tooltip
                  // className={classes.csttooltip}
                  classes={{ tooltip: classes.maxWidth }}
                  interactive={true}
                  placement="right"
                  title={
                    <p style={{ fontSize: 12, marginBottom: 0 }}>
                      {/* <Box
                        component="img"
                        src={matrisTooltip}
                        style={{ width: 300 }}
                      ></Box> */}
                      <div>
                        <img src={matrisTooltip} />
                      </div>
                    </p>
                  }
                >
                  <img
                    src={tooltipblue}
                    alt="tooltip"
                    className={classes.tooltipicon}
                  />
                </Tooltip>
              </Box>
            }
            labelPlacement="end"
          />
        </RadioGroup>
        <Box style={{ position: 'relative' }}>
          {useFormad.questionText.map((item, index) => {
            if (index === 0) {
              return (
                <FormControl fullWidth className={classes.mb40} key={index}>
                  <InputLabel
                    htmlFor={`questionText${index}`}
                    className={classes.inputLabel}
                  >
                    {useFormad.questionType == 4
                      ? 'Enter Instruction Text'
                      : useFormad.questionType == 3
                      ? 'Enter Instructions'
                      : 'Enter Question Text'}
                  </InputLabel>
                  <Input
                    id={`questionText${index}`}
                    key={index.toString()}
                    className={classes.input}
                    value={item}
                    placeholder={
                      useFormad.questionType == 4
                        ? 'Enter Instruction Text'
                        : useFormad.questionType == 3
                        ? 'Enter Instructions'
                        : 'Enter Question Text'
                    }
                    onChange={handleInputChange('questionText', index)}
                    fullWidth
                  />
                  {errors.questionText && (
                    <FormHelperText
                      id="my-helper-text-questionText"
                      className="myhelpText"
                    >
                      {errors.questionText}
                    </FormHelperText>
                  )}
                </FormControl>
              )
            }
          })}
        </Box>
        {useFormad.questionType != 3 ? null : (
          <Box style={{ position: 'relative', marginBottom: 30 }}>
            {useFormad.answerValues.map((item, index) => {
              return (
                <FormControl
                  fullWidth
                  className={classes.questiontxtmatrix}
                  key={index}
                >
                  <Box className={classes.matrixquestNumber}>{index + 1}</Box>
                  <InputLabel
                    htmlFor={`answerValues${index}`}
                    className={classes.inputLabel}
                  >
                    Enter Question Text
                  </InputLabel>
                  <Input
                    id={`answerValues${index}`}
                    className={classes.input}
                    value={item}
                    placeholder={`Enter Question text line ${index + 1}`}
                    onChange={handleInputChange('answerValues', index)}
                    fullWidth
                  />
                  {index === 0 && errors.answerValues ? (
                    <FormHelperText
                      id="my-helper-text-answerValues"
                      className="myhelpText"
                    >
                      {errors.answerValues}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              )
            })}
            <Box
              component="button"
              className={classes.addquestionbtn}
              onClick={addquestionbtn}
            >
              Add New Question
            </Box>
          </Box>
        )}
      </Box>
    )
  }

  function renderSingleSelect() {
    function handleDeleteChip(chip, index) {
      setUseformad({
        ...useFormad,
        listValues: useFormad.listValues
          .slice(0, index)
          .concat(useFormad.listValues.slice(index + 1)),
      })
    }
    return (
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        className={classes.leftSection}
      >
        <FormLabel component="legend" className={classes.qtypelable}>
          Choose Type
        </FormLabel>
        <RadioGroup
          aria-label="position"
          name="position"
          value={useFormad.listType}
          style={{ marginBottom: 20 }}
          onChange={handleInputChange('listType')}
          row
        >
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="1"
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label="Radio Button"
            labelPlacement="end"
          />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="2"
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label="Dropdown"
            labelPlacement="end"
          />
          {errors.listType && (
            <FormHelperText id="my-helper-text-listType" className="myhelpText">
              {errors.listType}
            </FormHelperText>
          )}
        </RadioGroup>
        <FormControl fullWidth className={classes.mb40}>
          <ChipInput
            label={
              useFormad.listType == 1
                ? 'Radio Button Options'
                : 'Dropdown Options'
            }
            value={useFormad.listValues}
            /* onAdd={chip => setListValues([...listValues, chip])} */
            onAdd={chip =>
              setUseformad({
                ...useFormad,
                listValues: [...useFormad.listValues, chip],
              })
            }
            onDelete={(chip, index) => handleDeleteChip(chip, index)}
          />
          {errors.listValues && (
            <FormHelperText
              id="my-helper-text-listValues"
              className="myhelpText"
            >
              {errors.listValues}
            </FormHelperText>
          )}
          <FormLabel component="legend" className={classes.marginTop}>
            <em>Note: Hit enter to type the next drop-down option</em>
          </FormLabel>
        </FormControl>
      </Box>
    )
  }

  function renderMatrix() {
    function handleDeleteChipMatrix(chip, index) {
      setScaleValues(
        scaleValues.slice(0, index).concat(scaleValues.slice(index + 1))
      )
    }
    const scalevalueLable = () => {
      if (useFormad.scaleType == 1) {
        return 'Outcome Scale'
      }
      if (useFormad.scaleType == 2) {
        return 'Practice Scale'
      }
      return 'Add New Scale'
    }
    return (
      <Box
        display="flex"
        flex="1"
        flexDirection="column"
        className={classes.leftSection}
      >
        <FormLabel component="legend" className={classes.qtypelable}>
          Scale Type
        </FormLabel>
        <RadioGroup
          aria-label="position"
          name="position"
          value={useFormad.scaleType}
          style={{ marginBottom: 20 }}
          onChange={handleInputChange('scaleType')}
          row
        >
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="1"
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label="Outcome Scale"
            labelPlacement="end"
          />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="2"
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label="Practice Scale"
            labelPlacement="end"
          />
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            value="3"
            control={
              <Radio
                color="primary"
                classes={{
                  checked: classes.checked,
                }}
              />
            }
            label="Add New Scale"
            labelPlacement="end"
          />
          {errors.scaleType && (
            <FormHelperText
              id="my-helper-text-scaleType"
              className="myhelpText"
            >
              {errors.scaleType}
            </FormHelperText>
          )}
        </RadioGroup>
        <FormControl fullWidth className={classes.mb40}>
          <ChipInput
            label={scalevalueLable()}
            disabled={useFormad.scaleType != 3 ? true : false}
            value={useFormad.scaleValues}
            onAdd={chip => setScaleValues([...scaleValues, chip])}
            onDelete={(chip, index) => handleDeleteChipMatrix(chip, index)}
          />
          {errors.scaleValues && (
            <FormHelperText
              id="my-helper-text-scaleValues"
              className="myhelpText"
            >
              {errors.scaleValues}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    )
  }
}

export default memo(AddAdditionalQuestion)
