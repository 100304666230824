import React, { Fragment, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Box,
  Input,
  InputAdornment,
  FormControl,
  IconButton,
  Button,
  ListItem,
  List,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import useStyles from './styles'
import editIcon from './assets/editIcon.svg'
import { useToasts } from 'react-toast-notifications'
import clsx from 'clsx'
import axios from 'axios'
import PopupModal from '../PopupModal'
import downloadGroupIcon from '../../pages/Demographics/assets/downloadGroupIcon.svg'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}

const api = new APIs('/api')

function SurveyProgress(props) {
  const {
    onSurveynameFetch,
    onSurveynameUpdate,
    surveyname,
    params,
    surveynameupdateStatus,
    surveysStatusFetch,
    statusList,
    ongetpidfn,
    history,
    hasEditorStateChanged,
    confirmationPopupToggle,
    isButtonToBeDisabled,
    isDemographicStateChanged,
    textUpdate,
  } = props
  const progressNames = [
    {
      step: '01',
      name: 'Define',
      route: '/define/client-info',
      isSelected: history.location.pathname.includes('define'),
    },
    {
      step: '02',
      name: 'Text Update',
      route: '/update-survey-text/introduction',
      isSelected: history.location.pathname.includes('update-survey-text'),
    },
    {
      step: '03',
      name: 'Demographics',
      route: '/survey/demographics',
      isSelected: history.location.pathname.includes('demographics'),
    },
    {
      step: '04',
      name: 'Test Emails',
      route: '/test-emails',
      isSelected:
        history.location.pathname.includes('sendpreview') ||
        history.location.pathname.includes('previewemail') ||
        history.location.pathname.includes('test-emails') ||
        history.location.pathname.includes('emailedit'),
    },
    {
      step: '05',
      name: 'Launch Survey',
      route: '',
      isSelected: false,
    },
    {
      step: '06',
      name: 'Closing Protocol',
      route: '/survey/GSDB',
      isSelected: history.location.pathname.includes('survey/GSDB'),
    },
  ]

  const classes = useStyles()

  const [useSurveyname, setSurveyname] = useState('')

  const [isEditable, setEditable] = useState(false)
  const { addToast } = useToasts()
  const [tostopen, setToastopne] = useState(false)
  const [status, setStatus] = useState('')
  const [progressBarItem, setProgressBarItem] = useState(progressNames)

  const user = JSON.parse(localStorage.getItem('userInfo'))

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [openSoftWarningPopup, setOpenSoftWarningPopup] = useState(false)
  const [statusToUpdate, setStatusToUpdate] = useState({})

  useEffect(() => {
    if (surveynameupdateStatus.get('isFetched') && tostopen) {
      addToast(surveynameupdateStatus.get('items'), {
        appearance: 'success',
        autoDismiss: true,
      })
      setToastopne(false)
    }
  }, [surveynameupdateStatus])
  //console.log()
  useEffect(() => {
    onSurveynameFetch(params)
    !statusList.get('isFetched') && surveysStatusFetch()
    /* const fetchData = async () => {
      const result = await axios({
        method: 'get',
        url: api.getURL(`surveys-status`),
      })
      setSurveystatusList(result.data.data)
    }
    fetchData() */
    //updatestatus()
  }, [])

  const updatestatus = async id => {
    try {
      const result = await axios({
        method: 'put',
        url: api.getURL(`surveys/updateSurveyStatus/${params}`),
        data: { id: params, status: id },
      })
      const sucsMsg = result.data.data
      addToast(sucsMsg, {
        appearance: 'success',
        autoDismiss: true,
      })
      onSurveynameFetch(params)
    } catch (error) {
      // console.log(error.response.data.data.message)
      //console.log(error)
      const errMsg = error.response.data.data.message
      addToast(errMsg, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  /* useEffect(()=>{
    ongetpidfn(pid)
  },[pid]) */
  useEffect(() => {
    if (
      surveyname &&
      surveyname.get('items') &&
      surveyname.get('items').get('name') !== ''
    ) {
      setSurveyname(surveyname.get('items').get('name'))
      setStatus(surveyname.get('items').get('status'))
      //setPid(surveyname.get('items').get('pid'))
      ongetpidfn(surveyname.get('items').get('pid'))
    } else {
      setSurveyname('New Survey')
      ongetpidfn('')
    }
  }, [surveyname])
  function handleChange() {
    return function(event) {
      setSurveyname(event.target.value)
    }
  }
  //console.log('surveryn', surveyname.toJS())
  function handleEditable(action) {
    return function(event) {
      if (action === 'edit') {
        setEditable(true)
      } else {
        setEditable(false)
        onSurveynameUpdate({ name: useSurveyname, id: params })
        setToastopne(true)
      }
    }
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
  function handleClosen() {
    setStatus(statusToUpdate.name)
    updatestatus(statusToUpdate.id)
    setAnchorEl(null)
    setOpenSoftWarningPopup(false)
  }

  const handleSetupSoftWarningPopupOpen = (name, id) => {
    setStatusToUpdate({ name, id })
    setOpenSoftWarningPopup(true)
    setAnchorEl(null)
  }

  const handleSoftWarningPopupClose = () => {
    setOpenSoftWarningPopup(false)
    setStatusToUpdate({})
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      {openSoftWarningPopup && (
        <PopupModal>
          <div className={classes.statusWarningContainer}>
            <img src={downloadGroupIcon} alt="" />
            <div>
              <span>Are you sure, you want to change </span>
              <span>
                the status of this survey to{' '}
                <span>"{statusToUpdate.name}"</span> ?
              </span>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  className="round_button height"
                  onClick={handleSoftWarningPopupClose}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="round_button round_button_container height"
                  onClick={handleClosen}
                >
                  YES, UPDATE
                </Button>
              </div>
            </div>
          </div>
        </PopupModal>
      )}
      <Box component="div" className={classes.logo} flex={1}>
        {renderSurveynameForm()}
        <Box
          style={{
            fontSize: '1.6vh',
            fontWeight: 400,
            display: 'flex',
            position: 'relative',
          }}
        >
          <Box style={{ paddingTop: '0.6vh', whiteSpace: 'nowrap' }}>
            {surveyname &&
            surveyname.get('items') &&
            surveyname.get('items').get('pid')
              ? surveyname.get('items').get('pid') + ' - '
              : null}
            {surveyname &&
            surveyname.get('items') &&
            surveyname.get('items').get('ohiId')
              ? surveyname.get('items').get('ohiId')
              : null}
          </Box>
          {user && user.roleName && user.roleName.includes('Admin') ? (
            <Box className={classes.changestatusctrl}>
              Survey Status:
              <Box component="strong">
                {' '}
                {statusToUpdate.name ? statusToUpdate.name : status}
              </Box>
              {status ? (
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  size="small"
                  className={classes.changebtn}
                >
                  (Choose)
                </Button>
              ) : null}
              {status ? (
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  style={{ zIndex: 99999 }}
                >
                  {statusList &&
                    statusList.get('items') &&
                    statusList.get('items').map(function(item) {
                      return (
                        <MenuItem
                          key={item.get('key')}
                          onClick={() => {
                            handleSetupSoftWarningPopupOpen(
                              item.get('value'),
                              item.get('key')
                            )
                          }}
                        >
                          {item.get('value')}
                        </MenuItem>
                      )
                    })}
                </Menu>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        component="div"
        display="flex"
        flex={3}
        className={classes.stepsWrapper}
      >
        {progressBarItem.map((item, index) => (
          <SurveyProgressLinks
            key={index}
            classes={classes}
            handleOnClick={onProgressBarItemClick}
            paramID={params}
            progressItem={item}
            index={index}
            surveyname={surveyname}
            hasEditorStateChanged={hasEditorStateChanged}
            confirmationPopupToggle={confirmationPopupToggle}
            isDemographicStateChanged={isDemographicStateChanged}
            textUpdate={textUpdate}
          />
        ))}
      </Box>
    </Box>
  )

  function onProgressBarItemClick({ route, id }) {
    const newArr = progressBarItem.map(item => {
      if (item.step === id) {
        item.isSelected = true
      } else item.isSelected = false
      return item
    })
    setProgressBarItem(newArr)
    history.push(route)
  }

  function renderSurveynameForm() {
    return (
      <Box>
        <FormControl
          className={clsx({
            [classes.surveynamefield]: true,
            [classes.disabledinput]: !isEditable,
          })}
          style={{ width: '100%' }}
        >
          <Input
            id="surveyname"
            disabled={!isEditable}
            type="text"
            style={{ fontSize: '1.9vh' }}
            value={useSurveyname || ''}
            onChange={handleChange()}
            endAdornment={
              useSurveyname !== 'New Survey' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Update Survey Name"
                    className={
                      isButtonToBeDisabled
                        ? classes.surveynameEditbtnDisabled
                        : classes.surveynameEditbtn
                    }
                    onClick={handleEditable(!isEditable ? 'edit' : 'save')}
                  >
                    {!isEditable ? (
                      <Box component="img" src={editIcon} />
                    ) : (
                      <Icon>save</Icon>
                    )}
                  </IconButton>
                </InputAdornment>
              ) : null
            }
          />
        </FormControl>
      </Box>
    )
  }
}

function SurveyProgressLinks({
  classes,
  handleOnClick,
  paramID,
  progressItem,
  index,
  surveyname,
  hasEditorStateChanged,
  confirmationPopupToggle,
  isDemographicStateChanged,
  textUpdate,
}) {
  let indexUpperLimit = 6
  if (surveyname.get('items').get('status') === 'Closed') {
    indexUpperLimit = 5
  }
  return (
    <Box
      componen="div"
      display="flex"
      className={clsx({
        [classes.steps]: true,
        [classes.stepsItemActive]: progressItem.isSelected,
        [classes.pointerEventsNone]:
          (index > 3 && index < indexUpperLimit) ||
          !surveyname.get('items').get('pid'),
      })}
      key={progressItem.step}
      flex={1}
      onClick={handleClick(
        `${progressItem.route}/${paramID}`,
        progressItem.step
      )}
    >
      <Box componen="div" display="flex" fontSize="3vh" marginRight="0.6vw">
        {progressItem.step}
      </Box>
      <Box componen="div" display="flex" style={{ position: 'relative' }}>
        {progressItem.name}
        {index > 3 && index < 5 ? (
          <div className={classes.comingtxt}>Coming Soon</div>
        ) : null}
      </Box>
    </Box>
  )

  function handleClick(route, id) {
    // return console.log('route', route, ':: id=', id)
    if (!textUpdate() && id === '02') {
      return false
    }
    return function() {
      if (hasEditorStateChanged || isDemographicStateChanged) {
        confirmationPopupToggle(handleOnClick, { route, id })
      } else handleOnClick({ route, id })
    }
  }
}

export default withRouter(SurveyProgress)
