import { call, put, takeLatest } from 'redux-saga/effects'

import {
  languageFetchSuccess,
  languageFetchFailed,
  analystFetchSuccess,
  analystFetchFailed,
  countriesFetchSuccess,
  countriesFetchFailed,
  populationsFetchSuccess,
  populationsFetchFailed,
  regionsFetchSuccess,
  regionsFetchFailed,
  surveyTypesSuccess,
  surveyTypesFailed,
  getResurveySuccess,
  getResurveyFailed,
  getSurveyinfoSuccess,
  getSurveyinfoFailed,
  surveyInfoSaveSuccess,
  surveyInfoSaveFailed,
  fetchCohesionDemoFailAction,
  fetchCohesionDemoSuccessAction,
  getOhiVersionSuccess,
  getOhiVersionFailed,
  getAllLanguagesSuccess,
  getAllLanguagesFailed,
} from './actions'
import {
  LANGUAGES_FETCH,
  ANALYSTS_FETCH,
  COUNTRIES_FETCH,
  POPULATIONS_FETCH,
  REGIONS_FETCH,
  SURVEYSTYPES_POPULATIONS_FETCH,
  GETRESURVEY_FETCH,
  SURVEYINFO_FETCH,
  SURVEYINFO_CREATE,
  COHESION_DEMO,
  OHI_VERSION_FETCH,
} from './constants'
import {
  // fetchLanguages,
  fetchAnalysts,
  fetchCountries,
  fetchPopulations,
  fetchRegions,
  fetchSurveyTypes,
  fetchResurvey,
  fetchSurveyinfo,
  saveSurveyInfo,
  // fetchCohesionDemoUrl,
  fetchOhiVersionUrl,
  fetchOhiVersionLanguages,
  fetchAllLanguagesUrl,
} from './apis'
import request from '../../utils/request'
import Endpoints from '../../Endpoints'

// SAVE SURVEYINFO
function* saveSurveyInfoFn({ payload }) {
  try {
    const response = yield call(saveSurveyInfo, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(surveyInfoSaveSuccess(data))
    } else {
      yield put(surveyInfoSaveFailed(response))
    }
  } catch (e) {
    yield put(surveyInfoSaveFailed(e.response))
  }
}

// Resurvey
function* fetchResurveyfn({ payload }) {
  try {
    const response = yield call(fetchResurvey, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(getResurveySuccess(data))
    } else {
      yield put(getResurveyFailed(response))
    }
  } catch (e) {
    yield put(getResurveyFailed(e.response))
  }
}

// SURVEYINFO
function* fetchSurveyinffn({ payload }) {
  try {
    const response = yield call(fetchSurveyinfo, payload)
    const data = { respData: response.data }
    if (response.status === 200) {
      yield put(getSurveyinfoSuccess(data))
    } else {
      yield put(getSurveyinfoFailed(response))
    }
  } catch (e) {
    yield put(getSurveyinfoFailed(e.response))
  }
}

// SURVEY TYPE POPULATIONS
function* fetchSurveyTypesfn({ payload }) {
  try {
    const response = yield call(fetchSurveyTypes, payload)
    //console.log(response.status )
    if (response.status === 200) {
      yield put(surveyTypesSuccess(response.data))
    } else {
      yield put(surveyTypesFailed(response))
    }
  } catch (e) {
    yield put(surveyTypesFailed(e.response))
    //console.error(e)
  }
}
// REGIONS
function* fetchRegionsfn() {
  try {
    const response = yield call(fetchRegions)
    if (response.status === 200) {
      yield put(regionsFetchSuccess(response.data))
    } else {
      yield put(regionsFetchFailed(response))
    }
  } catch (e) {
    yield put(regionsFetchFailed(e.response))
  }
}
// POPULATIONS
function* fetchPopulationsfn() {
  try {
    const response = yield call(fetchPopulations)
    if (response.status === 200) {
      yield put(populationsFetchSuccess(response.data))
    } else {
      yield put(populationsFetchFailed(response))
    }
  } catch (e) {
    yield put(populationsFetchFailed(e.response))
  }
}
// COUNTRIES
function* fetchCountriesfn() {
  try {
    const response = yield call(fetchCountries)
    if (response.status === 200) {
      yield put(countriesFetchSuccess(response.data))
    } else {
      yield put(countriesFetchFailed(response))
    }
  } catch (e) {
    yield put(countriesFetchFailed(e.response))
  }
}
// LANGUAGES
function* fetchLanguagesfn({ payload }) {
  try {
    const response = yield call(fetchOhiVersionLanguages, payload)
    if (response.status === 200) {
      yield put(languageFetchSuccess(response.data))
    } else {
      yield put(languageFetchFailed(response))
    }
  } catch (e) {
    yield put(languageFetchFailed(e.response))
  }
}
// ANALYSTS
function* fetchAnalystsfn() {
  try {
    const response = yield call(fetchAnalysts)
    if (response.status === 200) {
      yield put(analystFetchSuccess(response.data))
    } else {
      yield put(analystFetchFailed(response))
    }
  } catch (e) {
    yield put(analystFetchFailed(e.response))
  }
}

function* fetchCohesionDemo({ payload }) {
  try {
    const { surveyId } = payload
    const response = yield call(request, {
      method: 'get',
      url: `/api/surveyquestions/demo-list/${surveyId}`,
    })
    const {
      response: { status },
      body: { data = [] },
    } = response
    if (status === 200) {
      const {
        body: { data },
      } = response
      yield put(fetchCohesionDemoSuccessAction(data))
    } else {
      yield put(fetchCohesionDemoSuccessAction([]))
    }
  } catch (e) {
    yield put(fetchCohesionDemoFailAction([]))
  }
}

// OHI VERSION LIST
function* fetchOhiVersionfn() {
  try {
    const response = yield call(fetchOhiVersionUrl)
    if (response.status === 200) {
      yield put(getOhiVersionSuccess(response.data))
    } else {
      yield put(getOhiVersionFailed(response))
    }
  } catch (e) {
    yield put(getOhiVersionFailed(e.response))
  }
}

function* fetchAllLanguages({ payload }) {
  try {
    const { id } = payload
    const { response } = yield call(request, {
      url: Endpoints.getClosingProtocolLang.replace(':surveyid', id),
      method: 'get',
    })
    if (response.status === 200) {
      yield put(getAllLanguagesSuccess(response.data))
    } else {
      yield put(getAllLanguagesFailed(response))
    }
  } catch (e) {
    yield put(getAllLanguagesFailed(e.response))
  }
}

const sagas = [
  takeLatest(`${LANGUAGES_FETCH}_PENDING`, fetchLanguagesfn),
  takeLatest(`${ANALYSTS_FETCH}_PENDING`, fetchAnalystsfn),
  takeLatest(`${COUNTRIES_FETCH}_PENDING`, fetchCountriesfn),
  takeLatest(`${POPULATIONS_FETCH}_PENDING`, fetchPopulationsfn),
  takeLatest(`${REGIONS_FETCH}_PENDING`, fetchRegionsfn),
  takeLatest(`${SURVEYSTYPES_POPULATIONS_FETCH}_PENDING`, fetchSurveyTypesfn),
  takeLatest(`${GETRESURVEY_FETCH}_PENDING`, fetchResurveyfn),
  takeLatest(`${SURVEYINFO_FETCH}_PENDING`, fetchSurveyinffn),
  takeLatest(`${SURVEYINFO_CREATE}_PENDING`, saveSurveyInfoFn),
  takeLatest(`${COHESION_DEMO}_PENDING`, fetchCohesionDemo),
  takeLatest(`${OHI_VERSION_FETCH}_PENDING`, fetchOhiVersionfn),
  takeLatest(`FETCH_ALL_LANG_PENDING`, fetchAllLanguages),
]
export default sagas
