import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      padding: '48px 48px 110px 69px',
      fontSize: 12,
      color: '#333333',
      paddingBottom: '110px',
      position: 'relative',
      textAlign: 'left',
      width: '100%',
    },
    questionListupdate: {
      display: 'flex',
      marginBottom: 18,
      '& img': {
        marginRight: 14,
        marginTop: 14,
      },
    },
    sectionHeading: {
      fontSize: 20,
      fontWeight: 900,
      marginBottom: '38px',
    },
    subSectionHeading: {
      fontSize: 18,
      fontWeight: 900,

      '& img': {
        marginLeft: '7px',
      },
    },
    reorderIcon: {
      width: 50,
    },
    qEdit: {
      width: 37,
      padding: 0,
      backgroundColor: 'transparent',
      border: 0,
      position: 'absolute',
      right: 3,
      top: 5,
      '& img': {
        margin: 0,
        maxWidth: '100%',
      },
      '&:focus': {
        outline: 'none',
      },
    },

    orderingTextbox: {
      width: 28,
      height: 28,
      border: 'solid 1px #eaeaea',
      borderRadius: '4px',
      backgroundColor: '#eaeaea',
      fontSize: 14,
      textDecoration: 'underline',
      textAlign: 'center',
      marginRight: 5,
    },
    questionText: {
      flex: '10 1 0%',
      position: 'relative',
    },
    questionTitle: {
      fontSize: 16,
      color: '#333333',
      borderBottom: '1px solid rgba(151, 151, 151, 0.37)',
      padding: '5px 0',
      fontWeight: 700,
      paddingRight: '65px',
    },
    questionListType: {
      fontSize: 11,
      color: '#333333',
    },
    pageno: {
      color: '#999999',
      fontSize: 9,
      width: '100%',
      textAlign: 'right',
      paddingBottom: 5,
    },
    hideReorder: {
      pointerEvents: 'none',
      opacity: 0.2,
    },
  }
})

export default styles
