import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Mid } from '@mid/sdk'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'

import './index.scss'
import { App } from './components'
import store from './store'
import theme from './theme'
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications'

import * as serviceWorker from './serviceWorker'

import AUTH0 from './auth_config'

const mid = new Mid({
  redirectUrl: `${AUTH0.redirectBaseUrl}/implicit/callback`,
  logoutRedirectUrl: `${AUTH0.redirectBaseUrl}/`,
  loginInfo: {
    appId: AUTH0.clientId,
    oidcConfigUrl: AUTH0.oidcConfigUrl,
    authDriver: 'mid',
  },
  async landingFn(): Promise<void> {
    window.location.hash = ''
  },
})

const ToastContainer = props => (
  <DefaultToastContainer
    className="toast-container"
    style={{ zIndex: 999999 }}
    {...props}
  />
)
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <ToastProvider
          components={{ ToastContainer }}
          autoDismissTimeout={10000}
        >
          <App mid={mid} />
        </ToastProvider>
      </CssBaseline>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
