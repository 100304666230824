import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from '@material-ui/core'
import profileImg from '../../assets/Profile-icon.png'
import logo from '../../assets/logo.png'
import homeIcon from '../../assets/HomeIcon.png'
import axios from 'axios'
import useStyles from './styles'
import { useToasts } from 'react-toast-notifications'
import { SwitchToggleSet } from '../index'
import {
  adminLockUnLockSurvey,
  unlockUserLockedSurvey,
  resetErrorState,
  getUserDetails,
} from '../../common/actions'
import Avatar from 'react-avatar'
import StorageInstance from '../../common/Storage'

function APIs(endpoint) {
  this.endpoint = endpoint
  this.getURL = getURL

  function getURL(url) {
    return `${this.endpoint}/${url}`
  }
}

const api = new APIs('/api')
const errorMsg =
  'It is taking more time than expected. Request you to please check if your changes have been made else please refresh your page and try again.'

function SurveyHeader({
  history,
  statuscode,
  surveyId,
  isSurveyAdminLocked,
  lockMessage,
  unlockUserLockedSurvey,
  adminLockUnLockSurvey,
  resetErrorState,
  userDetails,
  getUserDetails,
}) {
  const user = JSON.parse(localStorage.getItem('userInfo'))
  const hideSurveyLockToggle_Msg =
    window.location.pathname.includes('manage-surveys') ||
    window.location.pathname.includes('user-management') ||
    window.location.pathname.includes('dashboard')
  const showManageSurvey =
    window.location.pathname.includes('manage-survey') ||
    window.location.pathname.includes('dashboard') ||
    window.location.pathname.includes('backcoding')
  const [open, setOpen] = useState(false)
  const [toggleState, setToggleState] = useState(false)
  const [errormsg, setErrormsg] = useState()
  const { addToast } = useToasts()
  const accessToken = localStorage.getItem('accessToken')

  useEffect(() => {
    if (accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
      // getUserDetails()
    } else {
      axios.defaults.headers.common['Authorization'] = `Bearer`
    }
  }, [])

  useEffect(() => {
    setToggleState(isSurveyAdminLocked)
  }, [isSurveyAdminLocked])
  const handleClose = () => {
    setOpen(false)
  }
  const classes = useStyles()
  function gotoFirstpage() {
    return function() {
      StorageInstance.delete()
      //console.log('dd')
      history.push('/home')
    }
  }
  function gotoManageSurveyPage() {
    return function() {
      StorageInstance.delete()
      history.push('/manage-surveys')
    }
  }

  const handleToggleChange = () => {
    return function() {
      adminLockUnLockSurvey(surveyId, !toggleState)
      setToggleState(!toggleState)
    }
  }
  const logoutbackend = async () => {
    const result = await axios({
      method: 'get',
      url: api.getURL(`account/logout`),
    })
    return result
  }

  const logoutUser = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }

  useEffect(() => {
    if (
      statuscode &&
      statuscode.get('errorstatus') &&
      statuscode.get('errorstatus') !== ''
    ) {
      if (
        statuscode.toJS()?.errorstatus &&
        statuscode.toJS()?.errorstatus?.payload &&
        statuscode.toJS()?.errorstatus?.payload?.status === 401
      ) {
        unlockUserLockedSurvey()
        resetErrorState()
        logoutbackend()
          .then(() => {
            logoutUser()
          })
          .catch(() => {
            logoutUser()
          })
      } else if (
        statuscode.toJS()?.errorstatus &&
        statuscode.toJS()?.errorstatus?.payload?.status !== 403 &&
        statuscode.toJS()?.errorstatus?.payload?.status !== 409
      ) {
        if (statuscode.toJS()?.errorstatus?.payload?.status === 504) {
          addToast(errorMsg, {
            appearance: 'error',
            autoDismiss: true,
          })
          resetErrorState()
        } else {
          if (statuscode.toJS()?.errorstatus?.payload?.data?.data) {
            addToast(
              statuscode.toJS()?.errorstatus?.payload?.data?.data?.message ||
                errorMsg,
              {
                appearance: 'error',
                autoDismiss: true,
              }
            )
            resetErrorState()
          } else {
            addToast(
              "We're unable to process your request. Please contact your administration for the solution.",
              {
                appearance: 'error',
                autoDismiss: true,
              }
            )
            resetErrorState()
          }
        }
      } else if (
        statuscode.toJS()?.errorstatus &&
        statuscode.toJS()?.errorstatus?.payload?.uploadedCode === 200
      ) {
        addToast(statuscode.toJS().errorstatus.payload.data, {
          appearance: 'success',
          autoDismiss: true,
        })
        resetErrorState()
      }
    }
  }, [statuscode])

  function logoutfn() {
    resetErrorState()
    unlockUserLockedSurvey()
    logoutbackend()
      .then(() => {
        logoutUser()
      })
      .catch(() => {
        logoutUser()
      })
  }

  function errorAlert() {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errormsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleClickOnManageUsers = () => {
    history.push('/user-management')
  }

  const handleClickOnDashboard = () => {
    history.push('/dashboard')
  }

  const handleClickOnBackcoding = () => {
    history.push('/backcoding')
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
    >
      <Box
        display="flex"
        flex-direction="row"
        component="div"
        className={classes.logo}
      >
        <div className="icon-container ohi-mckinsey-icon">
          <img
            className="ohi-mckinsey-icon"
            src={logo}
            onClick={gotoFirstpage()}
            alt="logo"
          />
        </div>
        <div className="icon-container home-icon">
          <img
            className="home-icon"
            src={homeIcon}
            onClick={gotoManageSurveyPage()}
            alt="home-icon"
          />
        </div>
      </Box>

      <Box
        display="flex"
        flex-direction="row"
        alignItems="center"
        className={classes.leftContentContainer}
      >
        {!hideSurveyLockToggle_Msg && (
          <Box className={classes.lockText}>{lockMessage}</Box>
        )}
        {user &&
          user.roleName &&
          showManageSurvey &&
          user.dashboardAccess === true && (
            <Box
              className={classes.manageUserText}
              onClick={handleClickOnDashboard}
            >
              DASHBOARD
            </Box>
          )}
        {user &&
          user.roleName &&
          user.roleName.includes('Admin') &&
          showManageSurvey && (
            <Box
              className={classes.manageUserText}
              onClick={handleClickOnBackcoding}
            >
              BACKCODING TOOL
            </Box>
          )}
        {user &&
          user.roleName &&
          user.roleName.includes('Admin') &&
          showManageSurvey && (
            <Box
              className={classes.manageUserText}
              onClick={handleClickOnManageUsers}
            >
              MANAGE USERS
            </Box>
          )}
        {user &&
          user.roleName &&
          user.roleName.includes('Admin') &&
          !hideSurveyLockToggle_Msg && (
            <div className={classes.toggleButtonContainer}>
              <Box
                display="flex"
                flex="1"
                flexDirection="column"
                alignItems="flex-start"
                className={classes.inputSet}
              >
                <Box className={classes.toggleText}>
                  <SwitchToggleSet
                    isChecked={toggleState || false}
                    onChange={handleToggleChange}
                    name="lockSurveyToggleButton"
                    text={{
                      txtOn: 'Lock Survey',
                      txtOff: 'Unlock Survey',
                      ariaLabel: 'lock survey toggle button',
                    }}
                  />
                </Box>
              </Box>
            </div>
          )}

        <Box className={classes.user}>
          {accessToken ? (
            <Box className={classes.logoutbtn} onClick={() => logoutfn()}>
              Logout
            </Box>
          ) : null}
          {accessToken && user && (user.firstName || user.lastName) ? (
            <Avatar
              component="img"
              className={classes.profilePic}
              size={40}
              name={`${user.firstName ? user.firstName : ''} ${
                user.lastName ? user.lastName : ''
              }`}
              round={true}
              maxInitials={2}
              textSizeRatio={2.5}
              color={'#999999'}
            />
          ) : (
            <Box
              component="img"
              className={classes.profilePic}
              src={profileImg}
              alt="profile"
              width={36}
              height={36}
            />
          )}
        </Box>
        {errorAlert()}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  isSurveyAdminLocked: state
    .get('surveyname')
    .get('items')
    .get('isAdminLock'),
  lockMessage: state
    .get('surveyname')
    .get('items')
    .get('LockMessage'),
  userDetails: state.get('userManagementReducer').data,
})

const mapDispatchToProps = {
  unlockUserLockedSurvey,
  adminLockUnLockSurvey,
  resetErrorState,
  getUserDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyHeader)
