import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { surveysStatusFetch } from '../ManageSurveys/actions'
import { surveynameFetch, surveynameUpdate } from '../ClientInfo/actions'
import {
  SurveyHeader,
  SurveyProgress,
  SurveySidebar,
  RightPanelTextEditor,
} from '../../components'
import {
  additionalQuestionsFetch,
  fetchIntroDataForLang,
  updateSurvey,
  replaceEverywhere,
} from './actions'
import {
  fetchSidePanelData,
  setSelectedLang,
  introductionFetch,
  setHasEditorStateChanged,
  fetchSummaryOfEdits,
  importFile,
  exportFile,
  toggleImportFileFailureContainer,
} from '../SurveyIntroduction/actions'
import {
  confirmationPopupToggle,
  toggleToastOnSuccess,
} from '../../common/actions'
import useStyles from './styles'
import { connect } from 'react-redux'
import IntroductionBottom from '../SurveyIntroduction/components/IntroductionBottom'
import pathOr from 'lodash/fp/pathOr'
import PopupModal from '../../components/PopupModal'
import ConfirmationPopup from '../../components/ConfirmationPopup'
import SummaryOfEdits from '../../components/SummaryOfEdits'
import { survey_Status_For_Disabling_Post_Req } from '../../utils/constants'
import { useToasts } from 'react-toast-notifications'
import { isTextUpdateEnabled } from '../../common/arrayFunctions'

function SurveyAdditionalQuestions({
  match,
  history,
  statuscode,
  surveynameFetch,
  surveynameUpdate,
  surveyname,
  surveynameupdateStatus,
  surveyStatus,
  surveysStatusFetch,
  additionalQuestionsFetch,
  additionalQuestionTexts,
  sidePanelData,
  getSidePanelData,
  isMultiLanguage,
  availableLangauge,
  multipleLanguageData,
  fetchIntroDataForLang,
  selectedLanguageId,
  setSelectedLang,
  baseLanguageName,
  introductionFetch,
  originalApiData,
  updateSurvey,
  numberOfBlocksElement,
  setHasEditorStateChanged,
  hasEditorStateChanged,
  confirmationPopupToggle,
  confirmationPopupIsOpened,
  summaryOfEditsIsOpened,
  fetchSummaryOfEdits,
  importFile,
  exportFile,
  baseLanguageId,
  replaceEverywhere,
  importFileFailureData,
  toggleImportFileFailureContainer,
  importFailureContainerIsOpened,
  successText,
  openToast,
  toggleToastOnSuccess,
  ohiTestModuleData,
  designsurveyget,
  designupdateStatus,
}) {
  document.title = 'OHI Portal - Text Update'
  const { addToast } = useToasts()
  const surveyStatusReturned = surveyname.get('items').get('status')
  const isSurveyUserLocked = surveyname.get('items').get('isUserLock')
  const isSurveyAdminLocked = surveyname.get('items').get('isAdminLock')
  const isButtonToBeDisabled =
    survey_Status_For_Disabling_Post_Req.indexOf(surveyStatusReturned) !== -1 ||
    isSurveyAdminLocked ||
    isSurveyUserLocked
  const viewSurveyURL = surveyname.get('items').get('surveyUrl')
  const surveyType = surveyname.get('items').get('surveyType')
  const sendHelpDeskEmailInitial = surveyname
    .get('items')
    .get('sendHelpDeskEmailInitial')

  const classes = useStyles()

  const [isDisabled, setIsdisable] = useState()
  const [editorState, seEditorState] = useState()

  const surveyId = pathOr('', 'params.id', match)
  const [
    hasUserSelcetdMultiLangOption,
    setHasUserSelcetdMultiLangOption,
  ] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [popupText, setPopupText] = useState('')
  const [popupAppearance, setPopupAppearance] = useState('success')

  function getpidfn(pid) {
    //console.log(pid)
    if (pid === '') {
      setIsdisable(true)
    } else {
      setIsdisable(false)
    }
  }

  function setEditorState(editorState) {
    seEditorState(editorState)
  }

  useEffect(() => {
    setHasEditorStateChanged(false)
    introductionFetch(surveyId)
    additionalQuestionsFetch(surveyId)
    getSidePanelData(surveyId)
  }, [])

  useEffect(() => {
    if (openToast || showPopup) {
      const text = successText !== '' ? successText : popupText
      addToast(text, {
        appearance: popupAppearance,
        autoDismiss: true,
      })
      toggleToastOnSuccess({ successText: '', openToast: false })
      setShowPopup(false)
      setPopupText('')
      setPopupAppearance('success')
    }
  }, [openToast, showPopup])

  useEffect(() => {
    if (!hasUserSelcetdMultiLangOption) {
      introductionFetch(surveyId)
    }
  }, [hasUserSelcetdMultiLangOption])

  const toggleShowPopup = (text, appearance) => {
    setShowPopup(true)
    setPopupText(text)
    setPopupAppearance(appearance)
  }

  function textEnabled() {
    const flag = isTextUpdateEnabled(
      sendHelpDeskEmailInitial,
      designupdateStatus,
      surveyType,
      designsurveyget,
      ohiTestModuleData
    )
    return flag
  }

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      {confirmationPopupIsOpened && (
        <PopupModal modalWidth="30vw">
          <ConfirmationPopup
            updateSurvey={updateSurvey}
            editorState={editorState}
            surveyId={surveyId}
            selectedLanguageId={selectedLanguageId}
            hasUserSelcetdMultiLangOption={hasUserSelcetdMultiLangOption}
          />
        </PopupModal>
      )}
      {summaryOfEditsIsOpened && (
        <PopupModal fixedHeight modalHeight="90vh" modalWidth="77vw">
          <SummaryOfEdits
            updateSurvey={updateSurvey}
            selectedLanguageId={selectedLanguageId}
            editorState={editorState}
          />
        </PopupModal>
      )}
      <SurveyHeader
        history={history}
        statuscode={statuscode}
        surveyId={match.params.id}
      />
      <SurveyProgress
        onSurveynameFetch={surveynameFetch}
        onSurveynameUpdate={surveynameUpdate}
        surveyname={surveyname}
        params={match.params.id}
        surveynameupdateStatus={surveynameupdateStatus}
        statusList={surveyStatus}
        surveysStatusFetch={surveysStatusFetch}
        ongetpidfn={getpidfn}
        history={history}
        isDisabled={isDisabled}
        hasEditorStateChanged={hasEditorStateChanged}
        confirmationPopupToggle={confirmationPopupToggle}
        isButtonToBeDisabled={isButtonToBeDisabled}
        textUpdate={textEnabled}
      />
      <Box display="flex" flex={1} className={classes.main}>
        <Box
          display="flex"
          flex={1}
          className={`${classes.sidebar} surveySideBar`}
        >
          <SurveySidebar
            params={match.params}
            isDisabled={isDisabled}
            isTextUpdateComponent={true}
            sidePanelData={sidePanelData}
            hasEditorStateChanged={hasEditorStateChanged}
            confirmationPopupToggle={confirmationPopupToggle}
          />
        </Box>
        <Box
          display="flex"
          flex={5}
          flexDirection="column"
          className={`${classes.formWrapper} scrollbar-class`}
          id="editableText"
        >
          <RightPanelTextEditor
            additionalQuestionsFetch={additionalQuestionsFetch}
            richTextContent={additionalQuestionTexts}
            customClassName={'addtional-question-editor'}
            isMultiLanguage={isMultiLanguage}
            availableLangauge={availableLangauge}
            multipleLanguageData={multipleLanguageData}
            fetchIntroDataForAdditionalQuestions={fetchIntroDataForLang}
            surveyId={surveyId}
            selectedLanguageId={selectedLanguageId}
            setSelectedLang={setSelectedLang}
            baseLanguageName={baseLanguageName}
            originalApiData={originalApiData}
            updateSurvey={updateSurvey}
            setHasEditorStateChanged={setHasEditorStateChanged}
            numberOfBlocksElement={numberOfBlocksElement}
            setEditorState={setEditorState}
            hasEditorStateChanged={hasEditorStateChanged}
            confirmationPopupToggle={confirmationPopupToggle}
            setHasUserSelcetdMultiLangOption={setHasUserSelcetdMultiLangOption}
            fetchSummaryOfEdits={fetchSummaryOfEdits}
            importFile={importFile}
            exportFile={exportFile}
            baseLanguageId={baseLanguageId}
            isButtonToBeDisabled={isButtonToBeDisabled}
            viewSurveyURL={viewSurveyURL}
            replaceEverywhere={replaceEverywhere}
            importFileFailureData={importFileFailureData}
            toggleImportFileFailureContainer={toggleImportFileFailureContainer}
            importFailureContainerIsOpened={importFailureContainerIsOpened}
            hasUserSelcetdMultiLangOption={hasUserSelcetdMultiLangOption}
            editorState={editorState}
            showPopup={toggleShowPopup}
            match={match}
          />
          <IntroductionBottom
            history={history}
            match={match}
            updateSurvey={updateSurvey}
            editorState={editorState}
            surveyId={surveyId}
            selectedLanguageId={selectedLanguageId}
            hasEditorStateChanged={hasEditorStateChanged}
            confirmationPopupToggle={confirmationPopupToggle}
            hasUserSelcetdMultiLangOption={hasUserSelcetdMultiLangOption}
            fetchSummaryOfEdits={fetchSummaryOfEdits}
            sidePanelData={sidePanelData}
            isButtonToBeDisabled={isButtonToBeDisabled}
            setHasEditorStateChanged={setHasEditorStateChanged}
          />
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => {
  return {
    surveyname: state.get('surveyname'),
    surveynameupdateStatus: state.get('surveynameupdateStatus'),
    surveyStatus: state.get('surveyStatus'),
    additionalQuestionTexts: state.get('surveyAdditionalQuestionsFetch')
      .additionalQuestionTexts,
    sidePanelData: state.get('sureveyIntroductionFetch').sidePanelData,
    isMultiLanguage: state.get('sureveyIntroductionFetch').introductionTexts
      .isMultipleLanguage,
    availableLangauge: state.get('sureveyIntroductionFetch').introductionTexts
      .availableLanguages,
    multipleLanguageData: state.get('surveyAdditionalQuestionsFetch')
      .multipleLanguageData,
    selectedLanguageId: state.get('sureveyIntroductionFetch').languageId,
    baseLanguageName: state.get('sureveyIntroductionFetch').introductionTexts
      .languageName,
    originalApiData: state.get('surveyAdditionalQuestionsFetch')
      .originalApiData,
    numberOfBlocksElement: state.get('surveyAdditionalQuestionsFetch')
      .numberOfBlocksElement,
    hasEditorStateChanged: state.get('sureveyIntroductionFetch')
      .hasEditorStateChanged,
    confirmationPopupIsOpened: state.get('confirmationPop')[
      'confirmationPopupIsOpened'
    ],
    summaryOfEditsIsOpened: state.get('summaryOfEdits')[
      'summaryOfEditsIsOpened'
    ],
    baseLanguageId: state.get('sureveyIntroductionFetch').introductionTexts
      .languageId,
    statuscode: state.get('statuscode'),
    importFileFailureData: state.get('sureveyIntroductionFetch')
      .importFileFailureData,
    importFailureContainerIsOpened: state.get('sureveyIntroductionFetch')
      .importFailureContainerIsOpened,
    successText: state.get('successToast')['successText'],
    openToast: state.get('successToast')['openToast'],
    designsurveyget: state.get('designsurveyget'),
    designupdateStatus: state.get('designupdateStatus'),
    ohiTestModuleData: state.get('ohiTestModuleReducer'),
  }
}

const mapDispatchToProps = {
  surveynameFetch,
  surveynameUpdate,
  surveysStatusFetch,
  additionalQuestionsFetch,
  getSidePanelData: fetchSidePanelData,
  fetchIntroDataForLang,
  setSelectedLang,
  introductionFetch,
  updateSurvey,
  setHasEditorStateChanged,
  confirmationPopupToggle,
  fetchSummaryOfEdits,
  importFile,
  exportFile,
  replaceEverywhere,
  toggleImportFileFailureContainer,
  toggleToastOnSuccess,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyAdditionalQuestions)
