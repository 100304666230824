import { combineReducers } from 'redux-immutable'

import loader from './loader'
import statuscode from './statuscode'

import {
  reducer as surveys,
  statusReducer as surveyStatus,
  surveycreatereducer as surveycreateres,
} from '../pages/ManageSurveys'

import {
  reducer as languages,
  analystsreducer as analysts,
  countriesreducer as countries,
  regionsreducer as regions,
  populationsreducer as populations,
  surveytypesreducer as surveytypes,
  resurveyreducer as resurvey,
  surveyinforeducer as surveyinfo,
  savesurveyinforeducer as savesurveyStatus,
  cohesionDemoReducer as cohesionDemo,
  OhiVersionreducer as OhiVersionList,
  ClosingProtocolLanguagesReducer as closingProtocolLang,
} from '../pages/SurveyInfo'
import {
  reducer as clients,
  surveyReducer as clientSurvey,
  clientinforeducer as clientinfo,
  saveclientinforeducer as clientinfoStatus,
  surveynamereducer as surveyname,
  surveynameupdatereducer as surveynameupdateStatus,
  createnewclienteducer as createclientstaus,
  getopportunityReducer as opportunityDetails,
} from '../pages/ClientInfo'
import {
  reducer as designsurveyget,
  designupdatereducer as designupdateStatus,
  savequestionreducer as questionpoststatus,
  outcomescalereducer as outcomescales,
  practicecalereducer as practicescales,
  questionlistreducer as questionlist,
  updatequestionlistereducer as updatequestionlistStatus,
  updatequestionereducer as updatequestionStatus,
  influencerreducer as influencer,
  updateinfluencerreducer as influencerStatus,
  valuesreducer as valuesdata,
  updatevaluesreducer as valuesdataStatus,
  inclusionreducer as inclusiondata,
  updateInclusionsreducer as inlusiondataStaus,
  ohiTestModuleReducer,
  ohiUpdateTestModuleReducer as ohiUpdateStatus,
  ohi4ModuleReducer as ohi4ModuleData,
  updateohi4Modulereducer as updateohi4ModuleStatus,
} from '../pages/DesignSurvey'
import { summaryreducer as summaryinfo } from '../pages/Summary'

import sureveyIntroductionFetch from '../pages/SurveyIntroduction/reducer'
import ohiOutcomeDataFetch from '../pages/SurveyOHI/reducer'
import surveyAdditionalQuestionsFetch from '../pages/SurveyAdditionalQuestions/reducer'
import deepDiveReducer from '../pages/DeepDive/reducer'
import summaryOfEdits from './summaryOfEdits'
import confirmationPop from './confirmationPop'
import progressBarLoader from './progressBarLoader'
import revertToLastUpdate from '../pages/RevertToLastUpdate/reducers'
import successToast from './successToast'
import GSDBReducer from './GSDB'
import userManagementReducer from './userManagement'
import demographicsReducer from '../pages/Demographics/redux/reducer'
import emailreducer from '../pages/TestEmail/reducer'
import { GetFilterReducer, SurveyDataReducer } from './surveyDashboard'
import { UploadBackcodingReducer as backcoding } from './backcodingReducer'
import {
  DownloadMappingFileReducer as downloadMappingReducer,
  GenerateLogsReducer,
  TaskLogsReducer,
} from './DownloadMapping'

const rootReducer = combineReducers({
  loader,
  surveys,
  surveyStatus,
  languages,
  analysts,
  clients,
  countries,
  regions,
  populations,
  surveytypes,
  resurvey,
  surveyinfo,
  clientSurvey,
  savesurveyStatus,
  surveycreateres,
  clientinfo,
  clientinfoStatus,
  designsurveyget,
  surveyname,
  surveynameupdateStatus,
  designupdateStatus,
  summaryinfo,
  questionpoststatus,
  outcomescales,
  practicescales,
  questionlist,
  updatequestionlistStatus,
  updatequestionStatus,
  influencer,
  influencerStatus,
  valuesdata,
  valuesdataStatus,
  inclusiondata,
  inlusiondataStaus,
  createclientstaus,
  statuscode,
  opportunityDetails,
  sureveyIntroductionFetch,
  ohiOutcomeDataFetch,
  surveyAdditionalQuestionsFetch,
  deepDive: deepDiveReducer,
  summaryOfEdits,
  confirmationPop,
  progressBarLoader,
  revertToLastUpdate,
  successToast,
  GSDBReducer,
  demographicsReducer,
  userManagementReducer,
  emailreducer,
  ohiTestModuleReducer,
  ohiUpdateStatus,
  GetFilterReducer,
  SurveyDataReducer,
  cohesionDemo,
  backcoding,
  downloadMappingReducer,
  GenerateLogsReducer,
  TaskLogsReducer,
  OhiVersionList,
  ohi4ModuleData,
  updateohi4ModuleStatus,
  closingProtocolLang,
})

export default rootReducer
