import React from 'react'
import PopupModal from '../PopupModal'
import { Box, Button } from '@material-ui/core'
import closeModalIcon from '../../pages/ClientInfo/assets/Cancel_Icon.png'

const ImportFailureContainer = ({
  importFileFailureData,
  toggleImportFileFailureContainer,
  errorStatus,
  importFailureContinue,
}) => {
  const closeImportFailureContainer = () => {
    toggleImportFileFailureContainer()
  }
  return (
    <PopupModal>
      <Box className="import_Failure_Container">
        <Box
          onClick={closeImportFailureContainer}
          className={'closeModal'}
          component="img"
          src={closeModalIcon}
        />
        {importFileFailureData?.message ||
          'It is taking more time than expected. Request you to please check if your changes have been made else please refresh your page and try again.'}
        <ul>
          {importFileFailureData ? (
            importFileFailureData?.errors?.map((item, index) => (
              <li key={index}>{item}</li>
            ))
          ) : (
            <li>{``}</li>
          )}
        </ul>
        {errorStatus === 409 && (
          <div className="inner_div_container">
            <Button
              variant="outlined"
              color="primary"
              className="round_button"
              onClick={closeImportFailureContainer}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="round_button round_button_container"
              onClick={importFailureContinue}
            >
              CONTINUE
            </Button>
          </div>
        )}
      </Box>
    </PopupModal>
  )
}

export default ImportFailureContainer
