import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import { SwitchToggleSet } from '../../../components'
import useStyles from './styles'
import PropTypes from 'prop-types'

function LanguageToggleButton({
  toggle,
  onToggle,
  isMultiLanguage,
  baseLanguageName,
  hasEditorStateChanged,
  confirmationPopupToggle,
}) {
  const classes = useStyles()

  function handleCheckboxChange() {
    return function() {
      if (hasEditorStateChanged) {
        confirmationPopupToggle(onToggle, toggle)
      } else onToggle(toggle)
    }
  }
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      alignItems="flex-start"
      className={clsx(
        classes.inputSet,
        !isMultiLanguage ? classes.displayNone : classes.displayBlock
      )}
    >
      <Box className={classes.toggleText}>
        <SwitchToggleSet
          isChecked={toggle}
          onChange={handleCheckboxChange}
          name="languageToggleButton"
          text={{
            txtOn: 'Multi Language',
            txtOff: `Base Language (${baseLanguageName})`,
            ariaLabel: 'language toggle button',
          }}
        />
      </Box>
    </Box>
  )
}

LanguageToggleButton.propTypes = {
  toggle: PropTypes.bool,
  onToggle: PropTypes.func,
  isMultiLanguage: PropTypes.bool,
  baseLanguageName: PropTypes.string,
  hasEditorStateChanged: PropTypes.bool,
  confirmationPopupToggle: PropTypes.func,
}

export default LanguageToggleButton
