import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => {
  return {
    root: {
      minHeight: '100vh',
    },
    main: {
      position: 'absolute',
      top: '17.3vh',
      width: '100%',
    },
    sidebar: {},
    formWrapper: {},
    textFormatter: {
      backgroundColor: '#f5f4f2',
    },
  }
})

export default styles
