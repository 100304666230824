import {
  ADDITIONAL_QUESTIONS_FETCH,
  FETCH_ADDITONAL_QUESTIONS_DATA_LANG_SUCCESS,
  FETCH_ADDITONAL_QUESTIONS_DATA_LANG_ERROR,
  FETCH_ADDITONAL_QUESTIONS_DATA_LANG,
  SET_SELECTED_LANG,
} from './constants'
import { transformData } from './utils'

const INITIAL_STATE = {
  additionalQuestionTexts: '',
  initialStateIntroTexts: '',
  occurenceIndices: [],
  findText: '',
  currentPositionToReplace: -1,
  originalApiData: null,
  multipleLanguageData: [],
  multipleLangDataError: false,
  languageId: -1,
  numberOfBlocksElement: 0,
}

const surveyAdditionalQuestionsFetch = (
  state = INITIAL_STATE,
  { type, data, languageId, outcomeId }
) => {
  switch (type) {
    case `${ADDITIONAL_QUESTIONS_FETCH}_PENDING`: {
      return state
    }

    case `${ADDITIONAL_QUESTIONS_FETCH}_SUCCESS`: {
      const updatedData = transformData(data)
      return {
        ...state,
        additionalQuestionTexts: updatedData.data,
        originalApiData: data,
        numberOfBlocksElement: updatedData.numberOfBlocksElement,
      }
    }

    case `${ADDITIONAL_QUESTIONS_FETCH}_FAILED`: {
      return state
    }

    case FETCH_ADDITONAL_QUESTIONS_DATA_LANG_SUCCESS: {
      const multLangData = JSON.parse(
        JSON.stringify(state.multipleLanguageData)
      )

      const sameLanguageIdFound = multLangData.find(
        data => data.languageId === languageId
      )
      if (sameLanguageIdFound) {
        multLangData.forEach(element => {
          if (element.languageId === languageId) {
            element.value = data
          }
        })
      } else {
        multLangData.push({ languageId: languageId, value: data })
      }

      const newData = JSON.parse(JSON.stringify(multLangData))
      const newState = {
        ...state,
        multipleLanguageData: newData,
        originalApiData: data,
      }
      return Object.assign({}, newState)
    }

    case FETCH_ADDITONAL_QUESTIONS_DATA_LANG_ERROR: {
      return { ...state, multipleLangDataError: true }
    }

    case FETCH_ADDITONAL_QUESTIONS_DATA_LANG: {
      return { ...state, multipleLangDataError: false }
    }

    case SET_SELECTED_LANG: {
      return { ...state, languageId: languageId }
    }

    default:
      return state
  }
}
export default surveyAdditionalQuestionsFetch
