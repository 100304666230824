import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function SelectIcon(props) {
  return (
    <SvgIcon className={`${props.className}`} {...props} viewBox="0 0 129 129">
      <g fill="none" stroke="black" fillRule="evenodd">
        <path
          strokeWidth="4"
          d="m89.27821,51.49121c-0.69729,-0.69729 -1.83038,-0.69729 -2.52767,0l-22.22608,22.26966l-22.26966,-22.26966c-0.69729,-0.69729 -1.83038,-0.69729 -2.52767,0c-0.69729,0.69729 -0.69729,1.83038 0,2.52767l23.48992,23.48992c0.34864,0.34864 0.78445,0.52297 1.26384,0.52297c0.43581,0 0.91519,-0.17432 1.26384,-0.52297l23.48992,-23.48992c0.74087,-0.69729 0.74087,-1.83038 0.04358,-2.52767z"
        />
      </g>
    </SvgIcon>
  )
}
