import { fromJS } from 'immutable'
import { SUMMARYINFO_FETCH } from './constants'

// CLIENTINFO FETCH
const INITIAL_SUMMARY_STATE = fromJS({
  isLoading: false,
  isFetched: false,
  items: {},
})
const summaryreducer = (state = INITIAL_SUMMARY_STATE, { type, payload }) => {
  switch (type) {
    case `${SUMMARYINFO_FETCH}_PENDING`: {
      return state.setIn(['isFetched'], false).setIn(['isLoading'], true)
    }
    case `${SUMMARYINFO_FETCH}_SUCCESS`: {
      const {
        respData: { data },
      } = payload
      //console.log('reducer', data)
      return state
        .updateIn(['items'], items => {
          return fromJS(data)
        })
        .setIn(['isFetched'], true)
        .setIn(['isLoading'], false)
    }
    case `${SUMMARYINFO_FETCH}_FAILED`: {
      //const { data } = payload.data
      //console.error(data)
      return state.setIn(['isFetched'], true).setIn(['isLoading'], false)
    }
    default:
      return state
  }
}

export default summaryreducer
