import { makeStyles } from '@material-ui/core/styles'
import logo from './assets/logo.png'
import bg from './assets/background.png'
const useStyles = makeStyles(theme => {
  return {
    LoginCtrl: {
      display: 'flex',
      flexDirection: 'row',
    },
    body: {
      backgroundImage: `url(${bg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
      fontFamily: "'Open Sans', sans-serif",
      textAlign: 'center',
    },
    logo: {
      backgroundImage: `url(${logo})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      width: '150px',
      height: '70px',
      display: 'inline-block',
      marginBottom: 30,
    },
    ctrl: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#b6b6b6',
      '& h4': {
        lineHeight: 1.6,
      },
    },

    bg: {
      background: 'rgba(0,0,0,0.7)',
      position: 'fixed',
      width: '100%',
      height: '100%',
      left: '0',
      top: '0',
    },
    bgimg: {
      backgroundImage: `url(${bg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'fixed',
      width: '100%',
      height: '100%',
      left: '0',
      top: '0',
    },
  }
})

export default useStyles
