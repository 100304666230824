import { GET_FILTER, FETCH_SURVEYS } from '../common/constants'

const FILTER_INITIAL_STATE = {
  isLoading: false,
  isDataFetched: false,
  items: [],
}

export const GetFilterReducer = (
  state = FILTER_INITIAL_STATE,
  { type, data }
) => {
  switch (type) {
    case `${GET_FILTER}_PENDING`:
      return { ...state, isLoading: true }
    case `${GET_FILTER}_SUCCESS`:
      return { ...state, isDataFetched: true, items: data, isLoading: false }
    default:
      return state
  }
}

const SURVEY_INITIAL_STATE = {
  items: [],
  isLoading: false,
  isDataFetched: false,
}
export const SurveyDataReducer = (
  state = SURVEY_INITIAL_STATE,
  { type, data }
) => {
  switch (type) {
    case `${FETCH_SURVEYS}_PENDING`:
      return { ...state, isLoading: true }
    case `${FETCH_SURVEYS}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        items: data.items,
        isDataFetched: true,
        totalCount: data.totalCount,
      }
    case `${FETCH_SURVEYS}_FAILED`:
      return { ...state, isLoading: false, items: [], isDataFetched: true }
    default:
      return state
  }
}
