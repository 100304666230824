import React, { useState, useEffect } from 'react'
import { Box, Input } from '@material-ui/core'

import searchIcon from '../../assets/search-icon.svg'
import useStyles from './styles'

function SearchInput({ onSearch, name, readOnly, flag = false }) {
  const classes = useStyles()
  const [text, setText] = useState('')
  useEffect(() => {
    setText('')
  }, [readOnly])
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      className={classes.root}
      style={{ borderBottom: flag ? '1px solid #e7e7e7' : '' }}
    >
      <Input
        label="Search"
        placeholder="Search"
        inputProps={{
          'aria-label': 'Search',
        }}
        className={classes.inputElem}
        value={text}
        readOnly={readOnly}
        name={name}
        onChange={handleChange}
        disableUnderline
        fullWidth
      />
      <Box component="img" src={searchIcon} />
    </Box>
  )

  function handleChange(e) {
    const { value } = e.target
    setText(value)
    onSearch(e)
  }
}

export default SearchInput
